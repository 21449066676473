import {
    Icon,
    IconButton,
    Pagination,
    PaginationItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,

} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import SvgIcon from "../../../components/Images/SvgIcon";
import { } from "../../../redux/actions/product.action";
import moment from "moment";
// import { getAllCategoryApi } from "../../../services/category.service";
// import {
//     startLoader,
//     stopLoader,
//     showToast    
// } from "../../../lib/global";

import { startLoader, stopLoader, showToast, open_dialog } from "../../../lib/global";
import { ACCESS_DENIED, DELETE_CONSUMER, PRODUCT_DELETE_DIALOG } from "../../../lib/constant";
import { getLocalStorage } from "../../../lib/session";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import { getAllConsumerList } from "../../../redux/actions/consumer-center.action";

import "../../../static/css/consumerCenterCss/consumerCenterOverview.css";

const initialSearchData = { offset:0,item: "", order: "" }

function Voucher_listing(props) {
    const [consumerData, setConsumerData] = useState([])
    const dispatch = useDispatch();
    const history = useHistory()
    const [searchData, setSearchData] = useState(initialSearchData)

    // useEffect(() => {
    //     if (props.search) {
    //         response(props.search);
    //     }
    // }, [props.search]);

    console.log(props);
    const { status } = props;
    useEffect(async () => {
        const breadcrumb = [
            {label: "Sales Promotion",url : "/merchant/promotion/sales_promotion"},
            { label: "Shop Voucher", url: "/merchant/promotion/voucher-listing/voucher_listing" },
            // { label: "Consumer Account", url: null },


        ];
        dispatch(breadcrumbAction(breadcrumb));
        const data = {
            status: props.status,
            ...props.search,
            ...searchData
        }

        const response = dispatch(getAllConsumerList(data));
        startLoader()
        response
            .then((data) => {
                if (data && data.data && data.data.status === 200) {
                    setConsumerData(data && data.data && data.data.data);
                    console.log(data.data.data);
                    stopLoader()
                } else {
                    stopLoader()
                }
            })
            .catch((error) => console.log("----------error----", error));
        stopLoader()
    }, [props.search, searchData]);


    const handlePageChange = (event, value) => {
        console.log(value, 'gggggggggggggggggggggggggggggggg');
        setSearchData({...searchData,offset:value-1})

    };

    // const getApiData = (searchingData) => {
    //     console.log(searchingData);
    //     startLoader()
    //     dispatch(getAllConsumerList(searchingData))
    //         .then((data) => {
    //             console.log(data);
    //             setConsumerData(data && data.data && data.data.data);
    //             setTimeout(() => {
    //                 stopLoader()
    //             }, 1000)
    //         }).catch(error => {
    //             stopLoader()
    //             console.log({ error }, 'errorr');
    //         })
    // };

    const getConsumerDataAfterDelete = (data) => {
        console.log(data, "hhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
        setConsumerData(data)

    }

    const handleDeleteConsumer = async (id) => {
        open_dialog(DELETE_CONSUMER, {
            onSubmit: getConsumerDataAfterDelete,
            id
        })
    };

    const addConsumerAccount = () => {
        history.push('/merchant/promotion/Consumer-account/consumer-accoungt')
    }
    const ViewAndEditConsumerAccount = (consumerId) => {
        history.push(`/consumer-center/edit-consumer/${consumerId}`)

    }

    return (
        <React.Fragment>
            <div className="d-flex justify-content-between">
                <div>
                    <span>ALL CONSUMER</span>
                </div>
                <div className="d-flex mb-3">
                    <button
                        type="button"
                        onClick={() => addConsumerAccount()}
                        className="btn btn-danger text-nowrap mx-3"
                    // disabled={permmisions.product.includes('add') ? "false" : "true"}
                    >
                        + Add Consumer
                    </button>
                </div>
            </div>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                className="th_cell"
                                component="th"
                            >
                                No.
                            </TableCell>

                            <TableCell
                                className="th_cell"
                                component="th"
                                align="center"
                            >

                                <div className="d-flex  ">
                                    <div>Name</div>
                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                            onClick={() => setSearchData({...searchData, item: "consumerName", order: "ASC" })}

                                        />
                                        <ExpandMoreIcon
                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                            onClick={() => setSearchData({...searchData, item: "consumerName", order: "DESC" })}

                                        />
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell
                                className="th_cell"
                                component="th"
                                align="center"
                            >

                                <div className="d-flex  ">
                                    <div>Date Registered</div>
                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                            onClick={() => setSearchData({...searchData, item: "createdAt", order: "ASC" })}

                                        />
                                        <ExpandMoreIcon
                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                            onClick={() => setSearchData({...searchData, item: "createdAt", order: "DESC" })}

                                        />
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell
                                className="th_cell"
                                component="th"
                                align="center"
                            >

                                <div className="d-flex  ">
                                    <div>Last Active</div>
                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                            onClick={() => setSearchData({...searchData, item: "status", order: "ASC" })}

                                        />
                                        <ExpandMoreIcon
                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                            onClick={() => setSearchData({...searchData, item: "status", order: "DESC" })}

                                        />
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell
                                className="th_cell"
                                component="th"
                                align="center"
                            >

                                <div className="d-flex  ">
                                    <div>Email</div>
                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                            onClick={() => setSearchData({...searchData, item: "email", order: "ASC" })}

                                        />
                                        <ExpandMoreIcon
                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                            onClick={() => setSearchData({...searchData, item: "email", order: "DESC" })}

                                        />
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell
                                className="th_cell"
                                component="th"
                                align="center"
                            >

                                <div className="d-flex  ">
                                    <div>Contact</div>
                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                            onClick={() => setSearchData({...searchData, item: "phoneNumber", order: "ASC" })}

                                        />
                                        <ExpandMoreIcon
                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                            onClick={() => setSearchData({...searchData, item: "phoneNumber", order: "DESC" })}

                                        />
                                    </div>
                                </div>
                            </TableCell>





                            <TableCell className="table_action_head" component="th" align="center">
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!consumerData?.length && (
                            <h3 className="text-muted m-auto">No data found!</h3>
                        )}
                        {consumerData &&
                            consumerData.length > 0 &&
                            consumerData.map((row, iindex) => (
                                <TableRow
                                    key={iindex}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                    }}
                                >
                                    <TableCell
                                        align="center"
                                        className="td_cell"
                                    >
                                        {searchData.offset*10+ (iindex + 1)}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        className="td_cell"
                                    >
                                        {row.consumerName || "N/A"}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        className="td_cell"
                                    >
                                        {row.createdAt ? moment(row.createdAt).format("DD/MM/YY") : "N/A"}

                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        className="td_cell"
                                    >
                                        {row.lastActive || "N/A"}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        className="td_cell"
                                    >
                                        {row.email || "N/A"}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        className="td_cell"
                                    >
                                        {row.phoneNumber || "N/A"}
                                    </TableCell>

                                    <TableCell className="d-flex justify-content-between table_action_item">
                                        <IconButton className="pointer" onClick={() => ViewAndEditConsumerAccount(row.consumerId)} >
                                            <SvgIcon
                                                src="/icons/view_icon.svg"
                                                alt="view icon"

                                            />
                                        </IconButton>
                                        <IconButton className="pointer" onClick={() => handleDeleteConsumer(row.consumerId)}>
                                            <SvgIcon
                                                src="/icons/delete_icon.svg"
                                                alt="delete icon"
                                            />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <div className="float-right mb-1">
                <Stack spacing={2}>
                    <Pagination
                        count={Math.ceil(props.totalItem / 10)}
                        shape="rounded"
                        size="small"
                    onChange={handlePageChange}
                    />
                </Stack>
            </div>
            <footer className="bg-white p-3 ">
                    <div className="d-flex justify-content-end">
                        <button
                            // onClick={() => history.push("/marketing/all-promo")}
                            onClick={()=> history.push("/merchant/promotion/sales_promotion")}
                            type="button"
                            className="btn btn-outline-dark mx-2"
                        // onClick={back}
                        >
                            Back
                        </button>
                        

                    </div>
                </footer>
        </React.Fragment>
    )
}



export default Voucher_listing;