import Image from "../../../components/Images/Image";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import SvgIcon from "../../../components/Images/SvgIcon";
import moment from "moment";
import { startLoader, stopLoader, showToast } from "../../../lib/global";
import "../../../static/css/orderCss/allOrder.css";
import {
  getAllTeamPurchease,
  getTeamPurcheaseByStoreId,
} from "../../../redux/actions/order.action";
import { IconButton, Pagination, Stack } from "@mui/material";
import { getLocalStorage } from "../../../lib/session";
import { useParams } from "react-router-dom";
import PagenationDropdown from "../../../components/DropDown/pagenationDropdown";
import decimalFormat from "../../../helpers/decimal.format";
import ablity from "../../../helpers/abilityGuard";
import { getTeamPurcheaseByMerchantId } from "../../../redux/actions/merchant/order.action";

function TeamPurchaseRetail(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [teamPurchase, setTeamPurchase] = useState([]);
  // const [offSet, setOffSet] = useState({ offset: 0 });

  const [pageNo, setPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const limit = useRef(10);
  const [offset, setOffset] = useState(0);
  const refSearchData = useRef({});

  const viewTPRetailPage = (id) => {
    history.push(`/order/view-team-purchase-retail/${id}`);
  };
  const viewAction = (id, productId) => {
    // history.push("/orders/view-order");
    history.push(`/orders/view-order/${id}?productId=${productId || ""}`);
  };
  const param = useParams();
  const role = param.mid ? param.mid : getLocalStorage("role");
  useEffect(() => {
    startLoader();

    let filteringData;
    if (pageLimit != limit.current) {
      limit.current = pageLimit;
      props.reset();
      setPageNo(1);
      setOffset(0);
      filteringData = {
        teamStatus: props.teamStatus,
        limit: pageLimit,
        offset: 0,
      };
    } else if (refSearchData.current !== props.search) {
      setPageNo(1);
      setOffset(0);
      filteringData = {
        ...props.search,
        teamStatus: props.teamStatus,
        limit: pageLimit,
        offset: 0,
      };
      refSearchData.current = props.search;
    } else {
      filteringData = {
        ...props.search,
        teamStatus: props.teamStatus,
        offset,
        limit: pageLimit,
      };
    }
    apiData(filteringData);
  }, [props.search, offset, pageLimit]);

  const apiData = (filteringData) => {
    const storeId =
      !role == "SUPERADMIN"
        ? param.mid
          ? param.mid
          : getLocalStorage("storeId")
        : getLocalStorage("storeId");
    const respond =
      role == "SUPERADMIN"
        ? dispatch(getAllTeamPurchease(filteringData))
        : dispatch(getTeamPurcheaseByMerchantId(filteringData));
        // getTeamPurcheaseByMerchantId
    var leftDatte = (countDownDate) => {
      var now = new Date().getTime();
      var distance = new Date(countDownDate).getTime() - now;

      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      let newtime =
        days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
      return newtime;
    };
    respond
      .then((data) => {
        stopLoader();
        setTeamPurchase(
          data &&
            data.data &&
            data.data.data.map((row, index) => {
              // console.log(row, "wwwwwwwwwwwwwwwwwwwwwwwwwwwwww");
              let teamPurchase = row?.teamPurchase || row?.teamPurchases[0];
              return {
                groupLeader: teamPurchase?.teamCreator?.consumerName || "N/A",
                merchantName:
                  row?.merchant?.preferredMerchantName ||
                  row?.merchantName ||
                  "N/A",
                productImages: row?.productImages || "/images/noImage.png",
                productName: row?.productName || "N/A",
                price: row?.price || 0,
                teamStatus: teamPurchase?.teamStatus || "N/A",
                createdAt: row?.createdAt,
                teamCloseingTime: teamPurchase?.teamCloseingTime || "N/A",
                productType: row?.product?.productType || "N/A",
                orderType: row?.orderType || "N/A",
                timeLeft:
                  new Date(teamPurchase?.teamCloseingTime) - new Date() > 0
                    ? leftDatte(teamPurchase?.teamCloseingTime)
                    : "EXPIRED" || "N/A",
                teamId: row?.teamId || "N/A",
                orderId: row?.orderId || "N/A",
                productId: row?.productId || "N/A",
                status: row?.status || "N/A",
              };
            })
        );
        props.setTotals(data.data.total);
      })
      .catch((error) => {
        stopLoader();
        console.log(error);
      });
  };

  const handlePageChange = (event, value) => {
    setOffset(value - 1);
    setPageNo(value);
  };
  const handleSorting = (item, sortType, isDate = false) => {
    startLoader();
    try {
      if (typeof teamPurchase[0][item] === "number") {
        let sortedData = teamPurchase.sort((a, b) => {
          if (sortType == "asc") {
            return a[item] - b[item];
          } else if (sortType == "desc") {
            return b[item] - a[item];
          }
        });
        setTimeout(() => {
          setTeamPurchase([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof teamPurchase[0][item] === "string" && !isDate) {
        let sortedData = teamPurchase.sort((a, b) => {
          let fa = a[item].toLowerCase(),
            fb = b[item].toLowerCase();
          if (sortType == "asc") {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else if (sortType == "desc") {
            if (fa < fb) {
              return 1;
            }
            if (fa > fb) {
              return -1;
            }
            return 0;
          }
        });
        setTimeout(() => {
          setTeamPurchase([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof teamPurchase[0][item] === "string" && isDate) {
        let sortedData = teamPurchase.sort((a, b) => {
          let da = new Date(a[item]);
          let db = new Date(b[item]);

          if (sortType == "asc") {
            return da - db;
          } else if (sortType == "desc") {
            return db - da;
          }
        });
        setTimeout(() => {
          setTeamPurchase([...sortedData]);
          stopLoader();
        }, [1000]);
      }
    } catch (error) {
      console.log({ error });
      stopLoader();
    }
  };

  return (
    <React.Fragment>
      <div className="allTeamPurchaseMain">
        <div className="mt-1 py-3  rounded bg-white">
          <div className="allTeamPurchaseMain">
            <div className="tableheadTeamPurchaseProduct teamPurchase_borderLine col-5">
              <div className="d-flex  ">
                <div>TeamID</div>
                <div className="d-flex flex-column ">
                  <ExpandLessIcon
                    style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                    onClick={() => handleSorting("teamId", "asc")}
                  />
                  <ExpandMoreIcon
                    style={{
                      width: "15px",
                      height: "15px",
                      margin: "-5px 3px",
                    }}
                    onClick={() => handleSorting("teamId", "desc")}
                  />
                </div>
              </div>
            </div>
            <div className="teamPurchase_tablehead teamPurchase_borderLine">
              <div className="d-flex  ">
                <div>OrderID</div>
                <div className="d-flex flex-column ">
                  <ExpandLessIcon
                    style={{ width: "10px", height: "15px", margin: "0px 3px" }}
                    onClick={() => handleSorting("orderId", "asc")}
                  />
                  <ExpandMoreIcon
                    style={{
                      width: "10px",
                      height: "15px",
                      margin: "-5px 3px",
                    }}
                    onClick={() => handleSorting("orderId", "desc")}
                  />
                </div>
              </div>
            </div>
            <div className="teamPurchase_tablehead teamPurchase_borderLine">
              <div className="d-flex  ">
                <div>
                  {" "}
                  {props.status == "CANCELLED"
                    ? "Request Date"
                    : "Order Status"}
                </div>
                <div className="d-flex flex-column ">
                  <ExpandLessIcon
                    style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                    onClick={() => handleSorting("status", "asc")}
                  />
                  <ExpandMoreIcon
                    style={{
                      width: "15px",
                      height: "15px",
                      margin: "-5px 3px",
                    }}
                    onClick={() => handleSorting("status", "desc")}
                  />
                </div>
              </div>
            </div>
            <div className="tableheadTeamPurchaseProduct teamPurchase_borderLine col-4">
              <div className="d-flex  ">
                <div>Product</div>
                <div className="d-flex flex-column ">
                  <ExpandLessIcon
                    style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                    onClick={() => handleSorting("productName", "asc")}
                  />
                  <ExpandMoreIcon
                    style={{
                      width: "15px",
                      height: "15px",
                      margin: "-5px 3px",
                    }}
                    onClick={() => handleSorting("productName", "desc")}
                  />
                </div>
              </div>
            </div>
            <div className="teamPurchase_tablehead teamPurchase_borderLine">
              <div className="d-flex  ">
                <div> Price</div>
                <div className="d-flex flex-column ">
                  <ExpandLessIcon
                    style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                    onClick={() => handleSorting("price", "asc")}
                  />
                  <ExpandMoreIcon
                    style={{
                      width: "15px",
                      height: "15px",
                      margin: "-5px 3px",
                    }}
                    onClick={() => handleSorting("price", "desc")}
                  />
                </div>
              </div>
            </div>
            <div className="teamPurchase_tablehead teamPurchase_borderLine">
              <div className="d-flex  ">
                <div> Order Type</div>
                <div className="d-flex flex-column ">
                  <ExpandLessIcon
                    style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                    onClick={() => handleSorting("orderType", "asc")}
                  />
                  <ExpandMoreIcon
                    style={{
                      width: "15px",
                      height: "15px",
                      margin: "-5px 3px",
                    }}
                    onClick={() => handleSorting("orderType", "desc")}
                  />
                </div>
              </div>
            </div>
            <div className="teamPurchase_tablehead teamPurchase_borderLine">
              <div className="d-flex  ">
                <div>Start Date</div>
                <div className="d-flex flex-column ">
                  <ExpandLessIcon
                    style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                    onClick={() => handleSorting("createdAt", "asc")}
                  />
                  <ExpandMoreIcon
                    style={{
                      width: "15px",
                      height: "15px",
                      margin: "-5px 3px",
                    }}
                    onClick={() => handleSorting("createdAt", "desc")}
                  />
                </div>
              </div>
            </div>
            <div className="teamPurchase_tablehead teamPurchase_borderLine">
              <div className="d-flex  ">
                <div>End Date</div>
                <div className="d-flex flex-column ">
                  <ExpandLessIcon
                    style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                    onClick={() => handleSorting("productType", "asc")}
                  />
                  <ExpandMoreIcon
                    style={{
                      width: "15px",
                      height: "15px",
                      margin: "-5px 3px",
                    }}
                    onClick={() => handleSorting("productType", "desc")}
                  />
                </div>
              </div>
            </div>
            <div className="teamPurchase_tablehead teamPurchase_borderLine">
              <div className="d-flex  ">
                <div>Product Type</div>
                <div className="d-flex flex-column ">
                  <ExpandLessIcon
                    style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                    onClick={() =>
                      handleSorting(
                        props.status == "CANCELLED"
                          ? "cancelReason"
                          : "courierName",
                        "asc"
                      )
                    }
                  />
                  <ExpandMoreIcon
                    style={{
                      width: "15px",
                      height: "15px",
                      margin: "-5px 3px",
                    }}
                    onClick={() =>
                      handleSorting(
                        props.status == "CANCELLED"
                          ? "cancelReason"
                          : "courierName",
                        "desc"
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="teamPurchase_tablehead teamPurchase_borderLine">
              <div className="d-flex  ">
                <div>Time Left</div>
                <div className="d-flex flex-column ">
                  <ExpandLessIcon
                    style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                    onClick={() =>
                      handleSorting(
                        props.status == "CANCELLED"
                          ? "cancelReason"
                          : "courierName",
                        "asc"
                      )
                    }
                  />
                  <ExpandMoreIcon
                    style={{
                      width: "15px",
                      height: "15px",
                      margin: "-5px 3px",
                    }}
                    onClick={() =>
                      handleSorting(
                        props.status == "CANCELLED"
                          ? "cancelReason"
                          : "courierName",
                        "desc"
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="tableheadfix tableheadfix_allorder teamPurchase_borderLine">
              Action
            </div>
            {!teamPurchase.length ? (
              <div>No data found</div>
            ) : (
              <>
                {teamPurchase.map((item, index) => (
                  <div className="info" key={index}>
                    <div className="teamPurchase-orderId-detail-maindiv">
                      <div className="justify-content-between d-flex teamPurchase-orderId-detail-section">
                        <div className="teamPurchase_orderId_Detail_Name w-100 mr-4 text-capitalize">
                          <p>Group Leader: {item?.groupLeader || "N/A"}</p>
                        </div>
                        <div className="teamPurchase_orderId_Detail_brand ml-5 text-capitalize">
                          <p>Merchant: {item?.merchantName || "N/A"}</p>
                          <Image
                            src="/icons/chat1.png"
                            className="teamPurchase_chat_icon mb-1"
                            alt="Header logo for admin dashboard"
                          />
                        </div>
                      </div>
                      <div className="teamPurchase-orderId-detail-indexId">
                        <div className="justify-content-between d-flex teamPurchase-orderId-detail-section">
                          <div className="teamPurchase_orderId_Detail_brand mx-4">
                            Time Left: {item?.timeLeft}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="teamPurchase_tablehead_details_head">
                      <div className="teamPurchase_tablehead">
                        <div>
                          <div className="teamPurchase_Page_ProductBox_ProductName">
                            {item?.teamId || "N/A"}
                          </div>
                        </div>
                      </div>
                      <div className="teamPurchase_tablehead">
                        <div>
                          <div className="teamPurchase_Page_ProductBox_ProductName">
                            {item?.orderId || "N/A"}
                          </div>
                        </div>
                      </div>
                      <div className="teamPurchase_tablehead">
                        <div>
                          <div className="teamPurchase_Page_ProductBox_ProductName">
                            {item?.status || "N/A"}
                          </div>
                          <div className="teamPurchase_Page_ProductBox_Product_varient"></div>
                        </div>
                      </div>
                      <div
                        className="tableheadTeamPurchaseProductName"
                        onClick={() =>
                          viewAction(item?.orderId, item?.productId)
                        }
                      >
                        <div className="d-flex">
                          <Image
                            src={item?.productImages || "/images/noImage.png"}
                            alt={"/images/noImage.png"}
                            className="teamPurchase_page_product_image"
                          />
                          <div className=" mx-2">
                            <div className="teamPurchase_Page_ProductBox_ProductName">
                              {item?.productName || "N/A"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="teamPurchase_tablehead">
                        <div>
                          <div className="teamPurchase_Page_ProductBox_ProductName">
                            RM
                            {`${decimalFormat(item?.price)}`}
                          </div>
                        </div>
                      </div>
                      <div className="teamPurchase_tablehead">
                        <div>
                          <div className="teamPurchase_Page_ProductBox_ProductName">
                            {item?.orderType || "N/A"}
                          </div>
                        </div>
                      </div>
                      <div className="teamPurchase_tablehead">
                        <div>
                          <div className="teamPurchase_Page_ProductBox_ProductName">
                            {item.createdAt
                              ? moment(item.createdAt).format("DD/MM/YY HH:mm")
                              : "N/A"}
                          </div>
                        </div>
                      </div>
                      <div className="teamPurchase_tablehead">
                        <div>
                          <div className="teamPurchase_Page_ProductBox_ProductName">
                            {item.teamCloseingTime != "N/A"
                              ? moment(item.teamCloseingTime).format(
                                  "DD/MM/YY HH:mm"
                                )
                              : "N/A"}
                          </div>
                        </div>
                      </div>
                      <div className="teamPurchase_tablehead">
                        <div>
                          <div className="teamPurchase_Page_ProductBox_ProductName">
                            {item?.productType || "N/A"}
                          </div>
                          <div className="teamPurchase_Page_ProductBox_Product_varient"></div>
                        </div>
                      </div>
                      <div className="teamPurchase_tablehead">
                        <div>
                          <div className="teamPurchase_Page_ProductBox_ProductName">
                            {item?.timeLeft || "N/A"}
                          </div>
                          <div className="teamPurchase_Page_ProductBox_Product_varient"></div>
                        </div>
                      </div>
                      <div className="tableheadfix">
                        <div className="d-flex justify-content-center align-center-center ">
                          <div className="teamPurchase_page_action_btn">
                            <div>
                              {ablity({ model: "Order", action: "read" }) ==
                                1 && (
                                <IconButton
                                  className="pointer"
                                  onClick={() => viewTPRetailPage(item.teamId)}
                                >
                                  <SvgIcon
                                    src="/icons/view_icon.svg"
                                    alt="view icon"
                                  />
                                </IconButton>
                              )}

                              {ablity({ model: "Order", action: "delete" }) ==
                                1 && (
                                <IconButton
                                  className="pointer"
                                  // disabled={item.purchaseType==="TEAM"?true:false}
                                  // onClick={() => deleteAction(item.orderId, permissions?.order?.includes('delete'))}
                                  // onClick={() =>
                                  //   deleteAction(item.teamId)
                                  // }
                                >
                                  {/* {console.log((item.purchaseType==="TEAM"?"true":"false"),'ooooooooooooo')} */}
                                  <SvgIcon
                                    src="/icons/delete_icon.svg"
                                    alt="delete icon"
                                  />
                                </IconButton>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <PagenationDropdown setPageQuant={setPageLimit} />

        <div className="float-right mb-1">
          <Stack spacing={2}>
            <Pagination
              count={Math.ceil((props?.totalItem || 0) / pageLimit)}
              shape="rounded"
              size="small"
              page={pageNo}
              onChange={handlePageChange}
            />
          </Stack>
        </div>
      </div>
      <br />
    </React.Fragment>
  );
}

export default TeamPurchaseRetail;
