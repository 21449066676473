import { IconButton, Pagination, Stack } from "@mui/material";
import Image from "../../../components/Images/Image";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import SvgIcon from "../../../components/Images/SvgIcon";
import moment from "moment";
import { startLoader, stopLoader, showToast } from "../../../lib/global";
import "../../../static/css/orderCss/allOrder.css";

import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import InputField from "../../../components/formControl/input/InputField";
import { useDispatch } from "react-redux";
import {
  getAllReturnOrder,
  getOrderReturnBystoreId,
} from "../../../redux/actions/finance.action";
import PagenationDropdown from "../../../components/DropDown/pagenationDropdown";
import { getLocalStorage } from "../../../lib/session";
import ablity from "../../../helpers/abilityGuard";
import { getOrderReturnByMerchant } from "../../../redux/actions/merchant/order.action";

const Return_Refund = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [allOrderReceiptData, setAllOrderReceiptData] = useState([]);

  const [pageNo, setPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const limit = useRef(10);
  const [offset, setOffset] = useState(0);
  const refSearchData = useRef({});

  useEffect(() => {
    let filteringData;
    if (pageLimit != limit.current) {
      limit.current = pageLimit;
      props.reset();
      setPageNo(1);
      setOffset(0);
      filteringData = {
        status: props.status,
        limit: pageLimit,
        offset: 0,
      };
    } else if (refSearchData.current !== props.search) {
      setPageNo(1);
      setOffset(0);
      filteringData = {
        ...props.search,
        status: props.status,
        limit: pageLimit,
        offset: 0,
      };
      refSearchData.current = props.search;
    } else {
      filteringData = {
        ...props.search,
        status: props.status,
        offset,
        limit: pageLimit,
      };
    }
    apiData(filteringData);
  }, [offset, pageLimit, props.search]);

  const apiData = (filteringData) => {
    startLoader();
    let storeId = getLocalStorage("storeId");
    let role = getLocalStorage("role");
    const response = !(role == "MERCHANT")
      ? dispatch(getAllReturnOrder(filteringData))
      : dispatch(getOrderReturnByMerchant(filteringData));

    response
      .then((data) => {
        props.setTotals(data.data.total || {});
        // setAllOrderReceiptData(data?.data?.data);
        setAllOrderReceiptData(
          data &&
          data.data &&
          data.data.data.map((row, index) => {
            return {
              consumerName: row?.consumerName || "N/A",
              merchantName: row?.merchantName || "N/A",
              caseId: row?.caseId || "N/A",
              orderId: row?.merchantOrderId || "N/A",
              productImages: row?.productImages || "/images/noImage.png",
              productName: row?.productName || "N/A",
              variation: row?.variation || "N/A",
              price: row?.price || 0,
              quantity: row?.order?.quantity || 0,
              totalPrice: row?.price * row?.order?.quantity || 0,

              createdAt: row?.createdAt,
              updatedAt: row?.updatedAt,
              agentName: row?.agentName || "N/A",

              reason: row?.reason?.replace(/_/g, " ") || "N/A",
              status: row?.status?.replace(/_/g, " ") || "N/A",
            };
          })
        );
        setTimeout(() => {
          stopLoader();
        }, 1000);
      })
      .catch((error) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
        console.log(error);
      });
  };
  const handlePageChange = (event, value) => {
    // setSearchData({ ...searchData, offset: value - 1 });
    // refOffset(value - 1)
    setOffset(value - 1);
    setPageNo(value);
  };

  const viewAction = (id) => {
    history.push(`/orders/return-and-refund/${id}`);
  };

  const handleSorting = (item, sortType, isDate = false) => {
    startLoader();
    try {
      if (typeof allOrderReceiptData[0][item] === "number") {
        let sortedData = allOrderReceiptData.sort((a, b) => {
          if (sortType == "asc") {
            return a[item] - b[item];
          } else if (sortType == "desc") {
            return b[item] - a[item];
          }
        });
        setTimeout(() => {
          setAllOrderReceiptData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof allOrderReceiptData[0][item] === "string" && !isDate) {
        let sortedData = allOrderReceiptData.sort((a, b) => {
          let fa = a[item].toLowerCase(),
            fb = b[item].toLowerCase();
          if (sortType == "asc") {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else if (sortType == "desc") {
            if (fa < fb) {
              return 1;
            }
            if (fa > fb) {
              return -1;
            }
            return 0;
          }
        });
        setTimeout(() => {
          setAllOrderReceiptData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof allOrderReceiptData[0][item] === "string" && isDate) {
        let sortedData = allOrderReceiptData.sort((a, b) => {
          let da = new Date(a[item]);
          let db = new Date(b[item]);

          if (sortType == "asc") {
            return da - db;
          } else if (sortType == "desc") {
            return db - da;
          }
        });
        setTimeout(() => {
          setAllOrderReceiptData([...sortedData]);
          stopLoader();
        }, [1000]);
      }
    } catch (error) {
      console.log({ error });
      stopLoader();
    }
  };

  return (
    <React.Fragment>
      <div className="mt-1 py-3  rounded bg-white">
        <div className="allTeamPurchaseMain">
          <div className="tableheadTeamPurchaseProduct teamPurchase_borderLine">
            <div className="d-flex  ">
              <div>Product</div>
              <div className="d-flex flex-column ">
                <div className="d-flex flex-column ">
                  <ExpandLessIcon
                    style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                    onClick={() => handleSorting("productName", "asc")}
                  />
                  <ExpandMoreIcon
                    style={{
                      width: "15px",
                      height: "15px",
                      margin: "-5px 3px",
                    }}
                    onClick={() => handleSorting("productName", "desc")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div> Total Price</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  onClick={() => handleSorting("totalPrice", "asc")}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  onClick={() => handleSorting("totalPrice", "desc")}
                />
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div> Request Date</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  onClick={() => handleSorting("createdAt", "asc", true)}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  onClick={() => handleSorting("createdAt", "desc", true)}
                />
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div> Reason</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  onClick={() => handleSorting("reason", "asc")}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  onClick={() => handleSorting("reason", "desc")}
                />
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div> Status</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  onClick={() => handleSorting("status", "asc")}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  onClick={() => handleSorting("status", "desc")}
                />
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div> Agent Name</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  onClick={() => handleSorting("agentName", "asc")}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  onClick={() => handleSorting("agentName", "desc")}
                />
              </div>
            </div>
          </div>
          <div className="tableheadfix teamPurchase_borderLine">Action</div>
          {!allOrderReceiptData.length ? (
            <div>No data found</div>
          ) : (
            <>
              {allOrderReceiptData.map((item, index) => (
                <div className="info">
                  <div className="teamPurchase-orderId-detail-maindiv">
                    <div className="justify-content-between d-flex teamPurchase-orderId-detail-section">
                      <div className="teamPurchase_orderId_Detail_Name">
                        <p>Buyer: {item?.consumerName || "N/A"}</p>
                        <Image
                          src="/icons/chat1.png"
                          className="teamPurchase_chat_icon mb-1"
                          alt="Header logo for admin dashboard"
                        />
                      </div>
                      <div className="teamPurchase_orderId_Detail_brand mx-4">
                        <p>Merchant: {item?.merchantName || "N/A"}</p>
                        <Image
                          src="/icons/chat1.png"
                          className="teamPurchase_chat_icon mb-1"
                          alt="Header logo for admin dashboard"
                        />
                      </div>
                    </div>
                    <div className="teamPurchase-orderId-detail-indexId">
                      <div className="justify-content-between d-flex teamPurchase-orderId-detail-section">
                        <div className="teamPurchase_orderId_Detail_Name">
                          Case ID:{item?.caseId}
                        </div>
                        <div className="teamPurchase_orderId_Detail_brand mx-4">
                          Order ID: {item?.orderId}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="teamPurchase_tablehead_details_head">
                    <div
                      className="tableheadTeamPurchaseProduct"
                      onClick={() => viewAction(item?.orderId)}
                    >
                      <div className="d-flex">
                        <Image
                          src={item?.productImages || "/images/noImage.png"}
                          alt={"/images/noImage.png"}
                          className="teamPurchase_page_product_image"
                        />
                        <div className=" mx-2">
                          <div className="teamPurchase_Page_ProductBox_ProductName text-capitalized">
                            {item?.productName || "N/A"}{" "}
                          </div>
                          <div className="teamPurchase_Page_ProductBox_Product_varient text-capitalized ">
                            Variation:
                            {`${(item?.variation &&
                                item?.variation?.length > 0 &&
                                item?.variation
                                  ?.map((Item, index) => Item?.value)
                                  .toString()) ||
                              " N/A"
                              }`}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName">{`${item?.order?.currencyName || "RM"
                          }${item?.totalPrice || "00"}.00`}</div>
                        {/* <div className="teamPurchase_Page_ProductBox_Product_varient">
                          Online Banking
                        </div> */}
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName">
                          {/* {item?.createdAt.split("T")[0]} */}
                          {moment(item?.createdAt).format("YYYY/MM/DD ")}
                        </div>
                        {/* <div className="teamPurchase_Page_ProductBox_Product_varient ">
                          To avoid late shisss
                        </div> */}
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName">
                          {item?.reason || "N/A"}
                        </div>
                        <div className="teamPurchase_Page_ProductBox_Product_varient"></div>
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName">
                          {item?.status || "N/A"}
                        </div>
                        {/* <div className="teamPurchase_Page_ProductBox_Product_varient ">
                          To avoid late shisss
                        </div> */}
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName">
                          {item?.agentName || "Yet to assign"}
                        </div>
                        <div className="teamPurchase_Page_ProductBox_Product_varient"></div>
                      </div>
                    </div>
                    <div className="tableheadfix">
                      <div className="d-flex justify-content-center ">
                        {ablity({ model: "Order", action: "read" }) == 1 && (
                          <IconButton
                            className="pointer"
                            onClick={() => viewAction(item?.orderId)}
                          >
                            <SvgIcon
                              src="/icons/view_icon.svg"
                              alt="view icon"
                            />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}

          {/* <div className="d-flex align-items-center " >
                        <div className="mr-3 text-muted">Expand ( {allProductData.length-2} Products) </div>

                        <button className="btn bg-white" onClick={() => setQuatity(allProductData.length)}
                        >
                            <Image  
                                src='/images/arrow_down.png'
                                alt='photo'
                                width={16}
                                height={10}
                            />
                        </button>
                    </div> */}
        </div>
      </div>

      <br />
      <div className="d-flex justify-content-between align-items-center">
        <PagenationDropdown setPageQuant={setPageLimit} />

        <div className="float-right mb-1">
          <Stack spacing={2}>
            <Pagination
              count={Math.ceil((props?.totalItem || 0) / pageLimit)}
              shape="rounded"
              size="small"
              page={pageNo}
              onChange={handlePageChange}
            />
          </Stack>
        </div>
      </div>
      <br />
    </React.Fragment>
  );
};

export default Return_Refund;
