import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import Image from "../../../components/Images/Image";
import {
getOrderById,
updateOrderStatus,
} from "../../../redux/actions/order.action";
import { useDispatch } from "react-redux";
import {
startLoader,
stopLoader,
showToast,
UploadPDF,
} from "../../../lib/global";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import { getLocalStorage } from "../../../lib/session";
import SvgIcon from "../../../components/Images/SvgIcon";
import decimalFormat from "../../../helpers/decimal.format";
import { getRequestQuotationById, getRequestQuotationByIdMerchant, updateRequestQuotationByMerchant } from "../../../redux/actions/requestQuotation.action";
import { MAX_IMAGE_SIZE, MAX_PDF_SIZE } from "../../../lib/config";
  
function ViewOrder() {
    const [fullData, setFullData] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [pdfFile, setPdfFile] = useState();
    const changedStatus = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
  
    const { id } = useParams();
    const param = useParams();
  
    const queryParams = new URLSearchParams(window.location.search);
    const productId = queryParams.get("productId");
    const role = getLocalStorage("role");
    const storeId = role == "MERCHANT" ? getLocalStorage("storeId") : null;
    const isAdmin = getLocalStorage("role") == "SUPERADMIN";
  
    useEffect(() => {
      var breadcrumb = [];
      if (param?.merchantId && param?.merchantName) {
        breadcrumb = [
          { label: "Merchant Profile", url: "/merchant-center/profiles" },
          {
            label: param.merchantName,
            url: `/merchant-center/profiles/${param.merchantId}/${param.merchantName}`,
          },
          { label: `Request Quotation ID : ${id}`, url: null },
        ];
      } else if (param?.storeId && getLocalStorage("role") == "MERCHANT") {
        breadcrumb = [
          { label: "My Income", url: null },
          { label: `View Order`, url: null },
        ];
      } else {
        const url =
          getLocalStorage("role") == "MERCHANT"
            ? "/merchant/orders/all-orders"
            : "/orders/all-orders";
        breadcrumb = [
          { label: "Orders", url },
          { label: `Request Quotation ID : ${id}`, url: null },
        ];
      }
  
      dispatch(breadcrumbAction(breadcrumb));
      if (id) {
 
        loadData();
      }
    }, [id]);

    const loadData = () => {
        startLoader();
        const response =
          getLocalStorage("role") == "SUPERADMIN"
            ? dispatch(getRequestQuotationById(id, { productId }))
            : dispatch(getRequestQuotationByIdMerchant(id, { productId }));
        response
          .then((data) => {
            if (data && data.data && data.data.data) {
              if (
                role == "MERCHANT" &&
                data?.data?.data.storeId != storeId
              ) {
                history.push("/404");
              }
              setFullData({ ...data?.data?.data });
              setTimeout(() => {
                stopLoader();
              }, 1000);
            }
          })
          .catch((error) => {
            setTimeout(() => {
              stopLoader();
            }, 1000);
          });
    }

    const onChangeData = (e, opt, val) => {
        const key = opt || e.target.name;
        const value = val || e.target.value;
        setFullData((pre) => ({ ...pre, [key]: value }));
    };

    const handleSave = async () => {
        startLoader();
        let payload = {
            unitPrice: fullData.unitPrice,
            finalAmount: fullData?.unitPrice * (fullData?.quantity || 1),
            status: "REVIEW_QUOTATION",
            note: fullData.note
        }
        let id = fullData?.id
        delete payload.id;
        payload.uploadFile = pdfFile? await UploadPDF(pdfFile,'request-quotation') : payload?.uploadFile;
        const response = dispatch(updateRequestQuotationByMerchant(id,payload));
        response
        .then((data) => {
            if (data && data.data && data.data.data) {
                showToast("Request Quotation Updated", "success");
                loadData();
                setIsEdit(false);
            }
        })
        .catch((error) => {
            console.log({ error });
        })
        .finally(() => {
            stopLoader();
        });
    };

    const handleAccept = async () => {
        setIsEdit(true);
    }

    const handleReject = async () => {
        startLoader();
        let payload = {
            status: "MERCHANT_REJECT",
            note: fullData.note
        }
        let id = fullData?.id
        const response = dispatch(updateRequestQuotationByMerchant(id,payload));
        response
        .then((data) => {
            if (data && data.data && data.data.data) {
                showToast("Request Quotation Updated", "success");
                loadData();
                setIsEdit(false);
            }
        })
        .catch((error) => {
            console.log({ error });
        })
        .finally(() => {
            stopLoader();
        });
    }

    const allowEdit = (status) => {
      if(status == "REVIEW_QUOTATION" || status == "PENDING"){
        return true;
      }
      return false
    }

    const handleUploadFile = async (e) => {
        const fileData = e.target.files[0];
        if (fileData.size > MAX_PDF_SIZE) {
          showToast(
            `Pdf with size more than ${
              MAX_PDF_SIZE / 1000000
            }MB is not supported.\nPlease upload pdf of size smaller than ${
              MAX_PDF_SIZE / 1000000
            }MB.\nCurrent file size: ${(fileData.size / 1000000).toFixed(2)}MB`,
            "error",
            3000,
            "bottom",
            "center"
          );
          return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(fileData);
        reader.onload = function () {
          setPdfFile(reader.result);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      };
  
    return (
      <React.Fragment>
        <div className="d-flex  position-relative  mx-4 ">
          <div className="col-8 pr-3">
            <div className="py-3">
              <div className="d-flex box justify-content-between align-items-center mb-2">
                <div>REQUEST QUOTATION INFORMATION</div>
                <div className="d-flex">
                    <div
                        className="d-flex align-items-center "
                        role="button"
                        >
                        <Image src="/trans.png" alt="image" width={18} height={18} />
                        <div className="pl-2">Order Detail</div>
                    </div>
                </div>
                
              </div>
              <div className="card p-3">
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className="th_cell"
                          component="th"
                          align="left"
                        >
                          Product
                        </TableCell>
                        <TableCell className="th_cell" component="th"></TableCell>
                        <TableCell className="th_cell" component="th"></TableCell>
                        <TableCell className="th_cell" component="th"></TableCell>
                        <TableCell className="th_cell" component="th"></TableCell>
                        <TableCell
                          className="th_cell"
                          component="th"
                          align="right"
                        >
                          Price
                        </TableCell>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="right"
                        >
                          Quantity
                        </TableCell>
                        <TableCell
                          className="th_cell"
                          component="th"
                          align="right"
                        >
                          <div>Subtotal</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* {[1].map((row, index) => ( */}
                      <TableRow
                        // key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className="td_cell"
                          component="td"
                          align="left"
                          colSpan={5}
                        >
                          <div className="d-flex">
                            <Image
                              // src="/images/shoes.jpeg"
                              src={fullData?.productImages || ""}
                              className="order_page_product_image"
                              alt="img"
                            />
                            <div className="px-2 w-50">
                              <div className="capitalize">
                                { isEdit && isAdmin ? <>
                                  <input
                                    type="text"
                                    placeholder="product title"
                                    value={fullData?.productName}
                                    className="form-control"
                                    onChange={(e) => onChangeData(e,'productName', e.target.value)}
                                  />
                                </> : fullData?.productName || "N/A"}
                              </div>
                              <div className="text-muted text-capitalize">
                                {`variation: ${
                                  fullData?.variation?.[0]?.value || "N/A"
                                }`}
                              </div>
                              <div className="capitalize">
                                { isEdit && isAdmin ? <>
                                    <textarea
                                      placeholder="product description"
                                      value={fullData?.productDescription}
                                      className="form-control"
                                      onChange={(e) => onChangeData(e,'productDescription', e.target.value)}
                                    />
                                </>
                                : `Description: ${fullData?.productDescription || "N/A"}`}
                              </div>
                            </div>
                          </div>
                        </TableCell>
  
                        <TableCell
                          className="td_cell"
                          component="td"
                          align="right"
                        >
                          { isEdit ?
                            <input
                              type="text"
                              placeholder="price"
                              value={fullData?.unitPrice}
                              className="form-control float-right"
                              style={{ width: "100px" }}
                              onChange={(e) => onChangeData(e,'unitPrice', e.target.value)}
                            />
                          : `${fullData?.currencyName || "RM"}${decimalFormat(
                            fullData?.unitPrice
                          )}`}
                        </TableCell>
                        <TableCell
                          className="td_cell"
                          component="td"
                          align="right"
                        >
                          { isEdit && isAdmin ?
                            <input
                              type="text"
                              placeholder="quantity"
                              value={fullData?.quantity}
                              className="form-control float-right"
                              style={{ width: "50px" }}
                              onChange={(e) => onChangeData(e,'quantity', e.target.value)}
                            />
                        : fullData?.quantity || 0}
                        </TableCell>
                        <TableCell
                          className="td_cell"
                          component="td"
                          align="right"
                        >
                          {`${fullData?.currencyName || "RM"}${decimalFormat(
                            fullData?.unitPrice * (fullData?.quantity || 1)
                          )}`}
                        </TableCell>
                      </TableRow>
                      {/* ))} */}
                    </TableBody>
                    <TableRow
                      sx={{
                        " td, th": {
                          border: 0,
                          "padding-top": 0,
                          "padding-bottom": 0,
                          "font-size": "1rem",
                          "font-weight": "700",
                        },
                      }}
                    >
                      <TableCell
                        className="td_cell"
                        component="td"
                        align="right"
                        colSpan={8}
                      >
                        Total Price : 
                        {` ${fullData?.currencyName || "RM"}${decimalFormat(
                            fullData?.unitPrice * (fullData?.quantity || 1)
                          )}`}
                      </TableCell>
                    </TableRow>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
  
          <div className="col-4 p-0">
            <div
              className="d-flex box justify-content-between mt-3 position-sticky r-0"
              style={{ right: "0px" }}
            ></div>
            <div
              className=" position-sticky r-0  "
              style={{ top: "0px", right: "0px" }}
            >
              { !isEdit && allowEdit(fullData?.status) && (
                <div className="d-flex">
                  <button className="btn btn-success" onClick={handleAccept} >Accept</button>
                  <button className="btn btn-danger ml-2" onClick={handleReject}>Rejected</button>
                </div>
              ) }
              <div className="d-flex justify-content-between">
                <div>OTHER INFORMATION</div>
              </div>
              <div className="card p-3  mt-2">
                <div className="mb-3 " style={{ fontSize: "0.8rem" }}>
                  <div className="d-flex justify-content-between py-1">
                    <div className="text-muted">Status</div>
  
                    <div
                      className="font-weight-bold text-success"
                      style={{ fontSize: "1rem" }}
                    >
                      {fullData?.status || "N/A"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-1">
                    <div className="text-muted">Order ID</div>
                    <div className="">
                      {/* {"#" + "012201" + (fullData?.orderId?.slice(-2) || "N/A")} */}
                      {fullData?.orderId || "N/A"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-1">
                    <div className="text-muted">Uploaded Document</div>
                    <div className="">
                      {isEdit ? (
                        <input
                          type="file"
                          className="form-control"
                          accept="application/pdf"
                          onChange={(e) => handleUploadFile(e)}
                        />
                      ) : ""}
                      { fullData?.uploadFile ? (
                        <a
                          href={fullData?.uploadFile}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Open Document
                        </a>
                      ): "N/A"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-1">
                    <div className="text-muted">Customer Name</div>
                    <div className="">
                      {/* {"#" + "012201" + (fullData?.orderId?.slice(-2) || "N/A")} */}
                      {fullData?.customerName || "N/A"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-1">
                    <div className="text-muted">Remark</div>
                    <div className="">
                      { isEdit ? (
                        <textarea
                          placeholder="Remark"
                          value={fullData?.note}
                          className="form-control"
                          onChange={(e) => onChangeData(e,'note', e.target.value)}
                        />
                      ): (fullData?.note || "N/A")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <footer className="d-flex flex-wrap justify-content-end align-items-center py-3 mt-3 bg-white border-top b-0">
          <div className="col-md-4 d-flex align-items-center mr-2 justify-content-end">
            <button
              type="button"
              className="btn border input-inside-text mr-2"
              onClick={history.goBack}
            >
              Cancel
            </button>
            {isEdit && (
                <button
                    type="button"
                    className="btn border btn-primary input-inside-text mr-2"
                    onClick={handleSave}
                >
                    Save
                </button>
            )}
            {param?.storeId && getLocalStorage("role") == "MERCHANT" && (
              <button
                type="button"
                className="btn-danger btn border input-inside-text mr-2"
                // onClick={history.goBack}
                disabled
              >
                Contact Merchant
              </button>
            )}
          </div>
        </footer>
      </React.Fragment>
    );
}
  
export default ViewOrder;
  