import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import InputField from "../../../components/formControl/input/InputField";
import { showToast, startLoader, stopLoader } from "../../../lib/global";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import {
  addBrandApi,
  getBrandById,
  updateBrandById,
} from "../../../services/brand.service";

const AddBrandPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [brandName, setBrandName] = useState();
  const [description, setDescription] = useState();

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      startLoader();
      dispatch(getBrandById(id))
        .then((data) => {
          stopLoader();
          const { brandName, description } = data.data.data;
          setBrandName(brandName);
          setDescription(description);
        })
        .catch((error) => {
          stopLoader;
          console.log({ error }, "error");
        });
    }
  }, [id]);

  useEffect(() => {
    const breadcrumb = [
      { label: "Product Brand", url: "/products/brands" },
      { label: id ? "Edit Brand" : "Add Brand", url: null },
    ];
    dispatch(breadcrumbAction(breadcrumb));
  }, []);

  const addBrandFun = () => {
    startLoader();
    const data = {
      brandName,
      description,
    };
    if (id) {
      dispatch(updateBrandById(id, data))
        .then((data) => {
          console.log(data);
          if (data && data.data && data.data.status === 201) {
            setTimeout(() => {
              showToast(data.data.message);
              stopLoader();
              history.push("/products/brands");
            }, 2000);
          } else {
            showToast(data.data.message);
            stopLoader();
            history.push("/products/brands");
          }
        })
        .catch((error) => {
          console.log(error);
          stopLoader();
        });
    } else {
      const response = dispatch(addBrandApi(data));
      response
        .then((data) => {
          if (data && data.data && data.data.status === 200) {
            setTimeout(() => {
              showToast(data.data.message);
              stopLoader();
              history.push("/products/brands");
            }, 2000);
          } else {
            stopLoader();
          }
        })
        .catch((error) => {
          console.log(error);
          stopLoader();
        });
    }
  };
  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-between flex-column h-100">
        <div className="mr-3 ml-4 mt-3 rounded  px-3 pt-4 pb-2 bg-white">
          <small>BASIC INFORMATION</small>
          <form className="mt-3  p-0">
            <div className="col-12 row mb-2">
              <label className="col-3 text-right m-0 p-0 small imp">
                Brand Name
              </label>
              <InputField
                className=""
                formClass="col-9"
                type="text"
                placeholder="Enter brand name"
                value={brandName}
                onChange={(e) => setBrandName(e.target.value)}
                required={true}
              />
            </div>
            <div className="col-12 row mb-2">
              <label className="col-3 text-right m-0 p-0 small imp">
                Brand Description
              </label>
              <InputField
                className=""
                formClass="col-9"
                type="textarea"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter brand description"
              />
            </div>
          </form>
        </div>
        <footer className="d-flex flex-wrap justify-content-end align-items-center py-3 mt-3 bg-white border-top">
          <div className="col-md-4 d-flex align-items-center mr-2 justify-content-end">
            <button
              onClick={history.goBack}
              type="button"
              className="btn border input-inside-text mr-2"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-dark btn-sm mr-2"
              onClick={() => addBrandFun()}
              disabled={!brandName || !description}
            >
              {id ? "Save" : "Create"}
            </button>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default AddBrandPage;
