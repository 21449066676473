import {
    Icon,
    IconButton,
    Pagination,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,

} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SvgIcon from "../../../components/Images/SvgIcon";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import { open_dialog } from "../../../lib/global";
import { VERIFY_MERCHANT_DIALOG } from "../../../lib/constant";
import { useHistory } from "react-router-dom";
import moment from "moment/moment";
import {
  getIncomeOverviewData,
  // reportGenerate,
  // reportGenerateTaxInvoice
} from "../../../redux/actions/finance.action";
import {
  reportGenerate,
  reportGenerateTaxInvoice
} from "../../../redux/actions/merchant/finance.action";
import { getLocalStorage } from "../../../lib/session";


const initialISLdata=[
    {
       statement:"1 Jan - 31 jan 2021" ,
       date:"12/02/2021"
    },
    {
        statement:"1 Jan - 31 jan 2021" ,
        date:"12/02/2021"
     },
]

const initialMTILdata=[
    {
       statement:"1 Jan - 31 jan 2021" ,
       price:'RM30.00',
       date:"12/02/2021"
    },
    {
        statement:"1 Jan - 31 jan 2021" ,
        price:'RM30.00',
        date:"12/02/2021"
     },
]


const MyBalance = () => {
     const [incomingStatementsListing,setIncomingStatementsListing]=useState(initialISLdata)
     const [taxInvoice,setTaxInvoive]=useState(initialMTILdata)
     const [verified, setVerified] = useState(false);
     const [incomeData, setIncomeData] = useState(null);
     const [settlementReport, setSettlementReport] = useState([]);
     const [taxReport, setTaxReport] = useState([]);
     const [settleReport, setSettleReport] = useState([]);
     const history=useHistory()
     const storeId = getLocalStorage("storeId");

     
     const dispatch=useDispatch()
     useEffect(() => {
        const breadcrumb = [{ label: "My Report", url: null }];
        dispatch(breadcrumbAction(breadcrumb));
        if (!verified) {
            open_dialog(VERIFY_MERCHANT_DIALOG, {
              onSubmit: (value) => {
                setVerified(value);
              },
              dialogClick: () => {
                history.goBack() || history.push("/");
              },
            });
          }
    }, []);

     useEffect(() => {
        dispatch(getIncomeOverviewData(storeId))
          .then((data) => {
            setIncomeData(data?.data?.data);
            const dates = settlementReportList(
              data?.data?.data?.createdDate?.createdAt
            );
            setSettlementReport([...dates]);
          })
          .catch((err) => {
            console.log({ err });
          });
     }, []);
     const viewAction=()=>{}
     const settlementReportList = (startDate) => {
        var dateStart = moment(startDate);
        var dateEnd = moment(new Date());

        var timeValues = [];
        var timeTax = [];
        var timeSettle = [];
        let count = 0;

        while (dateEnd > dateStart || dateStart.format("M") === dateEnd.format("M")) {
          const date = new Date(dateStart.format("YYYY-MM"));
          var firstDay = count == 0 ? new Date(dateStart.format("YYYY-MM-DD")) : new Date(date.getFullYear(), date.getMonth(), date.getDate());

          dateStart.add(1, "month");

          var lastDay =
            dateEnd == dateStart
              ? new Date()
              : new Date(
                date.getFullYear(),
                date.getMonth() + 1,
                date.getDate() - 1
              );

          let payload = {
            name:
              moment(firstDay).format("DD MMM") +
              " - " +
              moment(lastDay).format("DD MMM YYYY"),
            startDate: moment(firstDay).format("YYYY-MM-DD"),
            endDate: moment(lastDay).format("YYYY-MM-DD"),
          };

          timeTax.push(payload);
          timeSettle.push(payload);
          timeValues.push([
            moment(firstDay).format("DD MMM"),
            moment(lastDay).format("DD MMM YYYY"),
          ]);
          count = count + 1;
    }

        timeValues = timeValues.map((item, index) => item.join(" - "));
        setTaxReport(timeTax);
       setSettleReport(timeSettle);

        return timeValues;
  };
     const handelDownloadReport = (reportType,startDate,endDate) => {
        const payload = {
          reportType,
          startDate,
          endDate,
          storeId
        };
        dispatch(reportGenerate(payload))
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${Date.now()}.xlsx`);
            // Append to html link element page
            document.body.appendChild(link);
            // Start download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
          })
          .catch((err) => {
            console.log({ err });
          });
      };
     const handelDownloadReportTaxInvoice = (startDate, endDate) => {
        const payload = {
          startDate,
          endDate,
        };

        dispatch(reportGenerateTaxInvoice(payload))
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `tax_invoice_${endDate}.pdf`);
            // Append to html link element page
            document.body.appendChild(link);
            // Start download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
          })
          .catch((err) => {
            console.log({ err });
          });

        // dispatch(reportGenerate(payload))
        //   //  const blob = await
        //   // res.blob()
        //   //  download(blob, "test.xlx");
        //
        //   .then((response) => {
        //     const url = window.URL.createObjectURL(new Blob([response.data]));
        //     const link = document.createElement("a");
        //     link.href = url;
        //     link.setAttribute("download", `${Date.now()}.xlsx`);
        //     // Append to html link element page
        //     document.body.appendChild(link);
        //     // Start download
        //     link.click();
        //     // Clean up and remove the link
        //     link.parentNode.removeChild(link);
        //   })
        //   .catch((err) => {
        //     console.log({ err });
        //   });
  };

    return (
        <>
        {/* <div className='card mx-4 p-3 '>*/}
        {/*    <span className='mb-3'>INCOME STATEMENTS LISTING</span>*/}
        {/*    <div className='border card '>*/}
        {/*        <span className='m-3'>ALL STATEMENT</span>*/}
        {/*        <TableContainer>*/}
        {/*            <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">*/}
        {/*                <TableHead>*/}
        {/*                    <TableRow>*/}
        {/*                        <TableCell*/}
        {/*                            className="th_cell"*/}
        {/*                            component="th"*/}
        {/*                        >*/}
        {/*                            Statements*/}
        {/*                        </TableCell>*/}
        {/*                        <TableCell*/}
        {/*                            className="th_cell"*/}
        {/*                            component="th"*/}
        {/*                            align="center"*/}
        {/*                        >*/}
        {/*                            <div className="d-flex  ">*/}
        {/*                                <div>Date</div>*/}
        {/*                                <div className="d-flex flex-column ">*/}
        {/*                                    <ExpandLessIcon*/}
        {/*                                        style={{ width: '15px', height: '15px', margin: "0px 3px" }}*/}
        {/*                                        // onClick={() => setSearchData({ ...searchData, item: "title", order: "ASC" })}*/}
        {/*                                    />*/}
        {/*                                    <ExpandMoreIcon*/}
        {/*                                        style={{ width: '15px', height: '15px', margin: "-5px 3px" }}*/}
        {/*                                        // onClick={() => setSearchData({ ...searchData, item: "title", order: "DESC" })}*/}
        {/*                                    />*/}
        {/*                                </div>*/}
        {/*                            </div>*/}
        {/*                        </TableCell>*/}
        {/*                        <TableCell*/}
        {/*                            className="table_action_head"*/}
        {/*                            component="th"*/}
        {/*                            align="center"*/}
        {/*                        >*/}
        {/*                            Action*/}
        {/*                        </TableCell>*/}
        {/*                    </TableRow>*/}
        {/*                </TableHead>*/}
        {/*                <TableBody>*/}
        {/*                    {!incomingStatementsListing?.length && (*/}
        {/*                        <div className="d-flex justify-content-center">*/}
        {/*                            <h3 className="text-muted m-auto">No data found!</h3>*/}
        {/*                        </div>*/}
        {/*                    )}*/}

        {/*                    {incomingStatementsListing &&*/}
        {/*                        incomingStatementsListing.length > 0 &&*/}
        {/*                        incomingStatementsListing.map((row, index) => (*/}
        {/*                            <TableRow*/}
        {/*                                key={index }*/}
        {/*                                sx={{*/}
        {/*                                    "&:last-child td, &:last-child th": { border: 0 },*/}
        {/*                                }}*/}

        {/*                            >*/}
        {/*                                <TableCell  className="td_cell">*/}
        {/*                                    {row?.statement}*/}
        {/*                                </TableCell>*/}
        {/*                                <TableCell className="td_cell">*/}
        {/*                                    {row?.date || "0"}*/}
        {/*                                </TableCell>*/}
        {/*                                <TableCell className="d-flex justify-content-center table_action_item">*/}
        {/*                                    <IconButton*/}
        {/*                                        className="pointer"*/}
        {/*                                        onClick={() => viewAction(row?.productId)}*/}
        {/*                                    >*/}
        {/*                                        <SvgIcon*/}
        {/*                                            src="/icons/download.png"*/}
        {/*                                            alt="view icon"*/}
        {/*                                        />*/}
        {/*                                    </IconButton>*/}
        {/*                                 */}
        {/*                                 */}
        {/*                                </TableCell>*/}
        {/*                            </TableRow>*/}
        {/*                        ))}*/}
        {/*                </TableBody>*/}
        {/*            </Table>*/}
        {/*        </TableContainer>*/}
        {/*    </div>*/}

        {/*</div>*/}
        {/*<br/>*/}
        {/*<div className='card mx-4 p-3 '>*/}
        {/*    <span className='mb-3'>MY TAX INVOICES LISTING</span>*/}
        {/*    <div className='border card '>*/}
        {/*        <span className='m-3'>ALL STATEMENT</span>*/}
        {/*        <TableContainer>*/}
        {/*            <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">*/}
        {/*                <TableHead>*/}
        {/*                    <TableRow>*/}
        {/*                        <TableCell*/}
        {/*                            className="th_cell"*/}
        {/*                            component="th"*/}
        {/*                        >*/}
        {/*                            Statements*/}
        {/*                        </TableCell>*/}
        {/*                        <TableCell*/}
        {/*                            className="th_cell"*/}
        {/*                            component="th"*/}
        {/*                            */}
        {/*                        >*/}
        {/*                            Price Released*/}
        {/*                        </TableCell>*/}
        {/*                        <TableCell*/}
        {/*                            className="th_cell"*/}
        {/*                            component="th"*/}
        {/*                            align="center"*/}
        {/*                        >*/}
        {/*                            <div className="d-flex  ">*/}
        {/*                                <div>Date</div>*/}
        {/*                                <div className="d-flex flex-column ">*/}
        {/*                                    <ExpandLessIcon*/}
        {/*                                        style={{ width: '15px', height: '15px', margin: "0px 3px" }}*/}
        {/*                                        // onClick={() => setSearchData({ ...searchData, item: "title", order: "ASC" })}*/}
        {/*                                    />*/}
        {/*                                    <ExpandMoreIcon*/}
        {/*                                        style={{ width: '15px', height: '15px', margin: "-5px 3px" }}*/}
        {/*                                        // onClick={() => setSearchData({ ...searchData, item: "title", order: "DESC" })}*/}
        {/*                                    />*/}
        {/*                                </div>*/}
        {/*                            </div>*/}
        {/*                        </TableCell>*/}
        {/*                        <TableCell*/}
        {/*                            className="table_action_head"*/}
        {/*                            component="th"*/}
        {/*                            align="center"*/}
        {/*                        >*/}
        {/*                            Action*/}
        {/*                        </TableCell>*/}
        {/*                    </TableRow>*/}
        {/*                </TableHead>*/}
        {/*                <TableBody>*/}
        {/*                    {!taxInvoice    ?.length && (*/}
        {/*                        <div className="d-flex justify-content-center">*/}
        {/*                            <h3 className="text-muted m-auto">No data found!</h3>*/}
        {/*                        </div>*/}
        {/*                    )}*/}

        {/*                    {taxInvoice  &&*/}
        {/*                        taxInvoice  .length > 0 &&*/}
        {/*                        taxInvoice  .map((row, index) => (*/}
        {/*                            <TableRow*/}
        {/*                                key={index}*/}
        {/*                                sx={{*/}
        {/*                                    "&:last-child td, &:last-child th": { border: 0 },*/}
        {/*                                }}*/}

        {/*                            >*/}
        {/*                                <TableCell  className="td_cell">*/}
        {/*                                    {row?.statement}*/}
        {/*                                </TableCell>*/}
        {/*                                <TableCell className="td_cell">*/}
        {/*                                    {row?.price || "0"}*/}
        {/*                                </TableCell>*/}
        {/*                                <TableCell className="td_cell">*/}
        {/*                                    {row?.date || "0"}*/}
        {/*                                </TableCell>*/}
        {/*                                <TableCell className="d-flex justify-content-center table_action_item">*/}
        {/*                                    <IconButton*/}
        {/*                                        className="pointer"*/}
        {/*                                        onClick={() => viewAction(row?.productId)}*/}
        {/*                                    >*/}
        {/*                                        <SvgIcon*/}
        {/*                                            src="/icons/download.png"*/}
        {/*                                            alt="view icon"*/}
        {/*                                        />*/}
        {/*                                    </IconButton>*/}
        {/*                                 */}
        {/*                                 */}
        {/*                                </TableCell>*/}
        {/*                            </TableRow>*/}
        {/*                        ))}*/}
        {/*                </TableBody>*/}
        {/*            </Table>*/}
        {/*        </TableContainer>*/}
        {/*    </div>*/}
        {/*</div>*/}
        <div className='card mx-4 p-3'>
          <span className='mb-3'>SETTLEMENT AND TAX INVOICE REPORT</span>
          <div className="row mr-1">
          <div className="col-md-6">
            <div className="merchant_income_statement py-3">
              <h6 className="px-3">Settlement Report </h6>
              <div className="marchant_income_statement_section px-3">
                <div>
                  <table className="w-100">
                    <tbody>
                    {settleReport?.map((item, index) => (
                      <tr className="my-3 fntSz-14" key={index + "ee"}>
                        <td className="text-secondary">{item.name}</td>
                        <td
                          style={{ cursor: "pointer" }}
                          className="text-primary float-right"
                          onClick={() =>
                            handelDownloadReport("settlementReport",item.startDate, item.endDate)
                          }
                        >
                          Download
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 pr-0">
            <div className="merchant_income_statement py-3">
              <h6 className="px-3">Tax Invoice</h6>
              <div className="marchant_income_statement_section px-3">
                <div>
                  <table className="w-100">
                    <tbody>
                    {taxReport?.map((item, index) => (
                      <tr className="my-3 fntSz-14" key={index + "ee"}>
                        <td className="text-secondary">{item.name}</td>
                        <td
                          style={{ cursor: "pointer" }}
                          className="text-primary float-right"
                          onClick={() =>
                            // handelDownloadReport("startDate", "endDate")
                            handelDownloadReportTaxInvoice(
                              item.startDate,
                              item.endDate
                            )
                          }
                        >
                          Download
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </>


    )
}

export default MyBalance;