import { IconButton, Pagination, Stack, TablePagination } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SvgIcon from "../../../components/Images/SvgIcon";
import InputField from "../../../components/formControl/input/InputField";
import { DELETE_DIALOG } from "../../../lib/constant";
import { open_dialog, startLoader, stopLoader } from "../../../lib/global";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import { getAllMerchantList } from "../../../redux/actions/merchant-center.action";
import "../../../static/css/merchantCss/MerchantAccount.css";
import PagenationDropdown from "../../../components/DropDown/pagenationDropdown";
import { ExportCSV } from "../../../components/export/exportXlsxFile";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import ablity from "../../../helpers/abilityGuard";

const initialMerchantSearchDetail = {
  storeId: "",
  merchantName: "",
  preferredMerchantName: "",
  merchantRegisteredDate: "",
};
const MerchantAccount = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [merchantData, setMerchantData] = useState([]);
  const [searchData, setSearchData] = useState(initialMerchantSearchDetail);
  const [totalCount, setTotalCount] = useState();
  const [storeId, setStoreId] = useState();
  const [merchantName, setMerchantName] = useState();
  const [preferredMerchantName, setPreferredMerchantName] = useState();
  const [merchantRegisteredDate, setMerchantRegisteredDate] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const [pageNo, setPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const limit = useRef(10);
  const [offset, setOffset] = useState(0);
  const refSearchData = useRef({});

  useEffect(() => {
    const breadcrumb = [{ label: "Merchant Account", url: null }];
    dispatch(breadcrumbAction(breadcrumb));
    let filteringdata = { ...searchData };

    if (pageLimit != limit.current) {
      limit.current = pageLimit;
      resetSearch();
      setPageNo(1);
      setOffset(0);
      filteringdata = {
        // status: props.status,
        limit: pageLimit,
        offset: 0,
      };
    } else if (refSearchData.current !== searchData) {
      setPageNo(1);
      setOffset(0);
      filteringdata = {
        ...searchData,
        // status: props.status,
        limit: pageLimit,
        offset: 0,
      };
      refSearchData.current = searchData;
    } else {
      filteringdata = {
        ...searchData,
        // status: props.status,
        offset,
        limit: pageLimit,
      };
    }

    apiData(filteringdata);
  }, [offset, pageLimit, searchData]);

  const handlePageChange = (event, value) => {
    // setSearchData({ ...searchData, offset: value - 1 });
    setOffset(value - 1);
    // refOffset(value - 1)
    setPageNo(value);
  };

  const apiData = (filteringdata) => {
    startLoader();
    setErrorMessage("Loading Data...");
    const response = dispatch(getAllMerchantList(filteringdata));
    response
      .then((data) => {
        if (data && data.data && data.data.status === 200) {
          setMerchantData(data && data.data && data.data.data);
          setTotalCount(data && data.data && data.data.totalCount);
          setErrorMessage("");
        } else {
          setErrorMessage("No Data !")
        }
        setTimeout(() => {
          stopLoader();
        }, 1500);
      })
      .catch((error) => {
        setErrorMessage("Error : "+error.message)
      });
    setTimeout(() => {
      stopLoader();
    }, 1000);
  };
  const download = () => {
    let filter = {
      // status: props.status,
      limit: 100000,
      offset: 0,
    };
    const response = dispatch(getAllMerchantList(filter));
    response
      .then((data) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
        if (data && data.data && data.data.status === 200) {
          setTimeout(() => {
            stopLoader();
            exportToCSV(data && data.data && data.data.data, "MerchantAccount");
          }, 1000);
        } else {
          setTimeout(() => {
            stopLoader();
          }, 1000);
        }
      })
      .catch((error) => console.log("----------error----", error));
    setTimeout(() => {
      stopLoader();
    }, 1000);
  };

  const exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const addMerchantAccount = () => {
    history.push(`/merchant-center/add-merchant-account`);
  };

  const editMerchantAccount = (storeId) => {
    history.push(`/merchant-center/edit-merchant-account/${storeId}`);
  };
  const viewMerchantAccount = (storeId) => {
    history.push(`/merchant-center/view-merchant-account/${storeId}`);
  };
  const searchMerchat = () => {
    setSearchData({
      ...searchData,
      storeId,
      merchantName,
      preferredMerchantName,
      merchantRegisteredDate,
    });
  };
  const resetSearch = () => {
    setStoreId("");
    setMerchantName("");
    setPreferredMerchantName("");
    setMerchantRegisteredDate("");
    setSearchData({
      ...initialMerchantSearchDetail,
      offset: searchData.offset,
    });
  };
  const getMerchantDataAfterDelete = (data) => {
    setMerchantData(data);
  };
  const handleAddPartner = (id) => {
    open_dialog(DELETE_DIALOG, {
      onSubmit: getMerchantDataAfterDelete,
      id,
    });
  };
  const handleSorting = (item, sortType, isDate = false) => {
    startLoader();
    try {
      if (typeof merchantData[0][item] === "number") {
        let sortedData = merchantData.sort((a, b) => {
          if (sortType == "asc") {
            return a[item] - b[item];
          } else if (sortType == "desc") {
            return b[item] - a[item];
          }
        });
        setTimeout(() => {
          setMerchantData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof merchantData[0][item] === "string" && !isDate) {
        let sortedData = merchantData.sort((a, b) => {
          let fa = a[item].toLowerCase(),
            fb = b[item].toLowerCase();
          if (sortType == "asc") {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else if (sortType == "desc") {
            if (fa < fb) {
              return 1;
            }
            if (fa > fb) {
              return -1;
            }
            return 0;
          }
        });
        setTimeout(() => {
          setMerchantData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof merchantData[0][item] === "string" && isDate) {
        let sortedData = merchantData.sort((a, b) => {
          let da = new Date(a[item]);
          let db = new Date(b[item]);

          if (sortType == "asc") {
            return da - db;
          } else if (sortType == "desc") {
            return db - da;
          }
        });
        setTimeout(() => {
          setMerchantData([...sortedData]);
          stopLoader();
        }, [1000]);
      }
    } catch (error) {
      console.log({ error });
      stopLoader();
    }
  };

  return (
    <React.Fragment>
      <div className="mt-1 mx-3 px-2 py-3 rounded bg-white">
        <div className="col-12 d-flex flex-wrap">
          <div className="form-label min-width-200 col-md-4 ">
            <label className="d-block">Merchant ID</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="storeId"
              value={storeId}
              onChange={(e) => setStoreId(e.target.value)}
            />
          </div>
          {/* <div className="form-label min-width-200 col-md-4 ">
            <label>Merchant Name</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="merchantName"
              value={merchantName}
              onChange={(e) => setMerchantName(e.target.value)}
            />
          </div> */}

          <div className="form-label min-width-200 col-md-4 ">
            <label className="row flex-nowrap ml-1">
              Merchant Preferred Name
            </label>
            <InputField
              type="text"
              className="form-control ml-1"
              placeholder="Input"
              id="preferredMerchantName"
              value={preferredMerchantName}
              onChange={(e) => setPreferredMerchantName(e.target.value)}
            />
          </div>

          <div className="form-label min-width-200 col-md-4 ">
            <label className="d-block">Registered Date</label>
            <InputField
              type="date"
              className="form-control"
              id="merchantRegisteredDate"
              value={merchantRegisteredDate}
              onChange={(e) => setMerchantRegisteredDate(e.target.value)}
              // disabled={true}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            className="btn input-inside-text mr-1 btn-white border px-4"
            onClick={() => resetSearch()}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn input-inside-text mx-1 btn-secondary px-3"
            onClick={() => searchMerchat()}
          >
            Search
          </button>
        </div>
      </div>

      <div className="mt-3 ">
        <div className="mx-3 my-3">
          <div className="py-3">
            <div className="border card p-3 my-3 ">
              <div className="d-flex justify-content-between">
                <div>
                  <span>ALL MERCHANT ACCOUNTS LISTING</span>
                </div>
                <div className="d-flex">
                  {/* <button
                    type="button"
                    className="btn add-product-botton py-1 mr-2 btn-dark"
                  >
                    <span className="text-nowrap">
                      <small className="text-nowrap">Export</small>
                    </span>
                  </button> */}
                  {/*<ExportCSV*/}
                  {/*  csvData={merchantData}*/}
                  {/*  fileName={"merchantAccounts"}*/}
                  {/*/>*/}
                  <button
                    type="button"
                    onClick={download}
                    className="btn add-product-botton py-1 mr-2 btn-dark"
                  >
                    <span className="text-nowrap">
                      <small className="text-nowrap">Export</small>
                    </span>
                  </button>

                  {ablity({ model: "Merchant", action: "create" }) == 1 && (
                    <button
                      type="button"
                      onClick={addMerchantAccount}
                      className="btn add-product-botton py-1 mr-2 btn-dark"
                    >
                      <span className="text-nowrap">
                        <small className="text-nowrap">Add Merchant</small>
                      </span>
                    </button>
                  )}
                </div>
              </div>
              <TableContainer>
                <Table
                  sx={{ minWidth: 650 }}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className="th_cell" component="th">
                        No
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
                        {/* Merchant ID */}
                        <div className="d-flex  ">
                          <div>Merchant ID</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() => handleSorting("storeId", "asc")}
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() => handleSorting("storeId", "desc")}
                            />
                          </div>
                        </div>
                      </TableCell>
                      {/* <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex">
                          <div>Name</div>
                          <div className="d-flex flex-column ">
                          <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() => handleSorting("merchantName", "asc")}

                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() => handleSorting("merchantName", "desc")}

                            />
                          </div>
                        </div>
                      </TableCell> */}
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex">
                          <div>Merchant Preferred Name</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() =>
                                handleSorting("preferredMerchantName", "asc")
                              }
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() =>
                                handleSorting("preferredMerchantName", "desc")
                              }
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex  ">
                          <div>Type</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() =>
                                handleSorting("businessType", "asc")
                              }
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() =>
                                handleSorting("businessType", "desc")
                              }
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex  ">
                          <div>Category</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() =>
                                handleSorting("businessType", "asc")
                              }
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() =>
                                handleSorting("businessType", "desc")
                              }
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex  ">
                          <div>Rating</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() => handleSorting("rating", "asc")}
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() => handleSorting("rating", "desc")}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex  ">
                          <div>Email</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() =>
                                handleSorting("companyEmail", "asc")
                              }
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() =>
                                handleSorting("companyEmail", "desc")
                              }
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex  ">
                          <div>Form Received Date</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() =>
                                handleSorting("formReceivedDate", "asc", true)
                              }
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() =>
                                handleSorting("formReceivedDate", "desc", true)
                              }
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex  ">
                          <div> Approved Date</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() =>
                                handleSorting("createdAt", "asc", true)
                              }
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() =>
                                handleSorting("createdAt", "desc", true)
                              }
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex  ">
                          <div>Processed By UU2</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() => handleSorting("createdBy", "asc")}
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() => handleSorting("createdBy", "desc")}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex  ">
                          <div> Updated On</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() =>
                                handleSorting("updatedAt", "asc", true)
                              }
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() =>
                                handleSorting("updatedAt", "desc", true)
                              }
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex  ">
                          <div> status</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() => handleSorting("status", "asc")}
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() => handleSorting("status", "desc")}
                            />
                          </div>
                        </div>
                      </TableCell>
                      {/* <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex  ">
                          <div> Account Balance</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              // onClick={() => apiData({ item: "   accountBalance", order: "ASC" })}
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              // onClick={() => apiData({ item: "   accountBalance", order: "DESC" })}
                            />
                          </div>
                        </div>
                      </TableCell> */}
                      <TableCell className="table_action_head" component="th">
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {errorMessage != "" && (
                      <TableRow>
                        <TableCell align="center" colSpan={5}>
                          <h4>{errorMessage}</h4>
                        </TableCell>
                      </TableRow>
                    )}
                    {[...merchantData].map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" className="td_cell">
                          {/* {searchData.offset * 10 + index + 1} */}
                          {offset * pageLimit + (index + 1)}
                        </TableCell>
                        <TableCell align="left" className="td_cell">
                          {row.storeId || "N/A"}
                        </TableCell>
                        {/* <TableCell align="left" className="td_cell">
                          {row.merchantName || "N/A"}
                        </TableCell> */}

                        <TableCell align="left" className="td_cell">
                          {row.preferredMerchantName || "N/A"}
                        </TableCell>
                        <TableCell align="left" className="td_cell">
                          {row.merchantType || "N/A"}
                        </TableCell>
                        <TableCell align="left" className="td_cell">
                          {row.category || "N/A"}
                        </TableCell>
                        <TableCell align="left" className="td_cell">
                          {String(row.rating) || "N/A"}
                        </TableCell>
                        <TableCell align="left" className="td_cell">
                          {row?.companyEmail || "N/A"}
                        </TableCell>
                        <TableCell align="left" className="td_cell">
                          {row.formReceivedDate
                            ? moment(row.formReceivedDate).format("DD/MM/YY")
                            : "N/A"}
                        </TableCell>
                        <TableCell align="left" className="td_cell">
                          {row.createdAt
                            ? moment(row.createdAt).format("DD/MM/YY, HH:mm")
                            : "N/A"}
                        </TableCell>
                        <TableCell align="left" className="td_cell">
                          {row.createdBy || "N/A  "}
                        </TableCell>
                        <TableCell align="left" className="td_cell">
                          {row.updatedAt
                            ? moment(row.updatedAt).format("DD/MM/YY, HH:mm")
                            : "N/A"}
                        </TableCell>
                        <TableCell align="left" className="td_cell">
                          {row.status || "N/A"}
                        </TableCell>
                        {/* <TableCell align="left" className="td_cell">
                          {row.accountBalance || "N/A"}
                        </TableCell> */}
                        <TableCell className="d-flex justify-content-between table_action_item">
                          {ablity({ model: "Merchant", action: "read" }) ==
                            1 && (
                            <IconButton
                              className="pointer"
                              onClick={() => viewMerchantAccount(row.storeId)}
                            >
                              <SvgIcon
                                src="/icons/view_icon.svg"
                                alt="view icon"
                              />
                            </IconButton>
                          )}

                          {ablity({ model: "Merchant", action: "update" }) ==
                            1 && (
                            <IconButton
                              className="pointer"
                              onClick={() => editMerchantAccount(row.storeId)}
                            >
                              <SvgIcon
                                src="/icons/edit_icon.svg"
                                alt="view icon"
                              />
                            </IconButton>
                          )}

                          {ablity({ model: "Merchant", action: "delete" }) ==
                            1 && (
                            <IconButton
                              className="pointer"
                              onClick={() => handleAddPartner(row.storeId)}
                            >
                              <SvgIcon
                                src="/icons/delete_icon.svg"
                                alt="delete icon"
                              />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <br />

              <div className="d-flex justify-content-between align-items-center">
                <PagenationDropdown setPageQuant={setPageLimit} />
                <div className=" float-right mb-1">
                  <Stack spacing={2}>
                    <Pagination
                      // value={searchData.offset}
                      count={Math.ceil(totalCount / pageLimit)}
                      shape="rounded"
                      size="small"
                      page={pageNo}
                      onChange={handlePageChange}
                    />
                  </Stack>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MerchantAccount;
