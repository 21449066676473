import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../../static/css/orderCss/allOrder.css";
import { TabPanel } from "../../../components/Tabs/TabPannel";
import TeamPurchaseRetail from "./TeamPurchaseRetail";
import { useDispatch } from "react-redux";
import InputField from "../../../components/formControl/input/InputField";
import DateRangePickerComp from "../../../components/datepicker/MUIDateRange";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import { getLocalStorage } from "../../../lib/session";

const initialSearchData = {
  buyerName: "",
  merchantName: "",
  orderId: "",
  productName: "",
  orderDate: "",
};

const initOrderRangeDate = {
  startDate: "",
  endDate: "",
};

function TeamPurchase(props) {
  const [tabIndex, setTabIndex] = useState(0);
  const [orderId, setOrderId] = useState("");
  const [buyerName, setBuyerName] = useState("");
  const [merchantName, setMerchantName] = useState("");
  const [productName, setProductName] = useState("");
  const [total, setTotal] = useState({});
  const [search, setSearch] = useState(initialSearchData);
  const [reset, setReset] = useState(false);
  const [orderDateRange, setOrderDateRange] = useState(initOrderRangeDate);
  const handleDateChange = (data) => {
    setOrderDateRange(data);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    const breadcrumb = [{ label: "Team-Purchase", url: null }];
    dispatch(breadcrumbAction(breadcrumb));
  }, [props.search]);

  const searchData = () => {
    setSearch({
      buyerName,
      merchantName,
      productName,
      orderId,
      ...orderDateRange,
    });
  };
  const handleResetSearch = () => {
    setReset(true);
    setOrderDateRange(initOrderRangeDate);
    setBuyerName("");
    setMerchantName("");
    setOrderId("");
    setProductName("");
    setSearch({
      buyerName: "",
      merchantName: "",
      productName: "",
      orderId: "",
    });
  };

  return (
    <React.Fragment>
      <div className="mt-1 mx-3 px-2 py-3 rounded bg-white">
        <div className="col-11 row flex-wrap">
          <div className="min-width-200 col-md-3">
            <label>Order ID</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="storeId"
              value={orderId}
              onChange={(e) => setOrderId(e.target.value)}
            />
          </div>
          <div className="min-width-200 col-md-3">
            <label>Buyer Name</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="merchantName"
              value={buyerName}
              onChange={(e) => setBuyerName(e.target.value)}
            />
          </div>
          {getLocalStorage("role") !== "MERCHANT" ?(
            <div className="min-width-200 col-md-3">
              <label>Merchant Name</label>
              <InputField
                type="text"
                className="form-control"
                placeholder="Input"
                id="storeId"
                value={merchantName}
                onChange={(e) => setMerchantName(e.target.value)}
              />
            </div>
          ):""}
          <div className="min-width-200 col-md-3">
            <label>Product Name</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="merchantName"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
            />
          </div>

          <div className="min-width-200 col-md-3">
            <label className=""> Order Date</label>
            <DateRangePickerComp
              reset={reset}
              setReset={setReset}
              handleDateChange={handleDateChange}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            className="btn input-inside-text mr-1 btn-white border px-4"
            onClick={() => handleResetSearch()}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn input-inside-text mx-1 btn-secondary px-3"
            onClick={() => searchData()}
          >
            Search
          </button>
        </div>
      </div>
      <br />
      <br />
      <div className="m-4 card p-4">
        <div>Team Purchase</div>
        <br />
        <div className=" border card my-3 ">
          <Tabs
            value={tabIndex}
            onChange={(e, value) => setTabIndex(value)}
            variant="scrollable"
            orientation="horizontal"
            scrollButtons={false}
            visibleScrollbar={false}
            className="px-3"
            aria-label="basic tabs example"
            style={{ backgroundColor: "secondary" }}
          >
            <Tab label={`ALL (${total?.total || 0})`} onClick={() => {
              setTabIndex(0)
            }} />
            <Tab
              label={`PENDING PAYMENT(${total?.pendingPayment || 0})`}
              onClick={() => {
                setTabIndex(1)
              }}
            />
            <Tab label={`AWAITING TP(${total?.awaitingTP || 0})`} onClick={() => {
              setTabIndex(2)
            }} />
            <Tab
              label={`CLOSED FULFILLED (${total?.toShip || 0})`}
              onClick={() => {
                setTabIndex(3)
              }}
            />
            <Tab
              label={`CLOSED UNFULFILLED (${total?.canceled || 0})`}
              onClick={() => {
                setTabIndex(4)
              }}
            />
            <Tab
              label={`UNREDEEM (${total?.unredeem || 0})`}
              onClick={() => {
                setTabIndex(5)
              }}
            />
            <Tab
              label={`COMPLETED / REDEEMED (${total?.completed || 0})`}
              onClick={() => {
                setTabIndex(6)
              }}
            />
          </Tabs>
          <TabPanel value={tabIndex} index={0} className={"px-3"} costumStyle={{ padding: "0px" }}>
            <TeamPurchaseRetail
              totalItem={total.total}
              storeId={props?.storeId || ""}
              search={props?.search || search || ""}
              teamStatus=""
              setTotals={setTotal}
              reset={handleResetSearch}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={1} className={"px-3"}  costumStyle={{ padding: "0px" }}>
            <TeamPurchaseRetail
              totalItem={total.pendingPayment}
              storeId={props?.storeId || ""}
              search={props?.search || search || ""}
              teamStatus="PENDINGPAYMENT"
              setTotals={setTotal}
              reset={handleResetSearch}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={2} className={"px-3"}  costumStyle={{ padding: "0px" }}>
            <TeamPurchaseRetail
              totalItem={total.awaitingTP}
              storeId={props?.storeId || ""}
              search={props?.search || search || ""}
              teamStatus="AWAITINGTP"
              setTotals={setTotal}
              reset={handleResetSearch}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={3} className={"px-3"}  costumStyle={{ padding: "0px" }}>
            <TeamPurchaseRetail
              totalItem={total.toShip}
              storeId={props?.storeId || ""}
              search={props?.search || search || ""}
              teamStatus="TOSHIP"
              setTotals={setTotal}
              reset={handleResetSearch}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={4} className={"px-3"}  costumStyle={{ padding: "0px" }}>
            <TeamPurchaseRetail
              totalItem={total.canceled}
              storeId={props?.storeId || ""}
              search={props?.search || search || ""}
              teamStatus="CANCELLED"
              setTotals={setTotal}
              reset={handleResetSearch}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={5} className={"px-3"}  costumStyle={{ padding: "0px" }}>
            <TeamPurchaseRetail
              totalItem={total.unredeemed}
              storeId={props?.storeId || ""}
              search={props?.search || search || ""}
              teamStatus="UNREDEEMED"
              setTotals={setTotal}
              reset={handleResetSearch}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={6} className={"px-3"}  costumStyle={{ padding: "0px" }}>
            <TeamPurchaseRetail
              totalItem={total.completed}
              storeId={props?.storeId || ""}
              search={props?.search || search || ""}
              teamStatus="COMPLETED"
              setTotals={setTotal}
              reset={handleResetSearch}
            />
          </TabPanel>
          
        </div>

      </div>
    </React.Fragment>
  );
}

export default TeamPurchase;
