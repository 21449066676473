import {
  IconButton,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import SvgIcon from "../../components/Images/SvgIcon";
import { startLoader, stopLoader, open_dialog } from "../../lib/global";
import { DELETE_BANNER_MODEL } from "../../lib/constant";
import { breadcrumbAction } from "../../redux/actions/config.action";
import ablity from "../../helpers/abilityGuard";
import { getAllConfig } from "../../redux/actions/app-config.action";

const initialSearchData = { offset: 0, item: "", order: "" };

function Configs(props) {
  const [ConfigData, setConfigData] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchData, setSearchData] = useState(initialSearchData);

  useEffect(async () => {
    const breadcrumb = [{ label: "Banners", url: null }];
    dispatch(breadcrumbAction(breadcrumb));
    startLoader();
    dispatch(getAllConfig())
      .then((data) => {
        console.log(data, "data data");
        if (data && data.data && data.data.status === 200) {
          setConfigData(data && data.data && data.data.data);
          console.log(data.data.data);
          setTimeout(() => {
            stopLoader();
          }, 1000);
        } else {
          setTimeout(() => {
            stopLoader();
          }, 1000);
        }
      })
      .catch((error) => {
        stopLoader();
      });
  }, [searchData]);

  const handlePageChange = (event, value) => {
    setSearchData({ ...searchData, offset: value - 1 });
  };

  const getBannersAfterDelete = (data) => {
    setConfigData(data);
  };

  const handleDeleteBanner = async (id) => {
    open_dialog(DELETE_BANNER_MODEL, {
      onSubmit: getBannersAfterDelete,
      id,
    });
  };

  const addConfig = () => {
    history.push("/config/add-config");
  };

  const editConfig = (key) => {
    history.push(`/config/${key}/edit`);
  };

  return (
    <React.Fragment>
      <div className="card mx-3 p-3">
        <div className="d-flex justify-content-between ">
          <div>
            <span>ALL CONFIG</span>
          </div>
          <div className="d-flex mb-3">
            {ablity({ model: "Banner", action: "create" }) === 1 && (
              <button
                type="button"
                onClick={() => addConfig()}
                className="btn btn-dark text-nowrap mx-3"
              >
                + Add Config
              </button>
            )}
          </div>
        </div>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className="th_cell " component="th">
                  No.
                </TableCell>

                <TableCell
                  className="th_cell col-3"
                  component="th"
                  align="center"
                >
                  <div className="d-flex  ">
                    <div>Key</div>
                  </div>
                </TableCell>

                <TableCell
                  className="th_cell col-3"
                  component="th"
                  align="center"
                >
                  <div className="d-flex  ">
                    <div>Name</div>
                  </div>
                </TableCell>

                <TableCell
                  className="th_cell col-3"
                  component="th"
                  align="center"
                >
                  <div className="d-flex  ">
                    <div>Value</div>
                  </div>
                </TableCell>

                <TableCell
                  className="table_action_head"
                  component="th"
                  align="center"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ConfigData.length > 0 &&
                ConfigData.map((row, iindex) => (
                  <TableRow
                    key={iindex}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="left" className="td_cell">
                      {searchData.offset * 10 + (iindex + 1)}
                    </TableCell>
                    <TableCell align="left" className="td_cell">
                      {row.key || "N/A"}
                    </TableCell>
                    <TableCell align="left" className="td_cell">
                      {row.name || "N/A"}
                    </TableCell>
                    <TableCell align="left" className="td_cell">
                      {row.value || "N/A"}
                    </TableCell>
                    <TableCell
                      className="d-flex justify-content-end table_action_item"
                      style={{ height: "113px" }}
                    >
                      {ablity({ model: "Banner", action: "update" }) === 1 && (
                        <IconButton
                          className="pointer my-3 px-3"
                          onClick={() => editConfig(row.key)}
                        >
                          <SvgIcon src="/icons/edit_icon.svg" alt="view icon" />
                        </IconButton>
                      )}

                      {/*{ablity({ model: "Banner", action: "delete" }) === 1 && (*/}
                      {/*  <IconButton*/}
                      {/*    className="pointer my-3 px-3"*/}
                      {/*    onClick={() => handleDeleteBanner(row.id)}*/}
                      {/*  >*/}
                      {/*    <SvgIcon*/}
                      {/*      src="/icons/delete_icon.svg"*/}
                      {/*      alt="delete icon"*/}
                      {/*    />*/}
                      {/*  </IconButton>*/}
                      {/*)}*/}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {!ConfigData?.length && (
          <h3 className="text-muted m-auto">No data found!</h3>
        )}

        <br />
        <div className="float-right col-12 mb-1">
          <Stack spacing={2} className="float-right">
            <Pagination
              count={Math.ceil((ConfigData?.length || 0) / 10)}
              shape="rounded"
              size="small"
              onChange={handlePageChange}
            />
          </Stack>
        </div>
      </div>
      <br />
      <br />
    </React.Fragment>
  );
}

export default Configs;
