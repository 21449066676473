import {
  IconButton,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import SvgIcon from "../../../../components/Images/SvgIcon";
import { IOSSwitch } from "../../../../components/switch/IOSSwitch";
import { } from "../../../../redux/actions/product.action";
import moment from "moment";

import {
  startLoader,
  stopLoader,
  showToast,
  open_dialog,
} from "../../../../lib/global";
import { DELETE_VOUCHER } from "../../../../lib/constant";
import { breadcrumbAction } from "../../../../redux/actions/config.action";
import PagenationDropdown from "../../../../components/DropDown/pagenationDropdown";
import InputField from "../../../../components/formControl/input/InputField";
import { getVoucher, updateStatusVoucher } from "../../../../redux/actions/marketing.action";

const initialSearchData = { offset: 0, item: "", order: "" };

function AllVoucher(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchData, setSearchData] = useState(initialSearchData);

  const [voucherData, setVoucherData] = useState([]);

  const [pageNo, setPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const limit = useRef(10);
  const [offset, setOffset] = useState(0);
  const refSearchData = useRef({});
  const { afterDelete } = props;

  const { status } = props;

  useEffect(async () => {
    const breadcrumb = [{ label: "Voucher Account", url: null }];
    dispatch(breadcrumbAction(breadcrumb));

    let filteringdata;
    if (pageLimit != limit.current) {
      limit.current = pageLimit;
      // props.reset();
      setPageNo(1);
      setOffset(0);
      filteringdata = {
        ...props.search,
        status: props.status,
        limit: pageLimit,
        offset: 0,
      };
    } else if (refSearchData.current !== props.search) {
      setPageNo(1);
      setOffset(0);
      filteringdata = {
        ...props.search,
        status: props.status,
        limit: pageLimit,
        offset: 0,
      };
      refSearchData.current = props.search;
    } else {

      filteringdata = {
        ...props.search,
        status: props.status,
        offset,
        limit: pageLimit,
      };
    }
    apiData(filteringdata);
  }, [offset, pageLimit, props.search]);

  const apiData = (filteringData) => {
    startLoader();
    const response = dispatch(getVoucher(filteringData));
    response
      .then((data) => {
        if (data && data.data && data.data.status === 200) {
          props.setTotals(data.data.total || {})
          setTimeout(() => {
            setVoucherData(data && data.data && data.data.data);
            // props.searchChangeCount(data.data.total);
            // setSearchData(initialSearchData)
            stopLoader();
          }, 1000);
        } else {
          setTimeout(() => {
            stopLoader();
          }, 1000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          console.log("Error ", error);
          stopLoader();
        }, 1000);
      });
  };

  const handlePageChange = (event, value) => {
    setOffset(value - 1);
    setPageNo(value);
  };

  const getVoucherDataAfterDelete = (data, total) => {
    afterDelete(data);
    apiData({})
  };

  const handleDeleteVoucher = async (id) => {
    open_dialog(DELETE_VOUCHER, {
      onSubmit: getVoucherDataAfterDelete,
      id,
    });
  };

  const isStatusActive = (disableAt) => {
    if(disableAt==null) {
      return true
    }
    return false
  }

  const toggleStatus = (e,id) => {
    const value = e.target.checked;
    const data = {
      is_active: value,
    }
    const response = dispatch(updateStatusVoucher(id,data)).then((data) => {
      if (data && data.data && data.data.status === 200) {
        showToast(data.data.message,"success");
        let filteringdata = {
          ...props.search,
          status: props.status,
          offset,
          limit: pageLimit,
        };
        apiData(filteringdata)
      } else {
        showToast(data.data.message,"error");
      }
    })
  }

  const addVoucher = () => {
    history.push("/merchant/marketing/promo/voucher/create");
  };
  const ViewAndEditVoucherAccount = (voucherId) => {
    history.push(`/merchant/marketing/promo/voucher/edit/${voucherId}`);
  };

  const handleSorting = (item, sortType, isDate = false) => {
    startLoader();
    try {
      if (typeof voucherData[0][item] === "number") {

        let sortedData = voucherData.sort((a, b) => {
          if (sortType == "asc") {
            return a[item] - b[item];
          } else if (sortType == "desc") {
            return b[item] - a[item];
          }
        });
        setTimeout(() => {
          setVoucherData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof voucherData[0][item] === "string" && !isDate) {
        let sortedData = voucherData.sort((a, b) => {
          let fa = a[item].toLowerCase(),
            fb = b[item].toLowerCase();
          if (sortType == "asc") {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else if (sortType == "desc") {
            if (fa < fb) {
              return 1;
            }
            if (fa > fb) {
              return -1;
            }
            return 0;
          }
        });
        console.log(sortedData, "llllllllll");
        setTimeout(() => {
          setVoucherData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof voucherData[0][item] === "string" && isDate) {
        let sortedData = voucherData.sort((a, b) => {
          let da = new Date(a[item]);
          let db = new Date(b[item]);

          console.log(da, db);

          if (sortType == "asc") {
            return da - db;
          } else if (sortType == "desc") {
            return db - da;
          }
        });
        setTimeout(() => {
          setVoucherData([...sortedData]);
          stopLoader();
        }, [1000]);
      }
    } catch (error) {
      console.log({ error });
      stopLoader()
    }
  };
  const ll = [
    {
      name: 'satyam',
      age: 13
    },
    {
      name: 'amit',
      age: 15
    },

  ]

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between">
        <div>
          <span></span>
        </div>
        <div className="d-flex mb-3">
          <InputField
            type="text"
            className=""
            placeholder="Search"
            id="search"
            // value={voucherName}
            // onChange={(e) => setVoucherName(e.target.value)}
          />
          <button
            type="button"
            onClick={() => addVoucher()}
            className="btn btn-dark btn-sm text-nowrap mx-3 my-2"
          >
            + Add Voucher
          </button>
        </div>
      </div>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell className="th_cell" component="th">
                No.
              </TableCell>

              <TableCell className="th_cell" component="th" align="center">
                <div className="d-flex  ">
                  <div>Name/Code</div>
                  <div className="d-flex flex-column ">
                    <ExpandLessIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "0px 3px",
                      }}
                      onClick={() => handleSorting("name", "asc")}

                    />
                    <ExpandMoreIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "-5px 3px",
                      }}
                      onClick={() => handleSorting("name", "desc")}

                    />
                  </div>
                </div>
              </TableCell>
              <TableCell className="th_cell" component="th" align="center">
                <div className="d-flex  ">
                  <div>Type</div>
                  <div className="d-flex flex-column ">
                    <ExpandLessIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "0px 3px",
                      }}
                      onClick={() => handleSorting("type", "asc", true)}

                    />
                    <ExpandMoreIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "-5px 3px",
                      }}
                      onClick={() => handleSorting("type", "desc", true)}

                    />
                  </div>
                </div>
              </TableCell>
              <TableCell className="th_cell" component="th" align="center">
                <div className="d-flex  ">
                  <div>Category</div>
                  <div className="d-flex flex-column ">
                    <ExpandLessIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "0px 3px",
                      }}
                      onClick={() => handleSorting("category", "asc", true)}

                    />
                    <ExpandMoreIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "-5px 3px",
                      }}
                      onClick={() => handleSorting("category", "desc", true)}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell className="th_cell" component="th" align="center">
                <div className="d-flex  ">
                  <div>Description</div>
                  <div className="d-flex flex-column ">
                    <ExpandLessIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "0px 3px",
                      }}
                      onClick={() => handleSorting("description", "asc")}

                    />
                    <ExpandMoreIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "-5px 3px",
                      }}
                      onClick={() => handleSorting("description", "desc")}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell className="th_cell" component="th" align="center">
                <div className="d-flex  ">
                  <div>Amount</div>
                  <div className="d-flex flex-column ">
                    <ExpandLessIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "0px 3px",
                      }}
                      onClick={() => handleSorting("amount", "asc")}

                    />
                    <ExpandMoreIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "-5px 3px",
                      }}
                      onClick={() => handleSorting("amount", "desc")}

                    />
                  </div>
                </div>
              </TableCell>
              <TableCell className="th_cell" component="th" align="center">
                <div className="d-flex  ">
                  <div>Usage Limit</div>
                  <div className="d-flex flex-column ">
                    <ExpandLessIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "0px 3px",
                      }}
                      onClick={() => handleSorting("usageLimitPerVoucher", "asc")}

                    />
                    <ExpandMoreIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "-5px 3px",
                      }}
                      onClick={() => handleSorting("usageLimitPerVoucher", "desc")}

                    />
                  </div>
                </div>
              </TableCell>
              <TableCell className="th_cell" component="th" align="center">
                <div className="d-flex  ">
                  <div>Usage</div>
                  <div className="d-flex flex-column ">
                    <ExpandLessIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "0px 3px",
                      }}
                      onClick={() => handleSorting("usage", "asc")}

                    />
                    <ExpandMoreIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "-5px 3px",
                      }}
                      onClick={() => handleSorting("usage", "desc")}

                    />
                  </div>
                </div>
              </TableCell>
              <TableCell className="th_cell" component="th" align="center">
                <div className="d-flex  ">
                  <div>Expired Date</div>
                  <div className="d-flex flex-column ">
                    <ExpandLessIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "0px 3px",
                      }}
                      onClick={() => handleSorting("endPeriodDate", "asc")}

                    />
                    <ExpandMoreIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "-5px 3px",
                      }}
                      onClick={() => handleSorting("endPeriodDate", "desc")}

                    />
                  </div>
                </div>
              </TableCell>
              <TableCell className="th_cell" component="th" align="center">
                <div className="d-flex  ">
                  <div>Status</div>
                  <div className="d-flex flex-column ">
                    <ExpandLessIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "0px 3px",
                      }}
                      onClick={() => handleSorting("endPeriodDate", "asc")}

                    />
                    <ExpandMoreIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "-5px 3px",
                      }}
                      onClick={() => handleSorting("endPeriodDate", "desc")}

                    />
                  </div>
                </div>
              </TableCell>
              <TableCell
                className="table_action_head"
                component="th"
                align="center"
              >
                Is Active
              </TableCell>

              <TableCell
                className="table_action_head"
                component="th"
                align="center"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!voucherData?.length && (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <h5 className="text-muted m-auto">No data found!</h5>
                </TableCell>
              </TableRow>
            )}
            {voucherData &&
              voucherData.length > 0 &&
              voucherData.map((row, iindex) => (
                <TableRow
                  key={iindex}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="center" className="td_cell">
                    {offset * pageLimit + (iindex + 1)}
                  </TableCell>
                  <TableCell align="left" className="td_cell">
                    {row.name || "N/A"} <br/>
                    {row.code || "N/A"}
                  </TableCell>
                  <TableCell align="left" className="td_cell">
                    {row.type.label || "N/A"}
                  </TableCell>
                  <TableCell align="left" className="td_cell">
                    {row.category.label || "N/A"}
                  </TableCell>
                  <TableCell align="left" className="td_cell">
                    {row.description || "N/A"}
                  </TableCell>
                  <TableCell align="left" className="td_cell">
                    {row.amount || "0"}
                  </TableCell>
                  <TableCell align="left" className="td_cell">
                    {row.usageLimitPerVoucher || "Unlimited"}
                  </TableCell>
                  <TableCell align="left" className="td_cell">
                    {row.usage || 0}
                  </TableCell>
                  <TableCell align="left" className="td_cell">
                    {row.endPeriodDate
                      ? moment(row.endPeriodDate).format("DD/MM/YYYY")
                      : "N/A"}
                  </TableCell>
                  <TableCell align="left" className="td_cell">
                    {row.status || "N/A"}
                  </TableCell>
                  <TableCell className="td_cell">
                    <IOSSwitch
                        name="isActive"
                        id="isActive"
                        onChange={(e)=>toggleStatus(e,row.id)}
                        checked={isStatusActive(row.disableAt)}
                        className="mt-2"
                      />
                  </TableCell>
                  <TableCell className="td_cell justify-content-between table_action_item">
                    <IconButton
                      className="pointer"
                      onClick={() => ViewAndEditVoucherAccount(row.id)}
                    >
                      {row.status == 'DRAFT' ? (
                            <SvgIcon src="/icons/edit_icon.svg" alt="view icon" />
                          ):(<SvgIcon src="/icons/view_icon.svg" alt="view icon" />)}
                    </IconButton>
                    {row.status == 'DRAFT' && (
                      <IconButton
                        className="pointer"
                        onClick={() => handleDeleteVoucher(row.id)}
                      >
                        <SvgIcon src="/icons/delete_icon.svg" alt="delete icon" />
                      </IconButton>
                    )}
                  </TableCell>

                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <div className="d-flex justify-content-between align-items-center">
        <PagenationDropdown setPageQuant={setPageLimit} />

        <div className="float-right mb-1">
          <Stack spacing={2}>
            <Pagination
              count={Math.ceil((props?.totalItem || 0) / pageLimit)}
              shape="rounded"
              size="small"
              page={pageNo}
              onChange={handlePageChange}
            />
          </Stack>
        </div>
      </div>
      <br />
    </React.Fragment>
  );
}

export default AllVoucher;
