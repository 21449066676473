import { IconButton, Pagination } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Image from "../../../../components/Images/Image";
import SvgIcon from "../../../../components/Images/SvgIcon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useDispatch } from "react-redux";
import { Stack } from "@mui/material";
import { getAllReturnOrder } from "../../../../redux/actions/finance.action";

import { startLoader, stopLoader } from "../../../../lib/global";
import PagenationDropdown from "../../../../components/DropDown/pagenationDropdown";
import ablity from "../../../../helpers/abilityGuard";

const AllReturnRefund = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState({ order: "", item: "" });
  const [allOrderReceiptData, setAllOrderReceiptData] = useState([]);

  const [pageNo, setPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const limit = useRef(10);
  const [offset, setOffset] = useState(0);
  const refSearchData = useRef({});

  const viewAction = (id) => {
    history.push(`/finance/return-refund/refund-request/${id}`);
  };

  useEffect(() => {
    let filteringData;
    if (pageLimit != limit.current) {
      limit.current = pageLimit;
      props.reset();
      setPageNo(1);
      setOffset(0);
      filteringData = {
        status: props.status,
        limit: pageLimit,
        offset: 0,
      };
    } else if (refSearchData.current !== props.search) {
      setPageNo(1);
      setOffset(0);
      filteringData = {
        ...props.search,
        status: props.status,
        limit: pageLimit,
        offset: 0,
      };
      refSearchData.current = props.search;
    } else {
      filteringData = {
        ...props.search,
        status: props.status,
        offset,
        limit: pageLimit,
      };
    }
    apiData(filteringData);
  }, [offset, pageLimit, props.search]);

  const apiData = (filteringData) => {
    startLoader();
    dispatch(getAllReturnOrder(filteringData))
      .then((data) => {
        setTimeout(() => {
          props.setTotals(data.data.total || {});
          // setAllOrderReceiptData(data?.data?.data)
          //   console.log(data?.data?.data, "pppppppppppppppppppppppppppppppppppp");
          setAllOrderReceiptData(
            data &&
              data.data &&
              data.data.data.map((row, index) => {
                return {
                  consumerName: row?.consumerName || "N/A",
                  merchantName: row?.merchantName || "N/A",
                  caseId: row?.caseId || "N/A",
                  orderId: row?.orderId || "N/A",
                  productImages: row?.productImages || "/images/noImage.png",
                  productName: row?.productName || "N/A",
                  variation: row?.variation || "N/A",
                  price: row?.price || 0,
                  quantity: row?.order?.quantity || 0,
                  totalPrice: row?.price * row?.order?.quantity || 0,

                  createdAt: row?.createdAt,
                  updatedAt: row?.updatedAt,
                  agentName: row?.agentName || "N/A",
                  refundMethod: row?.refundMethod || "N/A",

                  reason: row?.reason?.replace(/_/g, " ") || "N/A",
                  status: row?.status?.replace(/_/g, " ") || "N/A",
                };
              })
          );
          stopLoader();
        }, 1000);
      })
      .catch((error) => {
        // console.log(error);
        setTimeout(() => {
          stopLoader();
        }, 1000);
      });
  };

  const handlePageChange = (event, value) => {
    // setSearchData({ ...searchData, offset: value - 1 });
    // refOffset(value - 1)
    setOffset(value - 1);
    setPageNo(value);
  };

  const handleSorting = (item, sortType, isDate = false) => {
    startLoader();
    try {
      if (typeof allOrderReceiptData[0][item] === "number") {
        let sortedData = allOrderReceiptData.sort((a, b) => {
          if (sortType == "asc") {
            return a[item] - b[item];
          } else if (sortType == "desc") {
            return b[item] - a[item];
          }
        });
        setTimeout(() => {
          setAllOrderReceiptData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof allOrderReceiptData[0][item] === "string" && !isDate) {
        let sortedData = allOrderReceiptData.sort((a, b) => {
          let fa = a[item].toLowerCase(),
            fb = b[item].toLowerCase();
          if (sortType == "asc") {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else if (sortType == "desc") {
            if (fa < fb) {
              return 1;
            }
            if (fa > fb) {
              return -1;
            }
            return 0;
          }
        });
        setTimeout(() => {
          setAllOrderReceiptData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof allOrderReceiptData[0][item] === "string" && isDate) {
        let sortedData = allOrderReceiptData.sort((a, b) => {
          let da = new Date(a[item]);
          let db = new Date(b[item]);

          if (sortType == "asc") {
            return da - db;
          } else if (sortType == "desc") {
            return db - da;
          }
        });
        setTimeout(() => {
          setAllOrderReceiptData([...sortedData]);
          stopLoader();
        }, [1000]);
      }
    } catch (error) {
      //   console.log({ error });
      stopLoader();
    }
  };

  return (
    <React.Fragment>
      <div>
        <div className="allTeamPurchaseMain">
          <div className="tableheadTeamPurchaseProduct teamPurchase_borderLine col-5">
            <div className="d-flex  ">
              <div>Product</div>

              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  onClick={() => handleSorting("productName", "asc")}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  onClick={() => handleSorting("productName", "desc")}
                />
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div> Total Price</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  onClick={() => handleSorting("totalPrice", "asc")}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  onClick={() => handleSorting("totalPrice", "desc")}
                />
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div> Refund Status</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  onClick={() => handleSorting("status", "asc")}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  onClick={() => handleSorting("status", "desc")}
                />
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div> Refund Method </div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  onClick={() => handleSorting("refundMethod", "asc")}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  onClick={() => handleSorting("refundMethod", "desc")}
                />
              </div>
            </div>
          </div>
          <div className="tableheadfix tableheadfix_allorder teamPurchase_borderLine">
            Action
          </div>
          {!allOrderReceiptData.length ? (
            <div>No data found</div>
          ) : (
            <>
              {allOrderReceiptData.map((item, index) => (
                <div className="info" key={item?.caseId}>
                  <div className="teamPurchase-orderId-detail-maindiv fntSz15">
                    <div className="justify-content-between d-flex teamPurchase-orderId-detail-section">
                      <div className="teamPurchase_orderId_Detail_Name w-100 m-0 text-capitalize">
                        <p>Buyer: {item.consumerName || "N/A"}</p>
                        <Image
                          src="/icons/chat1.png"
                          className="teamPurchase_chat_icon mb-1"
                          alt="Header logo for admin dashboard"
                        />
                      </div>
                      <div className="teamPurchase_orderId_Detail_brand mx-4 text-capitalize">
                        <p>Merchant: {item?.merchantName || "N/A"}</p>
                        <Image
                          src="/icons/chat1.png"
                          className="teamPurchase_chat_icon mb-1"
                          alt="Header logo for admin dashboard"
                        />
                      </div>
                    </div>
                    <div className="teamPurchase-orderId-detail-indexId pr-2">
                      <div className="d-flex flex-row flex-nowrap">
                        <div className="px-5">
                          Case ID: {item?.caseId || "N/A"}
                        </div>
                        <div>Order ID: {item?.orderId || "N/A"}</div>
                      </div>
                    </div>
                  </div>

                  <div className="teamPurchase_tablehead_details_head">
                    <div
                      className="tableheadTeamPurchaseProduct"
                      onClick={() => viewAction(item.orderId)}
                    >
                      <div className="d-flex ">
                        <Image
                          src={item?.productImages || "/images/noImage.png"}
                          alt={"photo"}
                          className="teamPurchase_page_product_image"
                        />
                        <div className=" mx-2">
                          <div className="teamPurchase_Page_ProductBox_ProductName text-capitalize">
                            {item?.productName || "N/A"}{" "}
                          </div>
                          <div className="teamPurchase_Page_ProductBox_Product_varient text-capitalize">
                            Variation:{" "}
                            {`${
                              (item?.variation &&
                                item?.variation?.length > 0 &&
                                item?.variation
                                  ?.map((Item, index) => Item?.value)
                                  .toString()) ||
                              " N/A"
                            }`}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName">{`${
                          item?.order?.currencyName || "RM"
                        }${item?.totalPrice || 0}.00`}</div>
                        {/* <div className="teamPurchase_Page_ProductBox_Product_varient">Online Banking</div> */}
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName text-capitalize">
                          {item?.status || "N/A"}
                        </div>
                        {/* <div className="teamPurchase_Page_ProductBox_Product_varient ">To avoid late shisss</div> */}
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName text-capitalize">
                          {item?.refundMethod || "N/A"}
                        </div>
                        {/* <div className="teamPurchase_Page_ProductBox_Product_varient"></div> */}
                      </div>
                    </div>
                    <div className="tableheadfix tableheadfix_allorder">
                      <div className="d-flex justify-content-center ">
                        <div>
                          {ablity({model:'Finance', action:'read'}) == 1 && (
                              <IconButton
                                className="pointer"
                                onClick={() => viewAction(item?.orderId)}
                              >
                                <SvgIcon
                                  src="/icons/view_icon.svg"
                                  alt="view icon"
                                />
                              </IconButton>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <br />
      <div className="d-flex justify-content-between align-items-center">
        <PagenationDropdown setPageQuant={setPageLimit} />

        <div className="float-right mb-1">
          <Stack spacing={2}>
            <Pagination
              count={Math.ceil((props?.totalItem || 0) / pageLimit)}
              shape="rounded"
              size="small"
              page={pageNo}
              onChange={handlePageChange}
            />
          </Stack>
        </div>
      </div>
      <br />
    </React.Fragment>
  );
};

export default AllReturnRefund;
