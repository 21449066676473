import AccountCircle from "@mui/icons-material/AccountCircle";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Badge, Breadcrumbs } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { spacing } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import isMobile from "../../hooks/isMobile";
import useTheme from "../../hooks/useTheme";
import { APP_NAME } from "../../lib/config";
import { open_drawer } from "../../lib/global";
import { SideNavbarToggleSubject } from "../../lib/rxSubject";
import {
  getLocalStorage,
  removeLocalStorageKey,
  setLocalStorage,
} from "../../lib/session";
import { actionThemeChange } from "../../redux/actions/home.action";
import Image from "../Images/Image";
import SvgIcon from "../Images/SvgIcon";
import { handleDocumentTitleLogout } from "../../lib/useDocumentTitle";
import { breadcrumbAction } from "../../redux/actions/config.action";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: spacing(2),
  },
  title: {
    display: "none",
    // [theme.breakpoints.up("sm")]: {
    //   display: "block",
    // },
  },
  searchIcon: {
    padding: spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${spacing(4)}px)`,
    width: "100%",
  },
  sectionDesktop: {},
  sectionMobile: {
    display: "flex",
  },
}));

const Headers = () => {
  const dispatch = useDispatch();
  const breadcrumb = useSelector((State) => State.store.breadcrumb);
  const [theme, themeType] = useTheme();
  const [mobileView] = isMobile();
  const Route = useHistory();
  const classes = useStyles();
  const role = getLocalStorage("role");
  const username = getLocalStorage("userName") || getLocalStorage("name");
  const createdBy = localStorage.getItem("createdBy");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [minimize, setMinimize] = useState(false);

  useEffect(() => {
    SideNavbarToggleSubject.subscribe((flag) => setMinimize(flag || false));
  }, []);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleThemeChange = () => {
    const newTheme = themeType === "dark" ? "light" : "dark";
    setLocalStorage("theme", newTheme);
    dispatch(actionThemeChange(newTheme));
  };

  const handleLogout = () => {
    handleMenuClose();
    removeLocalStorageKey("token");
    removeLocalStorageKey("assignMethod");
    removeLocalStorageKey("role");
    removeLocalStorageKey("uid");
    removeLocalStorageKey("storeId");
    removeLocalStorageKey("createdBy")
    removeLocalStorageKey("email")
    removeLocalStorageKey("rules")
    removeLocalStorageKey("userName")
    handleDocumentTitleLogout();
    const breadcrumb = [{ label: "", url: "" }];
    dispatch(breadcrumbAction(breadcrumb));
    Route.push("/auth");
  };

  const handleNotification = () => {
    Route.push("/merchant/notification");
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handleThemeChange}>
        <IconButton>
          <Brightness4Icon />
        </IconButton>
        {themeType === "dark" ? "Light Theme" : "Dark Theme"}
      </MenuItem> */}
      <MenuItem onClick={handleLogout}>
        <IconButton>
          <ExitToAppIcon />
        </IconButton>
        <Typography>Logout</Typography>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleThemeChange}>
        <IconButton>
          <Brightness4Icon />
        </IconButton>
        {themeType === "dark" ? "Light Theme" : "Dark Theme"}
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <IconButton>
          <ExitToAppIcon />
        </IconButton>
        <Typography>Logout</Typography>
      </MenuItem>
    </Menu>
  );

  const handleToggleDrawer = () => {
    if (mobileView) {
      open_drawer(
        "SIDEBAR",
        {
          paperClass: "mv_side_navbar",
        },
        "left"
      );
    } else {
      SideNavbarToggleSubject.next(!minimize);
    }
  };

  return (
    <div
      className={`${classes.grow} main-header ${
        mobileView ? "mobile" : "desktop"
      }`}
    >
      {!mobileView ? (
        <AppBar
          style={{
            backgroundColor: theme.headerBgColor,
            boxShadow: "none",
          }}
          className={`header_sidebar ${minimize ? "min" : "max"}`}
          position="static"
        >
          <div className="d-flex object-fit-coveralign-items-center">
            <Image
              src={"/icons/savvy_mart.png"}
              height="auto"
              width="150px"
              className="p-3"
              alt="Header logo for admin dashboard"
            />
            <div className="text-start w-100 text-dark my-3">
              {/* <Typography

              >
                UU2
              </Typography> */}
              {/* <h4 className="text-danger"></h4> */}
              <Typography
                className={(classes.title, "Bold")}
                variant="h6"
                noWrap
              >
                <p className="mb-0 text-danger " style={{ lineHeight: "1rem" }}>
                  Savvy Mart
                </p>
                {/*{role == "SUPERADMIN" || createdBy == "SUPERADMIN" ? "Admin Center" : "Merchant Panel"}*/}
                {role == "MERCHANT" && createdBy != "SUPERADMIN"
                  ? "Merchant Panel"
                  : "Admin Center"}
              </Typography>
            </div>
          </div>
        </AppBar>
      ) : (
        <></>
      )}

      <AppBar
        style={{
          backgroundColor: theme.headerBgColor,
          boxShadow: "rgb(0 0 0 / 20%) 2px 2px 6px -1px",
          color: "#000",
        }}
        className={`header_body ${minimize ? "min" : "max"}`}
        position="static"
      >
        <Toolbar>
          {mobileView ? (
            <IconButton
              edge="start"
              className={classes.menuButton}
              onClick={handleToggleDrawer}
              color="inherit"
              aria-label="open drawer"
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <></>
          )}
          {mobileView ? (
            <div className="w-100 text-start">
              <h4 className="m-0">
                {breadcrumb && breadcrumb.length > 0
                  ? breadcrumb[0].label
                  : role == "SUPERADMIN" || createdBy == "SUPERADMIN"
                  ? "Admin Center"
                  : "Merchant Panel"}
              </h4>
            </div>
          ) : (
            <></>
          )}
          <Typography className={classes.title} variant="h5" noWrap>
            {APP_NAME}
          </Typography>

          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {!mobileView
              ? breadcrumb?.map((item, index) => {
                  if (item.url) {
                    return (
                      <Link
                        key={index}
                        underline="hover"
                        color="inherit"
                        to={item.url}
                      >
                        {item.label}
                      </Link>
                    );
                  } else {
                    return (
                      <Typography key={index} color="text.primary">
                        {item.label}
                      </Typography>
                    );
                  }
                })
              : ""}
          </Breadcrumbs>
          <div className={classes.grow} />

          <div className={classes.sectionDesktop}>
            {/*<IconButton aria-label="show 4 new mails" color="inherit">*/}
            {/*  <Badge badgeContent={4} color="secondary">*/}
            {/*    /!* <ChatRoundedIcon /> *!/*/}
            {/*    <SvgIcon src="/icons/chat_icon.svg" alt="chat icon" />*/}
            {/*  </Badge>*/}
            {/*</IconButton>*/}

            <IconButton
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={handleNotification}
            >
              {/*<Badge badgeContent={17} color="secondary">*/}
              {/*<Badge badgeContent={""} color="secondary">*/}
              {/*</Badge>*/}
              <SvgIcon src="/icons/bell_icon.svg" alt="notification icon" />
            </IconButton>

            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle fontSize="large" />
            </IconButton>
          </div>

          <div className={[classes.sectionMobile, " ml-2"]}>
            <p className="admin_name my-auto">{username || ""}</p>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
};

export default Headers;
