import {
    IconButton,
    Pagination,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SvgIcon from "../../components/Images/SvgIcon";
import { breadcrumbAction } from "../../redux/actions/config.action";
import { deleteBrandById, getAllBrandApi } from "../../services/brand.service";
import "../../static/css/productCss/ProductBrand.css"
import { open_dialog, showToast, startLoader, stopLoader } from "../../lib/global";
import { DELETE_HELP_CENTER_TICKET } from "../../lib/constant";
import { create } from "@mui/material/styles/createTransitions";

  
  const initialSearchData = {
    search: "",
    offset: 0
  }
  
  const initalData=[
    {
      status:"Closed",
      created:"11/12/2021",
      updated:"11/12/2021",
      admin:"001/Christine",
      lastest:"Issue solve"
    },
    {
      status:"Open",
      created:"11/12/2021",
      updated:"11/12/2021",
      admin:"002/Jason",
      lastest:"This is a testing message, if the message is too long, it will display this way and if longbhdbxjdnxdsaxxdsxkjsanxksax"
    },
  ]
  const HelpCenter = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [brandData, setBrandData] = useState(initalData);
    const [searchData, setSearchData] = useState(initialSearchData)
    const [totalBrands, setTotalBrands] = useState(0)
    const createNewTicket = () => {
      history.push("/merchant/help-center/create-new-ticket");
    };

    const viewAction=(id)=>{
      history.push(`view?id=${id}`)
    }
  
  
    useEffect(() => {
      const breadcrumb = [
        { label: "Help Center", url: null }
      ];
      dispatch(breadcrumbAction(breadcrumb));
      startLoader()
      // const data= {...searchData }
      dispatch(getAllBrandApi(searchData))
        .then((data) => {
          console.log(data);
          setTimeout(()=>{
            stopLoader()
          },1000)
          if (data && data.data && data.data.status === 200) {
            setBrandData(data && data.data && data.data.data && data.data.data);
            setTotalBrands(data && data.data && data.data.data && data.data.totalBrand)
          }
        })
        .catch((error) => {
          console.log({ error }, "kkkkkkkkkkkkkkkkkkkkkk");
          setTimeout(()=>{
            stopLoader()
          },1000)
        });
    }, [searchData]);
  
  
    const getApiData = (searchingData) => {
      console.log(searchingData);
      startLoader()
      dispatch(getAllBrandApi(searchingData))
        .then((data) => {
          console.log(data);
          setBrandData(data && data.data && data.data.data);
          setTimeout(() => {
            stopLoader()
          }, 1000)
        }).catch(error => {
          stopLoader()
          console.log({ error }, 'errorr');
        })
    };
  
    const handlePageChange = (event, value) => {
      console.log(value, 'gggggggggggggggggggggggggggggggg');
      setSearchData({ ...searchData, offset: value - 1 })
  
    };
  
    const getBrandsDataAfterDelete = (data) => {
      console.log(data, "hhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
      setBrandData(data)
  
    }
    // const DeleteBrandById = (id) => {
    //   open_dialog(DELETE_HELP_CENTER_TICKET, {
    //     onSubmit: getBrandsDataAfterDelete,
    //     id
    //   });
    // };
  
  
    // const EditBrandfun = (id) => {
    //   history.push(`/brand-desk/${id}`)
    // }
  
    // const onChangeSeachData = (data) => {
  
    //   setSearchData(data)
    // }
  
    return (
      <div>
        <div className="container my-3">
          <div className=" px-4 py-3 bg-body bg-white rounded shadow-sm">
            <span>TICKET LISTING</span>
            <div className="container border card p-3 my-3 ">
              <div className="d-flex justify-content-between">
                <div>
                  <span>{`ALL TICKETS`}</span>
                </div>
                <div className="d-flex my-3">
                  {/* <input
                    type="search"
                    placeholder="search"
                    className="form-control mx-3"
                    onChange={(e) => onChangeSeachData({ ...searchData, search: e.target.value })}
                  /> */}
                  <button
                    onClick={() => createNewTicket()}
                    type="button" 
                    className="btn btn-dark btn-sm text-nowrap mx-3"
                  >
                    {" "}
                    + Create New Ticket
                  </button>
                </div>
              </div>
              <TableContainer>
                <Table
                  sx={{ minWidth: 650 }}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className="th_cell" component="th">
                        No
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex  ">
                          <div>Status</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                              onClick={() => setSearchData({ ...searchData, item: "Status", order: "ASC" })}
  
                            />
                            <ExpandMoreIcon
                              style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                              onClick={() => setSearchData({ ...searchData, item: "Status", order: "DESC" })}
  
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
  
                        <div className="d-flex  ">
                          <div>Created</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                            onClick={() => getApiData({ item: "Created", order: "ASC" })}
  
                            />
                            <ExpandMoreIcon
                              style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                            onClick={() => getApiData({ item: "Created", order: "DESC" })}
  
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
  
                        <div className="d-flex  ">
                          <div>Updated</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                            onClick={() => getApiData({ item: "Updated", order: "ASC" })}
  
                            />
                            <ExpandMoreIcon
                              style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                            onClick={() => getApiData({ item: "Updated", order: "DESC" })}
  
                            />
                          </div>
                        </div>
  
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
  
                        <div className="d-flex  ">
                          <div> UU2 Admin ID/Name</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                            onClick={() => getApiData({ item: "UU2 Admin ID/Name", order: "ASC" })}
  
                            />
                            <ExpandMoreIcon
                              style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                            onClick={() => getApiData({ item: "UU2 Admin ID/Name", order: "DESC" })}
  
                            />
                          </div>
                        </div>
  
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
  
                        <div className="d-flex  ">
                          <div>  Lastest Message</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                            onClick={() => getApiData({ item: "Lastest Message", order: "ASC" })}
  
                            />
                            <ExpandMoreIcon
                              style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                            onClick={() => getApiData({ item: "Lastest Message", order: "DESC" })}
  
                            />
                          </div>
                        </div>
                      </TableCell>
  
                      <TableCell className="table_action_head" component="th">
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!brandData?.length && (
                      <div className="d-flex justify-content-center">
                        <h3 className="text-muted m-auto">No data found!</h3>
                      </div>
                    )}
                    {brandData.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" className="td_cell">
                        {searchData.offset*10 + (index + 1)}
                        </TableCell>
                        <TableCell align="left" className="td_cell capitalize">
                          {row.status || "N/A"}
                        </TableCell>
                        <TableCell align="center" className="td_cell">
                          {row.created || "0"}
                        </TableCell>
                        <TableCell align="center" className="td_cell">
                          {row.updated || "0"}
                        </TableCell>
                        <TableCell align="center" className="td_cell">
                          {row.admin || "0"}
                        </TableCell>
                        <TableCell align="left" className="text-wrap col-2 text-truncate td_cell">
                          {row.lastest || "N/A"}
                        </TableCell>
  
                        <TableCell className="d-flex justify-content-between table_action_item">
                        <IconButton
                          className="pointer"
                          // onClick={() => viewAction(row.productId)}
                        >
                          <SvgIcon
                              src="/icons/view_icon.svg"
                              alt="view icon"
                          />
                      </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
  
              <br />
              <hr />
              <div className="col-12">
                <div className="float-right mb-1">
                  <Stack spacing={2}>
                    <Pagination
                      count={Math.ceil(totalBrands / 10)}
                      shape="rounded"
                      size="small"
                      onChange={handlePageChange}
                    />
                  </Stack>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default HelpCenter;
  