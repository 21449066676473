import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import InputField from "../../../components/formControl/input/InputField";

import { postRedemption } from "../../../redux/actions/order.action";
import ablity from "../../../helpers/abilityGuard";
import { getLocalStorage } from "../../../lib/session";
import { postRedemptionByMerchant } from "../../../redux/actions/merchant/order.action";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const OrderListing = () => {
  const dispatch = useDispatch();
  const [orderId, setOrderId] = useState("");
  const [redemptionCode, setRedemptionCode] = useState("");

  // const [search, setSearch] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [reset, setReset] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    // startLoader();
    const breadcrumb = [{ label: "Redemption", url: null }];
    dispatch(breadcrumbAction(breadcrumb));
  }, [tabIndex]);

  const handleRedemption = () => {
    // console.log(orderId, "+++++++++++++++++++++++++++");
    // console.log(redemptionCode, "+++++++++++++++++++++++++++");
    const response =
      getLocalStorage("role") == "SUPERADMIN"
        ? dispatch(postRedemption(orderId, redemptionCode))
        : dispatch(postRedemptionByMerchant(orderId, redemptionCode));
    response
      .then(({ data }) => {
        // console.log(data, "dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
        setMessage(data.message);
        setReset(true);
      })
      .catch((err) => {
        setMessage(
          err.response.data != null ? err.response.data.message : err.message
        );
        setReset(true);
      });
  };
  const handleReset = () => {
    setOrderId("");
    setRedemptionCode("");
    setReset(false);
  };

  return (
    <React.Fragment>
      <div className="mt-1 mx-3 px-2 py-4 rounded bg-white">
        <div className="col-11 row flex-wrap">
          <div className="min-width-200 col-md-4">
            <label>Order ID</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="storeId"
              value={orderId}
              onChange={(e) => setOrderId(e.target.value)}
            />
          </div>
          <div className="min-width-200 col-md-4">
            <label>Redemption Code</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="merchantName"
              value={redemptionCode}
              onChange={(e) => setRedemptionCode(e.target.value)}
            />
          </div>
        </div>
        {reset ? <span className="px-3">{message}</span> : null}

        <div className="d-flex justify-content-end mt-5 mr-5">
          {ablity({ model: "Order", action: "create" }) == 1 && (
            <button
              type="button"
              className="btn input-inside-text mr-1 btn-white border px-4"
              onClick={() => handleReset()}
            >
              Reset
            </button>
          )}
          {ablity({ model: "Order", action: "create" }) == 1 && (
            <button
              type="button"
              className="btn input-inside-text mx-1 btn-danger px-4"
              onClick={handleRedemption}
            >
              Redeem
            </button>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrderListing;
