import {
  Icon,
  IconButton,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import SvgIcon from "../../components/Images/SvgIcon";
import moment from "moment";
import {
  startLoader,
  stopLoader,
  showToast,
  open_dialog,
} from "../../lib/global";
import { DELETE_BANNER_MODEL } from "../../lib/constant";
import { breadcrumbAction } from "../../redux/actions/config.action";
import { getAllConsumerList } from "../../redux/actions/consumer-center.action";
import Image from "../../components/Images/Image";
import { getAllBanners } from "../../redux/actions/banner.action";
import ablity from "../../helpers/abilityGuard";

const initialSearchData = { offset: 0, item: "", order: "" };

function Banner(props) {

  const [BannerData, setBannerData] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchData, setSearchData] = useState(initialSearchData);

  useEffect(async () => {
    const breadcrumb = [{ label: "Banners", url: null }];
    dispatch(breadcrumbAction(breadcrumb));
    startLoader();
    dispatch(getAllBanners())
      .then((data) => {
        console.log(data, "data data");
        if (data && data.data && data.data.status === 200) {
          setBannerData(data && data.data && data.data.data);
          console.log(data.data.data);
          setTimeout(() => {
            stopLoader();
          }, 1000);
        } else {
          setTimeout(() => {
            stopLoader();
          }, 1000);
        }
      })
      .catch((error) => {
        console.log("----------error----", error);
        stopLoader();
      });
  }, [searchData]);

  const handlePageChange = (event, value) => {
    console.log(value, "gggggggggggggggggggggggggggggggg");
    setSearchData({ ...searchData, offset: value - 1 });
  };

  const getBannersAfterDelete = (data) => {
    console.log(data, "hhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
    setBannerData(data);
  };

  const handleDeleteBanner = async (id) => {
    open_dialog(DELETE_BANNER_MODEL, {
      onSubmit: getBannersAfterDelete,
      id,
    });
  };

  const addBanner = () => {
    history.push("/banners/add-banner");
  };
  const editBanner = (id) => {
    history.push(`/banners/edit-banner/${id}`);
  };

  return (
    <React.Fragment>
      <div className="card mx-3 p-3">
        <div className="d-flex justify-content-between ">
          <div>
            <span>ALL BANNERS</span>
          </div>
          <div className="d-flex mb-3">
            {ablity({model:'Banner', action:'create'}) == 1 && (
                <button
                  type="button"
                  onClick={() => addBanner()}
                  className="btn btn-dark text-nowrap mx-3"
                >
                  + Add Banner
                </button>
            )}
          </div>
        </div>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className="th_cell " component="th">
                  No.
                </TableCell>

                <TableCell
                  className="th_cell col-3"
                  component="th"
                  align="center"
                >
                  <div className="d-flex  ">
                    <div>Web Image </div>
                  </div>
                </TableCell>
                <TableCell
                  className="th_cell col-3"
                  component="th"
                  align="center"
                >
                  <div className="d-flex  ">
                    <div>Mobile Image</div>
                  </div>
                </TableCell>
                <TableCell
                  className="th_cell col-3"
                  component="th"
                  align="center"
                >
                  <div className="d-flex  ">
                    <div>Title</div>
                  </div>
                </TableCell>
                <TableCell
                  className="th_cell col-3"
                  component="th"
                  align="center"
                >
                  <div className="d-flex  ">
                    <div>Linked With</div>
                  </div>
                </TableCell>

                <TableCell
                  className="table_action_head"
                  component="th"
                  align="center"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!BannerData?.length && (
                <h3 className="text-muted m-auto">No data found!</h3>
              )}
              {BannerData &&
                BannerData.length > 0 &&
                BannerData.map((row, iindex) => (
                  <TableRow
                    key={iindex}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="left" className="td_cell">
                      {searchData.offset * 10 + (iindex + 1)}
                    </TableCell>
                    <TableCell align="left" className="td_cell">
                      <Image
                        src={row?.webImage || "/icons/upload_icon.svg"}
                        alt="upload"
                        className={`pointer upload_image_label`}
                      />
                    </TableCell>
                    <TableCell align="left" className="td_cell">
                      <Image
                        src={row?.mobileImage || "/icons/upload_icon.svg"}
                        alt="upload"
                        className={`pointer upload_image_label`}
                      />
                    </TableCell>
                    <TableCell align="left" className="td_cell">
                      {row.title || "N/A"}
                    </TableCell>
                    <TableCell align="left" className="td_cell">
                      {row.linkedWith || "N/A"}
                    </TableCell>
                    <TableCell
                      className="d-flex justify-content-between table_action_item"
                      style={{ height: "113px" }}
                    >
                      {ablity({model:'Banner', action:'update'}) == 1 && (
                          <IconButton
                            className="pointer my-3 px-3"
                            onClick={() => editBanner(row.id)}
                          >
                            <SvgIcon src="/icons/edit_icon.svg" alt="view icon" />
                          </IconButton>
                      )}

                      {ablity({model:'Banner', action:'delete'}) == 1 && (
                          <IconButton
                            className="pointer my-3 px-3"
                            onClick={() => handleDeleteBanner(row.id)}
                          >
                            <SvgIcon
                              src="/icons/delete_icon.svg"
                              alt="delete icon"
                            />
                          </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <div className="float-right col-12 mb-1">
          <Stack spacing={2} className="float-right">
            <Pagination
              count={Math.ceil((BannerData?.length || 0) / 10)}
              shape="rounded"
              size="small"
              onChange={handlePageChange}
            />
          </Stack>
        </div>
      </div>
      <br />
      <br />
    </React.Fragment>
  );
}

export default Banner;
