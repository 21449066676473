import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { showToast, startLoader, stopLoader } from "../../lib/global";
import { breadcrumbAction } from "../../redux/actions/config.action";
import {
  createNewConfig,
  getConfigByKey,
  updateConfigByKey,
} from "../../redux/actions/app-config.action";

function AddConfig() {
  const { key } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [configKey, setConfigKey] = useState();
  const [name, setName] = useState();
  const [configValue, setConfigValue] = useState();

  useEffect(() => {
    const breadcrumb = [
      { label: "Config", url: "/config" },
      { label: key ? "Edit Config" : "Add Config", url: null },
    ];
    dispatch(breadcrumbAction(breadcrumb));
    if (key) {
      dispatch(getConfigByKey(key))
        .then((data) => {
          console.log(data, "config data by id");
          setConfigKey(data.data.data.key);
          setName(data.data.data.name);
          setConfigValue(data.data.data.value);
        })
        .catch((err) => {
          console.log({ err });
        });
    }
  }, []);

  const onSubmit = async () => {
    startLoader();

    let payload = {
      key: configKey,
      name: name,
      value: configValue,
    };

    if (!key) {
      dispatch(createNewConfig(payload))
        .then((res) => {
          setTimeout(() => {
            stopLoader();
            showToast("Config added successfully", "success");
            history.push("/config");
          }, 1000);
        })
        .catch((err) => {
          history.push("/config");
          showToast("Config not added", "error");
        });
    } else {
      dispatch(updateConfigByKey(payload, key))
        .then((res) => {
          setTimeout(() => {
            stopLoader();
            showToast("Config updated successfully", "success");
            history.push("/config");
          }, 1000);
        })
        .catch((err) => {
          history.push("/config");
          showToast("Config not updated", "error");
        });
    }
  };

  return (
    <>
      <div className="card m-4 p-3">
        <div>
          <span>{key ? "Edit Config" : "Add Config"}</span>
        </div>
        <br />
        <div>
          <div className="d-flex">
            <label htmlFor="#" className="col-2 imp">
              Config Key
            </label>
            <input
              type="text"
              placeholder="enter config key"
              value={configKey}
              className="px-3 col-6  form-control"
              onChange={(e) => setConfigKey(e.target.value)}
            />
          </div>
          <br />
          <div className="d-flex">
            <label htmlFor="#" className="col-2 imp">
              Config Name
            </label>
            <input
              type="text"
              placeholder="enter config name"
              value={name}
              className="px-3 col-6  form-control"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <br />
          <div className="d-flex">
            <label htmlFor="#" className="col-2 imp">
              Value
            </label>
            <input
              type="text"
              placeholder="enter config value"
              value={configValue}
              className="px-3 col-6  form-control"
              onChange={(e) => setConfigValue(e.target.value)}
            />
          </div>
        </div>
        <br />
      </div>
      <footer className="d-flex position-sticky b-0 flex-wrap justify-content-end align-items-center py-3 mt-3 bg-white border-top">
        <div className="col-md-12  ">
          <div className="d-flex justify-content-end ">
            <button
              type="button"
              className="btn  btn-outline-danger input-inside-text mr-2"
              onClick={history.goBack}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn  btn-success  input-inside-text mr-2"
              onClick={() => onSubmit()}
            >
              {key ? "Save" : "Create"}
            </button>
          </div>
        </div>
      </footer>
    </>
  );
}

export default AddConfig;
