import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,

} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SvgIcon from "../../../../../components/Images/SvgIcon";
import InputField from "../../../../../components/formControl/input/InputField";
import { getAllGroupDeals } from "../../../../../redux/actions/marketing.action";
import { stopLoader, startLoader, open_dialog } from "../../../../../lib/global";
import moment from "moment";
import { ACCESS_DENIED, DELETE_GROUP_DEAL } from "../../../../../lib/constant";
import { getLocalStorage } from "../../../../../lib/session";
import { breadcrumbAction } from "../../../../../redux/actions/config.action";
import ablity from "../../../../../helpers/abilityGuard";

const GroupDealsListing = () => {
  const [permissions, setPermissions] = useState(getLocalStorage("assignMethod"));
  const dispatch = useDispatch()
  const history  = useHistory()
  const [allGroupDeals, setGroupDeals] = useState([])

  const apiData = () => {
    startLoader()
    dispatch(getAllGroupDeals())
      .then((data) => {
        console.log(data);
        setGroupDeals(
          data &&
          data.data &&
          data.data.data.map((row, index) => {
            return {
              id: row?.id || "N/A",
              startDate: row?.startDate || "N/A",
              endDate: row?.endDate || "N/A",
              merchantLimitQuota: row?.merchantLimitQuota || 0,
              productLimitQuota: row?.productLimitQuota|| 0,
              consumerLimitQuota: row?.consumerLimitQuota|| 0
            };
          })
        );

        setTimeout(() => {
          stopLoader();
        }, 1000);
      })
      .catch((err) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
        console.log({ err });
      });
  }

  useEffect(() => {
    const breadcrumb = [
      { label: "All Promo", url: "/marketing/all-promo" },
      { label: "Group Deals", url: null }
    ];
    dispatch(breadcrumbAction(breadcrumb));

    apiData()
  }, []);

  const addProduct = () => {
      history.push(`/marketing/all-promo/group-deals/create`);
  };

  const viewAction = (groupDealsId) => {
    history.push(`/marketing/all-promo/group-deals/detail/${groupDealsId}`);
  };

  const getDataAfterDelete = () => {
    apiData()
  };

  const deleteAction = (id) => {
    open_dialog(DELETE_GROUP_DEAL, {
      onSubmit: getDataAfterDelete,
      id,
    });
  };

  return (
    <React.Fragment>
      <div className=" d-flex flex-column justify-content-between container_body vh-100">
        <div className="container-fluid">
          <div className="card p-3">
            <h5>
              GROUP DEALS LISTING
            </h5>
            <div className="card p-3 m-1 border rounded">
              <div className="mb-3">
                <h6 className="float-left">ALL GROUP DEAL</h6>
                <div className="float-right">
                  {ablity({model:'Marketing', action:'create'}) == 1 && (
                      <button
                        onClick={() => addProduct()}
                        type="button"
                        className="btn btn-dark btn-sm text-nowrap mx-3"
                      >
                        + Add Group Deal
                      </button>
                  )}
                </div>
              </div>

              <TableContainer>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
                        <div className="d-flex  ">
                          <div>Group Deal ID</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                              // onClick={() => getApiData({ item: "title", order: "ASC" })}

                            />
                            <ExpandMoreIcon
                              style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                              //onClick={() => getApiData({ item: "title", order: "DESC" })}

                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
                        <div className="d-flex  ">
                          <div>Start Date</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                              //onClick={() => getApiData({ item: "title", order: "ASC" })}

                            />
                            <ExpandMoreIcon
                              style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                              // onClick={() => getApiData({ item: "title", order: "DESC" })}

                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell "
                        component="th"
                        align="center"
                      >
                        <div className="d-flex  ">
                          <div>End Data</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                              // onClick={() => getApiData({ item: "Rating", order: "ASC" })}

                            />
                            <ExpandMoreIcon
                              style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                              // onClick={() => getApiData({ item: "Rating", order: "DESC" })}

                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell "
                        component="th"
                        align="center"
                      >
                        <div className="d-flex  ">
                          <div>Merchant Limit</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                              // onClick={() => getApiData({ item: "Rating", order: "ASC" })}

                            />
                            <ExpandMoreIcon
                              style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                              // onClick={() => getApiData({ item: "Rating", order: "DESC" })}

                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell "
                        component="th"
                        align="center"
                      >
                        <div className="d-flex  ">
                          <div>Product Limit Per Merchant</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                              // onClick={() => getApiData({ item: "Rating", order: "ASC" })}

                            />
                            <ExpandMoreIcon
                              style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                              // onClick={() => getApiData({ item: "Rating", order: "DESC" })}

                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell "
                        component="th"
                        align="center"
                      >
                        <div className="d-flex  ">
                          <div>Customer Purchase Limit</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                              // onClick={() => getApiData({ item: "Rating", order: "ASC" })}

                            />
                            <ExpandMoreIcon
                              style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                              // onClick={() => getApiData({ item: "Rating", order: "DESC" })}

                            />
                          </div>
                        </div>
                      </TableCell>

                      <TableCell className="table_action_head text-secondary" component="th">
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {allGroupDeals &&
                      allGroupDeals.length > 0 &&
                      allGroupDeals.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center" className="td_cell">
                            {row?.id}
                          </TableCell>
                          <TableCell align="center" className="td_cell">
                            {row?.startDate}
                          </TableCell>
                          <TableCell align="center" className="td_cell">
                            {row?.endDate}
                          </TableCell>

                          <TableCell align="center" className="td_cell">
                            {row?.merchantLimitQuota}
                          </TableCell>
                          <TableCell align="center" className="td_cell">
                            {row?.productLimitQuota}
                          </TableCell>
                          <TableCell align="center" className="td_cell">
                            {row?.consumerLimitQuota}
                          </TableCell>


                          <TableCell className="d-flex  table_action_item">
                            {ablity({model:'Marketing', action:'read'}) == 1 && (
                                <IconButton
                                  // onClick={() => history.push("/marketing/all-promo/flash-sale/participate-overview")}
                                  className="pointer"
                                  onClick={() => viewAction(row.id)}
                                >
                                  <SvgIcon src="/icons/view_icon.svg" alt="view icon" />
                                </IconButton>
                            )}
                            {ablity({model:'Marketing', action:'delete'}) == 1 && (
                                <IconButton
                                    className="pointer"
                                    onClick={() => deleteAction(row.id)}
                                >
                                    <SvgIcon src="/icons/delete_icon.svg" alt="view icon" />
                                </IconButton>
                            )}


                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={10}
                component="div"
                // count={allProductData.length}
                count={2}
                rowsPerPage={10}
                page={0}
                onPageChange={(e) => {
                  console.log("page change", e);
                }}
                // onRowsPerPageChange={(e)=>{console.log('page change', e)}}
              />
            </div>

          </div>
        </div>

        <footer className="bg-white p-3 ">
          <div className="d-flex justify-content-end">
            <button
              onClick={() => history.push("/marketing/all-promo")}
              type="button"
              className="btn btn-outline-dark mx-2"
              // onClick={back}
            >
              Back
            </button>


          </div>
        </footer>
      </div>
    </React.Fragment>
  )
}


export default GroupDealsListing;
