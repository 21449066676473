import React, { useEffect, useState } from "react";
import CustomizedTables from "../../components/tables";
import { Checkbox, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  startLoader,
  stopLoader,
} from "../../lib/global";
import StatusBar from "../../components/status-bar/StatusBar";
import Image from "../../components/Images/Image";
import EditIcon from '@mui/icons-material/Edit';
import CustomCheckbox from "../../components/checkbox";

const UsersPage = () => {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [activeTab, setActiveTab] = useState("Active");
  const [columns, setColumns] = useState([
    { id: "_id", label: "SL No.", type: "index" },
    {
      id: "profilePic",
      label: "Profile Pic",
      align: "left",
      component: (profilePic) => <Image className="user_dp" src={profilePic} width="50px" height="50px"/>,
    },
    { id: "name", label: "Name", align: "left" },
    { id: "email", label: "Email", align: "left" },
    { id: "gender", label: "Gender", align: "left"},
    { id: "city", label: "City", align: "left"},
    { id: "country", label: "Country", align: "left"},
    {
      id: "_id",
      label: "Action",
      component: (value, data) => {
        return (
          <div className="d-flex align-items-center">
            <Checkbox
                checked={selectedUsers?.includes(data?._id)}
                onClick={()=>handleSelectUser(data._id)}/>
            <IconButton
              disabled={data.code == "en"}
              title="Edit user details"
              onClick={() => handleEditUser(data)}
            >
              <EditIcon color="secondary" />
            </IconButton>
          </div>
        );
      },
    },
  ]);

  useEffect(() => {
    startLoader();
    getTotallData();
  }, []);

  const getTotallData = () => {
      stopLoader()
      const userData = [
          {
            _id: '4932492739874327',
            name: "Alen Jack",
            profilePic: "https://i.imgur.com/TdXCYM5.png",
            email: "alen@gmail.com",
            gender: "Male",
            city: "Chandigarh",
            country: "India"
          }
      ]
      setData(userData)
    //   const list = {
    //       skip: 0,
    //       limit: 10, 
    //       status: 1
    //   }
    // api
    //   .getUsers(list)
    //   .then((res) => {
    //     if (res && res.data && res.data.data && res.data.data.data) {
    //       setData(res.data.data.data);
    //       setTotalCount(res.data.data.totalCount);
    //       stopLoader();
    //     }
    //   })
    //   .catch((error) => {
    //     stopLoader();
    //     console.error(error);
    //   });
  };

  const handleStatusChange = (data) => {
      console.log('data', data)
    // startLoader();
    // api
    //   .changeUserStatus({ _id: data._id, status: data.status == 1 ? 0 : 1 })
    //   .then(() => getTotallData())
    //   .catch((err) => console.error(err));
  };

  const handleEditUser = (data) => {
      console.log('data', data)
    // startLoader();
    // api
    //   .changeUserStatus({ _id: data._id })
    //   .then(() => getTotallData())
    //   .catch((err) => console.error(err));
  };

  const handlePageChange = () => {
    console.log("page change");
  };

  const handleAddUser = (data) => {
      console.log('data', data)
  };

  const handleSelectUser = (id) => {
      console.log('handleSelectUser', id, selectedUsers);
    //   if(selectedUsers.includes(id)){
    //       setSelectedUsers(pre=>pre.filter(item=>item!=id))
    //   }else{
          setSelectedUsers(pre=>[...pre, id])
    //   }

  }
  return (
    <React.Fragment>
      <div className="col-12 p-3">
          <h2 className="text-start page_title">Customers</h2>
        <div className="col-12 d-flex py-3 card_bg shadow">
          <StatusBar
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            options={["Active", "Deleted", "Suspended", "Reported"]}
          ></StatusBar>
          <div className="ml-auto d-flex align-items-center">
            <button
              onClick={()=>handleStatusChange('4')}
              disabled={!selectedUsers?.length}
              title="Delete selected customers"
              className="btn btn-danger btn-sm mx-2 my-auto fit-content"
            >
              <DeleteIcon fontSize="small" /> Delete {selectedUsers?.length}
            </button>
            <button
              onClick={()=>handleStatusChange('3')}
              disabled={!selectedUsers?.length}
              title="Suspend selected customers"
              className="btn btn-warning btn-sm mx-2 my-auto fit-content"
            >
              <DeleteIcon fontSize="small" /> Suspend {selectedUsers?.length}
            </button>
          </div>
        </div>

        <div className="card shadow">
          <CustomizedTables
            tableData={data}
            columns={columns}
            totalCount={totalCount}
            handlePageChange={handlePageChange}
          ></CustomizedTables>
        </div>
      </div>

      <style jsx="true">{`
        .user_dp{
            border-radius: 20%;
        }
      `}</style>
    </React.Fragment>
  );
};

export default UsersPage;
