import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import { getOrderRecieptById } from "../../../redux/actions/finance.action";
import decimalFormat from "../../../helpers/decimal.format";
import { getLocalStorage } from "../../../lib/session";

function ViewOrderAndReceiptInfo() {
  const [item, setItem] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  
  const role = getLocalStorage("role");
  const storeId = role == "MERCHANT" ? getLocalStorage("storeId") : null;

  useEffect(() => {
    const breadcrumb = [
      { label: "Order & Receipt", url: "/finance/order-receipt" },
      { label: `Order ID ${id || ""}`, url: null },
    ];
    dispatch(breadcrumbAction(breadcrumb));
    if (id) {
      dispatch(getOrderRecieptById(id))
        .then((data) => {
          if (role == "MERCHANT" && data?.data?.data?.storeId != storeId) {
            history.push("/404");
          }
          if (data && data.data && data.data.data) {
            setItem(data?.data?.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id]);

  const netSettlement = () => {
      return Number(totalBuyerPayment()) - Number(totalDeduction())
  }

  return (
    <React.Fragment>
      <div className="m-3  position-relative">
        <div className="card p-3">
          <h5>ORDER INFORMATION</h5>
          <div className="my-2">
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right ">
                Merchant Name
              </label>
              <div className="ml-2 text-capitalize">
                {item?.merchantName || "N/A"}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Merchant ID
              </label>
              <div className="ml-2 text-capitalize">
                {item?.storeId || "N/A"}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Buyer Name
              </label>
              <div className="ml-2 text-capitalize">
                {item?.consumerName || "N/A"}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Product Name
              </label>
              <div className="ml-2 text-capitalize">
                {item?.product?.name || "N/A"}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Product Category
              </label>
              <div className="ml-2 text-capitalize">
                {item?.product?.category || "N/A"}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Order ID
              </label>
              <div className="ml-2 text-capitalize">
                {item?.order?.id || "N/A"}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Order Date
              </label>
              <div className="ml-2 text-capitalize">
                {moment(item?.order?.date).format("ll") || "11-03-2022 11:30 am"}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Transaction Type
              </label>
              <div className="ml-2 text-capitalize">{ item?.order?.transactionType || "N/A"}</div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Order Status
              </label>
              <div className="ml-2 text-capitalize">
                {item?.order?.status || "N/A"}
              </div>
              {/* <div className='ml-2 text-capitalize'>{item?.status == 'PROCESSING' ? ' To Ship' : item?.status == 'PENDINGPAYMENT' ? 'TO PAY' : "DELIVERED" || "N/A"}</div> */}
            </div>
          </div>
        </div>
        <br />
        <div className="card p-3">
          <h5>PAYMENT INFORMATION</h5>
          <div className="my-2">
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Payment Method
              </label>
              <div className="ml-2 text-capitalize">
                { item?.payment?.method ||"N/A"}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Payment Date
              </label>
              <div className="ml-2 text-capitalize">
                {moment(item?.payment?.date).format("ll") || "N/A"}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Ref ID
              </label>
              <div className="ml-2 text-capitalize">
                {item?.payment?.refId || "N/A"}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Product Price
              </label>
              <div className="ml-2 text-capitalize">{`RM${
                decimalFormat(item?.product?.price || 0) || 0
              }`}</div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Shipping Fee
              </label>
              <div className="ml-2 text-capitalize">
                RM{decimalFormat(item?.fee?.shipping || 0)}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Discount/Rebate
              </label>
              <div className="ml-2 text-capitalize">
                RM{decimalFormat(item?.payment?.discount || 0)}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Total Paid Amount
              </label>
              <div className="ml-2 text-success font-weight-bold">{`RM ${decimalFormat(item?.payment?.buyerPaid || 0)}`}</div>
            </div>
          </div>
        </div>
        <br />
        <div className="card p-3">
          <h5>TOTAL DEDUCTION BY UU2</h5>
          <div className="my-2">
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Service Fee
              </label>
              <div className="ml-2">
                RM
                {`${decimalFormat(item?.fee?.service || 0)}`}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Transaction Fee
              </label>
              <div className="ml-2">
                RM
                {`${decimalFormat(item?.fee?.transaction || 0)}`}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Commission Fee
              </label>
              <div className="ml-2">
                RM
                {`${decimalFormat(item?.fee?.commission || 0)}`}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Discount/Rebate
              </label>
              <div className="ml-2">
                RM
                {`${decimalFormat(item?.payment?.discount || 0)}`}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Settlement Status
              </label>
              <div className="ml-2">{item?.order?.status}</div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Total Deduction
              </label>
              <div className="ml-2 text-success font-weight-bold">{`RM ${decimalFormat(item?.fee?.total || 0)}`}</div>
            </div>
          </div>
        </div>
        <br />
      </div>
      <footer className="d-flex position-sticky b-0 flex-wrap justify-content-end align-items-center pt-3 mt-3 bg-white border-top">
        <div className="col-md-12 d-flex align-items-center mr-2 justify-content-between">
          <div className="col-8">
            <p className="mb-0">Net Settlement Amount To Merchant</p>
            <h5 className="font-weight-bold text-danger">
              {`RM ${decimalFormat(item?.merchantIncome || 0)}`}
            </h5>
          </div>
          <button
            type="button"
            className="btn  btn-outline-danger input-inside-text mr-2"
            onClick={history.goBack}
          >
            Cancel
          </button>
          {/* <button
                        type="button"
                        className="btn border btn-dark input-inside-text mr-2"
                        onClick={history.goBack}
                    >
                        Create
                    </button> */}
        </div>
      </footer>
    </React.Fragment>
  );
}

export default ViewOrderAndReceiptInfo;
