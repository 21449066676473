import React from 'react';
import "../../../static/css/consumerCenterCss/consumerCenterOverview.css"


function ConsumerCenterOverview() {
    return (
        <React.Fragment>
            <div className='customer_center_overview_main_container'>
                <div className='product_performance_overview'>
                    <div className=' customer_center_overview_head'>
                        <div>
                            <p>OVERVIEW</p>
                        </div>
                        <div className=' customer_center_overview_dateBox'>
                            <input type="date" />
                        </div>

                    </div>
                    <div className='product_performance_overview_body'>
                        <div className="product_performance_overview_body_itembox">
                            <div className='product_performance_overview_body_itemboxtop'>
                                <div className='product_performance_overview_body_itemboxtop_title'>Total Consumer</div>
                                <div className='product_performance_overview_body_itemboxtop_detail'>1,289</div>
                            </div>

                        </div>

                        <div className="product_performance_overview_body_itembox">
                            <div className='product_performance_overview_body_itemboxtop'>
                                <div className='product_performance_overview_body_itemboxtop_title'>New Consumer</div>
                                <div className='product_performance_overview_body_itemboxtop_detail'>2</div>
                            </div>

                        </div>

                        <div className="product_performance_overview_body_itembox">
                            <div className='product_performance_overview_body_itemboxtop'>
                                <div className='product_performance_overview_body_itemboxtop_title'>Active Consumer</div>
                                <div className='product_performance_overview_body_itemboxtop_detail'>1,234</div>
                            </div>

                        </div>

                        <div className="product_performance_overview_body_itembox">
                            <div className='product_performance_overview_body_itemboxtop'>
                                <div className='product_performance_overview_body_itemboxtop_title'>Inactive Consumer</div>
                                <div className='product_performance_overview_body_itemboxtop_detail'>2</div>
                            </div>

                        </div>

                        <div className="product_performance_overview_body_itembox">
                            <div className='product_performance_overview_body_itemboxtop'>
                                <div className='product_performance_overview_body_itemboxtop_title'>Block Consumer</div>
                                <div className='product_performance_overview_body_itemboxtop_detail'>0</div>
                            </div>

                        </div>




                    </div>


                </div>
                <div className='customer_center_overview_exportBtn '>
                    <button type="button" className="btn btn-md btn-dark px-4">Export</button>
                </div>
                <div className='product_performance_overview_chart'>

                </div>
            </div>
        </React.Fragment>
    )
}

export default ConsumerCenterOverview;
