import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SvgIcon from "../../../../../../components/Images/SvgIcon";
import { getAllGroupDealsProduct } from "../../../../../../redux/actions/marketing.action";
import {
  stopLoader,
  startLoader,
  open_dialog,
  parseQuery,
} from "../../../../../../lib/global";
import {
  ACCESS_DENIED,
  DELETE_GROUP_DEAL_PRODUCT,
} from "../../../../../../lib/constant";
import { getLocalStorage } from "../../../../../../lib/session";
import { breadcrumbAction } from "../../../../../../redux/actions/config.action";
import { v2GetAllGroupDealsProduct } from "../../../../../../redux/actions/merchant/marketing.merchant.action";

const MerchantGroupDealsProductListing = () => {
  const merchantId = getLocalStorage("storeId");
  const dispatch = useDispatch();
  const history = useHistory();
  const { groupDealsId } = parseQuery(history.location.search) || {};
  const [allGroupDealsProduct, setGroupDealsProduct] = useState([]);

  const apiData = (payload) => {
    startLoader();
    // dispatch(getAllGroupDealsProduct(payload))
      dispatch(v2GetAllGroupDealsProduct(payload))
      .then((data) => {
        console.log(data, "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
        setGroupDealsProduct(
          data &&
            data.data &&
            data.data.data.map((row, index) => {
              console.log(row);
              return {
                id: row?.groupDealsProductId || "N/A",
                title: row?.product.title || "N/A",
              };
            })
        );

        setTimeout(() => {
          stopLoader();
        }, 1000);
      })
      .catch((err) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
        console.log({ err });
      });
  };

  useEffect(() => {
    const breadcrumb = [
      { label: "All Promo", url: "/merchant/marketing/promo" },
      { label: "Group Deals", url: "/merchant/marketing/promo/group-deals" },
      { label: "Product", url: null },
    ];
    dispatch(breadcrumbAction(breadcrumb));

    let payload = {
      groupDealsId: groupDealsId,
      merchantId: merchantId,
    };
    apiData(payload);
  }, []);

  const addProduct = () => {
    history.push(
      `/merchant/marketing/promo/group-deals-product/create?groupDealsId=${groupDealsId}`
    );
  };

  const viewAction = (groupDealsProductId) => {
    history.push(
      `/merchant/marketing/promo/group-deals-product/detail/${groupDealsProductId}?groupDealsId=${groupDealsId}`
    );
  };

  const editAction = (groupDealsProductId) => {
    history.push(
      `/merchant/marketing/promo/group-deals-product/edit/${groupDealsProductId}?groupDealsId=${groupDealsId}`
    );
  };

  const getDataAfterDelete = () => {
    let payload = {
      groupDealsId: groupDealsId,
      merchantId: merchantId,
    };
    apiData(payload);
  };

  const deleteAction = (id) => {
    open_dialog(DELETE_GROUP_DEAL_PRODUCT, {
      onSubmit: getDataAfterDelete,
      id,
      groupDealsId: groupDealsId,
    });
  };

  return (
    <React.Fragment>
      <div className=" d-flex flex-column justify-content-between container_body vh-100">
        <div className="container-fluid">
          <div className="card p-3">
            <h5>GROUP DEALS PRODUCT</h5>
            <div className="card p-3 m-1 border rounded">
              <div className="mb-3">
                <h6 className="float-left"></h6>
                <div className="float-right">
                  <button
                    onClick={() => addProduct()}
                    type="button"
                    className="btn btn-dark btn-sm text-nowrap mx-3"
                  >
                    + Add Group Deal Product
                  </button>
                </div>
              </div>

              <TableContainer>
                <Table
                  sx={{ minWidth: 650 }}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex  ">
                          <div>Product Name</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              // onClick={() => getApiData({ item: "title", order: "ASC" })}
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              //onClick={() => getApiData({ item: "title", order: "DESC" })}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="table_action_head text-secondary"
                        component="th"
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allGroupDealsProduct &&
                      allGroupDealsProduct.length > 0 &&
                      allGroupDealsProduct.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left" className="td_cell">
                            {row?.title}
                          </TableCell>
                          <TableCell className="d-flex  table_action_item">
                            <IconButton
                              className="pointer"
                              onClick={() => viewAction(row.id)}
                            >
                              <SvgIcon
                                src="/icons/view_icon.svg"
                                alt="view icon"
                              />
                            </IconButton>
                            <IconButton
                              className="pointer"
                              onClick={() => editAction(row.id)}
                            >
                              <SvgIcon
                                src="/icons/edit_icon.svg"
                                alt="view icon"
                              />
                            </IconButton>
                            <IconButton
                              className="pointer"
                              onClick={() => deleteAction(row.id)}
                            >
                              <SvgIcon
                                src="/icons/delete_icon.svg"
                                alt="view icon"
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={10}
                component="div"
                // count={allProductData.length}
                count={2}
                rowsPerPage={10}
                page={0}
                onPageChange={(e) => {
                  console.log("page change", e);
                }}
                // onRowsPerPageChange={(e)=>{console.log('page change', e)}}
              />
            </div>
          </div>
        </div>

        <footer className="bg-white p-3 ">
          <div className="d-flex justify-content-end">
            <button
              onClick={() =>
                history.push("/merchant/marketing/promo/group-deals")
              }
              type="button"
              className="btn btn-outline-dark mx-2"
              // onClick={back}
            >
              Back
            </button>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default MerchantGroupDealsProductListing;
