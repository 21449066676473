import {
    IconButton,
    Pagination,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SvgIcon from "../../../components/Images/SvgIcon";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import "../../../static/css/productCss/ProductBrand.css";
import {
    open_dialog,
    showToast,
    startLoader,
    stopLoader,
} from "../../../lib/global";
import { MERCHANT_IMPORT_PRODUCT_DIALOG } from "../../../lib/constant";
import PagenationDropdown from "../../../components/DropDown/pagenationDropdown";
import ablity from "../../../helpers/abilityGuard";
import { uploadImportProduct, templateImportProduct, getProductsImport, downloadCategoryProduct, downloadBrandProduct } from "../../../redux/actions/merchant/product.action";


const ProductImport = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState(0);

    const [pageNo, setPageNo] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const limit = useRef(10);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const breadcrumb = [{ label: "Product Import", url: null }];
        dispatch(breadcrumbAction(breadcrumb));
        startLoader();

        let filteringdata;
        if (pageLimit != limit.current) {
            limit.current = pageLimit;
            setPageNo(1);
            setOffset(0);
            filteringdata = {
                limit: pageLimit,
            };
        } else {
            filteringdata = {
                offset,
                limit: pageLimit,
            };
        }
        apiData(filteringdata);
    }, [offset, pageLimit]);

    const apiData = (filteringdata) => {
        dispatch(getProductsImport(filteringdata))
            .then((data) => {
                setTimeout(() => {
                    stopLoader();
                }, 1000);
                console.log(data.data.data)
                if (data && data.data.data) {
                    setData(
                        data &&
                        data.data &&
                        data.data.data.map((row, index) => {
                            return {
                                productName: row?.productName || "N/A",
                                uploadDatetime: row?.uploadDatetime || 0,
                                uploadBy: row?.uploadBy || 0,
                                status: row?.status || 0,
                                errorMessage: row?.errorMessage || "",
                            };
                        })
                    );
                    setTotalData(data && data.data.total && data.data.total.total);
                }
            })
            .catch((error) => {
                console.log({ error }, "kkkkkkkkkkkkkkkkkkkkkk");
                setTimeout(() => {
                    stopLoader();
                }, 1000);
            });
    };

    const handlePageChange = (event, value) => {
        setOffset(value - 1);
        setPageNo(value);
    };

    const handleSorting = (item, sortType, isDate = false) => {
        startLoader();
        try {
            if (typeof brandData[0][item] === "number") {
                let sortedData = brandData.sort((a, b) => {
                    if (sortType == "asc") {
                        return a[item] - b[item];
                    } else if (sortType == "desc") {
                        return b[item] - a[item];
                    }
                });
                setTimeout(() => {
                    setBrandData([...sortedData]);
                    stopLoader();
                }, [1000]);
            } else if (typeof brandData[0][item] === "string" && !isDate) {
                let sortedData = brandData.sort((a, b) => {
                    let fa = a[item].toLowerCase(),
                        fb = b[item].toLowerCase();
                    if (sortType == "asc") {
                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    } else if (sortType == "desc") {
                        if (fa < fb) {
                            return 1;
                        }
                        if (fa > fb) {
                            return -1;
                        }
                        return 0;
                    }
                });
                console.log(sortedData, "llllllllll");
                setTimeout(() => {
                    setBrandData([...sortedData]);
                    stopLoader();
                }, [1000]);
            } else if (typeof brandData[0][item] === "string" && isDate) {
                let sortedData = brandData.sort((a, b) => {
                    let da = new Date(a[item]);
                    let db = new Date(b[item]);

                    console.log(da, db);

                    if (sortType == "asc") {
                        return da - db;
                    } else if (sortType == "desc") {
                        return db - da;
                    }
                });
                setTimeout(() => {
                    setBrandData([...sortedData]);
                    stopLoader();
                }, [1000]);
            }
        } catch (error) {
            console.log({ error });
            stopLoader();
        }
    };

    const handleImportProduct = () => {
        open_dialog(MERCHANT_IMPORT_PRODUCT_DIALOG, {
            onSubmit: onSubmitImportProduct,
        });
    };

    const handleDownloadFormatImport = async () => {
        startLoader()
        dispatch(templateImportProduct())
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `template_product.xlsx`);
                // Append to html link element page
                document.body.appendChild(link);
                setTimeout(() => {
                    // Start download
                    link.click();
                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    stopLoader()
                }, 1000)
            }).catch(err => {
                console.log({ err });
                alert('error .somthing is wrong')
                stopLoader()
            })
    }

    const onSubmitImportProduct = (dataModal) => {
        let file = dataModal.file;

        if (file == undefined || file == null) {
            startLoader();
            setTimeout(() => {
                showToast("No file chosen!", "error");
                stopLoader();
            }, 1000);
        } else if (file !== null) {
            let formData = new FormData();
            formData.append("file", file);
            for (let key in dataModal) {
                if (key !== "file") {
                    formData.append(key, dataModal[key]);
                }
            }


            dispatch(uploadImportProduct(formData))
                .then(({ data }) => {
                    if (data.status == 201) {
                        setTimeout(() => {
                            stopLoader();
                            // if (data.data) {
                            //   setResult(data.data);
                            // }
                            console.log(data.data)
                            apiData({ offset, limit: pageLimit });
                            showToast(data.message, "success");
                        }, 1000);
                    }
                })
                .catch((err) => {
                    startLoader();
                    setTimeout(() => {
                        showToast(err.message, "error");
                        stopLoader();
                    }, 1000);
                });
        }

    };

    const handleDownloadBrand = async () => {
        startLoader()
        dispatch(downloadBrandProduct())
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `brand.xlsx`);
                // Append to html link element page
                document.body.appendChild(link);
                setTimeout(() => {
                    // Start download
                    link.click();
                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    stopLoader()
                }, 1000)
            }).catch(err => {
                console.log({ err });
                alert('error .somthing is wrong')
                stopLoader()
            })
    }

    const handleDownloadCategory = async () => {
        startLoader()
        dispatch(downloadCategoryProduct())
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `category.xlsx`);
                // Append to html link element page
                document.body.appendChild(link);
                setTimeout(() => {
                    // Start download
                    link.click();
                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    stopLoader()
                }, 1000)
            }).catch(err => {
                console.log({ err });
                alert('error .somthing is wrong')
                stopLoader()
            })
    }

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="card px-4 py-3 bg-body bg-white rounded shadow-sm">
                    <span>PRODUCT IMPORT LISTING</span>
                    <div className="border card p-3 my-3">
                        <div className="d-flex justify-content-between">
                            <span>{`All Product Import (${totalData})`}</span>
                            <div className="d-flex my-3">
                                <button
                                    onClick={handleDownloadFormatImport}
                                    type="button"
                                    className="btn btn-success btn-sm text-nowrap mx-3"
                                >
                                    Download Template
                                </button>
                                <button
                                    onClick={handleDownloadBrand}
                                    type="button"
                                    className="btn btn-success btn-sm text-nowrap mx-3"
                                >
                                    Download Brand
                                </button>
                                <button
                                    onClick={handleDownloadCategory}
                                    type="button"
                                    className="btn btn-success btn-sm text-nowrap mx-3"
                                >
                                    Download Category
                                </button>


                                <button
                                    onClick={handleImportProduct}
                                    type="button"
                                    className="btn btn-warning btn-sm text-nowrap mx-3"
                                >
                                    Import product
                                </button>
                            </div>
                        </div>
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 650 }}
                                stickyHeader
                                aria-label="sticky table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="th_cell" component="th">
                                            No
                                        </TableCell>
                                        <TableCell className="th_cell" component="th" align="left">
                                            <div className="d-flex  ">
                                                <div>Product Name</div>
                                                <div className="d-flex flex-column ">
                                                    <ExpandLessIcon
                                                        style={{
                                                            width: "15px",
                                                            height: "15px",
                                                            margin: "0px 3px",
                                                        }}

                                                        onClick={() => handleSorting("productName", "asc")}
                                                    />
                                                    <ExpandMoreIcon
                                                        style={{
                                                            width: "15px",
                                                            height: "15px",
                                                            margin: "-5px 3px",
                                                        }}

                                                        onClick={() => handleSorting("productName", "desc")}
                                                    />
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className="th_cell"
                                            component="th"
                                            align="center"
                                        >
                                            <div className="d-flex  ">
                                                <div>Upload Time</div>
                                                <div className="d-flex flex-column ">
                                                    <ExpandLessIcon
                                                        style={{
                                                            width: "15px",
                                                            height: "15px",
                                                            margin: "0px 3px",
                                                        }}
                                                        onClick={() => handleSorting("uploadDatetime", "asc")}
                                                    />
                                                    <ExpandMoreIcon
                                                        style={{
                                                            width: "15px",
                                                            height: "15px",
                                                            margin: "-5px 3px",
                                                        }}
                                                        onClick={() => handleSorting("uploadDatetime", "desc")}
                                                    />
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className="th_cell"
                                            component="th"
                                            align="center"
                                        >
                                            <div className="d-flex  ">
                                                <div>Upload By</div>
                                                <div className="d-flex flex-column ">
                                                    <ExpandLessIcon
                                                        style={{
                                                            width: "15px",
                                                            height: "15px",
                                                            margin: "0px 3px",
                                                        }}
                                                        onClick={() => handleSorting("uploadBy", "asc")}
                                                    />
                                                    <ExpandMoreIcon
                                                        style={{
                                                            width: "15px",
                                                            height: "15px",
                                                            margin: "-5px 3px",
                                                        }}
                                                        onClick={() => handleSorting("uploadBy", "desc")}
                                                    />
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className="th_cell"
                                            component="th"
                                            align="center"
                                        >
                                            <div className="d-flex  ">
                                                <div>Status</div>
                                                <div className="d-flex flex-column ">
                                                    <ExpandLessIcon
                                                        style={{
                                                            width: "15px",
                                                            height: "15px",
                                                            margin: "0px 3px",
                                                        }}
                                                        onClick={() =>
                                                            handleSorting("status", "asc")
                                                        }
                                                    />
                                                    <ExpandMoreIcon
                                                        style={{
                                                            width: "15px",
                                                            height: "15px",
                                                            margin: "-5px 3px",
                                                        }}
                                                        onClick={() =>
                                                            handleSorting("status", "desc")
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell className="th_cell" component="th" align="left">
                                            <div className="d-flex  ">
                                                <div>Error Message</div>
                                                <div className="d-flex flex-column ">
                                                    <ExpandLessIcon
                                                        style={{
                                                            width: "15px",
                                                            height: "15px",
                                                            margin: "0px 3px",
                                                        }}
                                                        onClick={() => handleSorting("errorMessage", "asc")}
                                                    />
                                                    <ExpandMoreIcon
                                                        style={{
                                                            width: "15px",
                                                            height: "15px",
                                                            margin: "-5px 3px",
                                                        }}
                                                        onClick={() => handleSorting("errorMessage", "desc")}
                                                    />
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!data?.length && (
                                        <div className="d-flex justify-content-center">
                                            <h3 className="text-muted m-auto">No data found!</h3>
                                        </div>
                                    )}
                                    {data.map((row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{
                                                "&:last-child td, &:last-child th": { border: 0 },
                                            }}
                                        >
                                            <TableCell align="center" className="td_cell">
                                                {offset * pageLimit + (index + 1)}
                                            </TableCell>
                                            <TableCell align="left" className="td_cell capitalize">
                                                {row.productName || "N/A"}
                                            </TableCell>
                                            <TableCell align="center" className="td_cell">
                                                {row.uploadDatetime || ""}
                                            </TableCell>
                                            <TableCell align="center" className="td_cell">
                                                {row.uploadBy || ""}
                                            </TableCell>
                                            <TableCell align="center" className={`td_cell ${row.status == 'success' ? 'text-success' : 'text-danger'}`}>
                                                {row.status || ""}
                                            </TableCell>
                                            <TableCell align="left" className="td_cell text-danger">
                                                <span>{row.errorMessage || ""}</span>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <br />
                        <hr />
                        <div className="d-flex justify-content-between align-items-center">
                            <PagenationDropdown setPageQuant={setPageLimit} />

                            <div className="float-right mb-1">
                                <Stack spacing={2}>
                                    <Pagination
                                        count={Math.ceil((totalData || 0) / pageLimit)}
                                        shape="rounded"
                                        size="small"
                                        page={pageNo}
                                        onChange={handlePageChange}
                                    />
                                </Stack>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ProductImport;
