import React, { useEffect } from "react";
import {
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,

} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SvgIcon from "../../../../../components/Images/SvgIcon";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import SelectInput from "../../../../../components/select";
import { breadcrumbAction } from "../../../../../redux/actions/config.action";
import InputField from "../../../../../components/formControl/input/InputField";
import Image from "../../../../../components/Images/Image";

import { useState } from "react";
import { CREATE_LIVESTREAM_ADD_PRODUCT } from "../../../../../lib/constant";
import { open_dialog, showToast, startLoader, stopLoader, UploadImage } from "../../../../../lib/global";
import { IOSSwitch } from "../../../../../components/switch/IOSSwitch";
import { searchMerchantList } from "../../../../../redux/actions/merchant-center.action";
import { MAX_IMAGE_SIZE } from "../../../../../lib/config";
import { createLiveStream, getLiveStreamById, updateLiveStream } from "../../../../../redux/actions/marketing.action";
import { getAllProductList } from "../../../../../redux/actions/product.action";


const initialData2 = [
  {
    id:'7f48bcb4-bd83-4bbd-8c02-f0429dabd350',
    voucherName: "RM 5.0 OFF with min. spend of RM 50.0",
    voucherCode: "ADKLXNCMZ",
    voucherType: "Streaming Exclusive",
    startDate: "2022-09-01",
    endDate: "2022-09-30",
    remain:30,
    status: 0
  },
  {
    id:'f984ecba-e8a6-41d5-8056-8e62cbd32985',
    voucherName: "RM 3.0 OFF with min. spend of RM 50.0",
    voucherCode: "SLKXNCNZ",
    voucherType: "Streaming Exclusive",
    startDate: "2022-09-01",
    endDate: "2022-09-30",
    status: 1
  }
];
const CreateLiveStream = () => {
  const { liveStreamId } = useParams();
  const [allProductData, setallProductData] = useState([]);
  const [voucherData, setVoucherData] = useState(initialData2);
  const [merchantList, setMerchantList] = useState([]);
  const [webImageFile, setWebImageFile] = useState();
  const [webImageUrl, setWebImageUrl] = useState();
  const [livestreamData, setLivestreamData] = useState({
    name:"",
    description:"",
    productLimit:10,
    isTest:false,
    merchant:null,
  });
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const breadcrumb = [
      { label: "Livestream", url: "/marketing/all-promo/livestream" },
      { label: liveStreamId ? "Edit Livestream" : "Create Livestream", url: null },
    ];
    dispatch(breadcrumbAction(breadcrumb));
    getMerchantList();
    if(liveStreamId){
      // startLoader();
      dispatch(getLiveStreamById(liveStreamId))
        .then((response) => {
          let temp = response.data.data;
          delete temp.createdAt
          delete temp.updatedAt
          setWebImageFile(temp.coverImage);
          setLivestreamData(temp);
          setallProductData(temp.products);
        })
        .catch((error) => {
          console.log({ error });
        });
    }
  },[])

  const handelAddProduct = () => {
    open_dialog(CREATE_LIVESTREAM_ADD_PRODUCT, {
      data: livestreamData,
      onSubmit: selectProductSuccess,
    });
  };

  const selectProductSuccess = (data) => {
    if(data != []){
      let temp = [...allProductData];
      data.map((item) => {
        item.variationList.map((variation) => {
          variation.discountPrice = 0
          variation.campaignStock = 0
          variation.unitCapped = 0
          variation.isActive = false
          variation.teamDiscountPrice = 0
          variation.teamCampaignStock = 0
          variation.teamUnitCapped = 0
          variation.teamIsActive = false
        })
        temp.push(item);
      })
      console.log(temp)
      setallProductData(temp)
    }
  };

  const onChangeLivestreamData = (e, opt, val) => {
    const key = opt || e.target.name;
    const value = val || e.target.value;
    setLivestreamData((pre) => ({ ...pre, [key]: value }));
  };

  const toggleLivestreamData = (e, opt, val) => {
    const value = e.target.checked;
    let temp = livestreamData;
    temp.isTest = value
    setLivestreamData(temp);
  };

  const onChangeProductData = (e, opt,variationIndex) => {
    const key = e.target.name;
    const value = e.target.value;
    let temp = []
    allProductData.map((item, index) => {
      let tempProduct = item;
      if(index == opt){
        tempProduct.variationList.map((variationItem, index2) => {
          if(index2 == variationIndex) {
            if(key == "discountPrice"){
              variationItem.discountPrice = value;
            }else if(key == "campaignStock"){
              variationItem.campaignStock = value;
            }else if(key == "unitCapped"){
              variationItem.unitCapped = value;
            }else if(key == "isActive"){
              variationItem.isActive = e.target.checked;
            }if(key == "teamDiscountPrice"){
              variationItem.teamDiscountPrice = value;
            }else if(key == "teamCampaignStock"){
              variationItem.teamCampaignStock = value;
            }else if(key == "teamUnitCapped"){
              variationItem.teamUnitCapped = value;
            }else if(key == "teamIsActive"){
              variationItem.teamIsActive = e.target.checked;
            }
          }
        })
      }
      temp.push(tempProduct);
    })
    setallProductData(temp)
  }


  const getMerchantList = (search) => {
    dispatch(searchMerchantList(search))
      .then((response) => {
        if (response?.data?.data) {
          const data = response.data.data.map((item) => ({
            title: item.merchantName,
            label: `${item.merchantName} - (${item.storeId})`,
            value: item.storeId,
          }));
          // console.log(data, "data");
          setMerchantList(data);
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const handleUploadFile = async (e) => {
    const fileData = e.target.files[0];
    if (fileData.size > MAX_IMAGE_SIZE) {
      showToast(
        `Image with size more than ${
          MAX_IMAGE_SIZE / 1000000
        }MB is not supported.\nPlease upload image of size smaller than ${
          MAX_IMAGE_SIZE / 1000000
        }MB.\nCurrent file size: ${(fileData.size / 1000000).toFixed(2)}MB`,
        "error",
        3000,
        "bottom",
        "center"
      );
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(fileData);
    reader.onload = function () {
      const imageUrlIns = reader.result;
      setWebImageFile(imageUrlIns);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const onSubmit = async () => {
    startLoader();

    let coverUrl = webImageFile? await UploadImage(webImageFile):webImageUrl;

    let payload = {
      ...livestreamData,
      coverImage: coverUrl,
      products: allProductData,
    };
    if (!payload.id) {
      dispatch(createLiveStream(payload))
        .then((res) => {
          setTimeout(() => {
            stopLoader();
            showToast("Livestream added successfully", "success");
            history.push("/merchant/marketing/promo/livestream");
          }, 1000);
        })
        .catch((err) => {
          stopLoader();
          console.log({ err });
          // history.push("/banner");
        });
    } else {
      dispatch(updateLiveStream(payload))
        .then((res) => {
          console.log(res, "res res");
          setTimeout(() => {
            stopLoader();
            showToast("Livestream updated successfully", "success");
            history.push("/merchant/marketing/promo/livestream");
          }, 1000);
        })
        .catch((err) => {
          console.log({ err });
          // history.push("/banner");
        });
    }
  };

  return (
    <React.Fragment>
      <div className="container-fluid ">
        <div className="card p-3">
          <h6>DETAILS OF LIVESTREAM</h6>
          <form className="mt-3 p-0">
            <div className="col-12 row my-3">
              <label className="col-3 text-right m-0 p-0 small imp">
                Merchant
              </label>
              <div className="form-group col-6">
                <SelectInput
                  options={merchantList}
                  name="merchant"
                  value={livestreamData.merchant}
                  onChange={(e) => onChangeLivestreamData(e, "merchant", e)}
                  required={true}
                  placeholder="Select Merchant"
                />
              </div>
            </div>
            <div className="col-12 row my-3">
              <label className="col-3 text-right m-0 p-0 small imp">
                Cover
              </label>
              <div className="icon-button-file col-9 d-flex flex-wrap">
                <div className="mx-2">
                  <input
                    accept="image/*"
                    id={`livestream-image`}
                    className="d-none"
                    onChange={(e) => handleUploadFile(e)}
                    multiple={false}
                    type="file"
                  />
                  <label htmlFor={`livestream-image`}>
                    <Image
                      src={ webImageFile || webImageUrl ||"/icons/upload_icon.svg"}
                      alt="upload"
                      className={`pointer upload_image_label`}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="col-12 row my-3">
              <label
                htmlFor="product-name"
                className="col-3 text-right m-0 p-0 small imp"
              >
                Title
              </label>
              <InputField
                className=""
                formClass="col-6"
                type="text"
                id="name"
                placeholder="Title"
                name="name"
                value={livestreamData.name}
                // onChange={(e) => onChangeLivestreamData(e, "title", e)}
                onChange={onChangeLivestreamData}
                required={true}
              />
            </div>
            <div className="col-12 row my-3">
              <label
                htmlFor="product-name"
                className="col-3 text-right m-0 p-0 small imp"
              >
                Description
              </label>
              <textarea
                id="textarea"
                className="form-control col-md-6 ml-3"
                style={{ minHeight: "10vh" }}
                value={livestreamData.description}
                onChange={(e) => onChangeLivestreamData(e, "description", e.target.value)}
                placeholder="Description"
              />
            </div>

            <div className="col-12 row my-3">
              <label className="col-3 my-auto text-right small imp">
                Test
              </label>
              <div className="form-group col-9 m-0">
                <IOSSwitch
                  name="isTest"
                  id="isTest"
                  onChange={toggleLivestreamData}
                  // checked={(e) => toggleLivestreamData(e, "is-test", e)}
                  value={livestreamData?.isTest}
                  className="mt-2"
                />
                {livestreamData.isTest}
              </div>
            </div>
            <div className="col-12 row my-3">
              <label
                htmlFor="product-limit"
                className="col-3 text-right m-0 p-0 small imp"
              >
                Livestream Product Limit
              </label>
              <InputField
                className=""
                formClass="col-6"
                type="number"
                id="productLimit"
                placeholder="Input"
                name="productLimit"
                value={livestreamData.productLimit}
                onChange={onChangeLivestreamData}
                required={true}
              />
            </div>
            <div className="col-12 row my-3">
              <label
                htmlFor="product-name"
                className="col-3 text-right m-0 p-0 small imp"
              >
                Link
              </label>
              <InputField
                className=""
                formClass="col-6"
                type="text"
                id="link"
                placeholder="Link"
                name="link"
                value={livestreamData.link}
                // onChange={(e) => onChangeLivestreamData(e, "title", e)}
                onChange={onChangeLivestreamData}
                required={true}
              />
            </div>
          </form>
        </div>
        <br />

        <div className="card p-3">
          <div className="d-flex justify-content-between">
            <div className="">
              <h6 className="m-0">PRODUCTS({allProductData.length}/{livestreamData.productLimit})</h6>
              <label className="text-muted  font-size-account">
                Add products from your shop, wishlist or purchased
              </label>
            </div>
            <div className="">
              <button
                onClick={() => handelAddProduct()}
                className="btn btn-dark px-2"
                disabled={livestreamData.merchant == null}
              >
                + Add Product
              </button>
            </div>
          </div>
          <div className="ml-3 mr-5">
            <hr />
          </div>
          <div>
            {allProductData &&
            allProductData.length > 0 &&
            allProductData.map((item, indexItem) => (
              <div className="">
                <div className="row p-4">
                  <div className="float-left d-flex align-items-center flex-nowrap ">
                    <Image
                      src={item.productImages[0]}
                      alt={'photo'}
                      className="teamPurchase_page_product_image"
                    />
                    <div className="px-2">{item.title}</div>
                  </div>
                </div>
                <h4>Individual Variation List</h4>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 650 }}
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="bg-light">
                      <TableRow>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Variation(s)</div>
                            <div className="d-flex flex-column ">
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Price</div>
                            <div className="d-flex flex-column ">
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Discounted Price</div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Campaign Stock</div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Stock</div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Unit Capped</div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="table_action_head text-secondary"
                          component="th"
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item.variationList &&
                        item.variationList.length > 0 &&
                        item.variationList.map((row, indexVariation) => (
                          <TableRow
                            key={item.id+'-'+indexVariation}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="left" className="td_cell">
                              {row.Type?row.Type:row.Size}
                            </TableCell>
                            <TableCell align="left" className="td_cell">
                              RM. {row?.price || "0"}
                            </TableCell>
                            <TableCell align="left" className="td_cell border-0">
                              <InputField
                                className="input_icon_left"
                                formClass="col-12 p-0"
                                type="number"
                                id="discountPrice"
                                icon="currency"
                                placeholder="Input"
                                name="discountPrice"
                                value={row?.discountPrice}
                                onChange={(e)=> onChangeProductData(e, indexItem,indexVariation)}
                                required={true}
                              />
                            </TableCell>
                            <TableCell align="left" className="td_cell border-0">
                              <input
                                type="number"
                                id="campaignStock"
                                name="campaignStock"
                                placeholder="Input"
                                className="w-75 form-control"
                                value={row?.campaignStock}
                                onChange={(e)=> onChangeProductData(e, indexItem,indexVariation)}
                                required={true}
                              />
                            </TableCell>
                            <TableCell align="left" className="td_cell border-0">
                              {row?.stock || "N/A"}
                            </TableCell>
                            <TableCell align="left" className="td_cell border-0">
                              <input
                                type="text"
                                placeholder="Input"
                                className="w-75 form-control"
                                id="unitCapped"
                                name="unitCapped"
                                value={row?.unitCapped}
                                onChange={(e)=> onChangeProductData(e, indexItem,indexVariation)}
                                required={true}
                              />
                            </TableCell>
                            <TableCell className="d-flex table_action_item">
                              <IOSSwitch
                                name="isActive"
                                checked={row.isActive == true? true : false}
                                onChange={(e)=> onChangeProductData(e, indexItem,indexVariation)}
                                value={row?.isActive}
                                className="mt-2"
                              />
                              <IconButton
                                // onClick={() => history.push(`edit?id=${row.productId}`)}
                                className="pointer"
                                // onClick={() => editAction(row.productId, permissions?.product?.includes('update'))}
                              >
                                <SvgIcon
                                  src="/icons/delete_icon.svg"
                                  alt="view icon"
                                />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <h4>Team Variation List</h4>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 650 }}
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="bg-light">
                      <TableRow>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Variation(s)</div>
                            <div className="d-flex flex-column ">
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Price</div>
                            <div className="d-flex flex-column ">
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Discounted Price</div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Campaign Stock</div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Stock</div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Unit Capped</div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="table_action_head text-secondary"
                          component="th"
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item.variationList &&
                        item.variationList.length > 0 &&
                        item.variationList.map((row, indexVariation) => (
                          <TableRow
                            key={item.id+'-'+indexVariation}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="left" className="td_cell">
                              {row.Type?row.Type:row.Size}
                            </TableCell>
                            <TableCell align="left" className="td_cell">
                              RM. {row?.teamPrice || "0"}
                            </TableCell>
                            <TableCell align="left" className="td_cell border-0">
                              <InputField
                                className="input_icon_left"
                                formClass="col-12 p-0"
                                type="number"
                                id="teamDiscountPrice"
                                icon="currency"
                                placeholder="Input"
                                name="teamDiscountPrice"
                                value={row?.teamDiscountPrice}
                                onChange={(e)=> onChangeProductData(e, indexItem,indexVariation)}
                                required={true}
                              />
                            </TableCell>
                            <TableCell align="left" className="td_cell border-0">
                              <input
                                type="number"
                                id="teamCampaignStock"
                                name="teamCampaignStock"
                                placeholder="Input"
                                className="w-75 form-control"
                                value={row?.teamCampaignStock}
                                onChange={(e)=> onChangeProductData(e, indexItem,indexVariation)}
                                required={true}
                              />
                            </TableCell>
                            <TableCell align="left" className="td_cell border-0">
                              {row?.teamStock || "N/A"}
                            </TableCell>
                            <TableCell align="left" className="td_cell border-0">
                              <input
                                type="text"
                                placeholder="Input"
                                className="w-75 form-control"
                                id="teamUnitCapped"
                                name="teamUnitCapped"
                                value={row?.teamUnitCapped}
                                onChange={(e)=> onChangeProductData(e, indexItem,indexVariation)}
                                required={true}
                              />
                            </TableCell>
                            <TableCell className="d-flex table_action_item">
                              <IOSSwitch
                                name="teamIsActive"
                                checked={row.teamIsActive == true? true : false}
                                onChange={(e)=> onChangeProductData(e, indexItem,indexVariation)}
                                value={row?.teamIsActive}
                                className="mt-2"
                              />
                              <IconButton
                                // onClick={() => history.push(`edit?id=${row.productId}`)}
                                className="pointer"
                                // onClick={() => editAction(row.productId, permissions?.product?.includes('update'))}
                              >
                                <SvgIcon
                                  src="/icons/delete_icon.svg"
                                  alt="view icon"
                                />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ))}
          </div>
        </div>

        <div className="card p-3 mt-2">
          <div className="d-flex justify-content-between">
            <div className="">
              <h6 className="m-0">VOUCHER</h6>
              <label className="text-muted  font-size-account">
                Add voucher
              </label>
            </div>
            <div className="">
              <button
                className="btn btn-dark px-2"
              >
                + Add Voucher
              </button>
            </div>
          </div>
          <div className="ml-3 mr-5">
            <hr />
          </div>
          <div>
            <TableContainer>
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead className="bg-light">
                  <TableRow>
                    <TableCell
                      className="th_cell"
                      component="th"
                      align="left"
                    >
                      <div className="d-flex  ">
                        <div>No</div>
                        <div className="d-flex flex-column ">
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      className="th_cell "
                      component="th"
                      align="left"
                    >
                      <div className="d-flex  ">
                        <div>Voucher</div>
                        <div className="d-flex flex-column ">
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      className="th_cell "
                      component="th"
                      align="left"
                    >
                      <div className="d-flex  ">
                        <div>Voucher Code</div>
                        <div className="d-flex flex-column ">
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      className="th_cell "
                      component="th"
                      align="left"
                    >
                      <div className="d-flex  ">
                        <div>Until</div>
                        <div className="d-flex flex-column ">
                        </div>
                      </div>
                    </TableCell>


                    <TableCell
                      className="table_action_head text-secondary"
                      component="th"
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {voucherData &&
                    voucherData.length > 0 &&
                    voucherData.map((row, index) => (
                  <TableRow
                    // key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="left" className="td_cell">
                      {index+1}
                    </TableCell>
                    <TableCell align="left" className="td_cell">
                      <span>{row.voucherName}</span> <br/>
                      <span className="badge badge-danger text-left">{row.voucherType}</span>
                    </TableCell>
                    <TableCell align="left" className="td_cell">
                      {row.voucherCode}
                    </TableCell>
                    <TableCell align="left" className="td_cell">
                      {row.endDate}
                    </TableCell>
                    <TableCell className="d-flex  table_action_item">
                      <IconButton
                        onClick={() =>
                          history.push(
                            "/marketing/all-promo/livestream/participate-overview"
                          )
                        }
                        className="pointer"
                        // onClick={() => editAction(row.productId, permissions?.product?.includes('update'))}
                      >
                        <SvgIcon
                          src="/icons/view_icon.svg"
                          alt="view icon"
                        />
                      </IconButton>
                      <IconButton
                        // onClick={() =>
                        //   history.push(
                        //     "/marketing/all-promo/flash-sale/participate-overview/nike-estore"
                        //   )
                        // }
                        className="pointer"
                        // onClick={() => editAction(row.productId, permissions?.product?.includes('update'))}
                      >
                        <SvgIcon
                          src="/icons/edit_icon.svg"
                          alt="view icon"
                        />
                      </IconButton>
                      <IconButton
                        // onClick={() => history.push(`edit?id=${row.productId}`)}
                        className="pointer"
                        // onClick={() => editAction(row.productId, permissions?.product?.includes('update'))}
                      >
                        <SvgIcon
                          src="/icons/delete_icon.svg"
                          alt="view icon"
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                   ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <br />
      <footer className="d-flex flex-wrap justify-content-end align-items-center py-3 mt-3 bg-white border-top">
        <div className="col-md-4 d-flex align-items-center mr-2 justify-content-end">
          <button
            type="button"
            className="btn border input-inside-text mr-2"
            onClick={history.goBack}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn border btn-dark input-inside-text mr-2"
            onClick={() => onSubmit()}
          >
            Confirm
          </button>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default CreateLiveStream;
