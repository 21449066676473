import React from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { breadcrumbAction } from '../../../redux/actions/config.action';
import ablity from "../../../helpers/abilityGuard";

const AppBanner = () => {
    const history = useHistory();
    const dispatch = useDispatch();

  const breadcrumb = [{ label: "App-Banner", url: null }];
  dispatch(breadcrumbAction(breadcrumb));

  return (
    <div className="container-fluid">
        <div className="px-2 py-3 mb-3">
            <div className="row pr-1">
                <div className="col-4 pr-1">
                    <div className="card p-3 d-flex flex-column justify-between rounded-lg">
                        <div className="">
                            Homepage Banner
                        </div>
                        <div className="text-muted">
                            maximum the exposure in homepage
                        </div>
                        <div className="">
                            {ablity({model:'Ads', action:'create'}) == 1 && (
                                <button onClick={() => history.push("/ads/app-banner/homepage-banner")} className="btn btn-danger mt-4">View</button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-4 pr-1">
                    <div className="card p-3 d-flex flex-column justify-between rounded-lg">
                        <div className="">
                            To Shop Banner
                        </div>
                        <div className="text-muted">
                            to engage only active merchant
                        </div>
                        <div className="">
                            {ablity({model:'Ads', action:'create'}) == 1 && (
                                <button className="btn btn-danger mt-4">View</button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-4 pr-1">
                    <div className="card p-3 d-flex flex-column justify-between rounded-lg">
                        <div className="">
                            To Travel Banner
                        </div>
                        <div className="text-muted">
                            to engage with blocked merchant
                        </div>
                        <div className="">
                            {ablity({model:'Ads', action:'create'}) == 1 && (
                                <button className="btn btn-danger mt-4">View</button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AppBanner