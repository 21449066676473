import {
  DeleteWithToken,
  GetWithToken,
  PostWithToken,
  PutWithToken,
  DeleteById,
  Get,
  GetWithTokenParams,
  GetWithTokenFile,
} from "../../lib/request";

export const createNewProduct = (data) => {
  return async function (dispatch) {
    const result = await PostWithToken("/product/createproduct", data);
    return result;
  };
};

export const getAllProductList = (query) => {
  return async function (dispatch) {
    const result = await GetWithToken(
      `/product?item=${query.item || ""}&order=${
        query.order || ""
      }&productName=${
        encodeURIComponent(query?.productName?.trim() || "") || ""
      }&categoryName=${
        encodeURIComponent(query?.categoryName?.trim() || "") || ""
      }&search=${query.search || ""}&statusText=${query.status || ""}&offset=${
        query.offset || 0
      }&limit=${query.limit || 10}`
    );
    return result;
  };
};

export const exportOverviewProduct = (query) => {
  console.log(query);
  const { status, startDate, endDate, categories } = query;

  const start =
    new Date(startDate).toISOString().split("T")[0] + "T00:00:00.000Z";
  const end = new Date(endDate).toISOString().split("T")[0] + "T00:00:00.000Z";
  const URL_categories = [];
  categories.map((cate) => {
    cate = cate.split(" & ").join("+%26+");
    URL_categories.push(`categories[]=${cate}`);
  });

  const url =
    `/product/overview?status=${status}&startDate=${start}&endDate=${end}&downloadFile=${true}&` +
    URL_categories.join("&");
  return async function (dispatch) {
    const result = await GetWithTokenFile(url);
    console.log(result, "result from export");
    return result;
  };
};

export const getAllOverviewProduct = (query) => {
  console.log(query);

  const url = `/product/overview`;
  return async function (dispatch) {
    // const result = await GetWithTokenFile(url);
    const result = await GetWithTokenParams(url, query);
    console.log(result, "result for overview products");

    return result;
  };
};

export const updateProduct = (data, id) => {
  console.log(data);
  return async function (dispatch) {
    const result = await PutWithToken(`/store/products/${id}`, data);
    return result;
  };
};

export const getAllProductListAfterDelete = (query = {}) => {
  return async function (dispatch) {
    const result = await GetWithToken("/product");
    console.log(result);
    return result;
  };
};

export const getProductById = (id) => {
  return async function (dispatch) {
    const result = await GetWithToken(`/product/${id}`);
    return result;
  };
};

export const getStoreProducts = (query) => {
  return async function (dispatch) {
    // const result = await GetWithToken("/merchant/product")
    const result = await GetWithToken(
      `/merchant/product?item=${query.item || ""}&order=${
        query.order || ""
      }&productName=${query.productName || ""}&categoryName=${
        query.categoryName || ""
      }&search=${query.search || ""}&statusText=${query.status || ""}&offset=${
        query.offset || 0
      }`
    );

    return result;
  };
};

export const addNewProductByStore = (data) => {
  return async function (dispatch) {
    const result = await PostWithToken("/store/products", data);
    return result;
  };
};

export const deleteProduct = (id) => {
  console.log("cllalalsdlfasfa");
  return async function (dispatch) {
    const result = await DeleteWithToken(`/product/${id}`);
    return result;
  };
};
export const merchantDeleteProduct = (id) => {
  console.log("sssssssssdfasdfasfaf");
  return async function (dispatch) {
    const result = await DeleteById(`/merchant/product/${id}`);
    return result;
  };
};
export const getProductsBymerchantId = (id, query) => {
  console.log(
    query,
    "bffffffffffffffffffffffffffffffffffffffbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb"
  );
  return async function (dispatch) {
    const result = await Get(
      `/product/storeid/${id}?item=${query.item || ""}&order=${
        query.order || ""
      }&productName=${query?.productName || ""}&categoryName=${
        query?.categoryName || ""
      }&status=${query?.status || ""}&search=${query?.search || ""}&offset=${
        query.offset || 0
      }&dateAdded=${query.dateAdded || ""}&statusText=${
        query.statusText || ""
      }&limit=${query.limit || 0}`
    );
    console.log(result, "jjjjjjjjjjjjjjjjjmmmmmmmmmmmmmmmmmmmm");
    return result;
  };
};
