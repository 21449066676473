import React, { useEffect } from "react";
import {
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,

} from "@mui/material";
import SvgIcon from "../../../../../components/Images/SvgIcon";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import SelectInput from "../../../../../components/select";
import { breadcrumbAction } from "../../../../../redux/actions/config.action";
import InputField from "../../../../../components/formControl/input/InputField";
import Image from "../../../../../components/Images/Image";

import { useState } from "react";
import { CREATE_LIVESTREAM_ADD_PRODUCT, CREATE_LIVESTREAM_ADD_PROMO } from "../../../../../lib/constant";
import { open_dialog, showToast, startLoader, stopLoader, UploadImage } from "../../../../../lib/global";
import { IOSSwitch } from "../../../../../components/switch/IOSSwitch";
import { searchMerchantList } from "../../../../../redux/actions/merchant-center.action";
import { MAX_IMAGE_SIZE } from "../../../../../lib/config";
import { createLiveStream, getLiveStreamById, updateLiveStream } from "../../../../../redux/actions/marketing.action";
import moment from "moment";

const CreateLiveStream = () => {
  const { liveStreamId } = useParams();
  const [storeId, setStoreId] = useState(localStorage.getItem("storeId"));
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [allProductData, setallProductData] = useState([]);
  const [voucherData, setVoucherData] = useState([]);
  const [merchantList, setMerchantList] = useState([]);
  const [webImageFile, setWebImageFile] = useState();
  const [webImageUrl, setWebImageUrl] = useState();
  const [livestreamData, setLivestreamData] = useState({
    name:"",
    description:"",
    productLimit:10,
    isTest:false,
    isLive:false,
    merchant:null,
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const userRole = localStorage.getItem("role");

  useEffect(() => {
    const breadcrumb = [
      { 
        label: "Livestream", 
        url: userRole === "MERCHANT" ? "/merchant/marketing/promo/livestream" : "/marketing/all-promo/livestream" 
      },
      { 
        label: liveStreamId ? "Edit Livestream" : "Create Livestream", 
        url: null 
      },
    ];
    dispatch(breadcrumbAction(breadcrumb));
    getMerchantList();
  },[])
  useEffect(() => {
    if(liveStreamId){
      // startLoader();
      dispatch(getLiveStreamById(liveStreamId))
        .then((response) => {
          if(response.data.data && userRole == "MERCHANT" && response.data.data.merchant.storeId != storeId){
            history.push("/merchant/marketing/promo/livestream");
            showToast("You are not authorized to access this page","error");
          }
          let temp = response.data.data;
          delete temp.createdAt
          delete temp.updatedAt
          setWebImageFile(temp.coverImage);
          merchantList.map((item)=>{
            if(item.value.storeId == temp.merchant.storeId){
              temp.merchant = item
            }
          })
          if(temp.startDateTime != null && temp.endDateTime == null){
            temp.isLive = true
          }else{
            temp.isLive = false
          }
          setLivestreamData(temp);
          setallProductData(temp.products);
          setVoucherData(temp.vouchers?temp.vouchers:[]);
        })
        .catch((error) => {
          console.log({ error });
        });
    }
  },[merchantList])

  const handelAddProduct = () => {
    open_dialog(CREATE_LIVESTREAM_ADD_PRODUCT, {
      data: livestreamData,
      onSubmit: selectProductSuccess,
    });
  };

  const handelAddPromo = () => {
    open_dialog(CREATE_LIVESTREAM_ADD_PROMO, {
      data: livestreamData,
      onSubmit: selectPromoSuccess,
    });
  };

  const selectProductSuccess = (data) => {
    if(data != []){
      let temp = [...allProductData];
      data.map((item) => {
        item.variationList.map((variation) => {
          variation.discountPrice = 0
          variation.campaignStock = 0
          variation.unitCapped = 0
          variation.isActive = false
          variation.teamDiscountPrice = 0
          variation.teamCampaignStock = 0
          variation.teamUnitCapped = 0
          variation.teamIsActive = false
        })
        temp.push(item);
      })
      setallProductData(temp)
    }
  };

  const selectPromoSuccess = (data) => {
    if(data != []){
      console.log(voucherData)
      let temp = [...voucherData];
      data.map((item) => {
        temp.push(item);
      })
      console.log(temp)
      setVoucherData(temp)
    }
  }

  const onChangeLivestreamData = (e, opt, val) => {
    const key = opt || e.target.name;
    let value = val || e.target.value;
    setLivestreamData((pre) => ({ ...pre, [key]: value }));
  };

  const toggleLivestreamData = (e, opt, val) => {
    console.log('click')
    const value = e.target.checked;
    let temp = {
      ...livestreamData
    };
    if(temp.startDateTime != null && value == false){
      temp.isLive = false
    }else{
      temp.isLive = value
    }
    console.log("Befor",temp)
    setLivestreamData(temp);
    console.log(livestreamData.isLive)
  };

  const onChangeProductData = (e, opt,variationIndex) => {
    const key = e.target.name;
    const value = e.target.value;
    let temp = []
    allProductData.map((item, index) => {
      let tempProduct = item;
      if(index == opt){
        tempProduct.variationList.map((variationItem, index2) => {
          if(index2 == variationIndex) {
            if(key == "discountPrice"){
              variationItem.discountPrice = value;
            }else if(key == "campaignStock"){
              variationItem.campaignStock = value;
            }else if(key == "unitCapped"){
              variationItem.unitCapped = value;
            }else if(key == "isActive"){
              variationItem.isActive = e.target.checked;
            }if(key == "teamDiscountPrice"){
              variationItem.teamDiscountPrice = value;
            }else if(key == "teamCampaignStock"){
              variationItem.teamCampaignStock = value;
            }else if(key == "teamUnitCapped"){
              variationItem.teamUnitCapped = value;
            }else if(key == "teamIsActive"){
              variationItem.teamIsActive = e.target.checked;
            }
          }
        })
      }
      temp.push(tempProduct);
    })
    setallProductData(temp)
  }


  const getMerchantList = (search) => {
    dispatch(searchMerchantList(search))
      .then((response) => {
        if (response?.data?.data) {
          let data = []
          if(role.toLowerCase() === "merchant"){
            response.data.data.map((item) => {
              let isPush = false;
              if(role.toLowerCase() === "merchant"){
                if(item.storeId === storeId){
                  isPush = true
                  setLivestreamData((pre) => ({ ...pre, merchant: {
                      title: item.merchantName,
                      label: `${item.merchantName} - (${item.storeId})`,
                      value: item,
                    }
                  }));
                }
              }else{
                isPush = true;
              }

              if(isPush){
                data.push({
                  title: item.merchantName,
                  label: `${item.merchantName} - (${item.storeId})`,
                  value: item,
                })
              }
            });
          }else{
            data = response.data.data.map((item) => ({
              title: item.merchantName,
              label: `${item.merchantName} - (${item.storeId})`,
              value: item,
            }));
          }

          setMerchantList(data);
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const handleUploadFile = async (e) => {
    const fileData = e.target.files[0];
    if (fileData.size > MAX_IMAGE_SIZE) {
      showToast(
        `Image with size more than ${
          MAX_IMAGE_SIZE / 1000000
        }MB is not supported.\nPlease upload image of size smaller than ${
          MAX_IMAGE_SIZE / 1000000
        }MB.\nCurrent file size: ${(fileData.size / 1000000).toFixed(2)}MB`,
        "error",
        3000,
        "bottom",
        "center"
      );
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(fileData);
    reader.onload = function () {
      const imageUrlIns = reader.result;
      setWebImageFile(imageUrlIns);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const removePromo = (index) => {
    let temp = [...voucherData];
    temp.splice(index, 1);
    setVoucherData(temp);
  }

  const isDisableInputMerchant = () =>{
    if(role.toLowerCase() === "merchant"){
      return true
    }else{
      return false
    }
  }

  const isValidate = () => {
    let error = "";
    if (!livestreamData.merchant) {
      error = "Please select merchant";
    }
    if (!livestreamData.name) {
      error = "Please enter title";
    }
    if (!livestreamData.description) {
      error = "Please enter description";
    }
    if (!webImageFile) {
      error = "Please insert Cover image";
    }
    if ((livestreamData.link == null || livestreamData.link == "") && livestreamData.isLive == true) {
      error = "Please enter link";
    }
    if (error != "") {
      showToast(error, "error");
      return false;
    }
    return true;
  }

  const handleAdditionalValidation = () => {
    let isValid = true;
    let message = "";

    allProductData.map((item, index) => {
      let tempProduct = item;
      tempProduct.variationList.map((variationItem, index2) => {
        if (variationItem.discountPrice == 0) {
          isValid = false;
          message = "Discount Price must be more than 0";
          return;
        }

        if (variationItem.teamDiscountPrice == 0) {
          isValid = false;
          message = "Team Discount Price must be more than 0";
          return;
        }

        if (variationItem.campaignStock == 0) {
          isValid = false;
          message = "Campaign Stock must be more than 0";
          return;
        }

        if (variationItem.teamCampaignStock == 0) {
          isValid = false;
          message = "Team Campaign Stock must be more than 0";
          return;
        }

        if (variationItem.unitCapped === '') {
          isValid = false;
          message = "Unit Capped Must Be Fullfiled";
          return;
        }

        if (variationItem.teamUnitCapped === '') {
          isValid = false;
          message = "Team Unit Capped Must Be Fullfiled";
          return;
        }

        })
    })

    return { isValid, message };
  };

  const onSubmit = async () => {
    if(isValidate()){
      startLoader();

      let { isValid, message } = handleAdditionalValidation();

      if (!isValid) {
        showToast(message, "error", 3000, "bottom", "center");
        stopLoader();
        return;
      }


      let coverUrl = webImageFile? await UploadImage(webImageFile):webImageUrl;

      let payload = {
        ...livestreamData,
        coverImage: coverUrl,
        products: allProductData,
        vouchers: voucherData,
      };
      payload.merchant = payload.merchant?.value
      const userRole = localStorage.getItem("role");
      if (!payload.id) {
        dispatch(createLiveStream(payload))
          .then((res) => {
            setTimeout(() => {
              stopLoader();
              showToast("Livestream added successfully", "success");
              if(userRole === "MERCHANT"){
                history.push("/merchant/marketing/promo/livestream");
              }else{
                history.push("/marketing/all-promo/livestream");
              }
            }, 1000);
          })
          .catch((err) => {
            stopLoader();
            const message = JSON.parse(err.request.responseText).message
            showToast("Fail Add Livestream ["+message+"]", "error");
            stopLoader();
          });
      } else {
        dispatch(updateLiveStream(payload))
          .then((res) => {
            setTimeout(() => {
              stopLoader();
              showToast("Livestream updated successfully", "success");
              if(userRole === "MERCHANT"){
                history.push("/merchant/marketing/promo/livestream");
              }else{
                history.push("/marketing/all-promo/livestream");
              }
            }, 1000);
          })
          .catch((err) => {
            const message = JSON.parse(err.request.responseText).message
            showToast("Fail Update Livestream ["+message+"]", "error");
            stopLoader();
          });
      }
    }

  };

  return (
    <React.Fragment>
      <div className="container-fluid ">
        <div className="card p-3">
          <h6>DETAILS OF LIVESTREAM</h6>
          <form className="mt-3 p-0">
            <div className="col-12 row my-3">
              <label className="col-3 text-right m-0 p-0 small imp">
                Merchant
              </label>
              <div className="form-group col-6">
                <SelectInput
                  options={merchantList}
                  name="merchant"
                  value={livestreamData.merchant}
                  onChange={(e) => onChangeLivestreamData(e, "merchant", e)}
                  required={true}
                  placeholder="Select Merchant"
                  disabled={isDisableInputMerchant()}
                />
              </div>
            </div>
            <div className="col-12 row my-3">
              <label className="col-3 text-right m-0 p-0 small imp">
                Cover
              </label>
              <div className="icon-button-file col-9 d-flex flex-wrap">
                <div className="mx-2">
                  <input
                    accept="image/*"
                    id={`livestream-image`}
                    className="d-none"
                    onChange={(e) => handleUploadFile(e)}
                    multiple={false}
                    type="file"
                  />
                  <label htmlFor={`livestream-image`}>
                    <Image
                      src={ webImageFile || webImageUrl ||"/icons/upload_icon.svg"}
                      alt="upload"
                      className={`pointer upload_image_label`}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="col-12 row my-3">
              <label
                htmlFor="product-name"
                className="col-3 text-right m-0 p-0 small imp"
              >
                Title
              </label>
              <InputField
                className=""
                formClass="col-6"
                type="text"
                id="name"
                placeholder="Title"
                name="name"
                value={livestreamData.name}
                // onChange={(e) => onChangeLivestreamData(e, "title", e)}
                onChange={onChangeLivestreamData}
                required={true}
              />
            </div>
            <div className="col-12 row my-3">
              <label
                htmlFor="product-name"
                className="col-3 text-right m-0 p-0 small imp"
              >
                Description
              </label>
              <textarea
                id="textarea"
                className="form-control col-md-6 ml-3"
                style={{ minHeight: "10vh" }}
                value={livestreamData.description}
                onChange={(e) => onChangeLivestreamData(e, "description", e.target.value)}
                placeholder="Description"
              />
            </div>

            <div className="col-12 row my-3">
              <label className="col-3 my-auto text-right small imp">
                Go Live ?
              </label>
              <div className="form-group col-2 m-0">
                <IOSSwitch
                  name="isLive"
                  id="isLive"
                  onChange={toggleLivestreamData}
                  // onChange={(e) => toggleLivestreamData(e, "isLive", e)}
                  checked={livestreamData.isLive}
                  value={livestreamData.isLive}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="col-12 row my-3">
              <label
                htmlFor="product-limit"
                className="col-3 text-right m-0 p-0 small imp"
              >
                Livestream Product Limit
              </label>
              <InputField
                className=""
                formClass="col-6"
                type="number"
                id="productLimit"
                placeholder="Input"
                name="productLimit"
                value={livestreamData.productLimit}
                onChange={onChangeLivestreamData}
                required={true}
              />
            </div>
            <div className="col-12 row my-3">
              <label
                htmlFor="product-name"
                className={(livestreamData.isLive ? "imp" : "") + " col-3 text-right m-0 p-0 small"}
              >
                Link
              </label>
              <InputField
                className=""
                formClass="col-6"
                type="text"
                id="link"
                placeholder="Link"
                name="link"
                value={livestreamData.link}
                // onChange={(e) => onChangeLivestreamData(e, "title", e)}
                onChange={onChangeLivestreamData}
                required={livestreamData.isLive}
              />
            </div>
          </form>
        </div>
        <br />

        <div className="card p-3">
          <div className="d-flex justify-content-between">
            <div className="">
              <h6 className="m-0">PRODUCTS({allProductData.length}/{livestreamData.productLimit})</h6>
              <label className="text-muted  font-size-account">
                Add products from your shop, wishlist or purchased
              </label>
            </div>
            <div className="">
              <button
                onClick={() => handelAddProduct()}
                className="btn btn-dark px-2"
                disabled={livestreamData.merchant == null}
              >
                + Add Product
              </button>
            </div>
          </div>
          <div className="ml-3 mr-5">
            <hr />
          </div>
          <div>
            {allProductData &&
            allProductData.length > 0 &&
            allProductData.map((item, indexItem) => (
              <div className="">
                <div className="row p-4">
                  <div className="float-left d-flex align-items-center flex-nowrap ">
                    <Image
                      src={item.productImages[0]}
                      alt={'photo'}
                      className="teamPurchase_page_product_image"
                    />
                    <div className="px-2">{item.title}</div>
                  </div>
                </div>
                <h4>Individual Variation List</h4>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 650 }}
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="bg-light">
                      <TableRow>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Variation(s)</div>
                            <div className="d-flex flex-column ">
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Price</div>
                            <div className="d-flex flex-column ">
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Discounted Price</div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Campaign Stock</div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Stock</div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Unit Capped</div>
                          </div>
                        </TableCell>
                        {/*<TableCell*/}
                        {/*  className="table_action_head text-secondary"*/}
                        {/*  component="th"*/}
                        {/*>*/}
                        {/*  Action*/}
                        {/*</TableCell>*/}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item.variationList &&
                        item.variationList.length > 0 &&
                        item.variationList.map((row, indexVariation) => (
                          <TableRow
                            key={item.id+'-'+indexVariation}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="left" className="td_cell">
                              {item.variation && item.variation.length > 0?row[item.variation[0].name]:(row.Type?row.Type:"N/A")}
                            </TableCell>
                            <TableCell align="left" className="td_cell">
                              RM. {row?.price || "0"}
                            </TableCell>
                            <TableCell align="left" className="td_cell border-0">
                              <InputField
                                className="input_icon_left"
                                formClass="col-12 p-0"
                                type="number"
                                id="discountPrice"
                                icon="currency"
                                placeholder="Input"
                                name="discountPrice"
                                value={row?.discountPrice}
                                onChange={(e)=> onChangeProductData(e, indexItem,indexVariation)}
                                required={true}
                              />
                            </TableCell>
                            <TableCell align="left" className="td_cell border-0">
                              <input
                                type="number"
                                id="campaignStock"
                                name="campaignStock"
                                placeholder="Input"
                                className="w-75 form-control"
                                value={row?.campaignStock}
                                onChange={(e)=> onChangeProductData(e, indexItem,indexVariation)}
                                required={true}
                              />
                            </TableCell>
                            <TableCell align="left" className="td_cell border-0">
                              {row?.stock || "N/A"}
                            </TableCell>
                            <TableCell align="left" className="td_cell border-0">
                              <input
                                type="text"
                                placeholder="Input"
                                className="w-75 form-control"
                                id="unitCapped"
                                name="unitCapped"
                                value={row?.unitCapped}
                                onChange={(e)=> onChangeProductData(e, indexItem,indexVariation)}
                                required={true}
                              />
                            </TableCell>
                            {/*<TableCell className="d-flex table_action_item">*/}
                            {/*  <IOSSwitch*/}
                            {/*    name="isActive"*/}
                            {/*    checked={row.isActive == true? true : false}*/}
                            {/*    onChange={(e)=> onChangeProductData(e, indexItem,indexVariation)}*/}
                            {/*    value={row?.isActive}*/}
                            {/*    className="mt-2"*/}
                            {/*  />*/}
                            {/*  <IconButton*/}
                            {/*    // onClick={() => history.push(`edit?id=${row.productId}`)}*/}
                            {/*    className="pointer"*/}
                            {/*    // onClick={() => editAction(row.productId, permissions?.product?.includes('update'))}*/}
                            {/*  >*/}
                            {/*    <SvgIcon*/}
                            {/*      src="/icons/delete_icon.svg"*/}
                            {/*      alt="view icon"*/}
                            {/*    />*/}
                            {/*  </IconButton>*/}
                            {/*</TableCell>*/}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <h4>Team Variation List</h4>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 650 }}
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="bg-light">
                      <TableRow>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Variation(s)</div>
                            <div className="d-flex flex-column ">
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Price</div>
                            <div className="d-flex flex-column ">
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Discounted Price</div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Campaign Stock</div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Stock</div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Unit Capped</div>
                          </div>
                        </TableCell>
                        {/*<TableCell*/}
                        {/*  className="table_action_head text-secondary"*/}
                        {/*  component="th"*/}
                        {/*>*/}
                        {/*  Action*/}
                        {/*</TableCell>*/}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item.variationList &&
                        item.variationList.length > 0 &&
                        item.variationList.map((row, indexVariation) => (
                          <TableRow
                            key={item.id+'-'+indexVariation}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="left" className="td_cell">
                              {item.variation && item.variation.length > 0?row[item.variation[0].name]:(row.Type?row.Type:"N/A")}
                            </TableCell>
                            <TableCell align="left" className="td_cell">
                              RM. {row?.teamPrice || "0"}
                            </TableCell>
                            <TableCell align="left" className="td_cell border-0">
                              <InputField
                                className="input_icon_left"
                                formClass="col-12 p-0"
                                type="number"
                                id="teamDiscountPrice"
                                icon="currency"
                                placeholder="Input"
                                name="teamDiscountPrice"
                                value={row?.teamDiscountPrice}
                                onChange={(e)=> onChangeProductData(e, indexItem,indexVariation)}
                                required={true}
                              />
                            </TableCell>
                            <TableCell align="left" className="td_cell border-0">
                              <input
                                type="number"
                                id="teamCampaignStock"
                                name="teamCampaignStock"
                                placeholder="Input"
                                className="w-75 form-control"
                                value={row?.teamCampaignStock}
                                onChange={(e)=> onChangeProductData(e, indexItem,indexVariation)}
                                required={true}
                              />
                            </TableCell>
                            <TableCell align="left" className="td_cell border-0">
                              {row?.teamStock || "N/A"}
                            </TableCell>
                            <TableCell align="left" className="td_cell border-0">
                              <input
                                type="text"
                                placeholder="Input"
                                className="w-75 form-control"
                                id="teamUnitCapped"
                                name="teamUnitCapped"
                                value={row?.teamUnitCapped}
                                onChange={(e)=> onChangeProductData(e, indexItem,indexVariation)}
                                required={true}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ))}
          </div>
        </div>

        <div className="card p-3 mt-2">
          <div className="d-flex justify-content-between">
            <div className="">
              <h6 className="m-0">VOUCHER</h6>
              <label className="text-muted  font-size-account">
                Add voucher
              </label>
            </div>
            <div className="">
              <button
                onClick={() => handelAddPromo()}
                className="btn btn-dark px-2"
              >
                + Add Voucher
              </button>
            </div>
          </div>
          <div className="ml-3 mr-5">
            <hr />
          </div>
          <div>
            <TableContainer>
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead className="bg-light">
                  <TableRow>
                    <TableCell
                      className="th_cell"
                      component="th"
                      align="left"
                    >
                      <div className="d-flex  ">
                        <div>No</div>
                        <div className="d-flex flex-column ">
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      className="th_cell "
                      component="th"
                      align="left"
                    >
                      <div className="d-flex  ">
                        <div>Voucher</div>
                        <div className="d-flex flex-column ">
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      className="th_cell "
                      component="th"
                      align="left"
                    >
                      <div className="d-flex  ">
                        <div>Voucher Code</div>
                        <div className="d-flex flex-column ">
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      className="th_cell "
                      component="th"
                      align="left"
                    >
                      <div className="d-flex  ">
                        <div>Until</div>
                        <div className="d-flex flex-column ">
                        </div>
                      </div>
                    </TableCell>


                    <TableCell
                      className="table_action_head text-secondary"
                      component="th"
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {voucherData &&
                    voucherData.length > 0 &&
                    voucherData.map((row, index) => (
                  <TableRow
                    // key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="left" className="td_cell">
                      {index+1}
                    </TableCell>
                    <TableCell align="left" className="td_cell">
                      <span>{row.name}</span> <br/>
                      <span className="badge badge-danger text-left">{row.type?.label}</span>
                    </TableCell>
                    <TableCell align="left" className="td_cell">
                      {row.code}
                    </TableCell>
                    <TableCell align="left" className="td_cell">
                      {row.endPeriodDate?moment(row.endPeriodDate).format("DD MMM YY"):'N/A'}
                    </TableCell>
                    <TableCell className="d-flex table_action_item">
                      <IconButton
                        className="pointer"
                        onClick={() => removePromo(index)}
                      >
                        <SvgIcon
                          src="/icons/delete_icon.svg"
                          alt="view icon"
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                   ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <br />
      <footer className="d-flex flex-wrap justify-content-end align-items-center py-3 mt-3 bg-white border-top">
        <div className="col-md-4 d-flex align-items-center mr-2 justify-content-end">
          <button
            type="button"
            className="btn border input-inside-text mr-2"
            onClick={history.goBack}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn border btn-dark input-inside-text mr-2"
            onClick={() => onSubmit()}
          >
            Confirm
          </button>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default CreateLiveStream;
