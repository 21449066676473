import React, {useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IOSSwitch } from "../../../../components/switch/IOSSwitch";

import {
    Divider,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SvgIcon from "../../../../components/Images/SvgIcon";
import Image from "../../../../components/Images/Image";
import { open_dialog, parseQuery, startLoader, stopLoader } from "../../../../lib/global";
import { FLASH_SALE_ADD_PRODUCT } from "../../../../lib/constant";
import InputField from "../../../../components/formControl/input/InputField";
import SelectInput from "../../../../components/select";
import {
  getAllFlashSaleProductByMerchantId,
  updateFlashSaleProductByTimeSlotID
} from "../../../../redux/actions/marketing.action";

const initialData = [
    {
        createDate: "Red",
        ticketNo: "RM50.00",
        ticketStatus: "45",
        updated: "6",
        merchantId: "30",
    },
    {
        createDate: "Red",
        ticketNo: "RM50.00",
        ticketStatus: "23",
        updated: "-",
        merchantId: "30",

    },

]


const FlashSaleProductInfo = () => {
    const [allTicketData, setallTicketData] = useState(initialData)
    const [allProductData, setAllProductData] = useState([])
  const [tempEditProductData, setTempEditProductData] = useState([])
    const history = useHistory()
    const dispatch = useDispatch();
    const [status, setStatus] = useState("Disable");
    const [editProduct, setEditProduct] = useState("disabledEdit")
    const { merchantid, timeslotid, datepromo, promoslotid } = parseQuery(history.location.search) || {};
    const [merchantId, setMerchantId] = useState({ merchantId: merchantid })
    const [listProductInput, setListProductInput] = useState([])
    let filterData = {}
    console.log('riptokun', listProductInput);

    // const [modalBoxOpen, setModalBoxOpen] = useState("onClose
    const handleAddProduct = () => {
        open_dialog(FLASH_SALE_ADD_PRODUCT, {
            onSubmit: selectProductSuccess,
            merchantId:merchantId,
            setMerchantId: setMerchantId,
          allProductData: allProductData,
          setAllProductData: setAllProductData,
          timeslotid: timeslotid,
          merchantid: merchantid,
          datepromo: datepromo,
          promoslotid: promoslotid,
            // setListProductInput: setListProductInput,
            // listProductInput:listProductInput
        })
    }

    const handleEditTempProductData = ({ indexKey, key, value }) => {
      let tempVariationData = tempEditProductData
      tempVariationData.variation[indexKey][key] = value
      setTempEditProductData(tempVariationData)
    }

    const toggleEditProduct = (productId = null) => {
      if (productId === null) {
        setEditProduct(`disabledEdit`)
        setTempEditProductData([])
      } else {
        setEditProduct(`enableEdit${productId}`)
        let productIndex = allProductData.findIndex(item => item.product.productId === productId)
        setTempEditProductData(allProductData[productIndex])
        console.log(productIndex, allProductData[productIndex]);
      }
    }

    const handleEditProduct = (productID) => {
      toggleEditProduct()
      let tempPatchFlashSaleProduct = allProductData
      let keyProduct = tempPatchFlashSaleProduct.findIndex(item => item.product.productId === productID)

      tempPatchFlashSaleProduct[keyProduct] = tempEditProductData

      let tempToSave = {
        products: []
      }
      tempPatchFlashSaleProduct.forEach(item => {
        tempToSave.products.push({
          flashSaleProductId: item.product.productId,
          discountPrice: null,
          campaignStock: null,
          unitCapped: null,
          variation: item.variation
        })
      })
      startLoader()
      dispatch(updateFlashSaleProductByTimeSlotID(timeslotid, tempToSave))
        .then(response => {
          console.log(response.data);
          stopLoader();
        })
        .catch(error => {
          console.log(error.response.data);
          stopLoader();
        })
    }

    const selectProductSuccess = (data) => {
        console.log(data);
    }

    useEffect(() => {
        filterData = {
            timeSlotId: timeslotid
        }
        apiData(merchantid, filterData)
    },[])

  useEffect(() => {
    console.log(allProductData);
  }, [allProductData])

    const apiData = (merchantid, filterData) => {
        startLoader()
        dispatch(getAllFlashSaleProductByMerchantId(merchantid, filterData))
          .then((data) => {
              setAllProductData(
                data &&
                data.data &&
                data.data.data.map((row, index) => {
                    return {
                        product: row?.product || "N/A",
                        variation: row?.variation || "N/A"
                    };
                })
              );

              setTimeout(() => {
                  stopLoader();
              }, 1000);
          })
          .catch((err) => {
              setTimeout(() => {
                  stopLoader();
              }, 1000);
              console.log({ err });
          });
    }

    return (
        <React.Fragment>
            <div className=" d-flex flex-column justify-content-between container_body vh-100">
                <div className="container-fluid">
                    {/*<div className="card p-4 px-5 mb-3">*/}
                    {/*    <h6>BASIC INFORMATION</h6>*/}
                    {/*    <br />*/}
                    {/*    <div>*/}
                    {/*        <div className="row">*/}
                    {/*            <label className="col-md-3 d-flex justify-content-end">Time Slot</label>*/}
                    {/*            <input*/}
                    {/*                type="date"*/}
                    {/*                className="form-control col-md-3"*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*        <br />*/}
                    {/*        <div className="row">*/}
                    {/*            <label className="col-md-3 d-flex justify-content-end">Product criteria</label>*/}
                    {/*            <textarea*/}
                    {/*                id="textarea"*/}
                    {/*                className="form-control col-md-7"*/}
                    {/*                style={{ minHeight: "20vh" }}*/}
                    {/*                placeholder="To be include"*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="card p-4 mb-3">
                        <div>
                            <div className="float-left">
                                <h6>FLASH SALE PRODUCT</h6>
                                <p className="fntSz14 mb-0 text-secondary">
                                    List product to participate in flash sale event.
                                    {/*You have <span className="text-danger">5</span> */}
                                    {/*out of <span className="text-danger">5</span> products joining flash sale.*/}
                                </p>
                            </div>
                            <div className="float-right">
                                <button
                                    onClick={() => handleAddProduct()}
                                    type="button"
                                    className="rounded btn btn-danger text-nowrap p-2 px-4"
                                >
                                    {" "}
                                    Add Product
                                </button>
                            </div>
                        </div>
                        <hr />
                        {allProductData.map((row, index) => (
                            <div className="mb-3">
                              <div className="row pb-3">
                                  <div className="col-6 d-flex align-items-center flex-nowrap ">
                                    <input type={"hidden"} value={JSON.stringify(row)}/>
                                      <Image
                                        src={row?.product?.productImages[0]}
                                        alt={'photo'}
                                        className="teamPurchase_page_product_image"
                                      />
                                      <div className="px-2">{row.product.title}</div>
                                  </div>
                                {/*<div className="col-6 text-right">*/}
                                {/*  <button className="btn btn-link text-danger">Edit</button>*/}
                                {/*  |*/}
                                {/*  <button className="btn btn-link text-danger">Delete</button>*/}
                                {/*</div>*/}
                                <div className="col-6 d-flex justify-content-end">
                                  <div className={`d-${editProduct !== `enableEdit${row.product.productId}` ? "block" : "none"}`}>
                                    <div className="text-primary d-flex flex-nowrap ">
                                      <div role="button" onClick={() => toggleEditProduct(row.product.productId)} className="btn btn-link text-danger">Edit</div>
                                      <div role="button" className="btn btn-link text-danger">Delete</div>
                                    </div>
                                  </div>
                                  <div className={`d-${editProduct === `enableEdit${row.product.productId}` ? "block" : "none"}`}>
                                    <label className="px-3">Participate</label>
                                    <IOSSwitch
                                      checked={status}
                                      onChange={(e) => setStatus(e.target.checked)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className={`bg-light d-${editProduct !== `enableEdit${row.product.productId}` ? "block" : "none"} rounded fntSz14 p-3`}>
                                  <div className="mb-2">
                                      <h6 className="mb-3 fntSz18">Individual Purchase</h6>
                                      {row.variation.map((row, index) => (
                                        <div className="row text-secondary">
                                            <div className="col-md-3 text-nowrap mb-2">Variation: <span className="text-dark">{row.variationOption}</span></div>
                                            <div className="col-md-3 text-nowrap mb-2">Original Price: <span className="text-dark">RM {row.price}</span></div>
                                            <div className="col-md-2 text-nowrap mb-2">Discounted Price: <span className="text-dark">RM {row.discountPrice}</span></div>
                                            <div className="col-md-2 text-nowrap mb-2">Campaign Stock: <span className="text-dark">{row.campaignStock}</span></div>
                                            <div className="col-md-2 text-center text-nowrap mb-2">Stock: <span className="text-dark">{row.stock}</span></div>
                                            <div className="col-md-3 text-nowrap mb-2">Unit Capped: <span className="text-dark">{row.unitCapped}</span></div>
                                            <div className="col-md-3 text-nowrap mb-2">Enable/Disable: <span className="text-success">{row.isActive ==true? 'Enable' : 'Disable'}</span></div>
                                        </div>
                                      ))}

                                  </div>

                                  <div>
                                      <h6 className="mb-3 fntSz18">Team Purchase</h6>
                                      {row.variation.map((row, index) => (
                                      <div className="row text-secondary">
                                          <div className="col-md-3 text-nowrap mb-2">Variation: <span className="text-dark">{row.variationOption}</span></div>
                                          <div className="col-md-3 text-nowrap mb-2">Original Price: <span className="text-dark">RM {row.teamPrice}</span></div>
                                          <div className="col-md-2 text-nowrap mb-2">Discounted Price: <span className="text-dark">RM {row.teamDiscountPrice}</span></div>
                                          <div className="col-md-2 text-nowrap mb-2">Campaign Stock: <span className="text-dark">{row.teamCampaignStock}</span></div>
                                          <div className="col-md-2 text-center text-nowrap mb-2">Stock: <span className="text-dark">{row.teamStock}</span></div>
                                          <div className="col-md-3 text-nowrap mb-2">Unit Capped: <span className="text-dark">{row.teamUnitCapped}</span></div>
                                          <div className="col-md-3 text-nowrap mb-2">Team Member: <span className="text-dark">{row.teamMembers}</span></div>
                                          <div className="col-md-3 text-nowrap mb-2">Enable/Disable: <span className="text-danger">{row.isActive ==true? 'Enable' : 'Disable'}</span></div>
                                      </div>
                                      ))}
                                  </div>
                              </div>
                              <div className={`d-${editProduct === `enableEdit${row.product.productId}` ? "block" : "none"}`}>
                                <div className="d-flex flex-row justify-content-between bg-light rounded p-3">
                                  <div className="d-flex flex-row align-items-center ">
                                    <p className="mt-4 text-nowrap">Batch Updated</p>
                                    <div className="d-flex mx-4 flex-column w-50">
                                      <label>Campaign Stock</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Input"
                                      />
                                    </div>
                                    <div className="d-flex flex-column w-50">
                                      <label>Unit Capped</label>
                                      <div >
                                        <SelectInput
                                          // options={merchantList}
                                          // name="merchant"
                                          // value={basicInformation.merchant}
                                          // onChange={(e) => onChangeBasicInfo(e, "merchant", e)}
                                          required={true}
                                          formSubmitted={false}
                                          placeholder="Limit"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <button className="btn btn-outline-danger m-3 px-3">Update All</button>
                                </div>
                                <br />
                                <div className="border"
                                     style={{ borderRadius: "10px", overflow: "hidden" }}
                                >
                                  <div className=" bg-light">
                                    <div className="pl-3 pt-3 mb-0 fntSz18 bg-light">Individual Purchase</div>
                                    <hr />
                                  </div>
                                  <div className="mx-3">
                                    <TableContainer>
                                      <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell
                                              className="th_cell"
                                              component="th"
                                              align="left"
                                            >
                                              <div className="d-flex  ">
                                                <div>Variation(s)</div>

                                              </div>
                                            </TableCell>
                                            <TableCell
                                              className="th_cell"
                                              component="th"
                                              align="left"
                                            >
                                              <div className="d-flex  ">
                                                <div>Original Price</div>

                                              </div>
                                            </TableCell>
                                            <TableCell
                                              className="th_cell "
                                              component="th"
                                              align="left"
                                            >
                                              <div className="d-flex  ">
                                                <div>Discounted Price</div>

                                              </div>
                                            </TableCell>
                                            <TableCell
                                              className="th_cell "
                                              component="th"
                                              align="left"
                                            >
                                              <div className="d-flex  ">
                                                <div>Campaign Stock</div>

                                              </div>
                                            </TableCell>
                                            <TableCell
                                              className="th_cell "
                                              component="th"
                                              align="left"
                                            >
                                              <div className="d-flex  ">
                                                <div>Stock</div>

                                              </div>
                                            </TableCell>
                                            <TableCell
                                              className="th_cell "
                                              component="th"
                                              align="left"
                                            >
                                              <div className="d-flex  ">
                                                <div>Unit Capped</div>

                                              </div>
                                            </TableCell>

                                            <TableCell className="table_action_head text-secondary" component="th">
                                              Enable/Disable
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {tempEditProductData.variation &&
                                            tempEditProductData.variation.length > 0 &&
                                            tempEditProductData.variation.map((variation, variationKey) => (
                                              <TableRow
                                                key={'variation-data-'+variationKey}
                                                className="border-0"
                                                sx={{
                                                  "&:last-child td, &:last-child th": { border: 0 },
                                                }}

                                              >

                                                <TableCell align="left" className="td_cell border-0">
                                                  {variation?.Size || "N/A"}s
                                                </TableCell>
                                                <TableCell align="left" className="td_cell border-0">
                                                  {variation?.price || "0"}
                                                </TableCell>
                                                <TableCell align="left" className="td_cell border-0">
                                                  <InputField
                                                    className="input_icon_left"
                                                    formClass="col-12 p-0"
                                                    type="number"
                                                    id="individual-price"
                                                    icon="currency"
                                                    placeholder="Input"
                                                    name="price"
                                                    defaultValue={variation?.discountPrice ?? 0}
                                                    onChange={e => handleEditTempProductData({
                                                      indexKey: variationKey,
                                                      key: 'discountPrice',
                                                      value: e.target.value
                                                    })}
                                                    // required={true}
                                                  />
                                                </TableCell>

                                                <TableCell align="left" className="td_cell border-0">
                                                  <input
                                                    type="text"
                                                    placeholder="Input"
                                                    className="w-75 form-control"
                                                    defaultValue={variation?.campaignStock ?? 0}
                                                    onChange={e => handleEditTempProductData({
                                                      indexKey: variationKey,
                                                      key: 'campaignStock',
                                                      value: e.target.value
                                                    })}
                                                  />
                                                </TableCell>
                                                <TableCell align="left" className="td_cell border-0">
                                                  {variation?.stock || "N/A"}
                                                </TableCell>
                                                <TableCell align="left" className="td_cell border-0">
                                                  <input
                                                    type="text"
                                                    placeholder="Input"
                                                    className="w-75 form-control"
                                                    defaultValue={variation?.unitCapped ?? 0}
                                                    onChange={e => handleEditTempProductData({
                                                      indexKey: variationKey,
                                                      key: 'unitCapped',
                                                      value: e.target.value
                                                    })}
                                                  />
                                                </TableCell>


                                                <TableCell className="d-flex border-0 table_action_item">
                                                  <IOSSwitch
                                                    checked={variation?.isActive}
                                                    // onChange={(e) => setStatus(e.target.checked)}
                                                    onChange={e => handleEditTempProductData({
                                                      indexKey: variationKey,
                                                      key: 'isActive',
                                                      value: !variation.isActive
                                                    })}
                                                  />
                                                </TableCell>
                                              </TableRow>
                                            ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </div>
                                </div>
                                <br />
                                <div className="border"
                                     style={{ borderRadius: "10px", overflow: "hidden" }}
                                >
                                  <div className=" bg-light">
                                    <div className="pl-3 pt-3 mb-0 fntSz18">Team Purchase</div>
                                    <hr />
                                  </div>
                                  <div className="mx-3">
                                    <TableContainer>
                                      <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell
                                              className="th_cell"
                                              component="th"
                                              align="left"
                                            >
                                              <div className="d-flex  ">
                                                <div>Variation(s)</div>

                                              </div>
                                            </TableCell>
                                            <TableCell
                                              className="th_cell"
                                              component="th"
                                              align="left"
                                            >
                                              <div className="d-flex  ">
                                                <div>Original Price</div>

                                              </div>
                                            </TableCell>
                                            <TableCell
                                              className="th_cell "
                                              component="th"
                                              align="left"
                                            >
                                              <div className="d-flex  ">
                                                <div>Discounted Price</div>

                                              </div>
                                            </TableCell>
                                            <TableCell
                                              className="th_cell "
                                              component="th"
                                              align="left"
                                            >
                                              <div className="d-flex  ">
                                                <div>Campaign Stock</div>

                                              </div>
                                            </TableCell>
                                            <TableCell
                                              className="th_cell "
                                              component="th"
                                              align="left"
                                            >
                                              <div className="d-flex  ">
                                                <div>Stock</div>

                                              </div>
                                            </TableCell>
                                            <TableCell
                                              className="th_cell "
                                              component="th"
                                              align="left"
                                            >
                                              <div className="d-flex  ">
                                                <div>Unit Capped</div>

                                              </div>
                                            </TableCell>

                                            <TableCell className="table_action_head text-secondary" component="th">
                                              Enable/Disable
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>

                                          {tempEditProductData.variation &&
                                            tempEditProductData.variation.length > 0 &&
                                            tempEditProductData.variation.map((variation, variationKey) => (
                                              <TableRow
                                                key={'team-purchase-'+variationKey}
                                                className="border-0"
                                                sx={{
                                                  "&:last-child td, &:last-child th": { border: 0 },
                                                }}

                                              >

                                                <TableCell align="left" className="td_cell border-0">
                                                  {variation?.Size || "N/A"}s
                                                </TableCell>
                                                <TableCell align="left" className="td_cell border-0">
                                                  {variation?.teamPrice || "0"}
                                                </TableCell>
                                                <TableCell align="left" className="td_cell border-0">
                                                  <InputField
                                                    className="input_icon_left"
                                                    formClass="col-12 p-0"
                                                    type="number"
                                                    id="individual-price"
                                                    icon="currency"
                                                    placeholder="Input"
                                                    name="price"
                                                    defaultValue={variation?.teamDiscountPrice ?? 0}
                                                    onChange={e => handleEditTempProductData({
                                                      indexKey: variationKey,
                                                      key: 'teamDiscountPrice',
                                                      value: e.target.value
                                                    })}
                                                    // onChange={onChangeSalesInfo}
                                                    // required={true}
                                                  />
                                                </TableCell>

                                                <TableCell align="left" className="td_cell border-0">
                                                  <input
                                                    type="text"
                                                    placeholder="Input"
                                                    className="w-75 form-control"
                                                    defaultValue={variation?.teamCampaignStock ?? 0}
                                                    onChange={e => handleEditTempProductData({
                                                      indexKey: variationKey,
                                                      key: 'teamCampaignStock',
                                                      value: e.target.value
                                                    })}
                                                  />
                                                </TableCell>
                                                <TableCell align="left" className="td_cell border-0">
                                                  {variation?.stock || "N/A"}
                                                </TableCell>
                                                <TableCell align="left" className="td_cell border-0">
                                                  <input
                                                    type="text"
                                                    placeholder="Input"
                                                    className="w-75 form-control"
                                                    defaultValue={variation?.teamUnitCapped ?? 0}
                                                    onChange={e => handleEditTempProductData({
                                                      indexKey: variationKey,
                                                      key: 'teamUnitCapped',
                                                      value: e.target.value
                                                    })}
                                                  />
                                                </TableCell>
                                                <TableCell className="d-flex border-0 table_action_item">
                                                  <IOSSwitch
                                                    checked={variation?.teamIsActive}
                                                    // onChange={(e) => setStatus(e.target.checked)}
                                                    onChange={e => handleEditTempProductData({
                                                      indexKey: variationKey,
                                                      key: 'teamIsActive',
                                                      value: !variation.teamIsActive
                                                    })}
                                                  />
                                                </TableCell>
                                              </TableRow>
                                            ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </div>
                                </div>
                                <br />
                                <div className="d-flex flex-row justify-content-end">
                                  <button
                                    // onClick={() => history.push("/marketing/all-promo/flash-sale/participate-overview")}
                                    onClick={() => toggleEditProduct()}
                                    type="button"
                                    className="btn btn-outline-danger px-4 mx-2"
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    onClick={handleEditProduct}
                                    className="btn btn-secondary px-4 mx-2"
                                    aria-disabled="true"
                                    // disabled
                                    // onClick={back}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                          </div>
                        ))}

                    </div>
                </div>


                <footer className="bg-white p-3 mt-5">
                    <div className={`d-flex justify-content-end`}>
                        <button
                            onClick={() => history.push("/marketing/all-promo/flash-sale/participate-overview")}
                            type="button"
                            className="btn btn-outline-danger mx-2"
                        // onClick={back}
                        >
                            Cancel
                        </button>
                        <button
                            // onClick={() => history.push("/marketing/all-promo/flash-sale")}
                            type="button"
                            className="btn btn-danger mx-2"
                        // onClick={back}
                        >
                            Confirm
                        </button>


                    </div>
                </footer>
            </div>
        </React.Fragment>
    )
}

export default FlashSaleProductInfo;
