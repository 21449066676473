import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,

} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { getAllMerchantGroupDeals } from "../../../../../redux/actions/marketing.action";
import { stopLoader, startLoader } from "../../../../../lib/global";
import moment from "moment";
import { breadcrumbAction } from "../../../../../redux/actions/config.action";
import SvgIcon from "../../../../../components/Images/SvgIcon";

const GroupDealsDetail = () => {
  const history  = useHistory()
  const { id } = useParams();
  const dispatch = useDispatch()
  const [allMerchant, setMerchant] = useState([])

  const apiData = (id) => {
    startLoader()
    dispatch(getAllMerchantGroupDeals(id))
      .then((data) => {
        setMerchant(
          data &&
          data.data &&
          data.data.data.map((row, index) => {
            return {
              storeId: row?.storeId || "N/A",
              merchantName: row?.merchantName || "N/A",
              merchantType: row?.merchantType || "N/A"
            };
          })
        );

        setTimeout(() => {
          stopLoader();
        }, 1000);
      })
      .catch((err) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
        console.log({ err });
      });
  }

  useEffect(() => {
    const breadcrumb = [
      { label: "All Promo", url: "/marketing/all-promo" },
      { label: "Group Deals", url: "/marketing/all-promo/group-deals" },
      { label: "Merchant Participate", url: null }
    ];
    dispatch(breadcrumbAction(breadcrumb));

    apiData(id)
  }, []);


  const viewAction = (storeId) => {
    history.push(`/marketing/all-promo/group-deals/product?groupDealsId=${id}&merchantId=${storeId}`);
  };

  return (
    <React.Fragment>
      <div className=" d-flex flex-column justify-content-between container_body vh-100">
        <div className="container-fluid">
          <div className="card p-3">
            <h5>
              GROUP DEALS MERCHANT PARTICIPATE
            </h5>
            <div className="card p-3 m-1 border rounded">
              <div className="mb-3">
                <h6 className="float-left">ALL MERCHANT</h6>
              </div>

              <TableContainer>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
                        <div className="d-flex  ">
                          <div>Merchant ID</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                              // onClick={() => getApiData({ item: "title", order: "ASC" })}

                            />
                            <ExpandMoreIcon
                              style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                              //onClick={() => getApiData({ item: "title", order: "DESC" })}

                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
                        <div className="d-flex  ">
                          <div>Merchant Name</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                              //onClick={() => getApiData({ item: "title", order: "ASC" })}

                            />
                            <ExpandMoreIcon
                              style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                              // onClick={() => getApiData({ item: "title", order: "DESC" })}

                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell "
                        component="th"
                        align="center"
                      >
                        <div className="d-flex  ">
                          <div>Merchant Type</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                              // onClick={() => getApiData({ item: "Rating", order: "ASC" })}

                            />
                            <ExpandMoreIcon
                              style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                              // onClick={() => getApiData({ item: "Rating", order: "DESC" })}

                            />
                          </div>
                        </div>
                      </TableCell>

                      <TableCell className="table_action_head text-secondary" component="th">
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {allMerchant &&
                      allMerchant.length > 0 &&
                      allMerchant.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left" className="td_cell">
                            {row?.storeId}
                          </TableCell>
                          <TableCell align="left" className="td_cell">
                            {row?.merchantName}
                          </TableCell>

                          <TableCell align="left" className="td_cell">
                            {row?.merchantType}
                          </TableCell>

                          <TableCell className="d-flex  table_action_item">
                            <IconButton
                              className="pointer"
                              onClick={() => viewAction(row.storeId)}
                            >
                              <SvgIcon src="/icons/view_icon.svg" alt="view icon" />
                            </IconButton>


                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={10}
                component="div"
                // count={allProductData.length}
                count={2}
                rowsPerPage={10}
                page={0}
                onPageChange={(e) => {
                  console.log("page change", e);
                }}
                // onRowsPerPageChange={(e)=>{console.log('page change', e)}}
              />
            </div>

          </div>
        </div>

        <footer className="bg-white p-3 ">
          <div className="d-flex justify-content-end">
            <button
              onClick={() => history.push("/marketing/all-promo")}
              type="button"
              className="btn btn-outline-dark mx-2"
              // onClick={back}
            >
              Back
            </button>


          </div>
        </footer>
      </div>
    </React.Fragment>
  )
}


export default GroupDealsDetail;
