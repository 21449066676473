import { IconButton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import InputField from "../../../../components/formControl/input/InputField";
import { startLoader, stopLoader, showToast, open_dialog } from "../../../../lib/global";
import { breadcrumbAction } from "../../../../redux/actions/config.action";

import "../../../../static/css/consumerCenterCss/consumerCenterOverview.css";
import AllVoucher from "./AllVoucher";
import { TabPanel } from "../../../../components/Tabs/TabPannel";
import SelectInput from "../../../../components/select";
import { IOSSwitch } from "../../../../components/switch/IOSSwitch";
import { searchMerchantList } from "../../../../redux/actions/merchant-center.action";
import {
  createVoucher,
  getLiveStreamById,
  getVoucherById,
  updateVoucher
} from "../../../../redux/actions/marketing.action";
import SvgIcon from "../../../../components/Images/SvgIcon";
import Select2 from "react-select";
import Checkbox from "../../../../components/checkbox";
import { CREATE_VOUCHER_ADD_PRODUCT } from "../../../../lib/constant";
import {formatDate} from "../../../../lib/helper";
import moment from "moment";


// const initialConsumerSearchDetail = {
//   consumerName: "",
//   consumerRegisteredDate: "",
// };

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function VoucherListing() {
  const { voucherId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [merchantList, setMerchantList] = useState([]);
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [voucherData,setVoucherData] = useState({
    name:"",
    code:"",
    category:null,
    description:"",
    type:null,
    sharingUU2:null,
    sharingMerchant:null,
    amount:null,
    isAllowFreeShiping:false,
    startPeriodDate:null,
    endPeriodDate:null,
    isFirstOrderOnly:false,
    minSpend:null,
    isIndividualUse:false,
    includeSaleItems:null,
    canBeUtilized:null,
    usageLimitPerVoucher:null,
    usageLimitPerConsumer:null,
    isEnableForAllMerchant:false,
    couponPriceDeducted:null,
    merchants:null,
    products:[]
  });
  const [categoryList,setCategoryList] = useState([
    {value:'SHOPPING',label:'Shopping'},
    {value:'SHIPPING',label:'Shipping'},
  ]);
  const [typeList,setTypeList] = useState([
    {value:'PERCENTAGE_DISCOUNT',label:'Percentage Discount'},
    {value:'FIXED_PRODUCT_DISCOUNT',label:'Fixed Product Discount'},
    // {value:'FREE_SHIPPING',label:'Free Shipping'},
  ]);
  const [canBeUtilizedList,setCanBeUtilizedList] = useState([
    {value:'APP',label:'App'},
    {value:'VIDEO_PLAYBACK',label:'Video/Playback'},
    {value:'WEBSITE',label:'Website'},
  ])
  const [includeSaleItemsList,setincludeSaleItemsList] = useState([
    {value:'FLASH_DEALS',label:'Flash Deals'},
    {value:'GROUP_DEALS',label:'Group Deals'},
    {value:'LIVE_DEALS',label:'Live Deals'},
    {value:'NORMAL_DEALS',label:'Normal Deals'},
  ]);
  const [couponPriceDeductedList,setCouponPriceDeductedList] = useState([
    {value:'MERCHANT_EARNING',label:'Merchant Earning'},
    {value:'ADMIN_COMMISSIONS',label:'Admin Commissions'},
    {value:'SHARED',label:'Shared'},
  ]);

  useEffect(() => {
    // startLoader();
    const breadcrumb = [
      { label: "Voucher", url: "/marketing/all-promo/voucher" },
      { label: voucherId?"Edit Voucher":"Create Voucher", url: null }
    ];
    dispatch(breadcrumbAction(breadcrumb));
    getMerchantList()
    if(voucherId){
      dispatch(getVoucherById(voucherId))
        .then((response) => {
          let temp = response.data.data;
          delete temp.createdAt
          delete temp.updatedAt
          setVoucherData(temp);
        })
        .catch((error) => {
          console.log({ error });
        });
    }
  }, []);

  const onChangeVoucherData = (e, opt, val) => {
    const key = opt || e.target.name;
    let value = val || e.target.value;
    setVoucherData((pre) => ({ ...pre, [key]: value }));
  };
  const toggleVoucherData = (e, opt, val) => {
    const value = e.target.checked;
    const key = e.target.name;
    // let temp = voucherData;
    setVoucherData((pre) => ({ ...pre, [key]: value }));
    if(key === 'isEnableForAllMerchant'){
      if(value){
        if(voucherData.isApplicableForAllProduct){
          showToast(" Voucher must choose one of the specific product or specific merchant", "error");
          setVoucherData((pre) => ({ ...pre, [key]: false }));
        }
        setVoucherData((pre) => ({ ...pre, merchants: null }));
      }
    }
    if(key === 'isApplicableForAllProduct' && value){
      if(voucherData.isEnableForAllMerchant){
        showToast(" Voucher must choose one of the specific product or specific merchant", "error");
        setVoucherData((pre) => ({ ...pre, [key]: false }));
      }
      // setVoucherData((pre) => ({...pre, products: null }));
    }
  };
  const isIncludeSaleItems = (item) => {
    let isChecked = false;
    if(voucherData.includeSaleItems){
      voucherData.includeSaleItems.map((i)=>{
        if(i.value == item){
          isChecked = true
        }
      })
    }
    return isChecked
  }
  const handleAddProduct = () => {
    open_dialog(CREATE_VOUCHER_ADD_PRODUCT,{
      data:voucherData,
      fullWidth:true,
      width:'md',
      onSubmit:selectProductSuccess
    })
  }
  function refreshTabs() {
    let currentTab = tabIndex;
    setTabIndex(0);
    setTimeout(() => {
      setTabIndex(currentTab);
    })
  }
  const selectProductSuccess = async (data) => {
    if(data != []){
      let products = voucherData.products;
      let listId = await products.map((i)=>i.productId);
      data.map(async (item) => {
        if (!await inArray(listId,item.productId)) {
          products.push(item)
          listId.push(item.productId)
        }
      })
      setVoucherData((pre) => ({ ...pre, products: products }));
      refreshTabs()
    }
  }

  const inArray = async (arr, item) => {
    let result = false;
    console.log(arr)
    await arr.map((i)=>{
      if(i.value == item){
        result = true;
      }
    })
    return result;
  }
  const setIncludeSaleItems = (item) => {
    let temp = voucherData.includeSaleItems;
    if(temp){
      let index = temp.findIndex((i)=>i.value == item.value)
      if(index > -1){
        temp.splice(index,1)
      }else{
        temp.push(item)
      }
    }else{
      temp = [item]
    }
    setVoucherData((pre) => ({ ...pre, includeSaleItems: temp }));
  }

  const isCanBeUtilized = (item) => {
    let isChecked = false;
    if(voucherData.canBeUtilized){
      voucherData.canBeUtilized.map((i)=>{
        if(i.value == item){
          isChecked = true
        }
      })
    }
    // console.log(item)
    // console.log(isChecked)
    return isChecked
  }

  const setCanBeUtilized = (item) => {
    let temp = voucherData.canBeUtilized;
    if(temp){
      let index = temp.findIndex((i)=>i.value == item.value)
      if(index > -1){
        temp.splice(index,1)
      }else{
        temp.push(item)
      }
    }else{
      temp = [item]
    }
    setVoucherData((pre) => ({ ...pre, canBeUtilized: temp }));
  }

  const getMerchantList = (search) => {
    dispatch(searchMerchantList(search,9999))
      .then((response) => {
        if (response?.data?.data) {
          let data = []
          if(role.toLowerCase() === "merchant"){
            response.data.data.map((item) => {
              let isPush = false;
              if(role.toLowerCase() === "merchant"){
                if(item.storeId === storeId){
                  isPush = true
                  setVoucherData((pre) => ({ ...pre, merchant: {
                      title: item.preferredMerchantName,
                      label: `${item.preferredMerchantName} - (${item.storeId})`,
                      value: item,
                    }
                  }));
                }
              }else{
                isPush = true;
              }

              if(isPush){
                data.push({
                  title: item.preferredMerchantName,
                  label: `${item.preferredMerchantName} - (${item.storeId})`,
                  value: item,
                })
              }
            });
          }else{
            data = response.data.data.map((item) => ({
              title: item.preferredMerchantName,
              label: `${item.preferredMerchantName} - (${item.storeId})`,
              value: item,
            }));
          }

          setMerchantList(data);
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const onSubmit = async(status) => {
    let payload = {
      ...voucherData,
      status:status,
    }
    let isValidate = validateForm(payload)
    console.log(isValidate,'isValidate')
    if(!isValidate){
      return isValidate
    }
    if (!payload.id) {
      dispatch(createVoucher(payload))
        .then((res) => {
          setTimeout(() => {
            stopLoader();
            showToast("Voucher added successfully", "success");
            history.push("/marketing/all-promo/voucher");
          }, 1000);
        })
        .catch((err) => {
          stopLoader();
          const message = JSON.parse(err.request.responseText).message
          showToast("Fail Add Voucher ["+message+"]", "error");
          // history.push("/banner");
        });
    }else{
      dispatch(updateVoucher(payload))
        .then((res) => {
          setTimeout(() => {
            stopLoader();
            showToast("Voucher updated successfully", "success");
            history.push("/marketing/all-promo/voucher");
          }, 1000);
        })
        .catch((err) => {
          stopLoader();
          const message = JSON.parse(err.request.responseText).message
          showToast("Fail Update Voucher ["+message+"]", "error");
          console.log({ err });
          // history.push("/banner");
        });
    }
  }

  const validateForm = (payload) => {
    if (!payload.name) {
      showToast("Voucher Name is required", "error");
      return false;
    }
    if (!payload.code) {
      showToast("Voucher Code is required", "error");
      return false;
    }
    if (!payload.type) {
      showToast("Voucher Type is required", "error");
      return false;
    }
    if (!payload.startPeriodDate && !payload.endPeriodDate) {
      showToast("Voucher Period is required", "error");
      return false;
    }
    if(payload.endPeriodDate && payload.endPeriodDate < moment().format('YYYY-MM-DD')){
      showToast("End Period Date must be greater than now ("+moment().format('DD/MM/YYYY')+")", "error");
      return false;
    }

    if(payload.includeSaleItems == null || payload.includeSaleItems.length==0){
      showToast("Include Sale Items is required, minimum 1", "error");
      return false;
    }
    if(!payload.couponPriceDeducted){
      showToast("Voucher Price Deducted is required", "error");
      return false
    }
    if(payload.couponPriceDeducted != null && payload.couponPriceDeducted.value == 'SHARED'){
      if(payload.sharingUU2 == null){
        showToast("Sharing UU2 is required", "error");
        return false;
      }
      if(payload.sharingMerchant == null){
        showToast("Sharing Merchant is required", "error");
      }
      if(parseFloat(payload.sharingUU2) + parseFloat(payload.sharingMerchant) != 100){
        showToast("Sharing UU2 + Sharing Merchant must be 100", "error");
        return false;
      }
    }
    return true
  }

  const removeAction = (index) => {
    let temp = voucherData.products;
    temp.splice(index,1)
    setVoucherData((pre) => ({ ...pre, products: temp }));
  }

  const allowNumberOnly = (e) => {
    let keyCode = e.keyCode ? e.keyCode : e.which;
    // keyCode is number or backspace or delete or .
    if (keyCode!= 46 && (keyCode < 48 || keyCode > 57)) {
      e.preventDefault();
    }
  };

  return (
    <div className="main_consumer_account_div">
      <div className="mt-1 mx-3 px-2 py-3 rounded bg-white">
        <div className="row">
          <div className="col-6">
            <div className="col-12 row my-3">
              <label className="col-4 text-right m-0 pt-2 imp">
                Voucher Name
              </label>
              <div className="form-group col-8">
                <InputField
                  type="text"
                  className="form-control"
                  placeholder="Voucher Name"
                  id="name"
                  name="name"
                  value={voucherData.name}
                  onChange={onChangeVoucherData}
                />
              </div>
            </div>
            <div className="col-12 row my-3">
              <label className="col-4 text-right m-0 pt-2 imp">
                Voucher Code
              </label>
              <div className="form-group col-8">
                <InputField
                  type="text"
                  className="form-control"
                  placeholder="Voucher Code"
                  name="code"
                  id="code"
                  value={voucherData.code}
                  onChange={onChangeVoucherData}
                />
              </div>
            </div>
            <div className="col-12 row my-3">
              <label className="col-4 text-right m-0 pt-0 imp">
                Voucher Category
              </label>
              <div className="form-group col-8">
                <SelectInput
                  options={categoryList}
                  name="category"
                  id="category"
                  value={voucherData.category}
                  onChange={(e) => onChangeVoucherData(e, "category", e)}
                  required={true}
                  placeholder="Select Category"
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row my-3 mx-2">
              <label
                htmlFor="product-name"
                className="col-4 text-right m-0 pr-2"
              >
                Voucher Description
              </label>
              <textarea
                id="textarea"
                className="form-control col-md-8 p-2"
                style={{ minHeight: "20vh" }}
                value={voucherData.description}
                onChange={(e) => onChangeVoucherData(e, "description", e.target.value)}
                placeholder="10% discount platform-wide for New Signup"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container_body container-fluid mt-2">
        <div className="card p-3">
          <div className=" border card rounded-lg my-3">
            <Tabs
              value={tabIndex}
              onChange={(e, value) => setTabIndex(value)}
              variant="scrollable"
              orientation="horizontal"
              scrollButtons={false}
              visibleScrollbar={false}
              className="px-3"
              aria-label="basic tabs example"
            >
              <Tab label={`General`} {...a11yProps(0)} />
              <Tab label={`Usage Restriction`} {...a11yProps(1)} />
              <Tab label={`Usage Limit`} {...a11yProps(2)} />
              <Tab label={`Merchant Limit`} {...a11yProps(3)} />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <div className="row">
                <div className="col-6">
                  <div className="col-12 row my-3">
                    <label className="col-4 text-right m-0 pt-0 imp">
                      Voucher Type
                    </label>
                    <div className="form-group col-8">
                      <SelectInput
                        options={typeList}
                        name="type"
                        id="type"
                        value={voucherData.type}
                        onChange={(e) => onChangeVoucherData(e, "type", e)}
                        required={true}
                        placeholder="Select Type"
                      />
                    </div>
                  </div>
                  <div className="col-12 row my-3">
                    <label className="col-4 text-right m-0 pt-2">
                      Voucher Amount
                    </label>
                    <div className="form-group col-8">
                      <InputField
                        type="number"
                        onKeyPress={allowNumberOnly}
                        className="form-control"
                        placeholder="Voucher Amount"
                        name="amount"
                        id="amount"
                        value={voucherData.amount}
                        onChange={onChangeVoucherData}
                      />
                    </div>
                  </div>

                  <div className="col-12 row my-3">
                    <label className="col-4 text-right m-0 pt-3 imp">
                      Voucher Period
                    </label>
                    <div className="form-group col-4">
                      <InputField
                        type="date"
                        className="form-control"
                        id="startPeriodDate"
                        name="startPeriodDate"
                        value={formatDate(voucherData.startPeriodDate,'YYYY-MM-DD')}
                        onChange={onChangeVoucherData}
                      />
                    </div>
                    <div className="form-group col-4">
                      <InputField
                        type="date"
                        className="form-control"
                        id="endPeriodDate"
                        name="endPeriodDate"
                        value={formatDate(voucherData.endPeriodDate,'YYYY-MM-DD')}
                        onChange={onChangeVoucherData}
                      />
                    </div>
                  </div>
                  <div className="col-12 row my-3">
                    <label className="col-4 my-auto text-right">
                      For first order only
                    </label>
                    <div className="form-group col-8 m-0">
                      <IOSSwitch
                        name="isFirstOrderOnly"
                        id="isFirstOrderOnly"
                        onChange={toggleVoucherData}
                        checked={voucherData?.isFirstOrderOnly}
                        value={voucherData?.isFirstOrderOnly}
                        className="mt-2"
                      />
                      {voucherData.isFirstOrderOnly}
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <div className="row">
                <div className="col-12">
                  <div className="col-12 row my-0">
                    <label htmlFor="" className="col-4 text-right pt-2">
                      Can Be utilized for
                    </label>
                    <div className="col-8">
                      <div className="row">
                        {canBeUtilizedList.map((item, index) => {
                          return (
                            <div className="col-6">
                              <Checkbox
                                formControlClass={"text-muted m-0"}
                                className={"text-dark"}
                                checked={isCanBeUtilized(item.value)}
                                onChange={() => setCanBeUtilized(item)}
                                label={item.label}
                              />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 row my-0">
                    <label htmlFor="" className="col-4 text-right pt-2 imp">
                      Include Sale Items
                    </label>
                    <div className="col-8">
                      <div className="row">
                        {
                          includeSaleItemsList.map((item, index) => {
                            return (
                              <div className="col-6">
                                <Checkbox
                                  formControlClass={"text-muted m-0"}
                                  className={"text-dark"}
                                  checked={isIncludeSaleItems(item.value)}
                                  onChange={() => setIncludeSaleItems(item)}
                                  label={item.label}
                                />
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-12 row my-0">
                    <label className="col-4 text-right pt-2">
                      Min Spend
                    </label>
                    <div className="form-group col-8">
                      <InputField
                        type="number"
                        className="form-control"
                        placeholder="No Minimum"
                        name="minSpend"
                        id="minSpend"
                        onKeyPress={allowNumberOnly}
                        value={voucherData.minSpend}
                        onChange={onChangeVoucherData}
                      />
                    </div>
                  </div>
                  <div className="col-12 row my-0">
                    <label className="col-4 text-right pt-2">
                    Maximum capped value
                    </label>
                    <div className="form-group col-8">
                      <InputField
                        type="number"
                        className="form-control"
                        placeholder="No Maximum Capped Value"
                        name="maxCappedValue"
                        id="maxCappedValue"
                        value={voucherData.maxCappedValue}
                        onKeyPress={allowNumberOnly}
                        onChange={onChangeVoucherData}
                      />
                    </div>
                  </div>
                  <div className="col-12 row my-0">
                    <label className="col-4 my-auto text-right">
                      Individual Use
                    </label>
                    <div className="form-group col-8 m-0">
                      <IOSSwitch
                        name="isIndividualUse"
                        id="isIndividualUse"
                        onChange={toggleVoucherData}
                        checked={voucherData?.isIndividualUse}
                        value={voucherData?.isIndividualUse}
                        className="mt-2"
                      />
                    </div>
                  </div>
                  <div className="col-12 row my-0">
                    <label className="col-4 my-auto text-right">
                      Applicable Product
                    </label>
                    <div className="form-group col-8 m-0">
                      <IOSSwitch
                        name="isApplicableForAllProduct"
                        id="isApplicableForAllProduct"
                        onChange={toggleVoucherData}
                        checked={voucherData?.isApplicableForAllProduct}
                        className="mt-2"
                      />
                      <span className="m-2">{voucherData.isApplicableForAllProduct?'All Product':'No'}</span>
                      <button className="ml-2 btn btn-dark" type="button" disabled={voucherData.isApplicableForAllProduct} onClick={()=>handleAddProduct()}>Add Product</button>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 650 }}
                      stickyHeader
                      aria-label="sticky table"
                    >
                      <TableHead className="bg-light">
                        <TableRow>
                          <TableCell
                            className="th_cell "
                            component="th"
                            align="left"
                          >
                            <div className="d-flex  ">
                              <div>Products</div>
                              <div className="d-flex flex-column ">
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            className="th_cell "
                            component="th"
                            align="left"
                          >
                            <div className="d-flex">
                              <div>Price</div>
                              <div className="d-flex flex-column ">
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            className="th_cell "
                            component="th"
                            align="left"
                          >
                            <div className="d-flex  ">
                              <div>Stock</div>
                            </div>
                          </TableCell>
                          <TableCell
                            className="table_action_head text-secondary"
                            component="th"
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {voucherData.products &&
                          voucherData.products.length > 0 &&
                          voucherData.products.map((row, indexProduct) => (
                            <TableRow
                              key={row.productId+'-'+indexProduct}
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                              }}
                            >
                              <TableCell align="left" className="td_cell">
                                <div className="d-flex flex-row align-items-center">
                                  <div>{row.title}</div>
                                </div>
                              </TableCell>
                              <TableCell align="left" className="td_cell">
                                RM{row.lowestPrice?row.lowestPrice.price:row.price}
                              </TableCell>
                              <TableCell align="left" className="td_cell border-0">
                                {row?.totalStock || "N/A"}
                              </TableCell>
                              <TableCell className="d-flex table_action_item">
                                <IconButton
                                  className="pointer"
                                  onClick={() => removeAction(indexProduct)}
                                >
                                  <SvgIcon
                                    src="/icons/delete_icon.svg"
                                    alt="view icon"
                                  />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <div className="row">
                <div className="col-6">
                  <div className="col-12 row my-0">
                    <label className="col-6 text-right pt-2">
                      Usage Limit per Voucher
                    </label>
                    <div className="form-group col-6">
                      <InputField
                        type="number"
                        className="form-control"
                        placeholder="Unlimited"
                        name="usageLimitPerVoucher"
                        id="usageLimitPerVoucher"
                        value={voucherData.usageLimitPerVoucher}
                        onKeyPress={allowNumberOnly}
                        onChange={onChangeVoucherData}
                      />
                    </div>
                  </div>
                  <div className="col-12 row my-0">
                    <label className="col-6 text-right pt-2">
                      Usage Limit per Consumer
                    </label>
                    <div className="form-group col-6">
                      <InputField
                        type="number"
                        className="form-control"
                        placeholder="Unlimited"
                        name="usageLimitPerConsumer"
                        id="usageLimitPerConsumer"
                        value={voucherData.usageLimitPerConsumer}
                        onKeyPress={allowNumberOnly}
                        onChange={onChangeVoucherData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
              <div className="row">
                <div className="col-12">
                  <div className="col-12 row my-0">
                    <label className="col-3 my-auto text-right">
                      Enable for all merchant
                    </label>
                    <div className="form-group col-1">
                      <IOSSwitch
                        name="isEnableForAllMerchant"
                        id="isEnableForAllMerchant"
                        onChange={toggleVoucherData}
                        checked={voucherData?.isEnableForAllMerchant}
                        // checked={(e) => toggleLivestreamData(e, "is-test", e)}
                        value={voucherData?.isEnableForAllMerchant}
                        className="mt-2"
                      />
                      {voucherData.isEnableForAllMerchant}
                    </div>
                    <label className="col-7 my-auto text-left small">
                      Check this box if you want to apply this coupon for all vendors
                    </label>
                  </div>
                  <div className="col-12 row my-3">
                    <label className="col-md-4 col-lg-3 text-right mt-3 imp">
                      Voucher Price Deducted
                    </label>
                    <div className="col-md-6 col-lg-3 mt-2">
                      <SelectInput
                        options={couponPriceDeductedList}
                        name="couponPriceDeducted"
                        id="couponPriceDeducted"
                        value={voucherData.couponPriceDeducted}
                        onChange={(e) => onChangeVoucherData(e, "couponPriceDeducted", e)}
                        required={true}
                        placeholder="Select Coupon"
                      />
                    </div>
                    <div className="col-md-12 col-lg-6 float-md-left float-lg-right row" style={{visibility: voucherData.couponPriceDeducted && voucherData.couponPriceDeducted.value == 'SHARED' ? 'visible' : 'hidden' }}>
                      <div className="form-group col-3">
                        <InputField
                          type="number"
                          className="form-control"
                          placeholder="60%"
                          name="sharingUU2"
                          id="sharingUU2"
                          value={voucherData.sharingUU2}
                          onChange={onChangeVoucherData}
                        />
                      </div>
                      <label className="col-2 text-left mt-3">UU2</label>
                      <div className="form-group col-3">
                        <InputField
                          type="number"
                          className="form-control"
                          placeholder="40%"
                          name="sharingMerchant"
                          id="sharingMerchant"
                          value={voucherData.sharingMerchant}
                          onChange={onChangeVoucherData}
                        />
                      </div>
                      <label className="col-4 text-left mt-3">Merchant</label>
                    </div>
                  </div>

                  <div className="col-12 row my-3" style={{visibility: !voucherData.isEnableForAllMerchant ? 'visible' : 'hidden' }}>
                    <label className="col-3 text-right m-0 pt-0">
                      Merchant
                    </label>
                    <div className="form-group col-9">
                      <Select2
                        options={merchantList}
                        // disabled={voucherData.isEnableForAllMerchant}
                        name="merchants"
                        id="merchants"
                        value={voucherData.merchants}
                        onChange={(e) => onChangeVoucherData(e, "merchants", e)}
                        required={true}
                        isMulti={true}
                        placeholder="Select Merchant"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </div>
          <div className="card-footer">
            <div className="row float-right">
              <button className="btn btn-outline-dark m-2" onClick={history.goBack}>Cancel</button>
              {(voucherData.status =='DRAFT' || voucherData.status==null) && (
                  <button className="btn btn-outline-dark m-2" onClick={()=>onSubmit('DRAFT')}>Save To Draft</button>
              )}
              {(voucherData.status =='DRAFT' || voucherData.status==null) && (
                  <button className="btn btn-outline-dark m-2" onClick={()=>onSubmit('PUBLISHED')}>Save & Publish</button>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VoucherListing;
