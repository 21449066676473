import React from "react";
import { useHistory } from "react-router-dom";
//material ui dialog component
import Dialog from "@mui/material/Dialog";
//material ui dialog content
import DialogContent from "@mui/material/DialogContent";
import { withStyles } from "@mui/styles";
import AddLanguage from "../../pages/language/AddLanguage";
import * as dialogs from "../../lib/constant";
import AddBankAccount from "../../pages/merchant-centre/add-merchant/AddBankAccount";
import AddAuthoried from "../../pages/merchant-centre/add-merchant/AddAuthoriedModal";
import AddShippingAddress from "../../pages/merchant-centre/add-merchant/AddShippingAddress";
import AddPartnerModal from "../../pages/merchant-centre/add-merchant/AddPartnerModal";
import AddSubAuthoried from "../../pages/merchant-centre/add-merchant/AddSubAuthoriedModal";
import CategorySelectionDialog from "../../pages/products/categories/CategorySelectionDialog";
import DeleteModal from "./DeleteModal";
import EditBankAccount from "../../pages/merchant-centre/add-merchant/EditBankAccount";
import EditMerchantAccount from "../../pages/merchant-centre/add-merchant/EditMerchnatAccount";
import EditMerchantShippingAddress from "../../pages/merchant-centre/add-merchant/EditShippingAddress";
import EditSubAccount from "../../pages/merchant-centre/add-merchant/EditSubAccount";
import EditPartnerModal from "../../pages/merchant-centre/add-merchant/EditPartners";
import VerifyMerchantDialog from "../../merchant-pages/account/VerifyMerchantDialog";

import AddConsumerBankAccount from "../../pages/consumer-center/add-consumer/AddConsumerBankAccount";
import AddConsumerShippingAddress from "../../pages/consumer-center/add-consumer/AddConsumerShippingAddress";
import EditConsumerBankAccount from "../../pages/consumer-center/add-consumer/EditConsumerBankAccount";
import EditConsumerShoppingAddress from "../../pages/consumer-center/add-consumer/EditConsumerShoppingAddress";
import DeleteConsumerAccountModal from "../../pages/consumer-center/consumer-account-listing/DeleteConsumerAccountModel";
import DeleteBrandModal from "../../pages/products/brands/DeleteBrand";
import ProductDelete from "../../pages/products/productListing/ProductDelet";
// import ToasterDrawer from "../drawer/toaster-drawer/toaster-drawer";
import DeleteProductModal from "../../merchant-pages/products/productListing/DeleteProductByMerchantModal";
import Delete from "@mui/icons-material/Delete";
import DeleteCategory from "../../pages/products/categories/ProductDeleteCategoryModal";
import ShowTrasactionHistoryModal from "../../pages/order/Order_Listing/ShowTrasactionHistoryModal";
import AccessDeniedModal from "./AccessDeniedModal";
import DeleteProductByMerchantModal from "../../merchant-pages/products/productListing/DeleteProductByMerchantModal";
import MerchantFlashSaleAddProductDialog from "../../merchant-pages/marketing/all-promo/flash-deals/MerchantFlashSaleAddProductDialog";
import MerchantFlashSaleDuplicateAddProduct from "../../merchant-pages/marketing/all-promo/flash-deals/duplicate-timeslot/MerchantFlashSaleDuplicateAddProduct";
import FlashSaleAddProductDialog from "../../pages/marketing/all-promo/FlashSaleListing/FlashSaleAddProductDialog";
import FlashSaleAddProductListDialog from "../../pages/marketing/all-promo/FlashSaleListing/FlashSaleAddProductListDialog";
import ViewOrderHistoryModal from "../../pages/finance/order-receipt/ViewOrderHistoryModal";
import FinanceGenerateReportModal from "../../pages/finance/report/FinanceGenerateReportModal";
import CreateLivestreamAddProductDialog from "../../pages/marketing/all-promo/livestream/createlivestream/CreateLivestreamAddProductDialog";
import CreateLivestreamAddPromoDialog from "../../pages/marketing/all-promo/livestream/createlivestream/CreateLivestreamAddPromoDialog";
import CreateVoucherAddProductDialog from "../../pages/marketing/all-promo/voucher/VoucherAddProductDialog";
import MerchantCreateVoucherAddProductDialog from "../../merchant-pages/marketing/all-promo/voucher/VoucherCreateProductDialog";
import GoLiveShowProductDialog from "../../pages/marketing/all-promo/livestream/golive/GoLiveShowProductDialog";
import GoLiveShowPromoDialog from "../../pages/marketing/all-promo/livestream/golive/GoLiveShowPromoDialog";
import CancelEditModal from "./cancelEditModal";
import DeleteOrder from "../../pages/order/Order_Listing/DeleteOrderModal";
import MerchantAddBankAccount from "../../merchant-pages/finance/myBankAccount/merchantPanelAddBankAccountModal";
import MerchantImportProduct from "../../merchant-pages/products/productListing/MerchantImportProductModal";
import HelpCenter from "../../merchant-pages/help-center";
import DeleteBannerModel from "../../pages/banner/DeleteBannerModel";
import UserRoleDeleteModal from "../../pages/user-role/UserRoleDeleteModal";
import ForgetPasswordAlertModal from "../../pages/login/ForgetPasswordAlertModal";
import {
  DELETE_GROUP_DEAL,
  MERCHANT_DELETE_TIMESLOT_DIALOG,
  MERCHANT_FLASH_SALE_ADD_PRODUCT
} from "../../lib/constant";
import MerchantDeleteTimeSlotDialog from "../../merchant-pages/marketing/all-promo/flash-deals/MerchantDeleteTimeSlotDialog";
import DeleteLiveStream from "../../pages/marketing/all-promo/livestream/DeleteLiveStream";
import DeleteVoucherModal from "../../pages/marketing/all-promo/voucher/DeleteVoucherDialog";
import DeleteGroupDealModal from "../../pages/marketing/all-promo/group-deals/group-deals/GroupDealsDeleteDialog";
import DeleteGroupDealProductModal from "../../merchant-pages/marketing/all-promo/group-deals/group-deals-product/delete/GroupDealsProductDeleteDialog";
import CreateGroupDealsProductDialog from "../../merchant-pages/marketing/all-promo/group-deals/group-deals-product/create/GroupDealsProductCreateDialog";
import ChatListMerchant from "../../pages/chat/ListMerchant";
import ChatListCustomer from "../../pages/chat/ListCustomer";

const styles = {
  root: {
    padding: "0px !important",
    overflowX: "hidden",
  },
  outerBox: {
    borderRadius: "8px !important",
    margin: "5px",
  },
};

const ModalDialog = (props) => {
  if (props && props.type) {
    const history = useHistory();
    let { classes, dialogData } = props;
    let dialogContent = () => {
      switch (props.type) {
        case "ADD_LANGUAGE":
          return (
            <AddLanguage
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.DELETE_BANNER_MODEL:
          return (
            <DeleteBannerModel
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.ADD_MERCHANT:
          return (
            <AddBankAccount
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );

        case dialogs.ADD_AUTHORIED:
          return (
            <AddAuthoried
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.ADD_SUB_ACCOUNT:
          return (
            <AddSubAuthoried
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.ADD_SHIPPING_ADDRESS:
          return (
            <AddShippingAddress
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );

        case dialogs.DELETE_HELP_CENTER_TICKET:
          return (
            <HelpCenter
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );

        case dialogs.MERCHANT_ADD_BANK_ACCOUNT:
          return (
            <MerchantAddBankAccount
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );

        case dialogs.MERCHANT_IMPORT_PRODUCT_DIALOG:
          return (
            <MerchantImportProduct
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );

        case dialogs.CATEGORY_SELECTION_DIALOG:
          return (
            <CategorySelectionDialog
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.CANCEL_EDIT_MODAL:
          return (
            <CancelEditModal
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.DELETE_DIALOG:
          return (
            <DeleteModal
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );

        case dialogs.CREATE_LIVESTREAM_ADD_PRODUCT:
          return (
            <CreateLivestreamAddProductDialog
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.DELETE_LIVESTREAM:
          return (
            <DeleteLiveStream
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );

        case dialogs.CREATE_LIVESTREAM_ADD_PROMO:
          return (
            <CreateLivestreamAddPromoDialog
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.GO_LIVESTREAM_SHOW_PRODUCT:
          return (
            <GoLiveShowProductDialog
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.GO_LIVESTREAM_SHOW_PROMO:
          return (
            <GoLiveShowPromoDialog
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.CREATE_VOUCHER_ADD_PRODUCT:
          return (
            <CreateVoucherAddProductDialog
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.MERCHANT_CREATE_VOUCHER_ADD_PRODUCT:
          return (
            <MerchantCreateVoucherAddProductDialog
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );

        case dialogs.MERCHANT_FLASH_SALE_ADD_PRODUCT:
          return (
            <MerchantFlashSaleAddProductDialog
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );

        case dialogs.MERCHANT_FLASH_SALE_DUPLICATE_ADD_PRODUCT:
          return (
            <MerchantFlashSaleDuplicateAddProduct
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );

        case dialogs.MERCHANT_DELETE_TIMESLOT_DIALOG:
          return (
            <MerchantDeleteTimeSlotDialog
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );

        case dialogs.FLASH_SALE_ADD_PRODUCT:
          return (
            <FlashSaleAddProductDialog
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );

        case dialogs.FLASH_SALE_ADD_PRODUCT_LIST:
          return (
            <FlashSaleAddProductListDialog
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );

        case dialogs.DELETE_PRODUCT_BY_MERCHANT_DIALOG:
          return (
            <DeleteProductByMerchantModal
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );

        case dialogs.DELETE_CONSUMER:
          return (
            <DeleteConsumerAccountModal
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );

        case dialogs.DELETE_BRAND:
          return (
            <DeleteBrandModal
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.DELETE_ORDER:
          return (
            <DeleteOrder
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.ACCESS_DENIED:
          return (
            <AccessDeniedModal
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.PASSWORD_FORGET_INFO_MODAL:
          return (
            <ForgetPasswordAlertModal
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );

        case dialogs.EDIT_BANK_ACCOUNT_DIALOG:
          return (
            <EditBankAccount
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.EDIT_MERCHANT_SHIPPING_ADDRESS_DIALOG:
          return (
            <EditMerchantShippingAddress
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.EDIT_MERCHANT_ACCOUNT_DIALOG:
          return (
            <EditMerchantAccount
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.EDIT_SUB_ACCOUNT_DIALOG:
          return (
            <EditSubAccount
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );

        // ADD_BUSINESS_PARTNER
        case dialogs.ADD_BUSINESS_PARTNER:
          return (
            <AddPartnerModal
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.EDIT_PARTNERS_ACCOUNT_DIALOG:
          return (
            <EditPartnerModal
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.VERIFY_MERCHANT_DIALOG:
          return (
            <VerifyMerchantDialog
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );

        case dialogs.ADD_CONSUMER_BANK_ACCOUNT:
          return (
            <AddConsumerBankAccount
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.PRODUCT_DELETE_DIALOG:
          return (
            <ProductDelete
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );

        case dialogs.MERCHANT_PRODUCT_DELETE_DIALOG:
          return (
            <DeleteProductModal
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );

        case dialogs.DELETE_CATEGORY:
          return (
            <DeleteCategory
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.SHOW_TRANSACTION_HISTORY:
          return (
            <ShowTrasactionHistoryModal
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );

        case dialogs.EDIT_CONSUMER_BANK_ACCOUNT:
          return (
            <EditConsumerBankAccount
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.ADD_CONSUMER_SHIPPING_ADDRESS:
          return (
            <AddConsumerShippingAddress
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.EDIT_CONSUMER_SHIPPING_ADDRESS:
          return (
            <EditConsumerShoppingAddress
              {...props.dialogData}
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.VIEW_ORDER_TRANSACTION_HISTORY:
          return (
            <ViewOrderHistoryModal
              {...props.dialogData}
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.FINANCE_REPORT_MODAL:
          return (
            <FinanceGenerateReportModal
              {...props.dialogData}
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.DELETE_USER_ROLE:
          return (
            <UserRoleDeleteModal
              {...props.dialogData}
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.DELETE_VOUCHER:
          return (
            <DeleteVoucherModal
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.DELETE_GROUP_DEAL:
          return (
            <DeleteGroupDealModal
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );

        case dialogs.DELETE_GROUP_DEAL_PRODUCT:
          return (
            <DeleteGroupDealProductModal
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );

        case dialogs.CREATE_GROUP_DEAL_PRODUCT:
          return (
            <CreateGroupDealsProductDialog
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.CHAT_LIST_MERCHANT:
          return (
            <ChatListMerchant
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );
        case dialogs.CHAT_LIST_CUSTOMER:
          return (
            <ChatListCustomer
              {...props.dialogData}
              handlerDialog={props.handlerDialog}
              onClose={props.handleClose}
            />
          );

        default:
          return <div>empty</div>;
        //  history.push('/merchant/dashboard')
      }
    };
    // let dialogContent = () => {
    //   switch (props.type) {
    //     case "WalletFilter":
    //       return <h1>demo</h1>;
    //       break;
    //     default:
    //       return <div>empty</div>;
    //   }
    // };

    return (
      //dialog component
      <Dialog
        test="dialog"
        // fullWidth={props.fullWidth}
        // maxWidth={props.maxWidth}
        maxWidth="md"
        fullWidth={true}
        open={props.open}
        onBackdropClick={
          dialogData && dialogData.dialogClick
            ? dialogData.dialogClick
            : props.handleClose
        }
        className={`${classes.outerBox} ${dialogData && dialogData.outerBox
          }  mu-dialog`}
        onClose={props.handleClose}
        disableBackdropClick={props.disableBackdropClick}
        classes={{
          paper: classes.outerBox,
        }}
        {...props.dialogModel}
      >
        <DialogContent
          test="DialogContent"
          className={(classes.root, "mu-dialog-content  custom_dialog")}
        >
          <div className="w-100">{dialogContent()}</div>
        </DialogContent>
        <style>{`
          :global(.mu-dialog > div > div) {
            overflow-y: visible !important;
          }
          :global(.mu-dialog) {
            margin: 11px !important;
          }
          :global(.MuiPaper-root.MuiDialog-paper) {
            min-width: ${dialogData?.minWidth || "450px"} !important;
            max-width: ${dialogData?.maxWidth || "600px"} !important;
          }
          .mu-dialog .MuiDialog-container > div{
            max-width: ${dialogData?.maxWidth || "600px"} !important;
          }
          @media only screen and (max-width: 767px) {
            :global(.mu-dialog > div > div) {
              max-width: ${dialogData?.maxWidth || "90vw"} !important;
              min-width: ${dialogData?.minWidth || "600px"} !important;
            }

            // :global(.MuiDialog-paper) {
            //   min-width: auto !important;
            // }
          }
        `}</style>
      </Dialog>
    );
  } else {
    return ''
  }
};

export default withStyles(styles)(ModalDialog);
