export const handleDocumentTitleLogin = (role) => {
  if (role == "MERCHANT") {
    document.title = "Savvy Mart Merchant";
  } else {
    document.title = "Savvy Mart";
  }
};

export const handleDocumentTitleLogout = () => {
  document.title = "Savvy Mart";
};
