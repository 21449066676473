import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IOSSwitch } from "../../../../../components/switch/IOSSwitch";

import {
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import Image from "../../../../../components/Images/Image";
import {
  open_dialog,
  parseQuery,
  startLoader,
  stopLoader,
} from "../../../../../lib/global";
import { MERCHANT_FLASH_SALE_ADD_PRODUCT } from "../../../../../lib/constant";
import InputField from "../../../../../components/formControl/input/InputField";
import SelectInput from "../../../../../components/select";
import { getAllFlashSaleProductByTimeSlotId } from "../../../../../redux/actions/marketing.action";
import { getLocalStorage } from "../../../../../lib/session";
import { v2GetAllFlashSaleProductByTimeSlotIdByMerchant } from "../../../../../redux/actions/merchant/marketing.merchant.action";

const MerchantFlashDealsTimeSlotShow = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [buttonTimeSlot, setButtonTimeSlot] = useState(false);
  const [allProductData, setAllProductData] = useState([]);
  const [timeSlotDateState, setTimeSlotDateState] = useState();
  const { id, date, promoslotid } = parseQuery(history.location.search) || {};
  let filterData = {};
  let productData = [];
  const [variationInfo, setVariationInfo] = useState({});
  const [variationData, setVariationData] = useState([]);
  const role = getLocalStorage('role')
  const storeId = getLocalStorage('storeId')

  useEffect(() => {
    apiData(id);
  }, []);

  const apiData = (timeSlotId) => {
    startLoader();
    dispatch(v2GetAllFlashSaleProductByTimeSlotIdByMerchant(timeSlotId))
      .then((data) => {
        if(data && data.data && data.data.data && data.data.data.length > 0){
          if(role == "MERCHANT" && data?.data?.data[0]?.merchant?.storeId != storeId){
            history.push('/404')
          }  
          setAllProductData(
            data &&
              data.data &&
              data.data.data.map((row, index) => {
                return {
                  productId: row?.product.productId,
                  productImages: row?.product.productImages[0],
                  title: row?.product.title,
                  variation: row?.product.variation,
                  variationList: row?.variation,
                };
              })
          );
        }
        setTimeout(() => {
          stopLoader();
        }, 1000);
      })
      .catch((err) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
      });
  };

  // console.log(allProductData, "===============");
  console.log(promoslotid, "this promoslotidddddddddddddddddddddddddddddd");

  const handelAddProduct = () => {
    open_dialog(MERCHANT_FLASH_SALE_ADD_PRODUCT, {
      onSubmit: selectProductSuccess,
      allProductData: allProductData,
      setAllProductData: setAllProductData,
      merchantId: getLocalStorage("storeId"),
    });
  };

  const selectProductSuccess = (data) => {
    console.log(data);
  };

  const saveActionAll = async () => {
    startLoader();
    allProductData.map((row, index) => {
      // productData.push(row)
      let productDataItem = {
        productId: row.productId,
        variation: row.variationList,
      };
      productData.push(productDataItem);
    });

    let payload = {
      promoSlotId: promoslotid,
      merchantId: getLocalStorage("storeId"),
      date: timeSlotDateState,
      productData: productData,
    };

    dispatch(createFlashSaleProduct(payload))
      .then((response) => {
        stopLoader();
        history.push(
          `/merchant/marketing/promo/flash-deals/time-slot-listing?promoslotid=${promoslotid}`
        );
      })
      .catch((error) => {
        stopLoader();
        console.error("Failed to upload product!", error);
      });
  };

  const onChangeVariation = (arrayVariation, row, i, e) => {
    const key = e.target.name;
    const value = e.target.value;
    const newObject = { [key]: value };
    arrayVariation.map((rowData, indexData) => {
      if (indexData == i) {
        if (key === "campaignStock") {
          rowData.campaignStock = value;
        } else if (key === "unitCapped") {
          rowData.unitCapped = value;
        } else if (key === "discountPrice") {
          rowData.discountPrice = value;
        } else if (key === "teamCampaignStock") {
          rowData.teamCampaignStock = value;
        } else if (key === "teamUnitCapped") {
          rowData.teamUnitCapped = value;
        } else if (key === "teamDiscountPrice") {
          rowData.teamDiscountPrice = value;
        }
        // Object.assign(rowData, newObject)
      }
    });
    setVariationData(arrayVariation);
  };

  const onChangeStatus = (arrayVariation, row, i, e) => {
    const keyCheck = e.target.name;
    const valCheck = e.target.checked;
    arrayVariation.map((rowData, indexData) => {
      if (indexData == i) {
        if (keyCheck === "isActive") {
          rowData.isActive = valCheck;
        } else if (keyCheck === "teamIsActive") {
          rowData.teamIsActive = valCheck;
        }
      }
    });

    // console.log(arrayVariation);
  };

  return (
    <React.Fragment>
      <div className=" d-flex flex-column justify-content-between container_body vh-100">
        <div className="container-fluid">
          <div className="card p-4 px-5 mb-3">
            <h6>BASIC INFORMATION</h6>
            <br />
            <div>
              <div className="row">
                <label className="col-md-3 d-flex justify-content-end">
                  Time Slot
                </label>
                <input
                  type="date"
                  className="form-control col-md-3"
                  value={date}
                  disabled={true}
                />
              </div>
              <br />
              <div className="row">
                <label className="col-md-3 d-flex justify-content-end">
                  Product criteria
                </label>
                <textarea
                  id="textarea"
                  className="form-control col-md-7"
                  style={{ minHeight: "20vh" }}
                  disabled={true}
                />
              </div>
            </div>
          </div>

          <div className="card p-4 mb-3">
            <div>
              <div className="float-left">
                <h6>EDIT FLASH DEALS PRODUCT</h6>
                <p className="fntSz14 mb-0 text-secondary">
                  Edit product to participate in flash sale event.
                  {/*You have <span className="text-danger">5</span> */}
                  {/*out of <span className="text-danger">5</span> products joining flash sale.*/}
                </p>
              </div>
              <div className={`float-right d-block`}></div>
            </div>
            <hr />
            <br />
            <br />

            {allProductData &&
              allProductData.length > 0 &&
              allProductData.map((row, index) => (
                <div className="mb-3">
                  <div>
                    <div className="float-left d-flex align-items-center flex-nowrap ">
                      <Image
                        src={row.productImages}
                        alt={"photo"}
                        className="teamPurchase_page_product_image"
                      />
                      <div className="px-2">{row.title}</div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />

                  <div className={`float-right d-block w-100`}>
                    <div
                      className="border"
                      style={{ borderRadius: "10px", overflow: "hidden" }}
                    >
                      <div className=" bg-light">
                        <div className="pl-3 pt-3 mb-0 fntSz18 bg-light">
                          Individual Purchase
                        </div>
                        <hr />
                      </div>
                      <div className="mx-3">
                        <TableContainer>
                          <Table
                            sx={{ minWidth: 650 }}
                            stickyHeader
                            aria-label="sticky table"
                          >
                            <TableHead>
                              <TableRow>
                                {row.variation &&
                                  row.variation.length > 0 &&
                                  row.variation.map((item, idx) => (
                                    <TableCell
                                      className="th_cell"
                                      component="th"
                                      align="left"
                                      key={idx}
                                    >
                                      <div className="d-flex  ">
                                        <div>{item?.name}</div>
                                      </div>
                                    </TableCell>
                                  ))}
                                <TableCell
                                  className="th_cell"
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Original Price</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Discounted Price</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Campaign Stock</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Stock</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Unit Capped</div>
                                  </div>
                                </TableCell>

                                <TableCell
                                  className="table_action_head text-secondary"
                                  component="th"
                                >
                                  Enable/Disable
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {row.variationList &&
                                row.variationList.length > 0 &&
                                row.variationList
                                  .filter((item) => {
                                    return (
                                      item[
                                        row.variation.map(
                                          (item) => item.name
                                        )[0]
                                      ]?.trim().length !== 0
                                    );
                                  })
                                  .map((row1, index1) => (
                                    <TableRow
                                      key={index1}
                                      className="border-0"
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      {row.variation.map((item, idx) => (
                                        <TableCell
                                          align="left"
                                          className="td_cell border-0"
                                          key={idx}
                                        >
                                          {row1[item.name]}
                                        </TableCell>
                                      ))}
                                      <TableCell
                                        align="left"
                                        className="td_cell border-0"
                                      >
                                        RM. {row1?.price || "0"}
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className="td_cell border-0"
                                      >
                                        <InputField
                                          className="input_icon_left"
                                          formClass="col-12 p-0"
                                          type="number"
                                          id="discountPrice"
                                          icon="currency"
                                          placeholder="Input"
                                          name="discountPrice"
                                          value={row1?.discountPrice}
                                          disable={true}
                                        />
                                      </TableCell>

                                      <TableCell
                                        align="left"
                                        className="td_cell border-0"
                                      >
                                        <input
                                          type="number"
                                          id="campaignStock"
                                          name="campaignStock"
                                          placeholder="Input"
                                          className="w-75 form-control"
                                          value={row1?.campaignStock}
                                        />
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className="td_cell border-0"
                                      >
                                        {row1?.stock || "N/A"}
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className="td_cell border-0"
                                      >
                                        <input
                                          type="text"
                                          placeholder="Input"
                                          className="w-75 form-control"
                                          id="unitCapped"
                                          name="unitCapped"
                                          value={row1?.unitCapped}
                                        />
                                      </TableCell>

                                      <TableCell className="d-flex border-0 table_action_item">
                                        <IOSSwitch
                                          name="isActive"
                                          checked={
                                            row1.isActive == true ? true : false
                                          }
                                          value={row1?.isActive}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                    <br />

                    <div
                      className="border"
                      style={{ borderRadius: "10px", overflow: "hidden" }}
                    >
                      <div className=" bg-light">
                        <div className="pl-3 pt-3 mb-0 fntSz18">
                          Team Purchase
                        </div>
                        <hr />
                      </div>
                      <div className="mx-3">
                        <TableContainer>
                          <Table
                            sx={{ minWidth: 650 }}
                            stickyHeader
                            aria-label="sticky table"
                          >
                            <TableHead>
                              <TableRow>
                                {row.variation &&
                                  row.variation.length > 0 &&
                                  row.variation.map((item, idx) => (
                                    <TableCell
                                      className="th_cell"
                                      component="th"
                                      align="left"
                                      key={idx}
                                    >
                                      <div className="d-flex">
                                        <div>{item?.name}</div>
                                      </div>
                                    </TableCell>
                                  ))}
                                <TableCell
                                  className="th_cell"
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Original Price</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Discounted Price</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Campaign Stock</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Stock</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Unit Capped</div>
                                  </div>
                                </TableCell>

                                {/* <TableCell
                                  className="table_action_head text-secondary"
                                  component="th"
                                >
                                  Enable/Disable
                                </TableCell> */}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {row.variationList &&
                                row.variationList.length > 0 &&
                                row.variationList
                                  .filter((item) => {
                                    return (
                                      item[
                                        row.variation.map(
                                          (item) => item.name
                                        )[0]
                                      ]?.trim().length !== 0
                                    );
                                  })
                                  .map((row2, index2) => (
                                    <TableRow
                                      key={index2}
                                      className="border-0"
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      {row2.teamIsActive == true && (
                                        <>
                                          {row.variation.map((item, idx) => (
                                            <TableCell
                                              align="left"
                                              className="td_cell border-0"
                                              key={idx}
                                            >
                                              {row2[item.name]}
                                            </TableCell>
                                          ))}
                                          <TableCell
                                            align="left"
                                            className="td_cell border-0"
                                          >
                                            RM. {row2?.teamPrice || "0"}
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            className="td_cell border-0"
                                          >
                                            <InputField
                                              className="input_icon_left"
                                              formClass="col-12 p-0"
                                              type="number"
                                              id="teamDiscountPrice"
                                              icon="currency"
                                              placeholder="Input"
                                              name="teamDiscountPrice"
                                              value={row2?.teamDiscountPrice}
                                            />
                                          </TableCell>

                                          <TableCell
                                            align="left"
                                            className="td_cell border-0"
                                          >
                                            <input
                                              type="text"
                                              placeholder="Input"
                                              className="w-75 form-control"
                                              id="teamCampaignStock"
                                              name="teamCampaignStock"
                                              value={row2?.teamCampaignStock}
                                            />
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            className="td_cell border-0"
                                          >
                                            {row2?.stock || "N/A"}
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            className="td_cell border-0"
                                          >
                                            <input
                                              type="text"
                                              placeholder="Input"
                                              className="w-75 form-control"
                                              id="teamUnitCapped"
                                              name="teamUnitCapped"
                                              value={row2?.teamUnitCapped}
                                            />
                                          </TableCell>

                                          {/* <TableCell className="d-flex border-0 table_action_item">
                                          <IOSSwitch
                                            checked={
                                              row2.teamIsActive == true
                                                ? true
                                                : false
                                            }
                                            value={row2?.teamIsActive}
                                            name="teamIsActive"
                                          />
                                        </TableCell> */}
                                        </>
                                      )}
                                    </TableRow>
                                  ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                    <br />
                    <br />
                  </div>
                </div>
              ))}
          </div>
        </div>

        <footer className="bg-white p-3 mt-5">
          <div className={`d-block`}>
            <div className="d-flex justify-content-end">
              <button
                onClick={() =>
                  history.push(
                    `/merchant/marketing/promo/flash-deals/time-slot-listing?promoslotid=${promoslotid}`
                  )
                }
                type="button"
                className="btn btn-outline-danger mx-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default MerchantFlashDealsTimeSlotShow;
