import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import { uploadCashbooks } from "../../../redux/actions/finance.action";
import { showToast, startLoader, stopLoader } from "../../../lib/global";
import ablity from "../../../helpers/abilityGuard";

const FinanceUploadCashbooks = () => {
  const dispatch = useDispatch();
  const [currentDate, setCurrentDate] = useState();
  const [file, setFile] = useState();
  const [result, setResult] = useState([]);

  useEffect(() => {
    startLoader();
    const breadcrumb = [{ label: "Cashbooks", url: null }];
    dispatch(breadcrumbAction(breadcrumb));

    const timer = setInterval(() => {
      setCurrentDate(new Date().toLocaleString());
      stopLoader();
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const submitted = (e) => {
    e.preventDefault();
    startLoader();
    if (file == undefined || file == null) {
      startLoader();
      setTimeout(() => {
        showToast("No file chosen!", "error");
        stopLoader();
      }, 1000);
    } else if (file !== null) {
      const data = new FormData();
      data.append("file", file);

      dispatch(uploadCashbooks(data))
        .then(({ data }) => {
          if (data.status == 201) {
            setTimeout(() => {
              stopLoader();
              if (data.data) {
                setResult(data.data);
              }
              showToast(data.message, "success");
            }, 1000);
          }
        })
        .catch((err) => {
          startLoader();
          setTimeout(() => {
            showToast(err.message, "error");
            stopLoader();
          }, 1000);
        });
    }
  };

  return (
    <React.Fragment>
      <div className=" container_body container-fluid">
        <div className="card p-3">
          <h6>UPLOAD CASHBOOKS</h6>
          <div className="border card px-4 py-2 my-3">
            <form
              id="form"
              onSubmit={(e) => submitted(e)}
              enctype="multipart/form-data"
            >
              <div className="d-flex flex-column">
                <input
                  className="rounded"
                  type="file"
                  id="file"
                  name="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    setFile(file);
                  }}
                />
                <div className="py-3">
                  <div className="teamPurchase_Page_ProductBox_ProductName text-capitalize">
                    <h6>User: {localStorage.userName}</h6>
                  </div>
                  <div className="teamPurchase_Page_ProductBox_Product_varient text-capitalize">
                    <h6>Add Date: {currentDate} </h6>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-3">
                {ablity({ model: "Finance", action: "create" }) == 1 && (
                  <input
                    type="submit"
                    className="btn mx-1 btn-danger py-2 px-4"
                    value="Submit"
                  />
                )}
              </div>
            </form>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Document Date</th>
                    <th scope="col">Order Number</th>
                    <th scope="col">Status</th>
                    <th scope="col">Message</th>
                  </tr>
                </thead>
                <tbody>
                  {result.map((item, index) => (
                    <tr key={index}>
                      <td>{item.documentDate}</td>
                      <td>{item.orderNumber}</td>
                      <td>{item.status}</td>
                      <td>{item.message}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FinanceUploadCashbooks;
