import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IOSSwitch } from "../../components/switch/IOSSwitch";
import { showToast, startLoader, stopLoader } from "../../lib/global";
import { getLocalStorage } from "../../lib/session";
import {
  createShopSettingMerchant,
  getShopSettingMerchant,
} from "../../redux/actions/merchant-center.action";

const initialBasicState = {
  smsVerification: false,
  vacationMode: false,
};
const initialVacationSetting = {
  defaultAutoReply: true,
  defaultAutoReplyContent: "",
  offWorkAutoReply: true,
  offWorkAutoReplyContent: "",
};
const initialWorkingHours = {
  Sunday: {
    selected: false,
    startTime: "09:00",
    endTime: "06:00",
  },
  Monday: {
    selected: false,
    startTime: "09:00",
    endTime: "06:00",
  },
  Tuesday: {
    selected: false,
    startTime: "09:00",
    endTime: "06:00",
  },
  Wednesday: {
    selected: false,
    startTime: "09:00",
    endTime: "06:00",
  },
  Thursday: {
    selected: false,
    startTime: "09:00",
    endTime: "06:00",
  },
  Friday: {
    selected: false,
    startTime: "09:00",
    endTime: "06:00",
  },
  Saturday: {
    selected: false,
    startTime: "09:00",
    endTime: "06:00",
  },
};

const initialChatSetting = {
  acceptChatFromProfilePage: false,
  blockedUsers: false,
};
const initialEmailNotificationSetting = {
  emailNotification: false,
  orderUpdate: false,
  newsLetter: false,
  listingUpdate: false,
  personalisedContent: false,
};


const ShopSettings = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("basicSetting");
  const [basicSetting, setBasicSetting] = useState(initialBasicState);
  const [vacationSetting, setVacationSetting] = useState(
    initialVacationSetting
  );
  console.log(getLocalStorage("storeId"));
  const storeId = getLocalStorage("storeId")
  const [workingHours, setWorkingHours] = useState(initialWorkingHours);
  const [editDefaultReply, setEditDefaultReply] = useState(false);
  const [editOffWorkReply, setEditOffWorkReply] = useState(false);
  const [defaultAutoReplyContent, setDefaultAutoReplyContent] = useState("");
  const [offWorkAutoReplyContent, setOffWorkAutoReplyContent] = useState("");

  const [chatSetting, setChatSetting] = useState(initialChatSetting);
  const [emailNotificationSetting, setEmailNotificationSetting] = useState(
    initialEmailNotificationSetting
  );

  useEffect(() => {
    // const storeId = 6;
    // console.log('storeId', storeId)
    dispatch(getShopSettingMerchant(storeId))
      .then((data) => {
        console.log('data', data)
        if (data && data.data && data.data.status == 200) {
          const data1 = data && data.data
          console.log(data1 && data1.data, "initialWorkingHours[0]");
          setBasicSetting({ smsVerification: data1 && data1.data.smsVerification, vacationMode: data1 && data1.data.vacationMode })
          setDefaultAutoReplyContent(data1 && data1.data.defaultAutoReplyContent)
          setOffWorkAutoReplyContent(data1 && data1.data.offWorkAutoReplyContent,)
          setWorkingHours(data1 && data1.data && data1.data.workingHours)
          setVacationSetting({
            defaultAutoReply: data1 && data1.data.defaultAutoReply,
            offWorkAutoReply: data1 && data1.data.offWorkAutoReply
          })
        }
      })
      .catch(error => {
        console.error({ error })
      })
  }, []);

  const onChangeSmsVerification = (key, value) => {
    const data = {
      workingHours, defaultAutoReplyContent, offWorkAutoReplyContent,
      smsVerification: value,
      vacationMode: basicSetting.vacationMode,
    }
    console.log(data, 'kkkkkkkkkkkkkkkkkkkkkkk');
    const response = dispatch(createShopSettingMerchant(storeId, data))
    startLoader()
    response
      .then((data) => {
        if (data && data.data && data.data.status == 200) {
          const response = dispatch(getShopSettingMerchant(storeId))
          response
            .then((data) => {
              console.log(data.data, 'data   data daat daat ');
              if (data && data.data && data.data.status == 200) {
                const data1 = data && data.data
                console.log(data1 && data1.data.defaultAutoReplyContent, "initialWorkingHours[0]");
                setBasicSetting({ smsVerification: data1 && data1.data.smsVerification, vacationMode: data1 && data1.data.vacationMode })
                setDefaultAutoReplyContent(data1 && data1.data.defaultAutoReplyContent)
                setOffWorkAutoReplyContent(data1 && data1.data.offWorkAutoReplyContent,)
                setWorkingHours(data1 && data1.data && data1.data.workingHours)
                setVacationSetting({ defaultAutoReply: data1 && data1.data.defaultAutoReply, offWorkAutoReply: data1 && data1.data.offWorkAutoReply })

              }
            })
          setTimeout(() => {
            stopLoader()
            showToast(data.data.message, "success")
          }, 1000)
        }
        else {
          setTimeout(() => {
            stopLoader()
            showToast("Internal Server Error!", "success")
          })
        }
      })
  };

  const onChangeBasicSetting = (key, value) => {
    const data = {
      workingHours, defaultAutoReplyContent, offWorkAutoReplyContent,
      smsVerification: basicSetting.smsVerification,
      vacationMode: value, defaultAutoReply: vacationSetting.defaultAutoReply, offWorkAutoReply: vacationSetting.offWorkAutoReply,
    }
    console.log(data, 'kkkkkkkkkkkkkkkkkkkkkkk');
    const response = dispatch(createShopSettingMerchant(storeId, data))
    startLoader()
    response
      .then((data) => {
        if (data && data.data && data.data.status == 200) {
          const response = dispatch(getShopSettingMerchant(storeId))
          response
            .then((data) => {
              console.log(data.data, 'data   data daat daat ');
              if (data && data.data && data.data.status == 200) {
                const data1 = data && data.data
                console.log(data1 && data1.data.defaultAutoReplyContent, "initialWorkingHours[0]");
                setBasicSetting({ smsVerification: data1 && data1.data.smsVerification, vacationMode: data1 && data1.data.vacationMode })
                setDefaultAutoReplyContent(data1 && data1.data.defaultAutoReplyContent)
                setOffWorkAutoReplyContent(data1 && data1.data.offWorkAutoReplyContent,)
                setWorkingHours(data1 && data1.data && data1.data.workingHours)
                setVacationSetting({ defaultAutoReply: data1 && data1.data.defaultAutoReply, offWorkAutoReply: data1 && data1.data.offWorkAutoReply })

              }
            })
          setTimeout(() => {
            stopLoader()
            showToast(data.data.message, "success")
          }, 1000)
        }
        else {
          setTimeout(() => {
            stopLoader()
            showToast("Internal Server Error!", "success")
          })
        }
      })
  };
  const onChangeVacationSettingVefaultAutoReply = (key, value) => {
    const data = {
      workingHours, defaultAutoReplyContent, offWorkAutoReplyContent,
      smsVerification: basicSetting.smsVerification, vacationMode: basicSetting.vacationMode,
      defaultAutoReply: value, offWorkAutoReply: vacationSetting.offWorkAutoReply,
    }
    console.log(data, "data   data ");
    const response = dispatch(createShopSettingMerchant(storeId, data))
    startLoader()
    response
      .then((data) => {
        if (data && data.data && data.data.status == 200) {
          const response = dispatch(getShopSettingMerchant(storeId))
          response
            .then((data) => {
              console.log(data.data, 'data   data daat daat ');
              if (data && data.data && data.data.status == 200) {
                const data1 = data && data.data
                console.log(data1 && data1.data.defaultAutoReplyContent, "initialWorkingHours[0]");
                setBasicSetting({ smsVerification: data1 && data1.data.smsVerification, vacationMode: data1 && data1.data.vacationMode })
                setDefaultAutoReplyContent(data1 && data1.data.defaultAutoReplyContent)
                setOffWorkAutoReplyContent(data1 && data1.data.offWorkAutoReplyContent,)
                setWorkingHours(data1 && data1.data && data1.data.workingHours)
                setVacationSetting({ defaultAutoReply: data1 && data1.data.defaultAutoReply, offWorkAutoReply: data1 && data1.data.offWorkAutoReply })
              }
            })
          setTimeout(() => {
            stopLoader()
            showToast(data.data.message, "success")
          }, 1000)
        }
        else {
          setTimeout(() => {
            stopLoader()
            showToast("Internal Server Error!", "success")
          })
        }
      }).catch((error) => {
        console.log({ error });
        stopLoader()
      })
  };

  const onChangeVacationSetting = (key, value) => {
    const data = {
      workingHours, defaultAutoReplyContent, offWorkAutoReplyContent,
      smsVerification: basicSetting.smsVerification, vacationMode: basicSetting.vacationMode,
      defaultAutoReply: vacationSetting.defaultAutoReply, offWorkAutoReply: value
    }
    console.log(data, "data   data ");
    const response = dispatch(createShopSettingMerchant(storeId, data))
    startLoader()
    response
      .then((data) => {
        if (data && data.data && data.data.status == 200) {
          const response = dispatch(getShopSettingMerchant(storeId))
          response
            .then((data) => {
              console.log(data.data, 'data   data daat daat ');
              if (data && data.data && data.data.status == 200) {
                const data1 = data && data.data
                console.log(data1 && data1.data.defaultAutoReplyContent, "initialWorkingHours[0]");
                setBasicSetting({ smsVerification: data1 && data1.data.smsVerification, vacationMode: data1 && data1.data.vacationMode })
                setDefaultAutoReplyContent(data1 && data1.data.defaultAutoReplyContent)
                setOffWorkAutoReplyContent(data1 && data1.data.offWorkAutoReplyContent,)
                setWorkingHours(data1 && data1.data && data1.data.workingHours)
                setVacationSetting({ defaultAutoReply: data1 && data1.data.defaultAutoReply, offWorkAutoReply: data1 && data1.data.offWorkAutoReply })
              }
            })
          setTimeout(() => {
            stopLoader()
            showToast(data.data.message, "success")
          }, 1000)
        }
        else {
          setTimeout(() => {
            stopLoader()
            showToast("Internal Server Error!", "success")
          })
        }
      }).catch((error) => {
        console.log({ error });
        stopLoader()
      })
  };
  const handleSaveAutoReplyContent = () => {
    onChangeVacationSetting("defaultAutoReplyContent", defaultAutoReplyContent);
    console.log(defaultAutoReplyContent, "kkkkkkkkkkkkkkkk");
    setEditDefaultReply(false);

    const data = {
      workingHours,
      defaultAutoReplyContent,
      offWorkAutoReplyContent,
      smsVerification: basicSetting.smsVerification,
      vacationMode: basicSetting.vacationMode,
    };
    const response = dispatch(createShopSettingMerchant(storeId, data));
    startLoader();
    response.then((data) => {
      if (data && data.data && data.data.status == 200) {
        setTimeout(() => {
          stopLoader();
          showToast(data.data.message, "success");
        }, 1000);
      } else {
        setTimeout(() => {
          stopLoader();
          showToast("Internal Server Error!", "success");
        });
      }
    });
  };

  const handleSaveOffWorkReplyContent = () => {
    onChangeVacationSetting("offWorkAutoReplyContent", offWorkAutoReplyContent);
    setEditOffWorkReply(false);
    const data = {
      workingHours,
      defaultAutoReplyContent,
      offWorkAutoReplyContent,
      smsVerification: basicSetting.smsVerification,
      vacationMode: basicSetting.vacationMode,
    };
    const response = dispatch(createShopSettingMerchant(storeId, data));
    startLoader();
    response.then((data) => {
      if (data && data.data && data.data.status == 200) {
        setTimeout(() => {
          stopLoader();
          showToast(data.data.message, "success");
        }, 1000);
      } else {
        setTimeout(() => {
          stopLoader();
          showToast("Internal Server Error!", "success");
        });
      }
    });
  };

  const onSelectSchedule = (e) => {
    const day = e.target.value;
    const workingHourIns = { ...workingHours };
    workingHourIns[day]["selected"] = e.target.checked;
    setWorkingHours(workingHourIns);
  };

  const updateWorkingHour = (day, time, value) => {
    const workingHourIns = { ...workingHours };
    workingHourIns[day][time] = value;
    setWorkingHours(workingHourIns);
  };

  const onSaveWorkingHour = () => {
    // console.log(JSON.stringify([{ workingHours }]));

    const data = {
      workingHours,
      defaultAutoReplyContent,
      offWorkAutoReplyContent,
      smsVerification: basicSetting.smsVerification,
      vacationMode: basicSetting.vacationMode,
    };
    const response = dispatch(createShopSettingMerchant(storeId, data));
    startLoader();
    response.then((data) => {
      if (data && data.data && data.data.status == 200) {
        setTimeout(() => {
          stopLoader();
          showToast(data.data.message, "success");
        }, 1000);
      } else {
        setTimeout(() => {
          stopLoader();
          showToast("Internal Server Error!", "success");
        });
      }
    });
  };

  const onChangeChatSetting = (key, value) => {
    setChatSetting((pre) => ({ ...pre, [key]: value }));
  };

  const onChangeEmailNotificationSetting = (key, value) => {
    setEmailNotificationSetting((pre) => ({ ...pre, [key]: value }));
  };

  // console.log(workingHours, 'lllllllllllll');
  return (
    <div>
      <div className="">
        <h4 className="bold m-0">Shop Setting</h4>
        <p className="text-muted small">Change the setting of merchant shop</p>
      </div>
      <div className="d-flex">
        <button
          onClick={() => setActiveTab("basicSetting")}
          className={`btn btn-${activeTab == "basicSetting" ? "dark" : "outline-dark"
            } btn-sm mr-3`}
        >
          Basic Setting
        </button>
        <button
          onClick={() => setActiveTab("chatSetting")}
          className={`btn btn-${activeTab == "chatSetting" ? "dark" : "outline-dark"
            } btn-sm mr-3`}
        >
          Chat Setting
        </button>
        <button
          onClick={() => setActiveTab("notificationSetting")}
          className={`btn btn-${activeTab == "notificationSetting" ? "dark" : "outline-dark"
            } btn-sm mr-3`}
        >
          Notification Setting
        </button>
      </div>
      <br />
      <div
        style={{ display: activeTab === "basicSetting" ? "block" : "none" }}
        className="basic-setting"
      >
        <div className="d-flex justify-content-between">
          <div className="">
            <h6 className="bold m-0">
              Protect your account with verification via SMS
            </h6>
            <p className="text-muted fntSz12">
              Manage merchant login credential
            </p>
          </div>
          <div className="">
            <IOSSwitch
              checked={basicSetting.smsVerification}
              onChange={(e) =>
                onChangeSmsVerification("smsVerification", e.target.checked)
              }
            />
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-between">
          <div className="">
            <h6 className="bold m-0">Vacation Mode</h6>
            <p className="text-muted fntSz12 pr-5">
              Turn on Vacation Mode to prevent buyers from placing new orders.
              Existing orders must still be fulfilled. It may take up to 1 hour
              to take effect.
            </p>
          </div>
          <div className="">
            <IOSSwitch
              checked={basicSetting.vacationMode}
              onChange={(e) =>
                onChangeBasicSetting("vacationMode", e.target.checked)
              }
            />
          </div>
        </div>
        <div
          style={{ display: basicSetting.vacationMode ? "block" : "none" }}
          className="vacation_mode_content p-3"
        >
          <div className="default_reply auto_reply">
            <div className="d-flex justify-content-between">
              <div className="">
                <h6 className="bold m-0">Default Auto-Reply</h6>
                <p className="text-muted fntSz12">
                  When enabled, this Auto-Reply will be sent once the buyer
                  initiates the conversation.
                </p>
              </div>
              <div className="">
                <IOSSwitch
                  checked={vacationSetting.defaultAutoReply}
                  onChange={(e) =>
                    onChangeVacationSettingVefaultAutoReply(
                      "defaultAutoReply",
                      e.target.checked
                    )
                  }
                />
              </div>
            </div>
            <label htmlFor="reply-content" className="fntSz12 text-muted m-0">
              Reply Content
            </label>
            <div className="position-relative">
              <textarea
                className="form-control pr-3"
                disabled={!editDefaultReply}
                value={defaultAutoReplyContent}
                rows={editDefaultReply ? 3 : 1}
                onChange={(e) => setDefaultAutoReplyContent(e.target.value)}
                placeholder="add reply content here...."
              ></textarea>
              <p
                onClick={() => setEditDefaultReply(true)}
                style={{
                  visibility: editDefaultReply ? "hidden" : "visible",
                }}
                className="text-info edit_txt"
              >
                Edit
              </p>
              <div
                style={{
                  visibility: editDefaultReply ? "visible" : "hidden",
                }}
                className="py-2 d-flex justify-content-end"
              >
                <button
                  onClick={() => {
                    setDefaultAutoReplyContent(
                      vacationSetting.defaultAutoReplyContent
                    );
                    setEditDefaultReply(false);
                  }}
                  className="btn btn-outline-dark mr-3 px-3 btn-sm"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSaveAutoReplyContent}
                  disabled={
                    !defaultAutoReplyContent ||
                    defaultAutoReplyContent ===
                    vacationSetting.defaultAutoReplyContent
                  }
                  className="btn btn-dark px-3 btn-sm"
                >
                  Save
                </button>
              </div>
            </div>
          </div>

          <div className="offwork_reply auto_reply">
            <div className="d-flex justify-content-between">
              <div className="">
                <h6 className="bold m-0">Off-Work Auto-Reply</h6>
                <p className="text-muted fntSz12">
                  When enabled, this Auto-Reply message will be sent if the
                  buyer initiates a conversation outside of working hours.
                </p>
              </div>
              <div className="">
                <IOSSwitch
                  checked={vacationSetting.offWorkAutoReply}
                  onChange={(e) =>
                    onChangeVacationSetting(
                      "offWorkAutoReply",
                      e.target.checked
                    )
                  }
                />
              </div>
            </div>
            <label htmlFor="reply-content" className="fntSz12 text-muted m-0">
              Reply Content
            </label>
            <div className="position-relative">
              <textarea
                className="form-control pr-3"
                disabled={!editOffWorkReply}
                value={offWorkAutoReplyContent}
                rows={editOffWorkReply ? 3 : 1}
                onChange={(e) => setOffWorkAutoReplyContent(e.target.value)}
                placeholder="add reply content here...."
              ></textarea>
              <p
                onClick={() => setEditOffWorkReply(true)}
                style={{
                  visibility: editOffWorkReply ? "hidden" : "visible",
                }}
                className="text-info edit_txt"
              >
                Edit
              </p>
              <div
                style={{
                  visibility: editOffWorkReply ? "visible" : "hidden",
                }}
                className="py-2 d-flex justify-content-end"
              >
                <button
                  onClick={() => {
                    setOffWorkAutoReplyContent(
                      vacationSetting.offWorkAutoReplyContent
                    );
                    setEditOffWorkReply(false);
                  }}
                  className="btn btn-outline-dark mr-3 px-3 btn-sm"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSaveOffWorkReplyContent}
                  disabled={
                    !offWorkAutoReplyContent ||
                    offWorkAutoReplyContent ===
                    vacationSetting.offWorkAutoReplyContent
                  }
                  className="btn btn-dark px-3 btn-sm"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <div className="working_hour">
            <div className="d-flex justify-content-between">
              <p>Working Hour</p>
              <span className="d-flex">
                <p
                  onClick={() => setWorkingHours(initialWorkingHours)}
                  className="text-info pr-3 pointer"
                >
                  Cancel
                </p>
                <p onClick={onSaveWorkingHour} className="text-info pointer">
                  Save
                </p>
              </span>
            </div>
            <div className="schedule_list">
              <ul>
                <li className="d-flex justify-content-between">
                  {/* <p>Sunday</p> */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={<CheckCircleIcon />}
                        onClick={onSelectSchedule}
                        checked={
                          workingHours &&
                          workingHours.Sunday &&
                          workingHours.Sunday.selected
                        }
                      />
                    }
                    value={"Sunday"}
                    label="Sunday"
                    style={{ height: "30px" }}
                  />
                  <div className="d-flex">
                    <input
                      type="text"
                      value={workingHours && workingHours.Sunday.startTime}
                      onChange={(e) =>
                        updateWorkingHour("Sunday", "startTime", e.target.value)
                      }
                      className="form-control"
                      placeholder="09:00"
                    />
                    <p className="my-0 mx-2 text-muted">to</p>
                    <input
                      type="text"
                      value={workingHours && workingHours.Sunday.endTime}
                      onChange={(e) =>
                        updateWorkingHour("Sunday", "endTime", e.target.value)
                      }
                      className="form-control"
                      placeholder="06:00"
                    />
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={<CheckCircleIcon />}
                        onClick={onSelectSchedule}
                        checked={
                          workingHours &&
                          workingHours.Monday &&
                          workingHours.Monday.selected
                        }
                      />
                    }
                    value={"Monday"}
                    label="Monday"
                    style={{ height: "30px" }}
                  />
                  <div className="d-flex">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="09:00"
                      value={workingHours && workingHours.Monday.startTime}
                      onChange={(e) =>
                        updateWorkingHour("Monday", "startTime", e.target.value)
                      }
                    />
                    <p className="my-0 mx-2 text-muted">to</p>
                    <input
                      type="text"
                      value={workingHours && workingHours.Monday.endTime}
                      onChange={(e) =>
                        updateWorkingHour("Monday", "endTime", e.target.value)
                      }
                      className="form-control"
                      placeholder="09:00"
                    />
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={<CheckCircleIcon />}
                        onClick={onSelectSchedule}
                        checked={
                          workingHours &&
                          workingHours.Tuesday &&
                          workingHours.Tuesday.selected
                        }
                      />
                    }
                    value={"Tuesday"}
                    label="Tuesday"
                    style={{ height: "30px" }}
                  />
                  <div className="d-flex">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="09:00"
                      value={workingHours && workingHours.Tuesday.startTime}
                      onChange={(e) =>
                        updateWorkingHour(
                          "Tuesday",
                          "startTime",
                          e.target.value
                        )
                      }
                    />
                    <p className="my-0 mx-2 text-muted">to</p>
                    <input
                      type="text"
                      value={workingHours && workingHours.Tuesday.endTime}
                      onChange={(e) =>
                        updateWorkingHour("Tuesday", "endTime", e.target.value)
                      }
                      className="form-control"
                      placeholder="09:00"
                    />
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={<CheckCircleIcon />}
                        onClick={onSelectSchedule}
                        checked={
                          workingHours &&
                          workingHours.Wednesday &&
                          workingHours.Wednesday.selected
                        }
                      />
                    }
                    value={"Wednesday"}
                    label="Wednesday"
                    style={{ height: "30px" }}
                  />
                  <div className="d-flex">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="09:00"
                      value={workingHours && workingHours.Wednesday.startTime}
                      onChange={(e) =>
                        updateWorkingHour(
                          "Wednesday",
                          "startTime",
                          e.target.value
                        )
                      }
                    />
                    <p className="my-0 mx-2 text-muted">to</p>
                    <input
                      type="text"
                      value={workingHours && workingHours.Wednesday.endTime}
                      onChange={(e) =>
                        updateWorkingHour(
                          "Wednesday",
                          "endTime",
                          e.target.value
                        )
                      }
                      className="form-control"
                      placeholder="09:00"
                    />
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={<CheckCircleIcon />}
                        onClick={onSelectSchedule}
                        checked={
                          workingHours &&
                          workingHours.Thursday &&
                          workingHours.Thursday.selected
                        }
                      />
                    }
                    value={"Thursday"}
                    label="Thursday"
                    style={{ height: "30px" }}
                  />
                  <div className="d-flex">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="09:00"
                      value={workingHours && workingHours.Thursday.startTime}
                      onChange={(e) =>
                        updateWorkingHour(
                          "Thursday",
                          "startTime",
                          e.target.value
                        )
                      }
                    />
                    <p className="my-0 mx-2 text-muted">to</p>
                    <input
                      type="text"
                      value={workingHours && workingHours.Thursday.endTime}
                      onChange={(e) =>
                        updateWorkingHour("Thursday", "endTime", e.target.value)
                      }
                      className="form-control"
                      placeholder="09:00"
                    />
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={<CheckCircleIcon />}
                        onClick={onSelectSchedule}
                        checked={
                          workingHours &&
                          workingHours.Friday &&
                          workingHours.Friday.selected
                        }
                      />
                    }
                    value={"Friday"}
                    label="Friday"
                    style={{ height: "30px" }}
                  />
                  <div className="d-flex">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="09:00"
                      value={workingHours && workingHours.Friday.startTime}
                      onChange={(e) =>
                        updateWorkingHour("Friday", "startTime", e.target.value)
                      }
                    />
                    <p className="my-0 mx-2 text-muted">to</p>
                    <input
                      type="text"
                      value={workingHours && workingHours.Friday.endTime}
                      onChange={(e) =>
                        updateWorkingHour("Friday", "endTime", e.target.value)
                      }
                      className="form-control"
                      placeholder="09:00"
                    />
                  </div>
                </li>
                <li className="d-flex justify-content-between">
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={<CheckCircleIcon />}
                        onClick={onSelectSchedule}
                        checked={
                          workingHours &&
                          workingHours.Saturday &&
                          workingHours.Saturday.selected
                        }
                      />
                    }
                    value={"Saturday"}
                    label="Saturday"
                    style={{ height: "30px" }}
                  />
                  <div className="d-flex">
                    <input
                      type="text"
                      className="form-control"
                      // placeholder="09:00"
                      value={workingHours && workingHours.Saturday.startTime}
                      onChange={(e) =>
                        updateWorkingHour(
                          "Saturday",
                          "startTime",
                          e.target.value
                        )
                      }
                    />
                    <p className="my-0 mx-2 text-muted">to</p>
                    <input
                      type="text"
                      value={workingHours && workingHours.Saturday.endTime}
                      onChange={(e) =>
                        updateWorkingHour("Saturday", "endTime", e.target.value)
                      }
                      className="form-control"
                      placeholder="09:00"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ display: activeTab === "chatSetting" ? "block" : "none" }}
        className="chat-setting"
      >
        <div className="d-flex justify-content-between">
          <div className="">
            <h6 className="bold m-0">Accept Chat From Profile Page</h6>
            <p className="text-muted fntSz12">
              Turn on to allow people to start a chat with you via your profile
              page. Recommended for sellers.
            </p>
          </div>
          <div className="">
            <IOSSwitch
              checked={chatSetting.acceptChatFromProfilePage}
              onChange={(e) =>
                onChangeChatSetting(
                  "acceptChatFromProfilePage",
                  e.target.checked
                )
              }
            />
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-between">
          <div className="">
            <h6 className="bold m-0">Blocked Users</h6>
            <p className="text-muted fntSz12">
              List of users you have blocked from chats.
            </p>
          </div>
          <div className="">
            <IOSSwitch
              checked={chatSetting.blockedUsers}
              onChange={(e) =>
                onChangeChatSetting("blockedUsers", e.target.checked)
              }
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: activeTab === "notificationSetting" ? "block" : "none",
        }}
        className="notification-setting"
      >
        <div className="d-flex justify-content-between">
          <div className="">
            <h6 className="bold m-0">Email Notifications</h6>
            <p className="text-muted fntSz12">
              Control when and how often UU2 sends emails to you.
            </p>
          </div>
          <div className="">
            <IOSSwitch
              checked={emailNotificationSetting.emailNotification}
              onChange={(e) =>
                onChangeEmailNotificationSetting(
                  "emailNotification",
                  e.target.checked
                )
              }
            />
          </div>
        </div>
        <div
          style={{
            display: emailNotificationSetting.emailNotification
              ? "block"
              : "none",
          }}
          className="notification_settings notification_setting_content p-3"
        >
          <div className="d-flex justify-content-between">
            <div className="">
              <h6 className="bold m-0">Order Updates</h6>
            </div>
            <div className="">
              <IOSSwitch
                checked={emailNotificationSetting.orderUpdate}
                onChange={(e) =>
                  onChangeEmailNotificationSetting(
                    "orderUpdate",
                    e.target.checked
                  )
                }
              />
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <div className="">
              <h6 className="bold m-0">Newsleter</h6>
            </div>
            <div className="">
              <IOSSwitch
                checked={emailNotificationSetting.newsLetter}
                onChange={(e) =>
                  onChangeEmailNotificationSetting(
                    "newsLetter",
                    e.target.checked
                  )
                }
              />
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <div className="">
              <h6 className="bold m-0">Listing Updates</h6>
            </div>
            <div className="">
              <IOSSwitch
                checked={emailNotificationSetting.listingUpdate}
                onChange={(e) =>
                  onChangeEmailNotificationSetting(
                    "listingUpdate",
                    e.target.checked
                  )
                }
              />
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <div className="">
              <h6 className="bold m-0">Personalised Content</h6>
            </div>
            <div className="">
              <IOSSwitch
                checked={emailNotificationSetting.personalisedContent}
                onChange={(e) =>
                  onChangeEmailNotificationSetting(
                    "personalisedContent",
                    e.target.checked
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopSettings;
