import { IconButton } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SvgIcon from "../../../components/Images/SvgIcon";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import "../../../static/css/marketingCss/marketing.css"






function MerketingAllPromo() {
    const history = useHistory()
    const dispatch = useDispatch()

    const breadcrumb = [
        { label: "All Promo", url: null }
    ];
    dispatch(breadcrumbAction(breadcrumb));

    return (
        <React.Fragment>
            <div className="card m-3 p-3">
                <h5>UU2 OFFICIAL EVENTS</h5>
                <div className="row">
                    <div className="col-4 p-2">
                        <div className="p-3 rounded marketing_Promo_Official_Card" onClick={() => history.push("/marketing/all-promo/flash-sale")}>
                            <div>
                                <p>Flash Deals</p>
                                <p className="text-secondary">Lower price than before</p>
                            </div>

                            <div className="d-flex justify-content-end w-100 pointer" >
                                <img
                                    height={"40px"}
                                    src="/icons/next_arrow.png"
                                    alt="view icon"

                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-4 p-2">
                        <div className="p-3 rounded marketing_Promo_Official_Card" onClick={() => history.push("/marketing/all-promo/group-deals")}>
                            <div>
                                <p>Group Deals</p>
                                <p className="text-secondary">Target group of people to join the event to hit the target</p>
                            </div>

                            <div className="d-flex justify-content-end w-100 pointer" >
                                <img
                                    height={"40px"}
                                    src="/icons/next_arrow.png"
                                    alt="view icon"

                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-4 p-2">
                        <div className="p-3 rounded marketing_Promo_Official_Card">
                            <div>
                                <p>Other Promo</p>
                                <p className="text-secondary">Create and view all other promos available</p>
                            </div>

                            <div className="d-flex justify-content-end w-100 pointer" >
                                <img
                                    height={"40px"}
                                    src="/icons/next_arrow.png"
                                    alt="view icon"

                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-4 p-2">
                        <div className="p-3 rounded marketing_Promo_Official_Card" onClick={() =>
                          history.push(
                            "/marketing/all-promo/voucher"
                          )
                        }>
                            <div>
                                <p>Voucher Campaign</p>
                                <p className="text-secondary">Increase exposure and sales</p>
                            </div>

                            <div className="d-flex justify-content-end w-100 pointer" >
                                <img
                                    height={"40px"}
                                    src="/icons/next_arrow.png"
                                    alt="view icon"

                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-4 p-2">
                        <div className="p-3 rounded marketing_Promo_Official_Card" onClick={() =>
                          history.push(
                            "/marketing/all-promo/livestream"
                          )
                        }>
                            <div>
                                <p>Livestream Event</p>
                                <p className="text-secondary">Increase exposure and sales</p>
                            </div>

                            <div className="d-flex justify-content-end w-100 pointer" >
                                <img
                                    height={"40px"}
                                    src="/icons/next_arrow.png"
                                    alt="view icon"

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card m-3 p-3">
                <h5>MERCHANT PROMO</h5>
                <div className="row">
                    <div className="col-4 p-2">
                        <div className="p-3 rounded marketing_Promo_Official_Card" onClick={()=>{
                            history.push(
                                "/marketing/all-promo/voucher-merchant"
                              )
                        }}>
                            <div>
                                <p>Voucher Campaign</p>
                                <p className="text-secondary">Increase exposure and sales</p>
                            </div>

                            <div className="d-flex justify-content-end w-100 pointer" >
                                <img
                                    height={"40px"}
                                    src="/icons/next_arrow.png"
                                    alt="view icon"

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card m-3 p-3">
                <h5>SALE SETTING</h5>
                <div className="row">
                    <div className="col-4 p-2">
                        <div className="p-3 rounded marketing_Promo_Official_Card" onClick={() => history.push("/marketing/all-promo/flash-sale-setting")}>
                            <div>
                                <p>Flash Deals Setting</p>
                                <p className="text-secondary">Adjust flash sale setting and rules</p>
                            </div>

                            <div className="d-flex justify-content-end w-100 pointer" >
                                <img
                                    height={"40px"}
                                    src="/icons/next_arrow.png"
                                    alt="view icon"

                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </React.Fragment>
    )
}

export default MerketingAllPromo
