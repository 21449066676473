import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import Button from "../../components/buttons";
import Input from "../../components/formControl/input";
import {
  CATEGORY_SELECTION_DIALOG,
  PASSWORD_FORGET_INFO_MODAL
} from "../../lib/constant";
import { open_dialog, setSessionData, showToast } from "../../lib/global";
import { consumerForgetPasswordUpdate } from "../../redux/actions/auth.action";

const ForgetPasswordRest = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, token } = useParams();
  const [confPassword, setConfPassword] = useState("");
  const [password, setPassword] = useState("");
  // const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFormInput = (event, key) => {
    const value = event.target.value;
    switch (key) {
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "confpassword":
        setConfPassword(value);
        break;
      default:
        return;
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    // setLoading(true)
    if (!password || !confPassword) {
      showToast("All fields are requrired!", "error");
    }

    if (password !== confPassword) {
      showToast("Password and Retype Password Not Match", "error");
    }

    const payload = { password, consumerId: id, token };

    dispatch(consumerForgetPasswordUpdate(payload))
      .then((response) => {
        showToast("Reset Password in successfully!", "success");
        history.push("/auth");
      })
      .catch((error) => {
        console.log(error);
        showToast(
          error?.response?.data?.message || "Failed to Reset Password!",
          "error"
        );
      });
  };

  const handleTest = () => {
    open_dialog(CATEGORY_SELECTION_DIALOG, {
      minWidth: "80vw",
      maxWidth: "80vw"
    });
  };

  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-lg navbar-light">
        <a className="navbar-brand" href="#">
          <h4 style={{ left: 10 }}>
            <img
              src="/icons/savvy_mart.png"
              style={{
                width: 150,
                right: 10
              }}
            />
          </h4>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">

        </div>
      </nav>
      <div
        className="vh-100 landing_image position-relative"
        style={{
          marginTop: -70
        }}
      >
        <div className="position-absolute w-100 h-100"></div>

        <div className="m-auto px-5 pt-5 h-100 d-flex flex-column justify-content-between">
          <div
            className="col d-flex flex-colummn"
            style={{
              alignItems: "center",
              flexDirection: "inherit"
            }}
          >
            <div className="p-4 position-absolute text-white login_input_box_section "
                 style={{
                   right: "auto",
                   marginTop: -60
                 }}
            >
              <form onSubmit={handleSubmitForm}>
                <br />
                <div
                  style={{
                    flexDirection: "row",
                    textAlign: "center"
                  }}
                >
                  <img
                    src="/icons/savvy_mart.png"
                    style={{
                      width: 200
                    }}
                  />
                </div>
                <p className="text-nowrap small text-black">Please key in your new password</p>
                <div>
                  <Input
                    className="input__textField mt-2 w-100"
                    type="password"
                    name="password"
                    id="password"
                    onChange={(e) => handleFormInput(e, "password")}
                    value={password}
                    placeholder="New Password"
                  />
                </div>
                <div>
                  <Input
                    className="input__textField mt-2 w-100"
                    type="password"
                    name="confpassword"
                    id="confpassword"
                    onChange={(e) => handleFormInput(e, "confpassword")}
                    value={confPassword}
                    placeholder="Retype New Password"
                  />
                </div>

                <Button type="submit" role="button" className="login_btn mt-5">
                  {loading ? "loading..." : "Reset Password"}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgetPasswordRest;
