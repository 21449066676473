import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Image from "../../components/Images/Image";
import InputField from "../../components/formControl/input/InputField";
import {
  ADD_MERCHANT,
  ADD_SHIPPING_ADDRESS,
  EDIT_BANK_ACCOUNT_DIALOG,
  EDIT_MERCHANT_SHIPPING_ADDRESS_DIALOG,
  VERIFY_MERCHANT_DIALOG,
} from "../../lib/constant";
import { close_dialog, open_dialog, UploadImage } from "../../lib/global";
import {
  getMerchantAccountDetails,
  updateMerchantAccount,
} from "../../redux/actions/merchant-account.action";
import { breadcrumbAction } from "../../redux/actions/config.action";
import { getCountryCode } from "../../redux/actions/merchant-center.action";
import SelectInput from "../../components/select";

const MerchantAccount = () => {
  const [verified, setVerified] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [editState, setEditState] = useState({
    merchantName: false,
    preferredMerchantName: false,
    description: false,
    socialAccounts: false,
    image: false,
    whatsappContactNumber: false
  });
  const [merchantNameContent, setMerchantNameContent] = useState("");
  const [preferredMerchantNameContent, setPreferredMerchantNameContent] =
    useState("");
  const [descriptionContent, setDescriptionContent] = useState("");
  const [imageContent, setImageContent] = useState("");
  const [imageFile, setImageFile] = useState();
  const [whatsappContactNumber, setWhatsappContactNumber] = useState("");
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState("");
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [socialAccounts, setSocialAccounts] = useState({
    facebook: "",
    instagram: "",
  });
  const [socialAccountsContent, setSocialAccountsContent] =
    useState(socialAccounts);
  const [bankInfo, setBankInfo] = useState([]);
  const [shippingInfo, setShippingInfo] = useState([]);

  const [account, setAccount] = useState({
    merchantName: "",
    preferredMerchantName: "",
    merchantId: "",
    image: "",
    description: "",
    socialAccounts: {
      facebook: "",
      instagram: "",
    },
    bankDetails: [],
    address: [],
    merchantCommissionFee: "",
    whatsappContactNumber: "",
    phoneNumberCountryCode: ""
  });

  useEffect(() => {
    const breadcrumb = [{ label: "My Account", url: null }];

    dispatch(breadcrumbAction(breadcrumb));
    if (!verified) {
      open_dialog(VERIFY_MERCHANT_DIALOG, {
        onSubmit: (value) => {
          setVerified(value);
        },
        dialogClick: () => {
          history.goBack() || history.push("/");
        },
      });
    }
    dispatch(getCountryCode()).then((response) => {
      let data = []
      if(response?.data?.data){
        response.data.data.map((item) => {
          const _item = {label: item.countryName	 + ' (' + item.dialCode+')', value: item.dialCode}
          data.push(_item)
        })
      }
      setCountryCodeList(data);
    })
  }, []);

  useEffect(() => {
    if (verified) {
      dispatch(getMerchantAccountDetails())
        .then((response) => {
          const result = response?.data?.data || {};
          setAccount({
            merchantName: result.merchantName,
            preferredMerchantName: result.preferredMerchantName,
            merchantId: result.storeId,
            image: result.merchantImage,
            description: result.description,
            socialAccounts: {
              facebook: result.facebookAccountName,
              instagram: result.instagramAccountName,
            },
            bankDetails: result.bankDetails,
            address: result.address,
            physicalCommission: result.physicalCommission,
            nonPhysicalCommission: result.nonPhysicalCommission,
            whatsappContactNumber: result.whatsappContactNumber,
            phoneNumberCountryCode: result.phoneNumberCountryCode
          });
          setBankInfo(result.bankDetails);
          setShippingInfo(result.address);
        })
        .catch((error) => {
          console.log({ error });
        });
    }
  }, [verified]);

  const handleSetAccount = (key, value) => {
    setAccount((pre) => ({ ...pre, [key]: value }));
  };
  const handleChangeEditOptn = (key, value) => {
    setEditState((pre) => ({ ...pre, [key]: value }));
  };

  const handleSaveMerchantName = () => {
    handleSetAccount("merchantName", merchantNameContent);
    handleChangeEditOptn("merchantName", false);
    dispatch(updateMerchantAccount({ merchantName: merchantNameContent })); // calling api
  };
  const handleCancelEditName = () => {
    handleChangeEditOptn("merchantName", false);
    setMerchantNameContent("");
  };
  const handleOpenEditName = () => {
    setMerchantNameContent(account.merchantName);
    handleChangeEditOptn("merchantName", true);
  };
  const handleOpenEditWhatsappNumber = () => {
    handleChangeEditOptn("whatsappContactNumber", true);
  }
  const handleCancelEditWhatsappNumber = () => {
    handleChangeEditOptn("whatsappContactNumber", false);
  };
  const handleSaveWhatsappNumber = () => {
    handleSetAccount("whatsappContactNumber", whatsappContactNumber);
    handleSetAccount("phoneNumberCountryCode", phoneNumberCountryCode.value);
    handleChangeEditOptn("whatsappContactNumber", false);
    dispatch(updateMerchantAccount({
      whatsappContactNumber,
      phoneNumberCountryCode: phoneNumberCountryCode.value
    })); // calling api
  }
  // Preferred Merchant Name
  const handleSavePreferredMerchantName = () => {
    handleSetAccount("preferredMerchantName", preferredMerchantNameContent);
    handleChangeEditOptn("preferredMerchantName", false);
    dispatch(
      updateMerchantAccount({
        preferredMerchantName: preferredMerchantNameContent,
      })
    ); // calling api
  };
  const handleCancelEditPreferredName = () => {
    handleChangeEditOptn("preferredMerchantName", false);
    setPreferredMerchantNameContent("");
  };
  const handleOpenEditPreferredName = () => {
    setPreferredMerchantNameContent(account.preferredMerchantName);
    handleChangeEditOptn("preferredMerchantName", true);
  };

  // description
  const handleSaveDescription = () => {
    handleSetAccount("description", descriptionContent);
    handleChangeEditOptn("description", false);
    dispatch(updateMerchantAccount({ description: descriptionContent })); // calling api
  };
  const handleCancelEditDescription = () => {
    handleChangeEditOptn("description", false);
    setDescriptionContent("");
  };
  const handleOpenEditDescription = () => {
    setDescriptionContent(account.description);
    handleChangeEditOptn("description", true);
  };

  // image
  const handleSaveImage = async () => {
    const imgUrl = await UploadImage(imageFile, "uu2/merchant");
    handleSetAccount("image", imgUrl);
    handleChangeEditOptn("image", false);
    dispatch(updateMerchantAccount({ merchantImage: imgUrl })); // calling api
  };
  const handleCancelEditImage = () => {
    handleChangeEditOptn("image", false);
    setImageContent("");
    setImageFile(null);
  };
  const handleOpenEditImage = () => {
    setImageContent(account.image);
    handleChangeEditOptn("image", true);
  };
  const handleUploadImage = (e) => {
    const fileData = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(fileData);
    reader.onload = function () {
      setImageFile(fileData);
      setImageContent(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  // social Accounts
  const handleSaveSocialAccounts = () => {
    dispatch(
      updateMerchantAccount({
        facebookAccountName: socialAccountsContent.facebook,
        instagramAccountName: socialAccountsContent.instagram,
      })
    ); // calling api
    handleSetAccount("socialAccounts", socialAccountsContent);
    handleChangeEditOptn("socialAccounts", false);
  };
  const handleCancelEditSocialAccounts = () => {
    handleChangeEditOptn("socialAccounts", false);
    setSocialAccountsContent(account);
  };
  const handleOpenEditSocialAccounts = () => {
    setSocialAccountsContent(account.socialAccounts);
    handleChangeEditOptn("socialAccounts", true);
  };
  const handleChangeSocialAccounts = (key, value) => {
    setSocialAccountsContent((pre) => ({ ...pre, [key]: value }));
  };

  // bank accounts
  const handleAddAccount = () => {
    open_dialog(ADD_MERCHANT, {
      onSubmit: onSubmitBankAccountModal,
    });
  };
  const onSubmitBankAccountModal = (data) => {
    console.log("data", data);
    dispatch(updateMerchantAccount({ bankDetails: [...bankInfo, data] })); // calling api
    setBankInfo((pre) => [...pre, data]);
  };

  const editBankAccount = (bankName, bankAccountNo, beneciaryName, index) => {
    open_dialog(EDIT_BANK_ACCOUNT_DIALOG, {
      onSubmit: onSubmitEditBankAccount,
      bankName,
      bankAccountNo,
      beneciaryName,
      index,
    });
  };
  const onSubmitEditBankAccount = (data) => {
    const items = [...bankInfo];
    items[data.index] = data;
    setBankInfo(items);
    dispatch(updateMerchantAccount({ bankDetails: items })); // calling api
  };

  const deleteBankAccount = (index1) => {
    const data = bankInfo.filter((item, index) => index !== index1);
    setBankInfo(data);
    dispatch(updateMerchantAccount({ bankDetails: data })); // calling api
  };

  // address
  const handleAddShippingAddress = () => {
    open_dialog(ADD_SHIPPING_ADDRESS, {
      onSubmit: onSubmitShippingAddressModal,
    });
  };
  const onSubmitShippingAddressModal = (data) => {
    console.log("data", data);
    dispatch(updateMerchantAccount({ address: [...shippingInfo, data] })); // calling api
    setShippingInfo((pre) => [...pre, data]);
  };

  const editShippingAddress = (
    fullName,
    phoneNumber,
    addressLine1,
    addressLine2,
    postalCode,
    state,
    setAsPickupAddress,
    setAsReturnAddress,
    index
  ) => {
    open_dialog(EDIT_MERCHANT_SHIPPING_ADDRESS_DIALOG, {
      onSubmit: onSubmitEditShippingAddress,
      fullName,
      phoneNumber,
      addressLine1,
      addressLine2,
      postalCode,
      state,
      setAsPickupAddress,
      setAsReturnAddress,
      index,
    });
  };
  const onSubmitEditShippingAddress = (data) => {
    const items = [...shippingInfo];
    items[data.index] = data;
    setShippingInfo(items);
    dispatch(updateMerchantAccount({ address: items }));
  };

  const deleteShippingAddress = (index1) => {
    console.log(index1);
    const data = shippingInfo.filter((item, index) => index !== index1);
    setShippingInfo(data);
    dispatch(updateMerchantAccount({ address: data }));
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  };

  return (
    <div className="container-fluid">
      <div className="card merchant_name p-3">
        <div className="col-12 row align-items-center">
          <div className="col-3">Merchant ID</div>
          <div className="col-7 text-muted">{account.merchantId || "N/A"}</div>
        </div>
      </div>
      <br />
      <div className="card merchant_name p-3">
        <div className="col-12 row align-items-center">
          <div className="col-3">Name</div>
          <div className="col-7 text-muted">
            {account.merchantName || "N/A"}
          </div>
          <div className="col-2 d-flex justify-content-end">
            <button
              visible={String(!editState.merchantName)}
              type="button"
              onClick={handleOpenEditName}
              className="btn btn-outline-dark btn-sm px-3"
            >
              Edit
            </button>
            <button
              visible={String(editState.merchantName)}
              type="button"
              onClick={handleCancelEditName}
              className="btn btn-outline-dark btn-sm px-3 mx-3"
            >
              Cancel
            </button>
            <button
              visible={String(editState.merchantName)}
              type="button"
              onClick={handleSaveMerchantName}
              className="btn btn-dark btn-sm px-3"
            >
              Save
            </button>
          </div>
        </div>
        <div
          visible={String(editState.merchantName)}
          className="col-12 row align-items-center p-5"
        >
          <label htmlFor="merchant-name" className="col-3 text-right">
            Name
          </label>
          <InputField
            formClass="col-9"
            type="text"
            id="merchant-name"
            value={merchantNameContent}
            onChange={(e) => setMerchantNameContent(e.target.value)}
          />
        </div>
      </div>
      <br />

      <div className="card merchant_name p-3">
        <div className="col-12 row align-items-center">
          <div className="col-3">Preferred Merchant Name</div>
          <div className="col-7 text-muted">
            {account.preferredMerchantName || "N/A"}
          </div>
          {/* <div className="col-2 d-flex justify-content-end">
            <button
              visible={String(!editState.preferredMerchantName)}
              type="button"
              onClick={handleOpenEditPreferredName}
              className="btn btn-outline-dark btn-sm px-3"
            >
              Edit
            </button>
            <button
              visible={String(editState.preferredMerchantName)}
              type="button"
              onClick={handleCancelEditPreferredName}
              className="btn btn-outline-dark btn-sm px-3 mx-3"
            >
              Cancel
            </button>
            <button
              visible={String(editState.preferredMerchantName)}
              type="button"
              onClick={handleSavePreferredMerchantName}
              className="btn btn-dark btn-sm px-3"
            >
              Save
            </button>
          </div> */}
        </div>
        {/* <div
          visible={String(editState.preferredMerchantName)}
          className="col-12 row align-items-center p-5"
        >
          <label htmlFor="preferred-merchant-name" className="col-3 text-right">
            Preferred Merchant Name
          </label>
          <InputField
            formClass="col-9"
            type="text"
            id="preferred-merchant-name"
            value={preferredMerchantNameContent}
            onChange={(e) => setPreferredMerchantNameContent(e.target.value)}
          />
        </div> */}
      </div>

      <br />
      <div className="card merchant_image p-3">
        <div className="col-12 row align-items-center">
          <div className="col-3">Image</div>
          <div className="col-7 text-muted">
            <Image
              src={account.image || "/icons/upload_icon.svg"}
              alt="upload"
              style={{ width: "50px", height: "50px" }}
              className={`pointer upload_image_label rounded-circle`}
            />
          </div>
          <div className="col-2 d-flex justify-content-end">
            <button
              visible={String(!editState.image)}
              type="button"
              onClick={handleOpenEditImage}
              className="btn btn-outline-dark btn-sm px-3"
            >
              Change
            </button>
            <button
              visible={String(editState.image)}
              type="button"
              onClick={handleCancelEditImage}
              className="btn btn-outline-dark btn-sm px-3 mx-3"
            >
              Cancel
            </button>
            <button
              visible={String(editState.image)}
              type="button"
              onClick={handleSaveImage}
              className="btn btn-dark btn-sm px-3"
            >
              Save
            </button>
          </div>
        </div>
        <div
          visible={String(editState.image)}
          className="col-12 row align-items-center p-5"
        >
          <label htmlFor="merchant-name" className="col-3 text-right">
            Image
          </label>
          <div className="mx-2">
            <input
              accept="image/*"
              id={`profile-image`}
              className="d-none"
              onChange={handleUploadImage}
              multiple={false}
              type="file"
            />
            <label htmlFor={`profile-image`}>
              <Image
                src={"/icons/upload_icon.svg"}
                alt="upload"
                style={{ width: "50px", height: "50px" }}
                className={`pointer upload_image_label mr-4`}
              />
            </label>
            <Image
              src={imageContent || "/icons/upload_icon.svg"}
              alt="upload"
              style={{ width: "50px", height: "50px" }}
              className={`pointer upload_image_label rounded-circle`}
            />
          </div>
        </div>
      </div>

      <br />
      <div className="card merchant_description p-3">
        <div className="col-12 row align-items-center">
          <div className="col-3">Description</div>
          <div className="col-7 text-muted word_wrap">
            {account.description || "N/A"}
          </div>
          <div className="col-2 d-flex justify-content-end">
            <button
              visible={String(!editState.description)}
              type="button"
              onClick={handleOpenEditDescription}
              className="btn btn-outline-dark btn-sm px-3"
            >
              Edit
            </button>
            <button
              visible={String(editState.description)}
              type="button"
              onClick={handleCancelEditDescription}
              className="btn btn-outline-dark btn-sm px-3 mx-3"
            >
              Cancel
            </button>
            <button
              visible={String(editState.description)}
              type="button"
              onClick={handleSaveDescription}
              className="btn btn-dark btn-sm px-3"
            >
              Save
            </button>
          </div>
        </div>
        <div
          visible={String(editState.description)}
          className="col-12 row align-items-center p-5"
        >
          <label htmlFor="merchant-description" className="col-3 text-right">
            Desctiption
          </label>
          <InputField
            formClass="col-9"
            type="textarea"
            rows={4}
            placeholder="Input"
            id="merchant-desctiption"
            value={descriptionContent}
            onChange={(e) => setDescriptionContent(e.target.value)}
          />
        </div>
      </div>

      <br />
      <div className="card merchant_name p-3">
        <div className="col-12 row align-items-center">
          <div className="col-3">Social Media Accounts</div>
          <div className="col-7 text-muted d-flex align-items-center">
            {Object.keys(account.socialAccounts).map((item, index) => (
              <p className="px-0 my-auto" key={index}>
                {item}
              </p>
            )) || "N/A"}
          </div>
          <div className="col-2 d-flex justify-content-end">
            <button
              visible={String(!editState.socialAccounts)}
              type="button"
              onClick={handleOpenEditSocialAccounts}
              className="btn btn-outline-dark btn-sm px-3"
            >
              Edit
            </button>
            <button
              visible={String(editState.socialAccounts)}
              type="button"
              onClick={handleCancelEditSocialAccounts}
              className="btn btn-outline-dark btn-sm px-3 mx-3"
            >
              Cancel
            </button>
            <button
              visible={String(editState.socialAccounts)}
              type="button"
              onClick={handleSaveSocialAccounts}
              className="btn btn-dark btn-sm px-3"
            >
              Save
            </button>
          </div>
        </div>
        <div visible={String(editState.socialAccounts)} className=" p-5">
          {Object.keys(socialAccounts).map((item, index) => (
            <div key={index} className="col-12 row align-items-center my-3">
              <label htmlFor="merchant-name" className="col-3 text-right">
                {item}
              </label>
              <InputField
                formClass="col-9"
                type="text"
                id="merchant-name"
                value={socialAccountsContent[item]}
                onChange={(e) =>
                  handleChangeSocialAccounts(item, e.target.value)
                }
              />
            </div>
          ))}
        </div>
      </div>
      <br />
      <div className="card merchant_name p-3">
        <div className="col-12 row align-items-center">
          <div className="col-3">Physical Commission Fee</div>
          <div className="col-7 text-muted">
            {`${account.physicalCommission}%` || "N/A"}
          </div>
        </div>
      </div>
      <br />
      <div className="card merchant_name p-3">
        <div className="col-12 row align-items-center">
          <div className="col-3">Non-Physical Commission Fee</div>
          <div className="col-7 text-muted">
            {`${account.nonPhysicalCommission}%` || "N/A"}
          </div>
        </div>
      </div>
      <br />
      <div className="card merchant_name p-3">
        <div className="col-12 row align-items-center">
          <div className="col-3">WhatsApp Number</div>
          <div className="col-7 text-muted">
            {`${account.phoneNumberCountryCode || ""}${account.whatsappContactNumber || ""}`}
          </div>
          <div className="col-2 d-flex justify-content-end">
            <button
              visible={String(!editState.whatsappContactNumber)}
              type="button"
              onClick={handleOpenEditWhatsappNumber}
              className="btn btn-outline-dark btn-sm px-3"
            >
              Edit
            </button>
            <button
              visible={String(editState.whatsappContactNumber)}
              type="button"
              onClick={handleCancelEditWhatsappNumber}
              className="btn btn-outline-dark btn-sm px-3 mx-3"
            >
              Cancel
            </button>
            <button
              visible={String(editState.whatsappContactNumber)}
              type="button"
              onClick={handleSaveWhatsappNumber}
              className="btn btn-dark btn-sm px-3"
            >
              Save
            </button>
          </div>
          <div
            visible={String(editState.whatsappContactNumber)}
            className="col-12 row"
          >
            <div className="col-3 pt-2">
              <SelectInput
                options={countryCodeList}
                id="phoneNumberCountryCode"
                placeholder="Select Country Code"
                value={phoneNumberCountryCode}
                onChange={(e)=>setPhoneNumberCountryCode(e)}
              />
            </div>
            <div className="col-4 d-flex">
              <InputField
                type="text"
                min="0"
                max="10"
                id="whatsappContactNumber"
                value={whatsappContactNumber}
                onKeyPress={(e) => handleKeyPress(e)}
                onChange={(e)=> {
                  if(e.target.value.length > 10){
                    return
                  }
                  setWhatsappContactNumber(e.target.value)
                }}
                className={"w-100"}
                placeholder="Phone Number Without Country Code"
              />
            </div>
          </div>
        </div>
      </div>

      <br />
      <div className="card p-3 ">
        <div className="d-flex justify-content-between">
          <div className="">
            <h6 className="m-0">BANK ACCOUNT INFORMATION</h6>
          </div>
          <div className="">
            <button onClick={handleAddAccount} className="btn btn-dark px-2">
              Add Bank Account
            </button>
          </div>
        </div>

        {bankInfo?.map((item, index) => (
          <div key={index} className="col-12 row">
            <hr className="w-100" />
            <div className="col-10">
              <div>
                <label className="col-3 my-auto small text-right text-muted">
                  Bank Name
                </label>
                <label className="col-9 my-auto small">{item.bankName}</label>
              </div>
              <div>
                <label className="col-3 my-auto small text-right text-muted">
                  Account No.
                </label>
                <label className="col-9 my-auto small">
                  {item.bankAccountNo}
                </label>
              </div>
              <div>
                <label className="col-3 my-auto small text-right text-muted">
                Beneficiary Name
                </label>
                <label className="col-9 my-auto small">
                  {item.beneciaryName}
                </label>
              </div>
            </div>
            <div className="col-2 d-flex">
              <div
                className="text-info"
                onClick={() =>
                  editBankAccount(
                    item.bankName,
                    item.bankAccountNo,
                    item.beneciaryName,
                    index
                  )
                }
              >
                Edit
              </div>
              <div className="text-info mx-2">|</div>
              <div
                className="text-info"
                onClick={() => deleteBankAccount(index)}
              >
                Delete
              </div>
            </div>
          </div>
        ))}
      </div>
      <br />
      <div className="card p-3">
        <div className="d-flex justify-content-between">
          <div className="">
            <h6 className="m-0">SHIPPING INFORMATION</h6>
            {/* <label className="text-muted  font-size-account">
                Manage pickup and return address
              </label> */}
          </div>
          <div className="">
            <button
              onClick={handleAddShippingAddress}
              className="btn btn-dark px-2"
            >
              Add New Address
            </button>
          </div>
        </div>
        {shippingInfo?.map((item, index) => (
          <div key={index} className="col-12 row">
            <hr className="w-100" />
            <div className="col-10">
              <div>
                <label className="col-3 my-auto small text-right text-muted">
                  Full Name
                </label>
                <label className="col-9 my-auto small">
                  {item.fullName}
                  {item.setAsPickupAddress && (
                    <span className="px-3 text-danger">Pickup Address</span>
                  )}
                  {item.setAsReturnAddress && (
                    <span className="px-3 text-success">Return Address</span>
                  )}
                </label>
              </div>
              <div>
                <label className="col-3 my-auto small text-right text-muted">
                  Phone Number
                </label>
                <label className="col-9 my-auto small">
                  {item.phoneNumber}
                </label>
              </div>
              <div>
                <label className="col-3 my-auto small text-right text-muted">
                  Address
                </label>
                <label className="col-9 my-auto small">{item.address}</label>
              </div>
            </div>
            <div className="col-2 d-flex">
              <div
                className="text-info"
                onClick={() =>
                  editShippingAddress(
                    item.fullName,
                    item.phoneNumber,
                    item.addressLine1,
                    item.addressLine2,
                    item.postalCode,
                    item.state,
                    item.setAsPickupAddress,
                    item.setAsReturnAddress,
                    index
                  )
                }
              >
                Edit
              </div>
              <div className="text-info mx-2">|</div>
              <div
                className="text-info"
                onClick={() => deleteShippingAddress(index)}
              >
                Delete
              </div>
            </div>
          </div>
        ))}
      </div>
      <br />
    </div>
  );
};

export default MerchantAccount;
