import { Tab, Tabs } from "@mui/material";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import InputField from "../../../components/formControl/input/InputField";
import { startLoader, stopLoader, showToast } from "../../../lib/global";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import { getAllConsumerList } from "../../../redux/actions/consumer-center.action";

import "../../../static/css/consumerCenterCss/consumerCenterOverview.css";
import AllConsumerAccounts from "./AllConsumerAccounts";
import { TabPanel } from "../../../components/Tabs/TabPannel";
import { setSeoData } from "../../../services/service";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// const initialConsumerSearchDetail = {
//   consumerName: "",
//   consumerRegisteredDate: "",
// };

function ConsumerAccount() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [consumerName, setConsumerName] = useState();
  const [consumerRegisteredDate, setConsumerRegisteredDate] = useState();
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState({});
  const [offSet, setOffSet] = useState(0);
  const handleOffSet = (num) => {
    setOffSet(num);
  };

  // const [searchData, setSearchData] = useState(initialConsumerSearchDetail);

  useEffect(() => {
    startLoader();
    const breadcrumb = [{ label: "Consumer Account", url: null }];
    dispatch(breadcrumbAction(breadcrumb));
  }, [tabIndex]);

  const searchChangeCount = (data) => {
    setTotal(data);
  };

  const afterDelete = (data) => {
    setTotal(data && data.total);
    setTabIndex(0);
  };

  const searchConsumer = () => {
    setSearch({ consumerName, consumerRegisteredDate });
  };

  const handleResetSearch = () => {
    setConsumerName("");
    setConsumerRegisteredDate("");
    setSearch({ consumerName: "", consumerRegisteredDate: "", offset: offSet });
  };

  return (
    <div className="main_consumer_account_div">
      <div className="mt-1 mx-3 px-2 py-3 rounded bg-white">
        <div className="col-12 row flex-wrap">
          <div className="min-width-200 col-md-3">
            <label>Consumer Name</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="consumerName"
              value={consumerName}
              onChange={(e) => setConsumerName(e.target.value)}
            />
          </div>

          <div className="min-width-200 col-md-3">
            <label>Registered Date</label>
            <InputField
              type="date"
              className="form-control"
              id="consumerRegisteredDate"
              value={consumerRegisteredDate}
              onChange={(e) => setConsumerRegisteredDate(e.target.value)}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            className="btn input-inside-text mr-1 btn-white border px-4"
            onClick={() => handleResetSearch()}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn input-inside-text mx-1 btn-secondary px-3"
            onClick={() => searchConsumer()}
          >
            Search
          </button>
        </div>
      </div>
      <br />
      <br />
      <div className="container_body container-fluid">
        <div className="card p-3">
          <span>ALL CONSUMER</span>
          <div className=" border card rounded-lg my-3">
            <Tabs
              value={tabIndex}
              onChange={(e, value) => setTabIndex(value)}
              variant="scrollable"
              orientation="horizontal"
              scrollButtons={false}
              visibleScrollbar={false}
              className="px-3"
              aria-label="basic tabs example"
            >
              <Tab label={`All(${total?.total || 0})`} {...a11yProps(0)} />
              <Tab label={`Active(${total?.active || 0})`} {...a11yProps(1)} />
              <Tab
                label={`Inactive(${total?.inactive || 0})`}
                {...a11yProps(2)}
              />
              <Tab
                label={`Blocked(${total?.blocked || 0})`}
                {...a11yProps(3)}
              />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <AllConsumerAccounts
                search={search}
                status=""
                totalItem={total?.total|| 0}
                setTotals={setTotal}
                afterDelete={afterDelete}
                reset={handleResetSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <AllConsumerAccounts
                search={search}
                status="ACTIVE"
                totalItem={total?.active || 0}
                setTotals={setTotal}
                afterDelete={afterDelete}
                reset={handleResetSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <AllConsumerAccounts
                search={search}
                status="INACTIVE"
                totalItem={total?.inactive || 0}
                setTotals={setTotal}
                afterDelete={afterDelete}
                reset={handleResetSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
              <AllConsumerAccounts
                search={search}
                status="BLOCKED"
                totalItem={total?.blocked || 0}
                setTotals={setTotal}
                afterDelete={afterDelete}
                reset={handleResetSearch}
              />
            </TabPanel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConsumerAccount;

// import { Tab, Tabs } from "@mui/material";

// import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import { useHistory } from "react-router-dom";
// import InputField from "../../../components/formControl/input/InputField";
// import {
//   startLoader,
//   stopLoader,
//   showToast,
// } from "../../../lib/global";
// import { breadcrumbAction } from "../../../redux/actions/config.action";
// import { getAllConsumerList } from "../../../redux/actions/consumer-center.action";

// import "../../../static/css/consumerCenterCss/consumerCenterOverview.css";
// import AllConsumerAccounts from "./AllConsumerAccounts";
// import { TabPanel } from "../../../components/Tabs/TabPannel";
// import { setSeoData } from "../../../services/service";

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

// // const initialConsumerSearchDetail = {
// //   consumerName: "",
// //   consumerRegisteredDate: "",
// // };

// function ConsumerAccount() {
//   const history = useHistory();
//   const dispatch = useDispatch();
//   const [tabIndex, setTabIndex] = useState(0);
//   const [consumerName, setConsumerName] = useState();
//   const [consumerRegisteredDate, setConsumerRegisteredDate] = useState();
//   const [search, setSearch] = useState("");
//   const [total, setTotal] = useState({});
//   const [offSet,setOffSet]=useState(0)
//   const handleOffSet=(num)=>{
//           setOffSet(num)
//   }

//   // const [searchData, setSearchData] = useState(initialConsumerSearchDetail);

//   useEffect(() => {
//     startLoader()
//     const breadcrumb = [{ label: "Consumer Account", url: null }];
//     dispatch(breadcrumbAction(breadcrumb));

//     dispatch(getAllConsumerList({}))
//       .then((data) => {
//         setTimeout(()=>{

//           stopLoader();
//       },1000)
//         console.log(data);
//         setTotal(data && data.data && data.data.total);
//       })
//       .catch((error) => {
//         setTimeout(()=>{

//           stopLoader();
//       },1000)
//         console.log(error);
//       });
//   }, [tabIndex]);

//   const searchChangeCount=(data)=>{
//        setTotal(data)
//   }

//   const searchConsumer = () => {
//     setSearch({ consumerName, consumerRegisteredDate });
//   };

//   const resetSearch = () => {
//     setConsumerName("");
//     setConsumerRegisteredDate("");
//     setSearch({ consumerName: "", consumerRegisteredDate: "" ,offset:offSet});

//   };

//   return (
//     <div className="main_consumer_account_div">
//       <div className="mt-1 mx-3 px-2 py-3 rounded bg-white">
//         <div className="col-12 row flex-wrap">
//           <div className="min-width-200 col-md-3">
//             <label>Consumer Name</label>
//             <InputField
//               type="text"
//               className="form-control"
//               placeholder="Input"
//               id="consumerName"
//               value={consumerName}
//               onChange={(e) => setConsumerName(e.target.value)}
//             />
//           </div>

//           <div className="min-width-200 col-md-3">
//             <label>Registered Date</label>
//             <InputField
//               type="date"
//               className="form-control"
//               id="consumerRegisteredDate"
//               value={consumerRegisteredDate}
//               onChange={(e) => setConsumerRegisteredDate(e.target.value)}
//             />
//           </div>
//         </div>
//         <div className="d-flex justify-content-end mt-3">
//           <button
//             type="button"
//             className="btn input-inside-text mr-1 btn-white border px-4"
//             onClick={() => resetSearch()}
//           >
//             Reset
//           </button>
//           <button
//             type="button"
//             className="btn input-inside-text mx-1 btn-secondary px-3"
//             onClick={() => searchConsumer()}
//           >
//             Search
//           </button>
//         </div>
//       </div>
//       <br />
//       <br />
//       <div className="container_body container-fluid">
//         <div className="card p-3">
//           <span>ALL CONSUMER</span>
//           <div className=" border card rounded-lg my-3">
//             <Tabs
//               value={tabIndex}
//               onChange={(e, value) => setTabIndex(value)}
//               variant="scrollable"
//               orientation="horizontal"
//               scrollButtons={false}
//               visibleScrollbar={false}
//               className="px-3"
//               aria-label="basic tabs example"
//             >
//               <Tab label={`All(${total?.total || 0})`} {...a11yProps(0)} />
//               <Tab label={`Active(${total?.active || 0})`} {...a11yProps(1)} />
//               <Tab
//                 label={`Inactive(${total?.inactive || 0})`}
//                 {...a11yProps(2)}
//               />
//               <Tab
//                 label={`Blocked(${total?.blocked || 0})`}
//                 {...a11yProps(3)}
//               />
//             </Tabs>
//             <TabPanel value={tabIndex} index={0}>
//               <AllConsumerAccounts
//                 search={search}
//                 status=""
//                 totalItem={total.total}
//                 searchChangeCount={searchChangeCount}
//                 handleOffSet={handleOffSet}

//               />
//             </TabPanel>
//             <TabPanel value={tabIndex} index={1}>
//               <AllConsumerAccounts
//                 search={search}
//                 status="ACTIVE"
//                 totalItem={total.active}
//                 searchChangeCount={searchChangeCount}
//                 handleOffSet={handleOffSet}
//               />
//             </TabPanel>
//             <TabPanel value={tabIndex} index={2}>
//             <AllConsumerAccounts
//                 search={search}
//                 status="INACTIVE"
//                 totalItem={total.inactive}
//                 searchChangeCount={searchChangeCount}
//                 handleOffSet={handleOffSet}

//               />
//             </TabPanel>
//             <TabPanel value={tabIndex} index={3}>
//             <AllConsumerAccounts
//                 search={search}
//                 status="BLOCKED"
//                 totalItem={total.blocked}
//                 searchChangeCount={searchChangeCount}
//                 handleOffSet={handleOffSet}

//               />
//             </TabPanel>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ConsumerAccount;
