import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TimelineDot from "@mui/lab/TimelineDot";
import { makeStyles } from "@mui/styles";
import SelectDropdown from "../../../components/select";

import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Image from "../../../components/Images/Image";
import { orderStatuses } from "../../../shared/language";
import {
  getOrderById,
  updateOrderShippingStatusById,
  updateOrderStatus,
} from "../../../redux/actions/order.action";
import { useDispatch } from "react-redux";
import { SHOW_TRANSACTION_HISTORY } from "../../../lib/constant";
import { open_dialog, startLoader, stopLoader } from "../../../lib/global";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import { getLocalStorage } from "../../../lib/session";
import moment from "moment";
import CustomizedSteppers from "../../../components/stepper/stepper";
import SvgIcon from "../../../components/Images/SvgIcon";

const useStyles = makeStyles({
  timelineitem: {
    // minHeight:"0px"
  },
  timelineContentOppositeContainer: {
    maxWidth: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  timing: {
    marginTop: "-5px",
    fontSize: "0.7rem",
  },
  timelineContent: {
    display: "inline-block",
    transform: "rotate(-90deg)",
    textAlign: "center",
    minWidth: 50,
  },
  timelineIcon: {
    transform: "rotate(-90deg)",
  },
  mainText: {
    fontSize: "0.9rem",
  },
  dot: {
    padding: "2px  !important",

    margin: "8.5px 4px !important",
  },
});
const order_status_names = {
  orderPlaced: "Order Placed",
  orderPaid: "Order Paid",
  prepairingToShipped: "Preparing To Ship",
  orderShipped: "Order Shipped",
  orderDelivered: "Order Delivered",
};

const orderStatusOptions_CancelledProduct = [
  "PROCESSING",
  "UNDER_REVIEW",
  "MERCHANT_TO_RESPOND",
  "SAVVY_TO_RESPOND",
];
const stringBreaker = (data, breakChr) => {
  return data.replaceAll(breakChr, " ");
};
function ViewCancelOrder() {
  const [fullData, setFullData] = useState({});
  const [orderShippingStatus, setOrderShippingStatus] = useState("");
  const [editOrderShipped, setEditOrderShipped] = useState(false);
  const [timeline, setTimeline] = useState([]);
  const [lastOrderUpdate, setLastOrderUpdate] = useState({});

  const [showStatusChange, setShowStatusChange] = useState(false);
  const changedStatus = useRef();
  const [submitStatusChange, setSubmitStatusChange] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const { id } = useParams();
  const param = useParams();
  console.log(param);

  const queryParams = new URLSearchParams(window.location.search);
  const productId = queryParams.get("productId");
  console.log(productId, "oooooo"); //pizza

  useEffect(() => {
    var breadcrumb = [];
    if (param?.merchantId && param?.merchantName) {
      breadcrumb = [
        { label: "Merchant Profile", url: "/merchant-center/profiles" },
        {
          label: param.merchantName,
          url: `/merchant-center/profiles/${param.merchantId}/${param.merchantName}`,
        },
        { label: `Order ID ${id}`, url: null },
      ];
    } else if (param?.storeId && getLocalStorage("role") == "MERCHANT") {
      breadcrumb = [
        { label: "My Income", url: null },
        { label: `View Order`, url: null },
      ];
    } else {
      const url =
        getLocalStorage("role") == "MERCHANT"
          ? "/merchant/orders"
          : "/orders/all-orders";
      breadcrumb = [
        { label: "Orders", url },
        { label: `Order ID ${id}`, url: null },
      ];
    }
    startLoader();
    dispatch(breadcrumbAction(breadcrumb));
    if (id) {
      dispatch(getOrderById(id, { productId }))
        .then((data) => {
          if (data && data.data && data.data.data) {
            let orderDetails = { ...data?.data?.data?.orderDetails };
            let trackingDetails = data?.data?.data?.trackingDetails;
            setFullData({ ...orderDetails, trackingDetails });
            setTimeline(
              data?.data?.data?.shippingStatus.map((item) => item.date)
            );
            const latest = data.data.data.shippingStatus
              .filter((item) => item.date !== null)
              .splice(-1)[0];
            console.log(latest, "jjjjjjjjjj");
            setLastOrderUpdate(latest);
            setOrderShippingStatus({
              value: latest?.status,
              label: order_status_names[latest?.status],
            });
            setTimeout(() => {
              stopLoader();
            }, 1000);
          }

          console.log(
            data?.data?.data,
            "lllllllllllllllllllllllllllllllllllllllll"
          );
        })
        .catch((error) => {
          setTimeout(() => {
            console.log(error);
            stopLoader();
          }, 1000);
        });
    }
  }, [id, submitStatusChange]);

  const updateOrderStatusFun = () => {
    // let orderType=fullData?.product?.productType
    let orderType = "cancelled";

    // let orderType=fullData?.status=="CANCELLED"?"cancelled":fullData?.product?.productType
    let status = changedStatus?.current;
    console.log(id, orderType, status, "oopopopopoppppopopop");
    dispatch(updateOrderStatus(id, orderType, status))
      .then((data) => {
        console.log(data);
        if (id) {
          startLoader();
          dispatch(getOrderById(id, { productId }))
            .then((data) => {
              if (data && data.data && data.data.data) {
                console.log(
                  data?.data?.data,
                  "data?.data?.datavvdata?.data?.datadata?.data?.data"
                );
                let orderDetails = { ...data?.data?.data?.orderDetails };
                let trackingDetails = data?.data?.data?.trackingDetails;
                setFullData({ ...orderDetails, trackingDetails });
                setTimeline(
                  data?.data?.data?.shippingStatus?.map((item) => item.date)
                );
                const latest = data?.data?.data?.shippingStatus
                  ?.filter((item) => item.date !== null)
                  .splice(-1)[0];
                console.log(latest, "jjjjjjjjjj");
                setLastOrderUpdate(latest);
                setOrderShippingStatus({
                  value: latest?.status,
                  label: order_status_names[latest?.status],
                });
                setTimeout(() => {
                  stopLoader();
                }, 1000);
              }

              console.log(
                data?.data?.data,
                "lllllllllllllllllllllllllllllllllllllllll"
              );
            })
            .catch((error) => {
              setTimeout(() => {
                console.log(error);
                stopLoader();
              }, 1000);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeShippingStatusFun = async () => {
    const data = {
      shippingStatus: orderShippingStatus.value,
    };
    console.log(data, "data");
    dispatch(updateOrderShippingStatusById(id, data))
      .then((data) => {
        console.log(data);
        dispatch(getOrderById(id))
          .then((data) => {
            if (data && data.data && data.data.data) {
              let orderDetails = { ...data?.data?.data?.orderDetails };
              let trackingDetails = data?.data?.data?.trackingDetails;
              setFullData({ ...orderDetails, trackingDetails });
              setTimeline(
                data?.data?.data?.shippingStatus.map((item) => item.date)
              );
              const latest = data.data.data.shippingStatus
                .filter((item) => item.date !== null)
                .splice(-1)[0];
              console.log(latest, "jjjjjjjjjj");
              setLastOrderUpdate(latest);
              setOrderShippingStatus({
                value: latest.status,
                label: order_status_names[latest?.status],
              });
            }
            console.log(data?.data?.data, "leeeeeeeeeeeeeeeeeeee");
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });

    setEditOrderShipped(false);
  };

  const handleShowTrasactionHistory = () => {
    open_dialog(SHOW_TRANSACTION_HISTORY, {});
  };

  const editStatusSave = () => {
    setShowStatusChange(false);
    setSubmitStatusChange(true);
    updateOrderStatusFun();

    // if (fullData?.product?.productType == "physical") {
    //   setFullData({ ...fullData, status: changedStatus?.current });
    // } else {
    //   setFullData({ ...fullData, NpStatus: changedStatus?.current });
    // }
  };

  const classes = useStyles();

  // console.log(fullData, "bbbbbbbbbbbbbbbbbbbb");

  return (
    <React.Fragment>
      <div className="d-flex  position-relative  mx-4 ">
        <div className="col-8  pr-3">
          <div className="py-3">
            <div className="mb-2">
              <div>CANCELLATION INFORMATION</div>
            </div>
            <div className="card p-3">
              <div className="ml-3 mb-3">
                <div style={{ color: "#000000" }}>Cancelled By</div>
                <div
                  className="text-muted"
                //  style={{fontSize:"13px",marginTop:"-7px"}}
                >
                  Buyer
                </div>
              </div>
              <div className="ml-3 mb-3">
                <div style={{ color: "#000000" }}>Reason From Buyer</div>
                <div
                  className="text-muted"
                //  style={{fontSize:"13px",marginTop:"-7px"}}
                >
                  Change Address
                </div>
              </div>
              <div className="ml-3 mb-3">
                <div style={{ color: "#000000" }}>Description</div>
                <div
                  className="text-muted"
                //  style={{fontSize:"13px",marginTop:"-7px"}}
                >
                  -
                </div>
              </div>
              <div className="ml-3 mb-3">
                <div style={{ color: "#000000" }}>Image</div>
                <div
                  className="text-muted"
                //  style={{fontSize:"13px",marginTop:"-7px"}}
                >
                  -
                </div>
              </div>
            </div>
          </div>

          {fullData?.product?.productType === "physical" && (
            <div className="py-3">
              <div className="d-flex box justify-content-between mt-3 mb-2">
                LOGISTIC INFORMATION
              </div>
              <div className="card py-3 ">
                <div className="d-flex position-relative">
                  <div className="col-11 d-flex flex-column justify-content-center  align-items-center">
                    <div style={{ fontSize: "1.4rem", fontWeight: "700" }}>
                      {order_status_names[lastOrderUpdate?.status] || "- - -"}
                    </div>
                    <div>
                      {moment(lastOrderUpdate?.date).format(
                        "dddd MM-DD-YYYY "
                      ) +
                        " at " +
                        moment(lastOrderUpdate?.date).format("hh:mma")}
                    </div>
                  </div>
                  <div className="d-flex mb-3 position-absolute r-0  mr-1 top-0">
                    {!editOrderShipped ? (
                      getLocalStorage("role") == "MERCHANT" ? (
                        ""
                      ) : (
                        <button
                          className=" btn border input-inside-text  "
                          onClick={() => setEditOrderShipped(true)}
                        >
                          Edit
                        </button>
                      )
                    ) : (
                      <div className="mr-2">
                        <button
                          className=" btn border input-inside-text mr-1 "
                          onClick={() => setEditOrderShipped(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className=" btn border btn-dark input-inside-text "
                          // onClick={() => setEditOrderShipped(false)}
                          onClick={() => changeShippingStatusFun()}
                          visible={String(editOrderShipped)}
                        >
                          save
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <br />

                <CustomizedSteppers timeline={timeline} />

                <br />

                <div
                  className="d-flex justify-content-center "
                  visible={String(editOrderShipped)}
                >
                  <label
                    className={`col-3 text-center m-0 px-2 py-3 ${classes.mainText}`}
                  >
                    Logistic Status
                  </label>
                  <div className=" mt-2 col-9 form-group">
                    <SelectDropdown
                      options={orderStatuses}
                      placeholder="Select status"
                      value={orderShippingStatus}
                      onChange={setOrderShippingStatus}
                    />
                    <div className={`text-muted ${classes.timing} pt-1`}>
                      *Please note that you are only allowed to edit status if
                      only logistic partner unable to update the status or
                      system error
                    </div>
                  </div>
                </div>

                <hr className="mx-3" />
                <div className="ml-4">
                  <div className="d-flex">
                    <div className="mx-2">
                      <TimelineDot className={`text-muted ${classes.dot}`} />
                    </div>
                    <div>
                      <div className={`text-success ${classes.mainText}`}>
                        Seller is preparing to ship your parcel
                      </div>
                      <div className={`text-muted ${classes.timing}`}>
                        02/03/2022 14:00pm
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="d-flex">
                    <div className="mx-2">
                      <TimelineDot className={`text-muted ${classes.dot}`} />
                    </div>
                    <div>
                      <div className={`text-success ${classes.mainText}`}>
                        Parcel has been picked up by courie
                      </div>
                      <div className={`text-muted ${classes.timing}`}>
                        02/03/2022 14:00pm
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="d-flex">
                    <div className="mx-2">
                      <TimelineDot className={`text-muted ${classes.dot}`} />
                    </div>
                    <div>
                      <div className={`text-success ${classes.mainText}`}>
                        Parcel has departed from station
                      </div>
                      <div className={`text-muted ${classes.timing}`}>
                        02/03/2022 14:00pm
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="d-flex">
                    <div className="mx-2">
                      <TimelineDot className={`text-muted ${classes.dot}`} />
                    </div>
                    <div>
                      <div className={`text-success ${classes.mainText}`}>
                        Parcel has arrived at Station: PCH Hub
                      </div>
                      <div className={`text-muted ${classes.timing}`}>
                        02/03/2022 14:00pm
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="d-flex">
                    <div className="mx-2">
                      <TimelineDot className={`text-muted ${classes.dot}`} />
                    </div>
                    <div>
                      <div className={`text-success ${classes.mainText}`}>
                        Parcel is out for delivery
                      </div>
                      <div className={`text-muted ${classes.timing}`}>
                        02/03/2022 14:00pm
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="d-flex">
                    <div className="mx-2">
                      <TimelineDot className={`text-muted ${classes.dot}`} />
                    </div>
                    <div>
                      <div className={`text-success ${classes.mainText}`}>
                        Parcel has been delivered
                      </div>
                      <div className={`text-muted ${classes.timing}`}>
                        02/03/2022 14:00pm
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          )}

          <div className="py-3">
            <div className="d-flex box justify-content-between  mb-2">
              <div>ORDER INFORMATION</div>
              <div
                className="d-flex align-items-center "
                onClick={() => handleShowTrasactionHistory()}
              >
                <Image src="/trans.png" alt="image" width={18} height={18} />
                <div className="pl-2">Transaction History</div>
              </div>
            </div>
            <div className="card p-3">
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        Product
                      </TableCell>
                      <TableCell className="th_cell" component="th"></TableCell>
                      <TableCell className="th_cell" component="th"></TableCell>
                      <TableCell className="th_cell" component="th"></TableCell>
                      <TableCell className="th_cell" component="th"></TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="right"
                      >
                        Price
                      </TableCell>
                      <TableCell
                        className="th_cell "
                        component="th"
                        align="right"
                      >
                        Quantity
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="right"
                      >
                        <div>Subtotal</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {[1].map((row, index) => ( */}
                    <TableRow
                      // key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        className="td_cell"
                        component="td"
                        align="left"
                        colSpan={5}
                      >
                        <div className="d-flex">
                          <Image
                            // src="/images/shoes.jpeg"
                            src={fullData?.productImages || ""}
                            className="order_page_product_image"
                            alt="img"
                          />
                          <div className="px-2">
                            <div className="capitalize">
                              {fullData?.productName || "N/A"}
                            </div>
                            <div className="text-muted">
                              {`variation: ${fullData?.variation?.name || "N/A"
                                }`}

                              {/* white */}
                            </div>
                          </div>
                        </div>
                      </TableCell>

                      <TableCell
                        className="td_cell"
                        component="td"
                        align="right"
                      >
                        {`${fullData?.currencyName || "RM"}${fullData?.price || "N/A"
                          }.00`}
                      </TableCell>
                      <TableCell
                        className="td_cell "
                        component="td"
                        align="right"
                      >
                        {fullData?.quantity || 0}
                      </TableCell>
                      <TableCell
                        className="td_cell"
                        component="td"
                        align="right"
                      >
                        {`${fullData?.currencyName || "RM"}${(fullData?.price || 0) * (fullData?.quantity || 0)
                          }.00`}
                      </TableCell>
                    </TableRow>
                    {/* ))} */}
                  </TableBody>
                  <TableRow
                    sx={{
                      " td, th": {
                        border: 0,
                        "padding-top": 0,
                        "padding-bottom": 0,
                        "font-size": "1rem",
                        "font-weight": "700",
                      },
                    }}
                  >
                    <TableCell
                      className="td_cell"
                      component="td"
                      align="right"
                      colSpan={8}
                    >
                      Total
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      " td, th": { border: 0, "padding-top": 1 },
                    }}
                  >
                    <TableCell
                      className="td_cell"
                      component="td"
                      align="right"
                      colSpan={7}
                    >
                      <div>Subtotal :</div>
                      <div>Shipping :</div>
                      <div>Fee & Charges :</div>
                      <div className="py-3">Total Income :</div>
                    </TableCell>
                    <TableCell className="td_cell" component="td" align="right">
                      <div>
                        {`${fullData?.currencyName || "RM"}${(fullData?.price || 0) * (fullData?.quantity || 0)
                          }.00`}
                      </div>
                      <div>{`${fullData?.currencyName || "RM"}${fullData?.shipping?.shippingCharge || "0"
                        }.00`}</div>
                      <div>{`${fullData?.currencyName || "RM"}${fullData?.shipping?.feeAndCharges || "0"
                        }.00`}</div>
                      <div
                        className="py-3 text-success "
                        style={{ fontSize: "1.2rem" }}
                      >
                        {`${fullData?.currencyName || "RM"}${(Number(fullData?.shipping?.shippingCharge) || 0) +
                          (fullData?.price || 0) * (fullData?.quantity || 0) +
                          (fullData?.shipping?.feeAndCharges || 0)
                          }.00`}
                      </div>
                    </TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </div>
          </div>

          <div className="py-3">
            <div className="d-flex box justify-content-between mt-3 mb-2">
              <div>BUYER PAYMENT</div>
            </div>
            <div className="card p-3">
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {" "}
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="right"
                        colSpan={8}
                      >
                        <div>Subtotal</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        " td, th": {
                          border: 0,
                          "padding-top": "1rem",
                          width: "8rem",
                        },
                      }}
                    >
                      <TableCell
                        className="td_cell"
                        component="td"
                        align="right"
                        colSpan={5}
                      >
                        <div>Merchandise Subtotal :</div>
                        <div>Shipping :</div>
                        <div>SavvyMart Voucher :</div>
                        <div>Seller Voucher :</div>
                        <div>Credit/Debit Card Promotion :</div>
                        <div className="py-3">Total Income :</div>
                      </TableCell>
                      <TableCell
                        className="td_cell"
                        component="td"
                        align="right"
                      >
                        <div>
                          {`${fullData?.currencyName || "RM"}${(fullData?.price || 0) * (fullData?.quantity || 0)
                            }.00`}
                        </div>
                        <div>
                          {`${fullData?.currencyName || "RM"}${fullData?.shipping?.shippingCharge || "0"
                            }.00`}{" "}
                        </div>
                        <div>
                          {`${fullData?.currencyName || "RM"}${fullData?.shipping?.uu2Voucher || "0"
                            }.00`}{" "}
                        </div>
                        <div className="text-muted">
                          {`${fullData?.currencyName || "RM"}${fullData?.shipping?.sellerVoucher || "0"
                            }.00`}{" "}
                        </div>
                        <div className="text-muted">
                          {`${fullData?.currencyName || "RM"}${fullData?.shipping?.creaditDebitCardVoucher || "0"
                            }.00`}
                        </div>
                        <div className="py-3" style={{ fontSize: "1.2rem" }}>
                          {`${fullData?.currencyName || "RM"}${(fullData?.price || 0) * (fullData?.quantity || 0) +
                            Number(fullData?.shipping?.shippingCharge || 0) +
                            (fullData?.shipping?.uu2Voucher || 0) +
                            (fullData?.shipping?.sellerVoucher || 0) +
                            (fullData?.shipping?.creaditDebitCardVoucher || 0)
                            }.00`}
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          <div className="py-3">
            <div className="d-flex box justify-content-between mt-3 mb-2">
              <div>CONSUMER INFORMATION</div>
            </div>
            <div className="card p-4 ">
              <div className="d-flex justify-content-between">
                <div className="col-4 p-0">
                  <div className="pb-3">Personal detail</div>
                  <div
                    className="d-flex justifly-content-between pt-1"
                    style={{ fontSize: "0.8rem" }}
                  >
                    <label htmlFor="" className="col-4 text-muted p-0">
                      Name:{" "}
                    </label>
                    <div className="col-8 p-0">
                      {fullData?.consumer?.consumerName || "N/A"}
                    </div>
                  </div>
                  <div
                    className="d-flex justifly-content-between pt-1"
                    style={{ fontSize: "0.8rem" }}
                  >
                    <label htmlFor="" className="col-4 text-muted p-0">
                      Email:{" "}
                    </label>
                    <div className="col-8 text-nowrap p-0">
                      {fullData?.consumer?.email || "N/A"}
                    </div>
                  </div>
                  <div
                    className="d-flex justifly-content-between pt-1"
                    style={{ fontSize: "0.8rem" }}
                  >
                    <label htmlFor="" className="col-4 text-muted p-0">
                      Phone:{" "}
                    </label>
                    <div className="col-8 p-0">
                      {fullData?.consumer?.phoneNumber || "N/A"}
                    </div>
                  </div>
                </div>
                <div className="col-7 p-0 ">
                  <div className=" p-0 mb-3">
                    <div className="pb-3">Billing Address</div>
                    <div
                      className="d-flex justifly-content-between pt-1"
                      style={{ fontSize: "0.8rem" }}
                    >
                      <label htmlFor="" className="col-2 text-muted p-0">
                        Address:{" "}
                      </label>
                      <div className="col-10 ">
                        {fullData?.billingAddress?.address || "N/A"}
                      </div>
                    </div>
                  </div>
                  <div className=" p-0 mt-2">
                    <div className="pb-3">Shipping Address</div>
                    <div
                      className="d-flex justifly-content-between pt-1"
                      style={{ fontSize: "0.8rem" }}
                    >
                      <label htmlFor="" className="col-2 text-muted p-0">
                        Address:{" "}
                      </label>
                      <div className="col-10 ">
                        {fullData?.shippingAddress?.address || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4 p-0">
          <div
            className="d-flex box justify-content-between mt-3 position-sticky r-0"
            style={{ right: "0px" }}
          ></div>
          <div
            className=" position-sticky r-0  "
            style={{ top: "0px", right: "0px" }}
          >
            <div className="d-flex justify-content-between">
              <div>OTHER INFORMATION</div>
              <div className=" bg-secondry" style={{ height: "25px" }}>
                <IconButton
                  className="pointer"
                  onClick={() => setShowStatusChange(true)}
                >
                  <SvgIcon src="/icons/edit_icon.svg" alt="view icon" />
                </IconButton>
              </div>
            </div>
            <div className="card p-3  mt-2">
              <div className="mb-3 " style={{ fontSize: "0.8rem" }}>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Status</div>

                  <div
                    className="font-weight-bold text-success"
                    style={{ fontSize: "1rem" }}
                  >
                    {!showStatusChange ? (
                      stringBreaker(fullData?.cancelOrderStatus || "N/A", "_")
                    ) : (
                      // fullData?.cancelOrderStatus || "N/A"
                      <>
                        <div className="d-flex justify-content-center">
                          <div
                            className="btn btn-light mr-1"
                            onClick={() => setShowStatusChange(false)}
                          >
                            cancel
                          </div>
                          <div
                            className="btn btn-dark btn-sm"
                            onClick={editStatusSave}
                          >
                            save
                          </div>
                        </div>
                        <br />
                        <div className="">
                          <select
                            className="btn border input-inside-text "
                            onChange={(e) =>
                              (changedStatus.current = e.target.value)
                            }
                          >
                            <option disabled selected>
                              {stringBreaker(
                                fullData?.cancelOrderStatus || "N/A",
                                "_"
                              )}
                            </option>
                            {orderStatusOptions_CancelledProduct.map(
                              (item, index) =>
                                fullData?.cancelOrderStatus !== item && (
                                  <option key={index} value={item}>
                                    {stringBreaker(item, "_")}
                                  </option>
                                )
                            )}
                          </select>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Order ID</div>
                  <div className="">
                    {"#" + "012201" + (fullData?.orderId?.slice(-2) || "N/A")}
                    {/* {fullData?.orderId || "N/A"} */}
                  </div>
                </div>
              </div>

              <div className="font-weight-bolder py-1">Payment</div>
              <div className="mb-3 " style={{ fontSize: "0.8rem" }}>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Payment Method</div>
                  <div className="">
                    {fullData?.payments?.length > 0
                      ? fullData?.payments[0]?.paymentMethod || "N/A"
                      : "N/A"}
                    {/* Creadit/Debit Card */}
                  </div>
                </div>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Payment Date</div>
                  <div className="">
                    {/* Shipping Address */}
                    {fullData?.payments?.length > 0
                      ? moment(fullData?.payments[0]?.createdAt).format(
                        "DD/MM/YY, HH:mm"
                      ) || "N/A"
                      : "N/A"}
                  </div>
                </div>
              </div>

              {fullData?.product?.productType === "physical" ? (
                <>
                  <div className="font-weight-bolder py-1">
                    Shipping Details
                  </div>
                  <div className="" style={{ fontSize: "0.8rem" }}>
                    <div className="d-flex justify-content-between py-1">
                      <div className="text-muted">Merchant</div>
                      <div className="">
                        {fullData?.merchantName || "N/A"}

                        {/* Nike eStore */}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between py-1">
                      <div className="text-muted">Shipping</div>
                      <div className="">
                        {fullData?.shippingDetails?.shippingType || "N/A"}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between py-1">
                      <div className="text-muted">Courier</div>
                      <div className="">
                        {fullData?.shippingDetails?.courierName || "N/A"}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between py-1">
                      <div className="text-muted">Tracking Number</div>
                      <div className="">
                        {fullData?.shippingDetails?.trackingNo || "N/A"}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="font-weight-bolder py-1">
                    Redemption Details
                  </div>
                  <div className="" style={{ fontSize: "0.8rem" }}>
                    <div className="d-flex justify-content-between py-1">
                      <div className="text-muted">Merchant</div>
                      <div className="">
                        {fullData?.merchantName || "N/A"}

                        {/* Nike eStore */}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between py-1">
                      <div className="text-muted">Purchase Date</div>
                      <div className="">
                        {moment(fullData?.createdAt).format(
                          "DD/MM/YY, HH:mm"
                        ) || "N/A"}
                        {/* {fullData?.shippingDetails?.shippingType || "N/A"} */}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between py-1">
                      <div className="text-muted">Validity</div>
                      <div className="">
                        {moment(fullData?.product?.expiryDate).format(
                          "DD/MM/YY"
                        ) || "N/A"}
                        {/* {fullData?.shippingDetails?.courierName || "N/A"} */}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between py-1">
                      <div className="text-muted">Redemption Code</div>
                      <div className="">
                        {fullData?.redemptionCode || "N/A"}

                        {/* {fullData?.shippingDetails?.trackingNo || "N/A"} */}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className=" p-3 mt-1 card">
              <div className="pb-3 font-weight-bolder">Shipping Address</div>
              <div
                className="d-flex justifly-content-between pt-1"
                style={{ fontSize: "0.8rem" }}
              >
                <label htmlFor="" className="col-2 text-muted p-0">
                  Address:{" "}
                </label>
                <div className="col-10 ">
                  {fullData?.shippingAddress?.address || "N/A"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="d-flex flex-wrap justify-content-end align-items-center py-3 mt-3 bg-white border-top b-0">
        <div className="col-md-4 d-flex align-items-center mr-2 justify-content-end">
          <button
            type="button"
            className="btn border input-inside-text mr-2"
            onClick={history.goBack}
          >
            Cancel
          </button>
          {param?.storeId && getLocalStorage("role") == "MERCHANT" && (
            <button
              type="button"
              className="btn-danger btn border input-inside-text mr-2"
              // onClick={history.goBack}
              disabled
            >
              Contact Merchant
            </button>
          )}
        </div>
      </footer>
    </React.Fragment>
  );
}

export default ViewCancelOrder;
