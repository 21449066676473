import { Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { TabPanel } from "../../../../components/Tabs/TabPannel";
import IncomeToRelease from "./toSettle";

import {getIncomeOverviewData} from "../../../../redux/actions/merchant/finance.action";

import { useDispatch } from "react-redux";
import { getLocalStorage } from "../../../../lib/session";
import decimalFormat from "../../../../helpers/decimal.format";
const moment = require("moment");

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MerchantIncome = (props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [incomeData, setIncomeData] = useState(null);
  const [settlementReport, setSettlementReport] = useState([]);
  const [taxReport, setTaxReport] = useState([]);

  const dispatch = useDispatch();

  const storeId = getLocalStorage("storeId");

  useEffect(() => {
    // dispatch(getIncomeOverviewData(storeId))
    dispatch(getIncomeOverviewData())
      .then((data) => {
        setIncomeData(data?.data?.data);
        const dates = settlementReportList(
          data?.data?.data?.createdDate?.createdAt
        );
        setSettlementReport([...dates]);
        // setTaxReport([...dates]);
      })
      .catch((err) => {
        console.log({ err });
      });
  }, []);

  // console.log(settlementReport);

  const settlementReportList = (startDate) => {
    var dateStart = moment(startDate);
    var dateEnd = moment(new Date());

    var timeValues = [];
    var timeTax = [];
    let count = 0;

    while (
      dateEnd > dateStart ||
      dateStart.format("M") === dateEnd.format("M")
    ) {
      const date = new Date(dateStart.format("YYYY-MM"));
      var firstDay =
        count == 0
          ? new Date(dateStart.format("YYYY-MM-DD"))
          : new Date(date.getFullYear(), date.getMonth(), date.getDate());

      dateStart.add(1, "month");

      var lastDay =
        dateEnd == dateStart
          ? new Date()
          : new Date(
              date.getFullYear(),
              date.getMonth() + 1,
              date.getDate() - 1
            );

      let payload = {
        name:
          moment(firstDay).format("DD MMM") +
          " - " +
          moment(lastDay).format("DD MMM YYYY"),
        startDate: moment(firstDay).format("YYYY-MM-DD"),
        endDate: moment(lastDay).format("YYYY-MM-DD"),
      };

      timeTax.push(payload);
      timeValues.push([
        moment(firstDay).format("DD MMM"),
        moment(lastDay).format("DD MMM YYYY"),
      ]);
      count = count + 1;
    }

    timeValues = timeValues.map((item, index) => item.join(" - "));
    setTaxReport(timeTax);

    return timeValues;
  };

  return (
    <div>
      <h3>Income Overview</h3>

      <div className="d-flex flex-direction-row justify-content-space-between align-items-end mt-3">
        <div className="min-width-200">
          <div>To Settle</div>
          <div className="text-secondary">Total</div>
          <div className="font-weight-bold">
            RM
            <span className="fntSz-25">
              {decimalFormat(incomeData?.PendingAmonut[0]?.total || 0.0)}
            </span>
          </div>
        </div>
        <div className="min-width-200">
          <div>Settled</div>
          <div className="text-secondary">This Week</div>
          <div className="font-weight-bold">
            RM
            <span className="fntSz-25">
              {decimalFormat(incomeData?.week[0].total || 0.0)}
            </span>
          </div>
        </div>
        <div className="min-width-200">
          <div className="text-secondary">This Month</div>
          <div className="font-weight-bold">
            RM
            <span className="fntSz-25">
              {decimalFormat(incomeData?.month[0].total || 0.0)}
            </span>
          </div>
        </div>
        <div className="min-width-200">
          <div className="text-secondary">Total</div>
          <div className="font-weight-bold">
            RM
            <span className="fntSz-25">
              {decimalFormat(incomeData?.totalAmount[0].total || 0.0)}
            </span>
          </div>
        </div>
      </div>
      <br />
      <div className="container_body">
        <div className="card">
          <div className=" border card  my-3">
            <Tabs
              value={tabIndex}
              onChange={(e, value) => setTabIndex(value)}
              variant="scrollable"
              orientation="horizontal"
              scrollButtons={false}
              visibleScrollbar={false}
              className="px-3"
              aria-label="basic tabs example"
            >
              <Tab label="To Settle" {...a11yProps(0)} />
              <Tab label="Settled" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <IncomeToRelease status="toSettle" />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <IncomeToRelease status="settle" />
            </TabPanel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantIncome;
