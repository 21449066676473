import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IOSSwitch } from "../../../../../../components/switch/IOSSwitch";
import { breadcrumbAction } from "../../../../../../redux/actions/config.action";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Image from "../../../../../../components/Images/Image";
import {
  open_dialog,
  parseQuery, showToast,
  startLoader,
  stopLoader
} from "../../../../../../lib/global";
import { CREATE_GROUP_DEAL_PRODUCT } from "../../../../../../lib/constant";
import InputField from "../../../../../../components/formControl/input/InputField";
import { createGroupDealsProduct } from "../../../../../../redux/actions/marketing.action";
import { getLocalStorage } from "../../../../../../lib/session";
import { v2CreateGroupDealsProduct } from "../../../../../../redux/actions/merchant/marketing.merchant.action";

const MerchantGroupDealsProductCreate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [buttonTimeSlot, setButtonTimeSlot] = useState(false);
  const [allProductData, setAllProductData] = useState([]);
  const [timeSlotDateState, setTimeSlotDateState] = useState();
  const [campaignStockState, setCampaignStockState] = useState();
  const [unitCappedState, setUnitCappedState] = useState();
  const [status, setStatus] = useState("Disable");
  const { groupDealsId } = parseQuery(history.location.search) || {};
  let filterData = {};
  let productData = [];
  const [variationInfo, setVariationInfo] = useState({});
  const [variationData, setVariationData] = useState([]);
  const [errors, setErrors] = useState({});
  const [isActivedProduct, setIsActivedProduct] = useState(0);


  const handelAddProduct = () => {
    open_dialog(CREATE_GROUP_DEAL_PRODUCT, {
      onSubmit: selectProductSuccess,
      allProductData: allProductData,
      setAllProductData: setAllProductData,
      merchantId: getLocalStorage("storeId"),
      groupDealsId:groupDealsId
    });
  };

  const selectProductSuccess = (data) => {
    console.log(data);
  };

  const validationBeforeConfirm = () => {
    const errorsList = {
      teamCampaignStock: false,
      teamUnitCapped: false,
      teamDiscountPrice: false,
      groupDealsTeamMember: false,
    };

    allProductData.map((row, index) => {
      row.variationList.map((row2, idx) => {
        if (!row2.teamCampaignStock) {
          errorsList.teamCampaignStock = true;
        }
        if (!row2.teamUnitCapped) {
          errorsList.teamUnitCapped = true;
        }
        if (!row2.teamDiscountPrice) {
          errorsList.teamDiscountPrice = true;
        }
        if (!row2.groupDealsTeamMember) {
          errorsList.groupDealsTeamMember = true;
        }
      });
    });

    setErrors((pre) => ({ ...pre, ...errorsList }));
    return errorsList;
  };

  const saveActionAll = async () => {
    startLoader();
    allProductData.map((row, index) => {
      let productDataItem = {
        productId: row.productId,
        variation: row.variationList,
      };
      productData.push(productDataItem);
    });

    let payload = {
      merchantId: getLocalStorage("storeId"),
      groupDealsId: groupDealsId,
      productData: productData,
    };

    // validationBeforeConfirm();
    if (
      // errors.teamCampaignStock ||
      // errors.teamUnitCapped ||
      // errors.teamDiscountPrice ||
      // errors.groupDealsTeamMember ||
      isActivedProduct === 0
    ) {
      stopLoader();
      // return;
      showToast(
        "At least one product must be activated and fill in all product prices, please!",
        "error"
      );
    } else {
      setTimeout(() => {
        dispatch(v2CreateGroupDealsProduct(payload))
          .then((response) => {
            stopLoader();
            history.push(
              `/merchant/marketing/promo/group-deals-product?groupDealsId=${groupDealsId}`
            );
          })
          .catch((error) => {
            stopLoader();
            let err = { error };
            showToast(err.error.response.data.message, "error");
          });
      }, 500);
    }
  };

  const onChangeVariation = (arrayVariation, row, i, e) => {
    const key = e.target.name;
    const value = parseFloat(e.target.value);
    console.log(key)
    const newObject = { [key]: value };

    if(validationChange(key,value)){
      e.target.value = value
      arrayVariation.map((rowData, indexData) => {
        if (indexData == i) {
          if (key === "teamCampaignStock") {
            rowData.teamCampaignStock = value;
          } else if (key === "teamUnitCapped") {
            rowData.teamUnitCapped = value;
          } else if (key === "teamDiscountPrice") {
            rowData.teamDiscountPrice = value;
          } else if (key === "groupDealsTeamMember") {
            rowData.groupDealsTeamMember = value;
          }
  
        }
      });
      setVariationData(arrayVariation);
    }
    // validationBeforeConfirm();
  };

  const validationChange = (key,value)=>{
    if(inArray(['teamCampaignStock','campaignStock','discountPrice','unitCapped','teamUnitCapped','teamDiscountPrice','groupDealsTeamMember'],key)){
      if(!isValidInputNumber(value)){
        showToast("Fill A valid number only", "error");
        return false
      }

      if(inArray(['teamCampaignStock','campaignStock','groupDealsTeamMember'],key) && value<0){
        showToast("Fill A number more than 0", "error");
        return false
      }

      if(inArray(['discountPrice','unitCapped','teamUnitCapped','teamDiscountPrice'],key) && value<0){
        showToast("Fill A number more than -1", "error");
        return false
      }
    }

    return true
  }

  const inArray = (arr, val) => {
    return arr.indexOf(val) !== -1
  }

  const isValidInputNumber = (val) =>{
    if(val != undefined ){
      return true;
    }
    return false
  }

  const onChangeStatus = (arrayVariation, row, i, e) => {
    const keyCheck = e.target.name;
    const valCheck = e.target.checked;
    arrayVariation.map((rowData, indexData) => {
      if (indexData == i) {
        if (valCheck) {
          setIsActivedProduct((a) => a + 1);
          if (keyCheck === "isActive") {
            rowData.isActive = valCheck;
          } else if (keyCheck === "teamIsActive") {
            rowData.teamIsActive = valCheck;
          }
        } else {
          setIsActivedProduct((a) => a - 1);
        }
      }
    });

    // console.log(isActivedProduct);
    // console.log(arrayVariation);
  };

  const onChangeVariationInfo = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setVariationInfo((pre) => ({ ...pre, [key]: value }));
  };

  const handleUpdateAll = () => {
    allProductData.map((row, index) => {
      row.variationList.map((r, i) => {
        Object.assign(r, variationInfo);
      });
    });
    // console.log(allProductData);
  };

  useEffect(() => {
    const breadcrumb = [
      { label: "All Promo", url: "/merchant/marketing/promo" },
      { label: "Group Deals", url: "/merchant/marketing/promo/group-deals" },
      { label: "Product", url: `/merchant/marketing/promo/group-deals-product?groupDealsId=${groupDealsId}` },
      { label: "Create", url: null }
    ];
    dispatch(breadcrumbAction(breadcrumb));

    allProductData.map((r, i) => {
      setVariationData(r.variationList);
    });
    // validationBeforeConfirm();
  }, [variationData]);

  return (
    <React.Fragment>
      <div className=" d-flex flex-column justify-content-between container_body vh-100">
        <div className="container-fluid">
          <div className="card p-4 mb-3">
            <div>
              <div className="float-left">
                <h6>GROUP DEALS PRODUCT</h6>
                <p className="fntSz14 mb-0 text-secondary">
                  Add product to participate in group deals event.
                </p>
              </div>
              <div
                className={`float-right d-block`}
              >
                <button
                  onClick={() => handelAddProduct()}
                  type="button"
                  className="rounded btn btn-danger text-nowrap p-2 px-4"
                >
                  {" "}
                  Add Product
                </button>
              </div>
            </div>
            <hr />
            <br />
            <br />

            {allProductData &&
              allProductData.length > 0 &&
              allProductData.map((row, index) => (
                <div className="mb-3">
                  <div>
                    <div className="float-left d-flex align-items-center flex-nowrap ">
                      <Image
                        src={row.productImages}
                        alt={"photo"}
                        className="teamPurchase_page_product_image"
                      />
                      <div className="px-2">{row.title}</div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />

                  <div className={`float-right d-block w-100`}>
                    <br />

                    <div
                      className="border"
                      style={{ borderRadius: "10px", overflow: "hidden" }}
                    >
                      <div className=" bg-light">
                        <div className="pl-3 pt-3 mb-0 fntSz18">
                          Team Purchase
                        </div>
                        <hr />
                      </div>
                      <div className="mx-3">
                        <TableContainer>
                          <Table
                            sx={{ minWidth: 650 }}
                            stickyHeader
                            aria-label="sticky table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  className="th_cell"
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Variation(s)</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell"
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Original Price</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Discounted Price</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>TP Member</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Campaign Stock</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Stock</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Unit Capped</div>
                                  </div>
                                </TableCell>

                                <TableCell
                                  className="table_action_head text-secondary"
                                  component="th"
                                >
                                  Enable/Disable
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {row.variationList &&
                                row.variationList.length > 0 &&
                                row.variationList.map((row2, index2) => (
                                  <TableRow
                                    key={index}
                                    className="border-0"
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell
                                      align="left"
                                      className="td_cell border-0"
                                    >
                                      <div dangerouslySetInnerHTML={{__html:row2.concatVariation}}></div>
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className="td_cell border-0"
                                    >
                                      RM. {row2?.teamPrice || "0"}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className="td_cell border-0"
                                    >
                                      <InputField
                                        className="input_icon_left"
                                        formClass="col-12 p-0"
                                        type="number"
                                        id="teamDiscountPrice"
                                        icon="currency"
                                        placeholder="Input"
                                        name="teamDiscountPrice"
                                        onChange={(e) =>
                                          onChangeVariation(
                                            row.variationList,
                                            row2,
                                            index2,
                                            e
                                          )
                                        }
                                        required={false}
                                      />

                                      <div className="error_container mr-3 rounded px-1 d-flex">
                                        {errors.teamDiscountPrice &&
                                        !row2.teamDiscountPrice ? (
                                          <label className="error fntSz10">
                                            *input team Discount Price !
                                          </label>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </TableCell>

                                    <TableCell
                                      align="left"
                                      className="td_cell border-0"
                                    >
                                      <input
                                        type="number"
                                        placeholder="Input"
                                        className="w-75 form-control"
                                        id="groupDealsTeamMember"
                                        name="groupDealsTeamMember"
                                        onChange={(e) =>
                                          onChangeVariation(
                                            row.variationList,
                                            row2,
                                            index2,
                                            e
                                          )
                                        }
                                        required={false}
                                      />

                                      <div className="error_container mr-3 rounded px-1 d-flex">
                                        {errors.groupDealsTeamMember &&
                                        !row2.groupDealsTeamMember ? (
                                          <label className="error fntSz10">
                                            *input team Purchase Member !
                                          </label>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </TableCell>

                                    <TableCell
                                      align="left"
                                      className="td_cell border-0"
                                    >
                                      <input
                                        type="number"
                                        placeholder="Input"
                                        className="w-75 form-control"
                                        id="teamCampaignStock"
                                        name="teamCampaignStock"
                                        onChange={(e) =>
                                          onChangeVariation(
                                            row.variationList,
                                            row2,
                                            index2,
                                            e
                                          )
                                        }
                                        required={false}
                                      />
                                      <div className="error_container mr-3 rounded px-1 d-flex">
                                        {errors.teamCampaignStock &&
                                        !row2.teamCampaignStock ? (
                                          <label className="error fntSz10">
                                            *input team Campaign Stock !
                                          </label>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className="td_cell border-0"
                                    >
                                      {row2?.stock || "N/A"}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className="td_cell border-0"
                                    >
                                      <input
                                        type="number"
                                        placeholder="Input"
                                        className="w-75 form-control"
                                        id="teamUnitCapped"
                                        name="teamUnitCapped"
                                        onChange={(e) =>
                                          onChangeVariation(
                                            row.variationList,
                                            row2,
                                            index2,
                                            e
                                          )
                                        }
                                        required={false}
                                      />
                                      <div className="error_container mr-3 rounded px-1 d-flex">
                                        {errors.teamUnitCapped &&
                                        !row2.teamUnitCapped ? (
                                          <label className="error fntSz10">
                                            *input team Unit Capped !
                                          </label>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </TableCell>

                                    <TableCell className="d-flex border-0 table_action_item">
                                      <IOSSwitch
                                        // checked={status}
                                        // onChange={(e) => setStatus(e.target.checked)}
                                        name="teamIsActive"
                                        onChange={(e) =>
                                          onChangeStatus(
                                            row.variationList,
                                            row2,
                                            index2,
                                            e
                                          )
                                        }
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                    <br />
                    <br />
                  </div>
                </div>
              ))}
          </div>
          {allProductData.length !== 0 &&
          Object.values(errors).filter((item) => item).length ? (
            <div className="error_container mr-3 rounded px-1 d-flex">
              <label className="error fntSz12">
                **Please fill in all input pages !
              </label>
            </div>
          ) : (
            <></>
          )}
          {allProductData.length !== 0 && isActivedProduct == 0 ? (
            <div className="error_container mr-3 rounded px-1 d-flex">
              <label className="error fntSz12">
                **At least one product must be activated !
              </label>
            </div>
          ) : (
            <></>
          )}
        </div>

        <footer className="bg-white p-3 mt-5">
          <div className={`d-block`}>
            <div className="d-flex justify-content-end">
              <button
                onClick={() =>
                  history.push(
                    `/merchant/marketing/promo/group-deals-product?groupDealsId=${groupDealsId}`
                  )
                }
                type="button"
                className="btn btn-outline-danger mx-2"
              >
                Cancel
              </button>
              <button
                onClick={() => saveActionAll()}
                type="button"
                className="btn btn-danger mx-2"
              >
                Confirm
              </button>
            </div>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default MerchantGroupDealsProductCreate;
