import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { TabPanel } from "../../components/Tabs/TabPannel";
import { VERIFY_MERCHANT_DIALOG } from "../../lib/constant";
import { open_dialog } from "../../lib/global";
import { breadcrumbAction } from "../../redux/actions/config.action";
import ShopSettings from "./ShopSettings";
import ShopRatting from "./ShopRatting";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MerchantProfile = () => {
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [verified, setVerified] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!verified) {
      open_dialog(VERIFY_MERCHANT_DIALOG, {
        onSubmit: (value) => {
          setVerified(value);
        },
        dialogClick: () => {
          history.goBack() || history.push("/");
        },
      });
    }
    const breadcrumb = [{ label: "My Profile", url: null }];
    dispatch(breadcrumbAction(breadcrumb));
  }, []);

  return (
    <React.Fragment>
      <div className="container_body container-fluid">
        <div className="card p-3">
          <Tabs
            value={tabIndex}
            onChange={(e, value) => setTabIndex(value)}
            variant="scrollable"
            orientation="horizontal"
            scrollButtons={false}
            visibleScrollbar={false}
            className="px-3"
            aria-label="basic tabs example"
          >
            <Tab label="Shop Setting" {...a11yProps(0)} />
            <Tab label="Shop Rating" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
            <ShopSettings />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <ShopRatting />
          </TabPanel>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MerchantProfile;
