import { Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import SelectInput from '../../../components/select';
import { TabPanel } from '../../../components/Tabs/TabPannel';
import { startLoader, stopLoader } from '../../../lib/global';
import { getAllCategoryForSearch } from '../../../redux/actions/category.action';
import { breadcrumbAction } from '../../../redux/actions/config.action';
import { getProductsBymerchantId } from '../../../redux/actions/product.action';
import Allproduct from '../../../merchant-pages/products/productListing/Allproduct';
import ShopSettings from "./shopSetting/ShopSettings"
import RatingIndex from './MerchantProfileShopRating/RatingIndex';
import FinanceInfoIndex from './Finance-Info';
import MerchantOrderSetting from './orders/merchantOrderSetting';
// import ProductListing from '../../../merchant-pages/products/productListing';
import MerchantAllProduct from './products/MerchantAllProduct';
import MerchantOrderListing from '../../../merchant-pages/order/Order_Listing';
import OrderListing from '../../order/Order_Listing';

const tabs = [
  "ONE",
  "TWO",
  "THREE"
]

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const initialSearhData = {
  productName: "",
  categoryId: "",
  createAt: "",
};

const MerchantProfileSetting = () => {
  const dispatch = useDispatch()
  const [tabIndex, setTabIndex] = useState(0)
  // const [productName, setProductName] = useState();
  // const [productSearchData, setProductSearchData] = useState();
  const { mid, merchantName } = useParams()

  // const [categoryList, setCategoryList] = useState()
  // const [searchData, setSearchData] = useState(initialSearhData)
  console.log(merchantName, "kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk");
  useEffect(() => {
    const breadcrumb = [
      { label: "Merchant Profile", url: "/merchant-center/profiles" },
      { label: merchantName, url: null }
    ];
    dispatch(breadcrumbAction(breadcrumb));
  }, [])




  useEffect(() => {

    getCategory()
  }, [])

  
  const getCategory = () => {
    const response = dispatch(getAllCategoryForSearch())
    response
      .then((data) => {
        if (data?.data?.data) {
          const data1 = data.data.data.map((item) => ({
            label: item.title,
            value: item.id,
          }));
          console.log(data1, "data  data ");
          setCategoryList(data1);
        }
        console.log(data, "jjjjjjjjjjjjjj");
      }).catch((error) => {
        console.log({ error });
      })
  }

  return (
    <React.Fragment>
      <div className="container_body container-fluid">
        <div className="card py-3">
          <Tabs
            value={tabIndex}
            onChange={(e, value) => setTabIndex(value)}
            aria-label="basic tabs example"
            className='mx-3'
            
            
            >
            <Tab label="Shop Setting" {...a11yProps(0)} />
            <Tab label="Shop Rating" {...a11yProps(1)} />
            <Tab label="Finance Info" {...a11yProps(2)} />
            <Tab label="Products" {...a11yProps(3)} />
            <Tab label="Orders" {...a11yProps(4)} />
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
            <ShopSettings />
          </TabPanel>
          <TabPanel value={tabIndex} index={1} >
            <RatingIndex />
          </TabPanel>
          <TabPanel value={tabIndex} index={2} costumStyle={{ padding: "0px" }}>
            <FinanceInfoIndex />
          </TabPanel>
          <TabPanel value={tabIndex} index={3} costumStyle={{ padding: "0px" }}>
            <MerchantAllProduct />
          </TabPanel>
          <TabPanel value={tabIndex} index={4} costumStyle={{ padding: "0px" }}>
            {/* <MerchantOrderListing /> */}
            <OrderListing/>
          </TabPanel>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MerchantProfileSetting;
