import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  IconButton,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import SvgIcon from "../../../../components/Images/SvgIcon";
import PagenationDropdown from "../../../../components/DropDown/pagenationDropdown";
import { getTimeSLotBySlotId } from "../../../../redux/actions/marketing.action";
import {
  open_dialog,
  parseQuery,
  startLoader,
  stopLoader,
} from "../../../../lib/global";
import { getLocalStorage } from "../../../../lib/session";
import {
  ACCESS_DENIED,
  MERCHANT_DELETE_TIMESLOT_DIALOG,
} from "../../../../lib/constant";
import { v2GetTimeSLotBySlotIdByMerchant } from "../../../../redux/actions/merchant/marketing.merchant.action";

const MerchantFlashDealsTimeSlotListing = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [allTimeSlotData, setAllTimeSlotData] = useState([]);
  const [permissions, setPermissions] = useState(
    getLocalStorage("assignMethod")
  );
  const { promoslotid } = parseQuery(history.location.search) || {};
  let filterData = {};

  useEffect(() => {
    if (permissions !== "undefined") {
      setPermissions(JSON.parse(getLocalStorage("assignMethod")));
      console.log(
        JSON.parse(getLocalStorage("assignMethod")),
        "hhhhhhhhhhhhhhhhhhhhhhhhhhhhh"
      );
    } else {
      setPermissions({});
    }
  }, []);

  useEffect(() => {
    console.log(permissions, "riptokun");
    let filterData = {
      merchantId: getLocalStorage("storeId"),
    };
    apiData(promoslotid, filterData);
  }, []);

  const apiDataByDate = (timeSlotDate) => {
    filterData = {
      merchantId: getLocalStorage("storeId"),
      timeSlotDate: timeSlotDate,
    };
    console.log(filterData);
    startLoader();
    // dispatch(getTimeSLotBySlotId(promoslotid, filterData))
    dispatch(v2GetTimeSLotBySlotIdByMerchant(promoslotid, timeSlotDate))
      .then((data) => {
        setAllTimeSlotData(
          data &&
            data.data &&
            data.data.data.map((row, index) => {
              return {
                timeSlotId: row?.timeSlotId || "N/A",
                date: row?.date || "N/A",
                totalProduct: row?.totalProduct || "0",
              };
            })
        );

        setTimeout(() => {
          stopLoader();
        }, 1000);
      })
      .catch((err) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
      });
  };

  const apiData = (promoslotid, filterData) => {
    startLoader();
    dispatch(v2GetTimeSLotBySlotIdByMerchant(promoslotid))
      .then((data) => {
        setAllTimeSlotData(
          data &&
            data.data &&
            data.data.data.map((row, index) => {
              return {
                timeSlotId: row?.timeSlotId || "N/A",
                date: row?.date || "N/A",
                totalProduct: row?.totalProduct || "0",
              };
            })
        );

        setTimeout(() => {
          stopLoader();
        }, 1000);
      })
      .catch((err) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
      });
  };

  const addTimeSlot = (id) => {
    history.push(`product-create?promoslotid=${id}`);
  };

  const getTimeSlotAfterDelete = (data) => {
    setAllTimeSlotData(data.data);
  };

  const deleteAction = (id, item) => {
    if (!item && item !== undefined) {
      open_dialog(ACCESS_DENIED, {});
    } else if (item == "undefined" || item == undefined) {
      open_dialog(MERCHANT_DELETE_TIMESLOT_DIALOG, {
        onSubmit: getTimeSlotAfterDelete,
        id,
        merchantId: getLocalStorage("storeId"),
        promoSlotId: promoslotid,
      });
    } else {
      open_dialog(MERCHANT_DELETE_TIMESLOT_DIALOG, {
        onSubmit: getTimeSlotAfterDelete,
        id,
        merchantId: getLocalStorage("storeId"),
        promoSlotId: promoslotid,
      });
    }
  };

  const editAction = (id, date, item, promoslotid) => {
    if (!item && item !== undefined) {
      open_dialog(ACCESS_DENIED, {});
    } else {
      history.push(
        `/merchant/marketing/promo/flash-deals/edit-time-slot?id=${id}&date=${date}&promoslotid=${promoslotid}`
      );
    }
  };

  const duplicateAction = (id, date, promoslotid) => {
    history.push(
      `/merchant/marketing/promo/flash-deals/duplicate-time-slot?id=${id}&date=${date}&promoslotid=${promoslotid}`
    );
  };

  const showAction = (id, date) => {
    history.push(
      `/merchant/marketing/promo/flash-deals/show-time-slot?id=${id}&date=${date}&promoslotid=${promoslotid}`
    );
  };

  return (
    <React.Fragment>
      <div className="mt-1 mx-3 px-2 py-3 rounded bg-white">
        <div className="col-12 row flex-wrap">
          <div className="min-width-200 col-md-5">
            <label>Date Time Slot</label>
            <input
              type="date"
              className="form-control"
              placeholder="Date Time Slot"
              onChange={(e) => apiDataByDate(e.target.value)}
            />
          </div>
        </div>
      </div>

      <br />
      <div className="container_body container-fluid">
        <div className="card p-3">
          <span>TIME SLOT LISTING</span>
          <div className="d-flex justify-content-end">
            <div className="d-flex my-3">
              <button
                onClick={() => addTimeSlot(promoslotid)}
                type="button"
                className="btn btn-dark btn-sm text-nowrap mx-3"
              >
                + Add Time Slot
              </button>
            </div>
          </div>

          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow>
                  <TableCell className="th_cell" component="th" align="left">
                    <div className="d-flex  ">
                      <div>Time Slot</div>
                      <div className="d-flex flex-column ">
                        <ExpandLessIcon
                          style={{
                            width: "15px",
                            height: "15px",
                            margin: "0px 3px",
                          }}
                          // onClick={() => handleSorting("productName", "asc")}
                        />
                        <ExpandMoreIcon
                          style={{
                            width: "15px",
                            height: "15px",
                            margin: "-5px 3px",
                          }}
                          // onClick={() => handleSorting("productName", "desc")}
                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell className="th_cell" component="th" align="center">
                    <div className="d-flex  ">
                      <div>Product</div>
                      <div className="d-flex flex-column ">
                        <ExpandLessIcon
                          style={{
                            width: "15px",
                            height: "15px",
                            margin: "0px 3px",
                          }}
                          // onClick={() => handleSorting("productType", "asc")}
                          // onClick={() => getApiData({ item: "title", order: "ASC" })}
                        />
                        <ExpandMoreIcon
                          style={{
                            width: "15px",
                            height: "15px",
                            margin: "-5px 3px",
                          }}
                          // onClick={() => handleSorting("productType", "desc")}
                          // onClick={() => getApiData({ item: "title", order: "DESC" })}
                        />
                      </div>
                    </div>
                  </TableCell>

                  <TableCell
                    className="table_action_head"
                    component="th"
                    align="center"
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!allTimeSlotData?.length && (
                  <div className="d-flex justify-content-center">
                    <h3 className="text-muted m-auto">No data found!</h3>
                  </div>
                )}

                {allTimeSlotData &&
                  allTimeSlotData.length > 0 &&
                  allTimeSlotData.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left" className="td_cell">
                        {row.date}
                      </TableCell>
                      <TableCell align="left" className="td_cell">
                        {row.totalProduct}
                      </TableCell>

                      <TableCell align="center" className="td_cell">
                        <IconButton
                          className="pointer"
                          onClick={() =>
                            showAction(row.timeSlotId, row.date, promoslotid)
                          }
                        >
                          <SvgIcon src="/icons/view_icon.svg" alt="view icon" />
                        </IconButton>
                        <IconButton
                          className="pointer"
                          onClick={() =>
                            editAction(
                              row.timeSlotId,
                              row.date,
                              permissions?.product?.includes("update"),
                              promoslotid
                            )
                          }
                        >
                          <SvgIcon src="/icons/edit_icon.svg" alt="view icon" />
                        </IconButton>
                        <IconButton
                          className="pointer"
                          onClick={() =>
                            duplicateAction(
                              row.timeSlotId,
                              row.date,
                              promoslotid
                            )
                          }
                        >
                          <SvgIcon
                            src="/icons/duplicate.svg"
                            alt="Duplicate icon"
                          />
                        </IconButton>
                        <IconButton
                          className="pointer"
                          onClick={() =>
                            deleteAction(
                              row.timeSlotId,
                              permissions?.product?.includes("delete")
                            )
                          }
                        >
                          <SvgIcon
                            src="/icons/delete_icon.svg"
                            alt="delete icon"
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <br />
          <div className="d-flex justify-content-between align-items-center">
            {/*<PagenationDropdown setPageQuant={setPageLimit} />*/}

            {/*<div className="float-right mb-1">*/}
            {/*  <Stack spacing={2}>*/}
            {/*    <Pagination*/}
            {/*      count={Math.ceil((props?.totalItem || 0) / pageLimit)}*/}
            {/*      shape="rounded"*/}
            {/*      size="small"*/}
            {/*      page={pageNo}*/}
            {/*      onChange={handlePageChange}*/}
            {/*    />*/}
            {/*  </Stack>*/}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MerchantFlashDealsTimeSlotListing;
