import React from 'react'
import { useDispatch } from 'react-redux';
import { breadcrumbAction } from '../../../redux/actions/config.action';

const WebBanner = () => {

    const dispatch = useDispatch();

  const breadcrumb = [{ label: "Web-Banner", url: null }];
  dispatch(breadcrumbAction(breadcrumb));

  return (
    <div>WebBanner</div>
  )
}

export default WebBanner