import React, { useState,useEffect,useRef } from "react";
import "../../../../static/css/deleteModal.css";
import CloseIcon from "@mui/icons-material/Close";
import {
  deleteMerchant,
  getAllMerchantListAfterDelete,
} from "../../../../redux/actions/merchant-center.action";
import { useDispatch } from "react-redux";
import { showToast, startLoader, stopLoader } from "../../../../lib/global";
import Image from "../../../../components/Images/Image";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import InputField from "../../../../components/formControl/input/InputField";
import { IOSSwitch } from "../../../../components/switch/IOSSwitch";
import { getAllProductList, getProductsBymerchantId } from "../../../../redux/actions/product.action";

const VoucherCreateProductDialog = (props) => {
  const [allProductDataList, setallProductDataList] = useState();
  const [limit, setLimit] = useState(props.data.productLimit);
  const [selectedProduct,setSelectedProduct] = useState([]);
  const [querySearch, setQuerySearch] = useState({
    order:'ASC',
    statusText:'PUBLISHED',
    limit:20
  });
  const [search, setSearch] = useState();

  const dispatch = useDispatch();
  const listInnerRef = useRef();
  const [currPage, setCurrPage] = useState(0); // storing current page number
  const [prevPage, setPrevPage] = useState(-1); // storing prev page number
  const [lastList, setLastList] = useState(false); // setting a flag to know the last list
  const [countProduct,setCountProduct] = useState(0);

  useEffect(() => {
    getProductList();
  },[])

  const getProductList = () => {
    const merchantCode = props.data.merchantCode

    querySearch.offset = currPage
    querySearch.limit = 9999
    dispatch(getProductsBymerchantId(merchantCode,querySearch))
      .then((response) => {
        if (response?.data?.data) {
          if (!response.data.data.length) {
            setLastList(true);
            return;
          }
          setPrevPage(currPage);
          const data = response.data.data.map((item) => ({
            ...item,
            isActive: true,
            isChecked:false
          }));
          setCountProduct(data.length)
          setallProductDataList(data);
        }
      })
  }

  const onScroll = () => {
    console.log("scrolling")
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setCurrPage(currPage + 1);
      }
    }
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
  }

  const searchProduct = (e) => {
    let temp = querySearch
    temp.search = search
    getProductList()
  }

  const onSubmmit = () => {
    props.onSubmit(selectedProduct)
    props.onClose()
  }

  const onCheckProduct = async (e,productId) => {
    let temp = []
    if(e.target.checked){
      temp = selectedProduct.map((item) => item)
      await allProductDataList.map((item) => {
        if(item.productId === productId){
          temp.push(item)
        }
      })
    }else{
      selectedProduct.map((item) => {
        if (item.productId !== productId) {
          temp.push(item)
        }
      })
    }
    await setSelectedProduct(temp)
  }

  const deleteFun = () => {
    const data = {
      status: "DELETED",
    };
    startLoader();
    const response = dispatch(deleteMerchant(data, props.id));
    response.then((data) => {
      if (data.data.status == 200) {
        const response = dispatch(getAllMerchantListAfterDelete());
        response
          .then((data) => {
            if (data && data.data && data.data.status === 200) {
              setTimeout(() => {
                stopLoader();
                showToast(data.data.message, "success");
                props.onSubmit([...data.data.data]);
                props.onClose();
              }, 1000);
            } else {
              stopLoader();
              showToast("Internal Server Error", "error");
              props.onClose();
            }
          })
          .catch((error) => {
            console.log({ error });
          });
      } else {
        stopLoader();
        showToast("Internal Server Error", "error");
      }
    });
  };
  return (
    <React.Fragment>
      <div>
        <div className="d-flex flex-row align-items-center justify-content-between px-3">
          <p className="mb-0">Add Product</p>
          <button
            onClick={props.onClose}
            className="btn fntSz24 text-secondary"
          >
            x
          </button>
        </div>
        <div className="border-top border-bottom py-2 row">
          <InputField
            className=""
            formClass="ml-2 col-6"
            type="text"
            id="search"
            placeholder="Search"
            name="search"
            value={search}
            onChange={onChangeSearch}
            onEnter={searchProduct}
          />
          <button
            type="button"
            className="btn btn-primary btn-sm p-1 px-3 mx-2"
            onClick={searchProduct}
          >
            Search
          </button>
        </div>
        <div className="border-top border-bottom py-1">
          <div
            style={{ maxHeight: "65vh", overflow: "auto" }}
            className="fntSz14 position-relative px-2"
          >
            <table className="table border-0">
              <thead className="position-sticky" style={{ top: "0" }}>
              <tr className="bg-light rounded-lg text-secondary">
                <th className="border-0">
                  <div
                    style={{ textAlign: "end" }}
                    className="align-middle border-0"
                  >
                    <input type="checkbox" />
                  </div>
                </th>
                <th className="border-0">
                  <div className="d-flex ">
                    <div>Products</div>
                  </div>
                </th>
                <th className="border-0 align-content-start">
                  <div className="d-flex  ">
                    <div>Price</div>
                  </div>
                </th>
                <th className="border-0 align-content-start">
                  <div className="d-flex  ">
                    <div>Stock</div>
                  </div>
                </th>
              </tr>
              </thead>
              <tbody>
              {allProductDataList &&
                allProductDataList.length > 0 &&
                allProductDataList.map((row, index) => (
                  <tr key={row.productId}>
                    <td
                      style={{ textAlign: "end" }}
                      className="align-middle border-0"
                    >
                      <input type="checkbox" disabled={row.totalStock == 0} onClick={(e)=>onCheckProduct(e,row.productId)}/>
                    </td>
                    <td className=" align-middle border-0">
                      <div className="d-flex flex-row align-items-center">
                        <Image
                          src={row.productImages[0]}
                          alt={"photo"}
                          className="flash_sale_product_image mr-1"
                        />
                        <div>{row.title}</div>
                      </div>
                    </td>
                    <td className="border-0 align-content-start">
                      RM{row.lowestPrice?row.lowestPrice.price:row.price}
                    </td>
                    <td className="border-0 align-content-start">
                      {row.totalStock}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-between m-3">
            <div className="text-secondary fntSz12">
              {selectedProduct.length} product selected of {countProduct} products
            </div>
            <div className="">
              <button
                onClick={props.onClose}
                type="button"
                className="btn btn-outline-danger btn-sm p-1 px-3 mx-2"
                // onClick={back}
              >
                Cancel
              </button>
              <button
                // onClick={() => history.push("/marketing/all-promo/flash-sale")}
                type="button"
                className="btn btn-danger btn-sm p-1 px-3 mx-2"
                onClick={onSubmmit}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>

        <style jsx="true">{`
          .card-body {
            min-height: 50vh;
          }
          .submit_btn {
            position: absolute;
            bottom: 15px;
            width: 100%;
            justify-content: center;
            display: flex;
          }
          .close_icon_btn {
            position: absolute;
            right: 0;
            cursor: pointer;
          }
          .flash_sale_product_image {
            width: 45px;
            height: 40px;
            border-radius: 3px;
          }
          input {
            cursor: pointer;
          }
        `}</style>
      </div>
    </React.Fragment>
  );
};

export default VoucherCreateProductDialog;
