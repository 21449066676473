
import React, { useState } from 'react'
import "../../static/css/orderCss/orderInformation.css"
import { useHistory } from "react-router-dom"
const ProductShiping = () => {
    const history = useHistory()
    const backproduct = () => {
        history.push("/all-products")
    }
    const backSalePorduct = () => {
        history.push("/product-sales-information")
    }


    return (
        <React.Fragment>

            <div>
                <div>
                    <div className="d-flex justify-content-center  p-3">
                        <small >SPECIFICATION</small>
                        <hr className="process-line mx-2" />
                        <small className="m-0 p-0 h-0">Sales Information</small>
                        <hr className="process-line process-line-dark mx-2" />
                        <div className="d-flex flex-column align-items-center">
                            <small >Shipping information</small>
                            <p className="process-indicate m-0"></p>
                        </div>
                    </div>
                </div>
                <div className="mr-3 ml-4 rounded  px-3 pt-4 pb-2 bg-white">
                    <h6 >SALES INFORMATION</h6>
                    <form className="mt-3  p-0">
                        <div className="form-group  d-flex ">
                            <label htmlFor="Marchant" className="col-xl-2 p-0 d-flex justify-content-end"><p className="my-0 input-inside-text mr-4">Brand</p></label>
                            <select className="form-select border input-inside-text rounded bg-white col-xl-6 text-muted" id="Marchant">
                                <option selected >Select Merchant</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                        </div>

                        <div className="form-group  d-flex ">
                            <label htmlFor="Marchant" className="col-xl-2 p-0 d-flex justify-content-end"><p className="my-0 input-inside-text mr-4">Ship From</p></label>
                            <select className="form-select border input-inside-text rounded bg-white col-xl-6 text-muted" id="Marchant">
                                <option selected >Select merchant</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                        </div>
                        <div className="form-group  d-flex ">
                            <label className="col-xl-2 p-0 d-flex justify-content-end"><p className="my-0 input-inside-text mr-4">Warranty</p></label>
                            <select className="form-select border input-inside-text rounded bg-white col-xl-6 text-muted" id="Marchant">
                                <option selected >Select merchant</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                        </div>
                    </form>
                </div>
                <div className="mr-3 ml-4 mt-3 rounded  px-3 pt-4 pb-2 bg-white">
                    <h6>SHIPPING INFORMATION</h6>
                    <form className="mt-3  p-0">
                        <div className="form-group d-flex ">
                            <label className="col-xl-2 p-0 d-flex justify-content-end"><p className="my-0 input-inside-text mr-4" >Weight</p></label>
                            <div className="input-group col-xl-6 p-0  rounded">
                                <div className="input-group border d-flex flex-row rounded">
                                    <input type="text" className="input-box input-inside-text w-75 mr-3 border-0" placeholder="Input" />
                                    <span className="input-group-text input-box border-0 px-2 ml-5 bg-white">kg</span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group d-flex   ">
                            <label className="col-xl-2 p-0 d-flex justify-content-end"><p className="my-0 input-inside-text mr-4">parcel Size</p></label>
                            <div className="input-group col-xl-6  p-0  align-items-center rounded">
                                <div className="input-group border col mr-1 px-1 rounded">
                                    <input type="text" className="form-control border-0 input-inside-text" placeholder="W" />
                                    <span className="input-group-text input-inside-text border-0 bg-white">| cm</span>
                                </div>
                                <div className="input-group border col px-1 rounded">
                                    <input type="text" className="form-control input-inside-text border-0" placeholder="L" />
                                    <span className="input-group-text  input-inside-text border-0 bg-white">| cm</span>
                                </div>
                                <div className="input-group border col ml-1 px-1  rounded">
                                    <input type="text" className="form-control input-inside-text border-0" placeholder="H" />
                                    <span className="input-group-text border-0 input-inside-text bg-white">| cm</span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group d-flex   ">
                            <label htmlFor="product-name" className="col-xl-2 p-0 d-flex justify-content-end"><p className="my-0 input-inside-text mr-4">Stock</p></label>
                            <div className="input-group col-xl-6 p-0 m-0 rounded">
                                <span className="input-inside-text mb-2">You can also <a href="#">Click here </a> to enable more shipping channel</span>
                                <div className="m-0 main-body col-xl-12 d-flex flex-column  px-0">
                                    <p className="product-list-tab border p-1 m-0 input-inside-text ">Delivery Free RM0:00</p>
                                    <small className=" px-2 py-2">DHL Express (max 3kg)</small>
                                    <small className=" px-2 pb-2">J&T Express (max 30kg)</small>
                                </div>
                                <small className="input-inside-text mt-2">Shipping settings will be applicable for this product only .Shipping fees displeyed are base rates and will be subject to change depending on buyer and selier location.
                                </small></div>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="disabledFieldsetCheck" />
                            <label className="form-check- input-inside-text" htmlFor="disabledFieldsetCheck">
                                I will cover shipping fees
                            </label>
                        </div>

                    </form>
                </div>
                <div className="mr-3 ml-4 mt-3 rounded  px-3 pt-2  bg-white">
                    <h6 >OTHER</h6>
                    <div className="form-group d-flex ">
                        <label htmlFor="product-name" className="col-xl-2 p-0 d-flex justify-content-end"><p className="my-0 input-inside-text mr-4">Pre-Order</p></label>
                        <div className="input-group col-xl-6 p-0  rounded">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                <label className="form-check-label input-inside-text" htmlFor="inlineRadio1">Yes</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                                <label className="form-check-label input-inside-text" htmlFor="inlineRadio2">No</label>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="d-flex flex-wrap justify-content-end align-items-center py-3 mt-3 bg-white border-top">
                    <div className="col-md-4 d-flex align-items-center mr-2 justify-content-end">
                        <button type="button" className="btn border mr-2 input-inside-text" onClick={() => backproduct()}>Cancel</button>
                        <button type="button" className="btn border mr-2 input-inside-text" onClick={() => backSalePorduct()}>Prev</button>
                        <button type="button" className="btn btn-dark input-inside-text" >Create & Publish</button>
                    </div>
                </footer>
            </div>
        </React.Fragment>

    )
}

export default ProductShiping
