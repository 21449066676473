import { Divider, IconButton } from '@mui/material';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Image from '../../../components/Images/Image';
import SvgIcon from '../../../components/Images/SvgIcon';
import { breadcrumbAction } from '../../../redux/actions/config.action';

const HomepageBanner = () => {
    const history = useHistory();

    const dispatch = useDispatch();
    const [hide, setHide] = useState(true);
    const [imageFiles, setImageFiles] = useState([]);
    const [imageUrls, setImageUrls] = useState([]);

    const handleUploadFile = async (e, index) => {
        const fileData = e.target.files[0];
        if (fileData.size > MAX_IMAGE_SIZE) {
            showToast(
                `Image with size more than ${MAX_IMAGE_SIZE / 1000000
                }MB is not supported.\nPlease upload image of size smaller than ${MAX_IMAGE_SIZE / 1000000
                }MB.\nCurrent file size: ${(fileData.size / 1000000).toFixed(2)}MB`,
                "error",
                3000,
                "bottom",
                "center"
            );
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(fileData);
        reader.onload = function () {
            const fileIns = [...imageUrls];
            console.log(imageUrls);
            const imageUrlIns = [...imageUrls];
            if (fileIns[index]) {
                fileIns[index] = fileData;
                imageUrlIns[index] = reader.result;
            } else {
                fileIns.push(fileData);
                imageUrlIns.push(reader.result);
            }
            setImageFiles(fileIns);
            setImageUrls(imageUrlIns);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };

    const breadcrumb = [
        { label: "App-Banner", url: "/ads/app-banner" },
        { label: "Homepage-Banner", url: null }
    ];
    dispatch(breadcrumbAction(breadcrumb));

    return (
        <div>
            <div className='container-fluid'>
                <div className="px-2 py-3 mb-3 card">
                    <h5>
                        Homepage Banner
                    </h5>
                    <div className="row">
                        <div className="col-3 py-2 text-right">Banner</div>
                        <div className="col-6 bg-light rounded p-3 row mx-0">
                            <div className="py-1 col-2 text-nowrap">Image</div>
                            <div className="icon-button-file col-10 d-flex flex-wrap">
                                {[0].map((item) => (
                                    <div className="mx-2 mb-1">
                                        <input
                                            accept="image/*"
                                            id={`product-image-${item}`}
                                            className="d-none"
                                            onChange={(e) => handleUploadFile(e, item)}
                                            multiple={false}
                                            type="file"
                                        />
                                        <div
                                            className="position-relative"
                                            onMouseEnter={() => setHide(false)}
                                            onMouseLeave={() => setHide(true)}
                                        >
                                            <label
                                                htmlFor={
                                                    imageUrls?.[item] ? "#" : `product-image-${item}`
                                                }
                                            >
                                                <Image
                                                    src={imageUrls?.[item] || "/icons/upload_icon.svg"}
                                                    alt="upload"
                                                    height="100px"
                                                    width="300px"
                                                    className={`pointer`}
                                                />
                                            </label>

                                            {!hide && imageUrls?.[item] && (
                                                <div
                                                    className="b-0 bg-dark text-light opacity-25 position-absolute w-100 d-flex justify-content-between"
                                                    style={{ opacity: "0.7" }}
                                                >
                                                    <IconButton className="pointer">
                                                        <label
                                                            htmlFor={`product-image-${item}`}
                                                            className="mb-0"
                                                        >
                                                            <SvgIcon
                                                                src="/icons/edit_icon.svg"
                                                                alt="view icon"
                                                            />
                                                        </label>
                                                    </IconButton>
                                                    <Divider
                                                        orientation="vertical"
                                                        variant="middle"
                                                        flexItem
                                                        className="bg-light text-light"
                                                    />

                                                    <IconButton
                                                        className="pointer"
                                                        onClick={() => {
                                                            setImageUrls(
                                                                imageUrls.filter((i, index) => index !== item)
                                                            );
                                                            setImageFiles(
                                                                imageUrls.filter((i, index) => index !== item)
                                                            );
                                                        }}
                                                    >
                                                        <SvgIcon
                                                            src="/icons/delete_icon.svg"
                                                            alt="delete icon"
                                                        />
                                                    </IconButton>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                                <div className="text-muted fntSz12">Note: Image size 428x926, maximum file size 2MB</div>
                            </div>
                            <div className='col-12 row mx-0 mt-4'>
                                <div className="col-2 px-0 pt-2 text-nowrap">Link</div>
                                <div className="col-8 px-1">
                                    <input type="text" className='form-control' placeholder='Input'/>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-12 row mx-0 my-2 p-0">
                            <div className="col-3 p-0"></div>
                            <div className="col-6 p-0">
                                <button className="btn border form-control">+ Add More</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="d-flex flex-wrap justify-content-end align-items-center py-3 mt-3 bg-white border-top">
                <div className="col-md-4 d-flex align-items-center mr-2 justify-content-end">
                    <button
                        type="button"
                        className="btn btn-outline-danger px-4 py-1 mr-2"
                        onClick={history.goBack}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn btn-danger mr-2 py-1"
                    // onClick={() => history.push("/content-center/livestream/create-livestream/go-live")}
                    >
                        Save
                    </button>
                </div>
            </footer>
        </div>
    )
}

export default HomepageBanner