import {
  DeleteWithToken,
  GetWithToken,
  PostWithToken,
  PutWithToken,
  DeleteById,
  Get,
  GetWithTokenParams,
  GetWithTokenFile,
  PostExcelWithToken
} from "../../../lib/request";

export const getStoreProducts = (query) => {
  if (query?.categoryName && query.categoryName.includes("&")) {
    query.categoryName = query.categoryName.replace("&", "%26");
  }
  return async function (dispatch) {
    const result = await GetWithToken(
      `/v2/merchant/product?item=${query.item || ""}&order=${query.order || ""
      }&productName=${encodeURIComponent(query?.productName?.trim() || "") || ""
      }&categoryName=${query.categoryName || ""}&search=${query.search || ""
      }&statusText=${query.status || ""}&offset=${query.offset || 0}`
    );

    return result;
  };
};

export const merchantDeleteProduct = (id) => {
  return async function (dispatch) {
    const result = await DeleteWithToken(`/v2/merchant/product/${id}`);
    return result;
  };
};

export const getProductById = (id) => {
  return async function (dispatch) {
    const result = await GetWithToken(`/v2/merchant/product/${id}`);
    return result;
  };
};

export const uploadImportProduct = (data) => {
  return async function (dispatch) {
    const result = await PostExcelWithToken("/v2/merchant/product/excel/import", data);

    console.log(result, "resultttttttttttttttt");

    return result;
  };
};

export const templateImportProduct = (query) => {
  return async function (dispatch) {
    const result = await GetWithTokenFile("/v2/merchant/product/excel/import/template");
    return result;
  };
};

export const getProductsImport = (query) => {
  return async function (dispatch) {
    const result = await GetWithToken(
      `/v2/merchant/product/import/list?item=${query.item || ""}&order=${query.order || ""}&offset=${query.offset || 0}`
    );

    return result;
  };
};

export const downloadCategoryProduct = (query) => {
  return async function (dispatch) {
    const result = await GetWithTokenFile("/v2/merchant/product/excel/import/category");
    return result;
  };
};

export const downloadBrandProduct = (query) => {
  return async function (dispatch) {
    const result = await GetWithTokenFile("/v2/merchant/product/excel/import/brand");
    return result;
  };
};
