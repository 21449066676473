import React, {  useRef, useState } from 'react';
import SendBird from 'sendbird';
import { SendBirdProvider as SBProvider } from "sendbird-uikit";
import { getLocalStorage } from '../../lib/session';
import { SENDBIRD_API_KEY } from '../../lib/config';
import CustomizedApp from './CustomizedApp';
import { CHAT_LIST_MERCHANT } from '../../lib/constant';
import { open_dialog } from '../../lib/global';
import { useDispatch } from "react-redux";
import { addUser, getById, updateUser } from '../../redux/actions/sendbird.action';
import './hidden-button-sendbird.css';
import { breadcrumbAction } from '../../redux/actions/config.action';

const name     = getLocalStorage('userName')
const userId   = getLocalStorage('storeId')?getLocalStorage('storeId'):getLocalStorage('uid');
const appId    = SENDBIRD_API_KEY;
const customType = 'MERCHANT_SUPERADMIN';
const breadcrumb = [
  { label: "Dashboard", url: "/dashboard" },
  { label: "Chat To Merchant", url: null }
];

const AdminToMerchant = () => {
  const dispatch = useDispatch();
  const [activeChannel, setActiveChannel] = useState(null);
  dispatch(breadcrumbAction(breadcrumb));
  const handleStartChat = () => {
    open_dialog(CHAT_LIST_MERCHANT, {
      onSubmit: startChatSuccess,
      fullWidth: true,
      width: 'md',
    });
  };

  const startChatSuccess = async (data) => {
    // console.log(data)
    let merchantName = data.preferredMerchantName || data.merchantName
    startChat(data.storeId,merchantName)
  }

  const isEmpty = (value) => {
    return value === undefined || value === null || value === '';
  }

  const checkIsUserExists = async (userId,nickname) => {
    try {
      return dispatch(getById(userId)).then((res) => {
        let data = res.data.data
        if(data){
          if(!isEmpty(nickname) && data.nickname != nickname){
            changeUsername(userId,nickname)
          }
          return true
        }
        return false
      })
    } catch (error) {
      console.log(error)
    }
  }

  const changeUsername = async (userId,nickname) => {
    try {
      let payload = {
        "user_id": userId,
        "nickname": nickname
      }
      return await updateUser(payload)
    }
    catch (error) {
      console.log(error)
    }
  }

  const createUser = async (storeId,merchantName) => {
    try {
      let payload = {
        "user_id": storeId,
        "nickname": merchantName,
        "profile_url": "https://static.sendbird.com/sample/profiles/profile_12_512px.png"
      }
      return dispatch(addUser(payload))
    }
    catch (error) {
      console.log(error)
    }
  }

  const startChat = async (userTargetId,userTargetName="") => {
    try {
      const sb = new SendBird({ appId: appId });
      await sb.connect(userId, (user, error) => {
        if (error) {
          console.error('Failed to connect with Sendbird:', error);
        } else {
          console.log('Success connect to sendbird Sendbird:', user)
        }
      });
      let isExists = await checkIsUserExists(userTargetId,userTargetName)
      if(!isExists){
        await createUser(userTargetId,userTargetName)
      }
      const channelParams = new sb.GroupChannelParams();
      channelParams.isDistinct = true;
      channelParams.customType = customType;
      channelParams.addUserIds([userId,userTargetId]);

      const channel = await sb.GroupChannel.createChannel(channelParams);
      setActiveChannel(channel)
      console.log('Success Create Channel:', channel);
    } catch (error) {
      console.error('Failed Create Channel:', error);
    }
  };

  return (
    <div className="app-wrapper">
      <button onClick={handleStartChat} className='btn btn-dark btn-md text-nowrap mx-3 mb-3'>Start Chat With Merchant</button>
      <SBProvider appId={appId} userId={userId} nickname={name}>
        <CustomizedApp customTypesFilter={[customType]} activeChannel={activeChannel} />
      </SBProvider>
    </div>
  );
};

export default AdminToMerchant;
