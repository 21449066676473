import { GetWithToken, PatchWithToken, PostWithToken } from "../../lib/request";

export const getAllConfig = () => {
  return async function (dispatch) {
    return await GetWithToken(`/configs`);
  };
};

export const getConfigByKey = (key) => {
  return async function (dispatch) {
    return await GetWithToken(`/configs/${key}`);
  };
};

export const createNewConfig = (data) => {
  return async function (dispatch) {
    return await PostWithToken("/configs", data);
  };
};

export const updateConfigByKey = (data, key) => {
  return async function (dispatch) {
    return await PatchWithToken(`/configs/${key}`, data);
  };
};
