import DashboardPage from "./pages/dashboard";

import Banner from "./pages/banner";
import AddBanner from "./pages/banner/AddBanner";

import ProductOverview from "./pages/products/Overview";
import {
  default as Performance,
  default as ProductPerformance,
} from "./pages/products/Performance";
import Product from "./pages/products/productListing";
import AddProduct from "./pages/products/add-product";
import ProductBrand from "./pages/products/brands";
import AddBrandPage from "./pages/products/brands/AddBrand";
import ProductCategory from "./pages/products/categories";
import ProductAddCategory from "./pages/products/categories/ProductAddCategory";
import ProductSalesInformation from "./pages/products/ProductSalesInformation";
import ProductShiping from "./pages/products/ProductShiping";
import ProductSubCategory from "./pages/products/categories/SubCategory";
import ProductListing from "./merchant-pages/products/productListing";
import MerchantAddProduct from "./merchant-pages/products/add-product";
import MerchantEditProduct from "./merchant-pages/products/edit-product";
import ProductImport from "./merchant-pages/products/productImport";

import Sales_promotions from "./merchant-pages/Promotion/sales-promotion";
import MerchantProfile from "./merchant-pages/profile";
import FinanceInfoIndex from "./merchant-pages/finance/myIncome/index";
import AddConsumerAccount from "./pages/consumer-center/add-consumer";
import ConsumerCenterAccount from "./pages/consumer-center/consumer-account-listing";
import ConsumerCenterOverview from "./pages/consumer-center/consumer-center-overview/ConsumerCenterOverview";
import NotFoundView from "./pages/errors/NotFoundView";
import FAQ from "./pages/FAQ";
import LanguagePage from "./pages/language";
import LoginPage from "./pages/login";
import AddMerchantAccount from "./pages/merchant-centre/add-merchant";
import MerchantProfiles from "./pages/merchant-centre/merchant-profile";
import MerchantProfileSetting from "./pages/merchant-centre/merchant-profile/MerchantProfileSetting";
import MerchantAccount from "./pages/merchant-centre/merchant-account";
// import MerchantAccount from "./pages/MechantCenter/MerchantAccount";
// import ProductAddCategory  from "./pages/products/ProductAddCategory";
import MerchantBroadcastManagement from "./pages/merchant-centre/merchnatBroadcastListing";
import MerchantSendBroadcastMsg from "./pages/merchant-centre/merchnatBroadcastListing/MerchantSendBroadcastMsg";
import MerchantsHelpCenter from "./pages/merchant-centre/help-center";
// import MerchantAction from "./pages/merchant-centre/Merchant_Action";
import MerchantReport from "./pages/merchant-centre/merchnat-report/Merchant_Report";
import MetatagsPage from "./pages/metatags";
// import AllOrders from "./pages/order/AllOrders";
// import Cancellation from "./pages/order/Cancellation";
import Overview from "./pages/order/Overview";
import Refund from "./pages/order/ReturnAndRefund.jsx";
import Redemption from "./pages/order/Redemption";
// import ReturnOrder from "./pages/order/ReturnOrder";
import ViewOrder from "./pages/order/Order_Listing/ViewOrder";
import ViewRequestQuotation from "./pages/order/RequestQuotation/Detail.jsx";
import MerchantViewRequestQuotation from "./merchant-pages/order/RequestQuotation/Detail.jsx";
import TPRetailViewPage from "./pages/order/TeamPurchase/TPRetailViewPage";
import ViewCancelOrder from "./pages/order/CancelOrder/ViewCancelOrder";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ReviewsPage from "./pages/reviews";
import TermsAndConditions from "./pages/TermsAndConditions";
import UsersPage from "./pages/users";
import MerchantAccountSetting from "./merchant-pages/account";

import MerchantMerketingAllPromo from "./merchant-pages/marketing/all-promo";
import MerchantFlashDealsListing from "./merchant-pages/marketing/all-promo/flash-deals/MerchantFlashDealsListing";
import MerchantFlashDealsProductCreate from "./merchant-pages/marketing/all-promo/flash-deals/MerchantFlashDealsProductCreate";
import MerchantFlashDealsTimeSlotListing from "./merchant-pages/marketing/all-promo/flash-deals/MerchantFlashDealsTimeSlotListing";
import MerchantFlashDealsTimeSlotEdit from "./merchant-pages/marketing/all-promo/flash-deals/edit-timeslot";
import MerchantFlashDealsTimeSlotDuplicate from "./merchant-pages/marketing/all-promo/flash-deals/duplicate-timeslot";
import MerchantFlashDealsTimeSlotShow from "./merchant-pages/marketing/all-promo/flash-deals/show-timeslot";
import MerchantLiveStreamCenter from "./merchant-pages/marketing/all-promo/livestream/LiveStream";
import MerchantCreateLiveStream from "./merchant-pages/marketing/all-promo/livestream/createlivestream/CreateLiveSttream";
import MerchantContentGoLive from "./merchant-pages/marketing/all-promo/livestream/golive/ContentGoLive";
import MerchantMarketingVoucherListing from "./merchant-pages/marketing/all-promo/voucher/VoucherListing";
import MerchantMarketingVoucherCreate from "./merchant-pages/marketing/all-promo/voucher/VoucherCreate";
import MerchantGroupDealsListing from "./merchant-pages/marketing/all-promo/group-deals/group-deals/GroupDealsListing";
import MerchantGroupDealsProductListing from "./merchant-pages/marketing/all-promo/group-deals/group-deals-product/listing/GroupDealsProductListing";
import MerchantGroupDealsProductEdit from "./merchant-pages/marketing/all-promo/group-deals/group-deals-product/edit/GroupDealsProductEdit";
import MerchantGroupDealsProductDetail from "./merchant-pages/marketing/all-promo/group-deals/group-deals-product/detail/GroupDealsProductDetail";
import MerchantGroupDealsProductCreate from "./merchant-pages/marketing/all-promo/group-deals/group-deals-product/create/GroupDealsProductCreate";
import MerchantChat from "./merchant-pages/sendbird/chat";
import MerchantChatToAdmin from "./merchant-pages/sendbird/chat/MerchantToAdmin";
import MerchantChatToCustomer from "./merchant-pages/sendbird/chat/MerchantToCustomer";

import OrderListing from "./pages/order/Order_Listing";
import AddUserRole from "./pages/user-role/add-user-role";
import EditUserRole from "./pages/user-role/edit-user-role";
import ChangesPassword from "./pages/changs-password";
import ListUserRole from "./pages/user-role/listing-user-role";
import MerchantOrderListing from "./merchant-pages/order/Order_Listing";
import ReturnOverView from "./pages/order/ReturnAndRefund.jsx/ReturnOverView";
import MerketingOverview from "./pages/marketing/overview";
import MerketingMerchantVoucher from "./pages/marketing/merchant-voucher";
import MerketingCalendar from "./pages/marketing/calender";
import MerketingVoucherCategory from "./pages/marketing/voucher-category";
import MerchantViewOrder from "./pages/merchant-centre/merchant-profile/orders/merchantViewOrder";

import MerketingAllPromo from "./pages/marketing/all-promo";
import FlashSaleListing from "./pages/marketing/all-promo/FlashSaleListing/FlashSaleListing";
import FlashSaleSetting from "./pages/marketing/all-promo/FlashSaleSetting";
import FlashSaleParticipateOverview from "./pages/marketing/all-promo/FlashSaleListing/FlashSaleParticipateOverview";
import FlashSaleProductInfo from "./pages/marketing/all-promo/FlashSaleListing/FlashSaleProductInfo";
import LiveStreamCenter from "./pages/marketing/all-promo/livestream/LiveStream";
import CreateLiveStream from "./pages/marketing/all-promo/livestream/createlivestream/CreateLiveStream";
import ContentGoLive from "./pages/marketing/all-promo/livestream/golive/ContentGoLive";
import GroupDealsListing from "./pages/marketing/all-promo/group-deals/group-deals/GroupDealsListing";
import GroupDealsCreate from "./pages/marketing/all-promo/group-deals/group-deals/GroupDealsCreate";
import GroupDealsDetail from "./pages/marketing/all-promo/group-deals/group-deals/GroupDealsDetail";
import GroupDealsProduct from "./pages/marketing/all-promo/group-deals/group-deals-product/GroupDealsProduct";

import MarketingVoucherListing from "./pages/marketing/all-promo/voucher/VoucherListing";
import MarketingVoucherCreate from "./pages/marketing/all-promo/voucher/VoucherCreate";
import MarketingVoucherMerchantListing from "./pages/marketing/all-promo/voucher-merchant/VoucherListing";
import MarketingVoucherMerchantCreate from "./pages/marketing/all-promo/voucher-merchant/VoucherCreate";

import FinanceOrderReceiptIndex from "./pages/finance/order-receipt";
import ViewOrderAndReceiptInfo from "./pages/finance/order-receipt/ViewOrderInfo";
import RetailTpInfo from "./pages/finance/order-receipt/retail-TP/RetailTpInfo";
import ReturnRefundIndex from "./pages/finance/return-refund";
import RequestRefundInfo from "./pages/finance/return-refund/RequestRefundInfo";
import RefundChatLog from "./pages/finance/return-refund/RefundChatLog";
import HelpChatLog from "./pages/merchant-centre/help-center/HelpChatLog";
import Consumer_Account from "./merchant-pages/Promotion/add-consumer/ConsumerAccount";
import FinanceUploadCashbooks from "./pages/finance/upload-cashbooks";

import EditProduct from "./pages/products/edit-product";
import ViewProduct from "./pages/products/view-product";
import ViewMerchantAccount from "./pages/merchant-centre/merchant-account/view-merchant-account";

import FinanceOverview from "./pages/finance/overview/index";
import Voucher_listing from "./merchant-pages/Promotion/Voucher listing/voucher_listing";

import MyBalance from "./merchant-pages/finance/myBalance";
import MyBankAccount from "./merchant-pages/finance/myBankAccount";
import MyIncome from "./merchant-pages/finance/myIncome";
import DetailMerchantIncome from "./merchant-pages/finance/myIncome/MerchantIncome/detail";

import HelpCenter from "./merchant-pages/help-center";
import CreateNewTicket from "./merchant-pages/help-center/createNewTicket";

import TeamPurchase from "./pages/order/TeamPurchase";

import ChatLog from "./pages/order/ReturnAndRefund.jsx/ChatLog";

import ForgetPasswordRest from "./pages/login/ForgetPasswordRest";
import MerchantTeamPurchase from "./merchant-pages/order/TeamPurchase/MerchantTeamPurchaseRetail";
import MerchantReturnOrder from "./merchant-pages/order/MerchantReturnOrder";
import ConsumerForgetPasswordRest from "./pages/login/ConsumerForgetPasswordRest";
import Notifications from "./merchant-pages/notification";
import AppBanner from "./pages/ads/app-banner/AppBanner";
import WebBanner from "./pages/ads/web-banner/WebBanner";
import HomepageBanner from "./pages/ads/app-banner/HomepageBanner";
import dashboardReport from "./pages/dashboard/dashboardReport";
import overviewProduct from "./pages/products/overviewProduct";
import OtpSetting from "./pages/setting-center/otp-setting";
import appVersion from "./pages/setting-center/app-version";
import AppVersionForm from "./pages/setting-center/app-version/form";

import AdminToCustomer from "./pages/chat/AdminToCustomer";
import AdminToMerchant from "./pages/chat/AdminToMerchant";
import Configs from "./pages/configs";
import AddConfig from "./pages/configs/AddConfig";

const PublicRoutes = [
  {
    path: "/auth",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/change-password/:id",
    name: "/change-password",
    component: ChangesPassword,
  },
  {
    path: "/forget-password/:id/:token",
    name: "/forget-password",
    component: ForgetPasswordRest,
  },
  {
    path: "/consumer/forget-password/:id/:token",
    name: "/consumer-forget-password",
    component: ConsumerForgetPasswordRest,
  },
];

const MerchantRoutes = [
  // {
  //   path: "/merchant/dashboard",
  //   name: "Dashboard",
  //   component: DashboardPage,
  // },
  {
    path: "/merchant/dashboard",
    name: "dashboard-report",
    component: dashboardReport,
  },
  // {
  //   path: "/merchant/dashboard/sales",
  //   name: "dashboard-report",
  //   component: dashboardReport,
  // },
  // {
  //   path: "/merchant/dashboard/products",
  //   name: "dashboard-report",
  //   component: dashboardReport,
  // },
  // {
  //   path: "/merchant/dashboard/traffic",
  //   name: "dashboard-report",
  //   component: dashboardReport,
  // },
  // {
  //   path: "/merchant/dashboard/marketing",
  //   name: "dashboard-report",
  //   component: dashboardReport,
  // },
  // {
  //   path: "/merchant/dashboard/livestream",
  //   name: "dashboard-report",
  //   component: dashboardReport,
  // },
  {
    path: "/merchant/products",
    name: "products",
    component: ProductListing,
  },
  {
    path: "/merchant/edit-product/:id?/:merchantId?/:merchantName?",
    name: "edit-products",
    component: MerchantEditProduct,
  },
  {
    path: "/merchant/add-product/:merchantId?/:merchantName?",
    name: "add product",
    component: MerchantAddProduct,
  },
  {
    path: "/merchant/products-import",
    name: "products-import",
    component: ProductImport,
  },
  // {
  //   path: "/merchant/orders",
  //   name: "order",
  //   component: MerchantOrderListing,
  // },

  // {
  //   title: 'Overview',
  //   path: '/merchant/orders/overview',
  //   conponent:Overview
  // },
  {
    title: "Buy Now",
    path: "/merchant/orders/all-orders",
    component: MerchantOrderListing,
  },
  // {
  //   title: "Team Purchase",
  //   path: "/merchant/orders/team-purchase",
  //   component: TeamPurchase,
  //   // component:MerchantTeamPurchase
  // },
  {
    title: "Return/Refund",
    path: "/merchant/orders/return-and-refund",
    component: Refund,
    // component:MerchantReturnOrder
  },
  {
    path: "/merchant/orders/redemption",
    name: "Redemption",
    component: Redemption,
  },
  {
    path: "/merchant/request-quotation/:id",
    name: "View-Request-Quotation",
    component: MerchantViewRequestQuotation,
  },
  {
    path: "/merchant/notification",
    name: "Merchant Notification",
    component: Notifications,
  },

  {
    path: "/merchant/account",
    name: "Merchant Account",
    component: MerchantAccountSetting,
  },
  {
    path: "/merchant/profile",
    name: "Merchant Profile",
    component: MerchantProfile,
  },

  // merchant finance

  {
    path: "/merchant/finance/my-income",
    name: "My Income",
    component: MyIncome,
  },
  {
    path: "/merchant/finance/my-balance",
    name: "My Balance",
    component: MyBalance,
  },
  {
    path: "/merchant/finance/my-bank-account",
    name: "My Bank Account",
    component: MyBankAccount,
  },

  //  Merchant Help Center

  {
    path: "/merchant/help-center",
    name: "Help Center",
    component: HelpCenter,
  },

  {
    path: "/merchant/help-center/create-new-ticket",
    name: "Help Center",
    component: CreateNewTicket,
  },
];

const SettingRoutes = [
  {
    path: "/settings/otp-setting",
    name: "OTP Setting",
    component: OtpSetting,
  },
  {
    path: "/settings/app-version",
    name: "App Version",
    component: appVersion,
  },
  {
    path: "/settings/app-version/add",
    name: "App Version",
    component: AppVersionForm,
  },
  {
    path: "/settings/app-version/edit",
    name: "App Version",
    component: AppVersionForm,
  },
];

const PrivateRoutes = [
  {
    path: "/banner",
    name: "all banner",
    component: Banner,
  },
  {
    path: "/banners/add-banner",
    name: "add banner",
    component: AddBanner,
  },
  {
    path: "/banners/edit-banner/:bannerId",
    name: "edit banner",
    component: AddBanner,
  },
  {
    path: "/config",
    name: "index config",
    component: Configs,
  },
  {
    path: "/merchant-center/add-product/:mid/:merchantName",
    name: "add product",
    component: MerchantAddProduct,
  },

  {
    path: "/merchant-report",
    name: "Merchant Report",
    component: MerchantReport,
  },

  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardPage,
  },
  {
    path: "/brand-desk",
    name: "Brand Desk",
    component: AddBrandPage,
  },
  {
    path: "/brand-desk/:id",
    name: "Edit-Brand Desk",
    component: AddBrandPage,
  },
  {
    path: "/products/add-category",
    name: "product add category",
    component: ProductAddCategory,
  },
  {
    path: "/products/edit-category/:id",
    name: "product edit category",
    component: ProductAddCategory,
  },
  // {
  //   path: "/products/delete-category/:id",
  //   name: "product delete category",
  //   component: ProductDeleteCategoryModal,
  // },
  {
    path: "/users",
    name: "Users",
    component: UsersPage,
  },
  {
    path: "/products/add-product",
    name: " Add Products",
    component: AddProduct,
  },
  {
    path: "/products/edit",
    name: " Edit Products",
    component: EditProduct,
  },
  {
    path: "/products/view",
    name: " View Products",
    component: ViewProduct,
  },
  {
    path: "/products/overview",
    name: "Overview",
    component: overviewProduct,
  },
  {
    path: "/products/performance",
    name: "Performance",
    component: ProductPerformance,
  },
  {
    path: "/products/all-products",
    name: "Products",
    component: Product,
  },
  {
    path: "/products/brands",
    name: "Product Brand",
    component: ProductBrand,
  },
  {
    path: "/product-sales-information",
    name: "Product  Sale Information",
    component: ProductSalesInformation,
  },
  {
    path: "/shipping-information",
    name: "Product Shipping Information",
    component: ProductShiping,
  },

  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardPage,
  },
  {
    path: "/language",
    name: "Languages",
    component: LanguagePage,
  },

  // {
  //   path: "/merchant-center/merchant-action",
  //   name: "Merchant Action",
  //   component: MerchantAction,
  // },

  // merchant
  {
    path: "/merchant-center/merchant-report",
    name: "Merchant Report",
    component: MerchantReport,
  },
  {
    path: "/merchant-center/merchant-account",
    name: "Merchant Account",
    component: MerchantAccount,
  },
  {
    path: "/merchant-center/add-merchant-account",
    name: " Add Merchant Account",
    component: AddMerchantAccount,
  },

  {
    path: "/merchant-center/edit-merchant-account/:id",
    name: " Add Merchant Account",
    component: AddMerchantAccount,
  },
  {
    path: "/merchant-center/view-merchant-account/:id",
    name: " View Merchant Account",
    component: ViewMerchantAccount,
  },
  {
    path: "/merchant-center/profiles",
    name: "Merchant Profiles",
    component: MerchantProfiles,
  },
  {
    path: "/merchant-center/profiles/:mid/:merchantName",
    name: "Merchant profile setting",
    component: MerchantProfileSetting,
  },
  {
    path: "/merchant-center/profiles/order/:mid/:merchantName/:id",
    name: "Merchant profile setting",
    component: MerchantViewOrder,
  },
  // {
  //   path: "/merchant-center/merchant-broadcast-management",
  //   name: "Broadcast Management",
  //   component: MerchantBroadcastManagement,
  // },

  {
    path: "/merchant-send-broadcast-massage",
    name: "Send Broadcast Management",
    component: MerchantSendBroadcastMsg,
  },
  // {
  //   path: "/merchant-center/merchant-help-center",
  //   name: "Help Center",
  //   component: MerchantsHelpCenter,
  // },
  {
    path: "/merchant-center/merchant-help-center/chat-log",
    name: "Chat Log",
    component: HelpChatLog,
  },

  {
    path: "/merchant/promotion/sales_promotion",
    name: "Sales promotion",
    component: Sales_promotions,
  },

  {
    path: "/merchant/promotion/voucher-listing/voucher_listing",
    name: "voucher listing",
    component: Voucher_listing,
  },

  {
    path: "/merchant/promotion/Consumer-account/consumer-accoungt",
    name: "Consumer Account",
    component: Consumer_Account,
  },
  {
    path: "/merchant/marketing/promo",
    name: "Merchant Marketing All Promo",
    component: MerchantMerketingAllPromo,
  },
  {
    path: "/merchant/marketing/promo/flash-deals",
    name: "Merchant Marketing Flash Deals Listing",
    component: MerchantFlashDealsListing,
  },
  {
    path: "/merchant/marketing/promo/flash-deals/product-create",
    name: "Merchant Marketing Flash Deals Product Create",
    component: MerchantFlashDealsProductCreate,
  },
  {
    path: "/merchant/marketing/promo/flash-deals/time-slot-listing",
    name: "Merchant Marketing Flash Deals Time Slot Listing",
    component: MerchantFlashDealsTimeSlotListing,
  },
  {
    path: "/merchant/marketing/promo/flash-deals/edit-time-slot",
    name: "Merchant Marketing Flash Deals Time Slot Edit",
    component: MerchantFlashDealsTimeSlotEdit,
  },
  {
    path: "/merchant/marketing/promo/flash-deals/duplicate-time-slot",
    name: "Merchant Marketing Flash Deals Time Slot Duplicate",
    component: MerchantFlashDealsTimeSlotDuplicate,
  },
  {
    path: "/merchant/marketing/promo/flash-deals/show-time-slot",
    name: "Merchant Marketing Flash Deals Time Slot Show",
    component: MerchantFlashDealsTimeSlotShow,
  },
  {
    path: "/merchant/marketing/promo/livestream",
    name: "Livestream",
    component: MerchantLiveStreamCenter,
  },
  {
    path: "/merchant/marketing/promo/livestream/create",
    name: "Create Livestream",
    component: MerchantCreateLiveStream,
  },
  {
    path: "/merchant/marketing/promo/livestream/edit/:liveStreamId",
    name: "Edit Livestream",
    component: MerchantCreateLiveStream,
  },
  {
    path: "/merchant/marketing/promo/livestream/go-live",
    name: "Go Live",
    component: MerchantContentGoLive,
  },
  {
    path: "/merchant/marketing/promo/voucher",
    name: "Voucher",
    component: MerchantMarketingVoucherListing,
  },
  {
    path: "/merchant/marketing/promo/voucher/create",
    name: "Create Voucher",
    component: MerchantMarketingVoucherCreate,
  },
  {
    path: "/merchant/marketing/promo/voucher/edit/:voucherId",
    name: "Edit Voucher",
    component: MerchantMarketingVoucherCreate,
  },
  {
    path: "/merchant/marketing/promo/group-deals",
    name: "Merchant All Group Deals",
    component: MerchantGroupDealsListing,
  },
  {
    path: "/merchant/marketing/promo/group-deals-product",
    name: "Merchant All Group Deals Product",
    component: MerchantGroupDealsProductListing,
  },
  {
    path: "/merchant/marketing/promo/group-deals-product/edit/:id",
    name: "Merchant Edit Group Deals Product",
    component: MerchantGroupDealsProductEdit,
  },
  {
    path: "/merchant/marketing/promo/group-deals-product/detail/:id",
    name: "Merchant Detail Group Deals Product",
    component: MerchantGroupDealsProductDetail,
  },
  {
    path: "/merchant/marketing/promo/group-deals-product/create",
    name: "Merchant Create Group Deals Product",
    component: MerchantGroupDealsProductCreate,
  },
  {
    path: "/merchant/chat",
    name: "Merchant Chat",
    component: MerchantChat,
  },
  {
    path: "/merchant/chat/merchant-to-admin",
    name: "Merchant Chat To Admin",
    component: MerchantChatToAdmin,
  },
  {
    path: "/merchant/chat/merchant-to-customer",
    name: "Merchant Chat To Customer",
    component: MerchantChatToCustomer,
  },
  {
    path: "/chat/admin-to-merchant",
    name: "Admin Chat To Merchant",
    component: AdminToMerchant,
  },
  {
    path: "/chat/admin-to-customer",
    name: "Admin Chat To Customer",
    component: AdminToCustomer,
  },

  // consumer
  {
    path: "/consumer-center/overview",
    name: "Overview",
    component: ConsumerCenterOverview,
  },
  {
    path: "/consumer-center/account",
    name: "Customer Account",
    component: ConsumerCenterAccount,
  },
  {
    path: "/consumer-center/add-consumer",
    name: "Add Consumer",
    component: AddConsumerAccount,
  },
  {
    path: "/consumer-center/edit-consumer/:id",
    name: "Edit Consumer",
    component: AddConsumerAccount,
  },

  {
    path: "/users",
    name: "Users",
    component: UsersPage,
  },
  // product
  // {
  //   path: "/products",
  //   name: "Products",
  //   component: ProductsPage,
  // },
  {
    path: "/products-performance",
    name: "Performance",
    component: Performance,
  },
  {
    path: "/products/categories",
    name: "Categories",
    component: ProductCategory,
  },
  {
    path: "/products/categories/:id",
    name: "Sub Categories",
    component: ProductSubCategory,
  },
  {
    path: "/review-and-ratings",
    name: "Ratings & Reviews",
    component: ReviewsPage,
  },
  // order
  {
    path: "/orders/overview",
    name: "Overview",
    component: Overview,
  },

  {
    path: "/orders/view-order/:id?/:merchantId?/:merchantName?",
    name: "View-Order",
    component: ViewOrder,
  },
  {
    path: "/merchant/finance/my-income/view-order/:id/:storeId",
    name: "Merchant-Panel-View-Order",
    component: ViewOrder,
  },
  {
    path: "/merchant/finance/my-income/view-to-settle/:id/:storeId",
    name: "Merchant-Panel-View-Order",
    component: DetailMerchantIncome,
  },
  {
    path: "/order/view-team-purchase-retail/:id",
    name: "View-Team-Purchase-Retail",
    component: TPRetailViewPage,
  },
  {
    path: "/orders/view-cancel-order/:id?/:productId?",
    name: "View-Order",
    component: ViewCancelOrder,
  },
  {
    path: "/request-quotation/:id",
    name: "View-Request-Quotation",
    component: ViewRequestQuotation,
  },
  // {
  //   path: "/orders/return-order",
  //   name: "Return-Order",
  //   component: ReturnOrder,
  // },
  {
    path: "/orders/team-purchase",
    name: "team-purchase",
    component: TeamPurchase,
  },
  {
    path: "/orders/all-orders",
    name: "All Orders",
    // component: AllOrders,
    component: OrderListing,
  },
  {
    path: "/order/return-refund/chat-log",
    name: "Chat Log",
    component: ChatLog,
  },
  {
    path: "/orders/return-and-refund",
    name: "Return/Refund",
    component: Refund,
  },
  {
    path: "/orders/redemption",
    name: "Redemption",
    component: Redemption,
  },
  {
    path: "/orders/return-and-refund/:id",
    name: "order Refund view",
    component: ReturnOverView,
  },

  // ads

  {
    path: "/ads/app-banner",
    name: "App Banner",
    component: AppBanner,
  },
  {
    path: "/ads/web-banner",
    name: "Web Banner",
    component: WebBanner,
  },
  {
    path: "/ads/app-banner/homepage-banner",
    name: "Homepage Banner",
    component: HomepageBanner,
  },

  {
    path: "/orders/return-and-refund/:id",
    name: "order Refund view",
    component: ReturnOverView,
  },

  // finance
  {
    path: "/finance/overview",
    name: "Overview",
    component: FinanceOverview,
  },
  {
    path: "/finance/order-receipt/view-order/:id",
    name: "Order & Receipt Order ID",
    component: ViewOrderAndReceiptInfo,
  },
  {
    path: "/finance/order-receipt",
    name: "Order & Receipt",
    component: FinanceOrderReceiptIndex,
  },
  {
    path: "/finance/order-receipt/group-id/:id",
    name: "Order & Receipt Group ID",
    component: RetailTpInfo,
  },
  {
    path: "/finance/return-refund",
    name: "Return/Refund",
    component: ReturnRefundIndex,
  },
  {
    path: "/finance/return-refund/refund-request/:id",
    name: "Return/Refund Order ID",
    component: RequestRefundInfo,
  },
  {
    path: "/finance/return-refund/refund-request/chat-log",
    name: "Return/Refund Order ID Chat Log",
    component: RefundChatLog,
  },
  {
    path: "/finance/upload-cashbooks",
    name: "Upload-Cashbooks",
    component: FinanceUploadCashbooks,
  },

  // marketing
  {
    path: "/marketing/overview",
    name: "Overview",
    component: MerketingOverview,
  },
  {
    path: "/marketing/all-promo",
    name: "All Promo",
    component: MerketingAllPromo,
  },
  {
    path: "/marketing/all-promo/flash-sale",
    name: "All Promo Flash Sale",
    component: FlashSaleListing,
  },
  {
    path: "/marketing/all-promo/flash-sale/participate-overview/listing-product",
    name: "All Product Promo Flash Sale Paricipate Overview Merchant",
    component: FlashSaleProductInfo,
  },
  {
    path: "/marketing/all-promo/flash-sale/participate-overview",
    name: "All Promo Flash Sale Paricipate Overview",
    component: FlashSaleParticipateOverview,
  },
  {
    path: "/marketing/all-promo/flash-sale-setting",
    name: "All Promo Flash Sale Setting",
    component: FlashSaleSetting,
  },
  {
    path: "/marketing/all-promo/livestream",
    name: "Livestream",
    component: LiveStreamCenter,
  },
  {
    path: "/marketing/all-promo/livestream/create",
    name: "Create Livestream",
    component: CreateLiveStream,
  },
  {
    path: "/marketing/all-promo/livestream/edit/:liveStreamId",
    name: "Edit Livestream",
    component: CreateLiveStream,
  },
  {
    path: "/marketing/all-promo/livestream/go-live",
    name: "Go Live",
    component: ContentGoLive,
  },
  {
    path: "/marketing/all-promo/voucher",
    name: "Voucher",
    component: MarketingVoucherListing,
  },
  {
    path: "/marketing/all-promo/voucher/create",
    name: "Create Voucher",
    component: MarketingVoucherCreate,
  },
  {
    path: "/marketing/all-promo/voucher/edit/:voucherId",
    name: "Edit Voucher",
    component: MarketingVoucherCreate,
  },
  {
    path: "/marketing/all-promo/voucher-merchant",
    name: "Voucher",
    component: MarketingVoucherMerchantListing,
  },
  {
    path: "/marketing/all-promo/voucher-merchant/create",
    name: "Create Voucher",
    component: MarketingVoucherMerchantCreate,
  },
  {
    path: "/marketing/all-promo/voucher-merchant/edit/:voucherId",
    name: "Edit Voucher",
    component: MarketingVoucherMerchantCreate,
  },
  {
    path: "/marketing/calender",
    name: "Calendar",
    component: MerketingCalendar,
  },
  {
    path: "/marketing/merchant-voucher",
    name: "Merchant Voucher",
    component: MerketingMerchantVoucher,
  },
  {
    path: "/marketing/voucher-category",
    name: "Voucher Category",
    component: MerketingVoucherCategory,
  },
  {
    path: "/marketing/all-promo/group-deals",
    name: "All Group Deals",
    component: GroupDealsListing,
  },
  {
    path: "/marketing/all-promo/group-deals/create",
    name: "Create Group Deals",
    component: GroupDealsCreate,
  },
  {
    path: "/marketing/all-promo/group-deals/detail/:id",
    name: "Detail Group Deals",
    component: GroupDealsDetail,
  },
  {
    path: "/marketing/all-promo/group-deals/product",
    name: "Get Group Deals Product",
    component: GroupDealsProduct,
  },
  {
    path: "/user-role",
    name: "/user-role",
    component: AddUserRole,
  },
  {
    path: "/user-role/edit/:userId",
    name: "/user-role",
    component: EditUserRole,
  },
  {
    path: "/user-account",
    name: "/user-account",
    component: ListUserRole,
  },
  {
    path: "/metatags",
    name: "Metatags",
    component: MetatagsPage,
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
  },
  {
    path: "/terms-and-conditions",
    name: "Terms & Conditions",
    component: TermsAndConditions,
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: PrivacyPolicy,
  },
  {
    path: "/config",
    name: "index config",
    component: Configs,
  },
  {
    path: "/config/add-config",
    name: "add config",
    component: AddConfig,
  },
  {
    path: "/config/:key/edit",
    name: "edit config",
    component: AddConfig,
  },
  ...MerchantRoutes,

  // settings
  ...SettingRoutes,
  {
    path: "*",
    name: "Page Not Found",
    component: NotFoundView,
  },
  {
    path: "/404",
    name: "Not Found Page",
    component: NotFoundView,
  },
];

export { PublicRoutes, PrivateRoutes };
