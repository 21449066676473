import { IconButton, Pagination, Stack } from "@mui/material";
import Image from "../../../components/Images/Image";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import SvgIcon from "../../../components/Images/SvgIcon";
import moment from "moment";
import {
  startLoader,
  stopLoader,
} from "../../../lib/global";
import "../../../static/css/orderCss/allOrder.css";
import { getLocalStorage } from "../../../lib/session";
import ablity from "../../../helpers/abilityGuard";
import PagenationDropdown from "../../../components/DropDown/pagenationDropdown";
import { getAllRequestQuotationListByMerchant } from "../../../redux/actions/requestQuotation.action";

function RequestQuotation(props) {
  const [permissions, setPermissions] = useState(
    getLocalStorage("assignMethod")
  );
  const [allOrderData, setAllOrderData] = useState([]);
  const [searchData, setSearchData] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const limit = useRef(10);
  const [offset, setOffset] = useState(0);
  const refSearchData = useRef({});
  const history = useHistory();
  useEffect(() => {
    if (permissions !== "undefined") {
      setPermissions(JSON.parse(getLocalStorage("assignMethod")));
    } else {
      setPermissions({});
    }
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    let filteringData;
    if (pageLimit != limit.current) {
      limit.current = pageLimit;
      props.reset();
      setPageNo(1);
      setOffset(0);
      filteringData = {
        limit: pageLimit,
        offset: 0,
      };
    } else if (refSearchData.current !== props.search) {
      setPageNo(1);
      setOffset(0);
      filteringData = {
        ...props.search,
        limit: pageLimit,
        offset: 0,
      };
      refSearchData.current = props.search;
    } else {
      filteringData = {
        ...props.search,
        offset,
        limit: pageLimit,
      };
    }
    apiData(filteringData);
  }, [offset, pageLimit, props.search]);

  const apiData = (filteringData) => {
    startLoader();

    const response = dispatch(getAllRequestQuotationListByMerchant(filteringData));
    response
      .then((data) => {
        const newData = data?.data?.data.filter(
          (item) => item.status !== "DELETED"
        );
        setAllOrderData(newData);

        setTimeout(() => {
          stopLoader();
        }, 1000);
      })
      .catch((error) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
        console.log(error);
      });
  };

  const handlePageChange = (event, value) => {
    setOffset(value - 1);
    setPageNo(value);
  };

  const viewAction = (id) => {
    history.push(
      `/merchant/request-quotation/${id}`
    );
  };

  return (
    <React.Fragment>
      <div>
        <div className="allTeamPurchaseMain">
          <div className="tableheadTeamPurchaseProduct teamPurchase_borderLine col-5">
            <div className="d-flex  ">
              <div>Product</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  onClick={() =>
                    setSearchData({ item: "productName", order: "ASC" })
                  }
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  onClick={() =>
                    setSearchData({ item: "productName", order: "DESC" })
                  }
                />
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div>Quantity</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  // onClick={() => getApiData({ item: "merchantType", order: "ASC" })}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  // onClick={() => getApiData({ item: "merchantType", order: "DESC" })}
                />
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div> Total Price</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  // onClick={() => getApiData({ item: "merchantType", order: "ASC" })}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  // onClick={() => getApiData({ item: "merchantType", order: "DESC" })}
                />
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div>Request Date</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  // onClick={() => getApiData({ item: "merchantType", order: "ASC" })}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  // onClick={() => getApiData({ item: "merchantType", order: "DESC" })}
                />
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div>Product Type</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  // onClick={() => getApiData({ item: "merchantType", order: "ASC" })}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  // onClick={() => getApiData({ item: "merchantType", order: "DESC" })}
                />
              </div>
            </div>
          </div>
          <div className="teamPurchase_tablehead teamPurchase_borderLine">
            <div className="d-flex  ">
              <div>Note</div>
              <div className="d-flex flex-column ">
                <ExpandLessIcon
                  style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                  // onClick={() => getApiData({ item: "merchantType", order: "ASC" })}
                />
                <ExpandMoreIcon
                  style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                  // onClick={() => getApiData({ item: "merchantType", order: "DESC" })}
                />
              </div>
            </div>
          </div>
          <div className="tableheadfix tableheadfix_allorder teamPurchase_borderLine">
            Action
          </div>
          {!allOrderData.length ? (
            <div>No data found</div>
          ) : (
            <>
              {allOrderData.map((item, index) => (
                <div className="info">
                  <div className="teamPurchase-orderId-detail-maindiv">
                    <div className="justify-content-between d-flex teamPurchase-orderId-detail-section">
                      <div className="teamPurchase_orderId_Detail_Name  mr-3">
                        <label className="mb-0"> Buyer:</label>
                        <span className="text-break text-capitalize">
                          {item?.consumer?.consumerName || "N/A"}
                        </span>
                        <Image
                          src="/icons/chat1.png"
                          className="teamPurchase_chat_icon mb-1"
                          alt="Header logo for admin dashboard"
                        />
                      </div>
                      <div className="teamPurchase_orderId_Detail_brand mx-4">
                        <label className="mb-0"> Merchant:</label>
                        <span className="text-break text-capitalize">
                          {item?.merchant?.preferredMerchantName || "N/A"}
                        </span>
                        <Image
                          src="/icons/chat1.png"
                          className="teamPurchase_chat_icon mb-1"
                          alt="Header logo for admin dashboard"
                        />
                      </div>
                    </div>
                    <div className="teamPurchase-orderId-detail-indexId mr-1">
                      Request ID: {item?.id}
                    </div>
                  </div>

                  <div className="teamPurchase_tablehead_details_head">
                    <div
                      className="tableheadTeamPurchaseProduct"
                      onClick={() => viewAction(item.orderId)}
                    >
                      <div className="d-flex ">
                        <Image
                          src={item.productImages || "/images/broken-image.jpeg"}
                          alt={"photo"}
                          className="teamPurchase_page_product_image"
                        />
                        <div className=" mx-2">
                          <div className="teamPurchase_Page_ProductBox_ProductName text-capitalize">
                            {item?.productName || "N/A"}{" "}
                          </div>
                          <div className="teamPurchase_Page_ProductBox_Product_varient text-capitalize">
                            Variation:{" "}
                            {`${
                              (item?.variation &&
                                item?.variation?.length > 0 &&
                                item?.variation
                                  ?.map((Item, index) => Item?.value)
                                  .toString()) ||
                              " N/A"
                            }`}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName text-capitalize">
                          {item?.quantity || "N/A"}
                        </div>
                        <div className="teamPurchase_Page_ProductBox_Product_varient"></div>
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName text-capitalize">{`${
                          item.currencyName || "RM"
                        }${item.finalAmount || "00"}.00`}</div>
                        {/* <div className="teamPurchase_Page_ProductBox_Product_varient">Online Banking</div> */}
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName text-capitalize">
                          {moment(item?.updatedAt).format("DD/MM/YY, HH:mm") ||
                            "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName text-capitalize">
                          {item?.product?.productType || "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="teamPurchase_tablehead">
                      <div>
                        <div className="teamPurchase_Page_ProductBox_ProductName text-capitalize">
                          {item?.note || "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="tableheadfix tableheadfix_allorder">
                      <div className="d-flex justify-content-center ">
                        <div>
                          {ablity({ model: "Order", action: "read" }) == 1 && (
                            <IconButton
                              className="pointer"
                              onClick={() =>
                                viewAction(
                                  item.id
                                )
                              }
                            >
                              <SvgIcon
                                src="/icons/view_icon.svg"
                                alt="view icon"
                              />
                            </IconButton>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>

      <br />
      <div className="d-flex justify-content-between align-items-center">
        <PagenationDropdown setPageQuant={setPageLimit} />
        <div className="float-right mb-1">
          <Stack spacing={2}>
            <Pagination
              count={Math.ceil((props?.count || 0) / pageLimit)}
              shape="rounded"
              size="small"
              page={pageNo}
              onChange={handlePageChange}
            />
          </Stack>
        </div>
      </div>
      <br />
    </React.Fragment>
  );
}

export default RequestQuotation;