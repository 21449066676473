import { GetWithToken, PutWithToken } from "../../lib/request";

export const getAllRequestQuotationList = (query) => {
    // console.log(query);
    return async function (dispatch) {
      // console.log("enter");
      const result = await GetWithToken(
        `/v2/admin/request-quotation?item=${query.item || ""}&order=${query.order || ""}&orderId=${
          query.orderId || ""
        }&buyerName=${query.buyerName || ""}&merchantName=${
          query.merchantName || ""
        }&productName=${query.productName || ""}&status=${
          query.status || ""
        }&offset=${query.offset || 0}&startDate=${
          query.startDate || ""
        }&endDate=${query.endDate || ""}&limit=${query.limit || 10}`
      );
      // const result = await GetWithToken(`/order?item=${query.item || ''}&order=${query.order || ''}&orderId=${query.orderId|| ''}&buyerName=${query.buyerName|| ''}&merchantName=${query.merchantName|| ''}&productName=${query.productName|| ''}&status=${query.status|| ''}&offset=${query.offset|| 0}`)
  
      console.log(result, "result for all consumer ");
      return result;
    };
};

export const getAllRequestQuotationListByMerchant = (query) => {
  // console.log(query);
  return async function (dispatch) {
    // console.log("enter");
    const result = await GetWithToken(
      `/v2/merchant/request-quotation?item=${query.item || ""}&order=${query.order || ""}&orderId=${
        query.orderId || ""
      }&buyerName=${query.buyerName || ""}&merchantName=${
        query.merchantName || ""
      }&productName=${query.productName || ""}&status=${
        query.status || ""
      }&offset=${query.offset || 0}&startDate=${
        query.startDate || ""
      }&endDate=${query.endDate || ""}&limit=${query.limit || 10}`
    );
    // const result = await GetWithToken(`/order?item=${query.item || ''}&order=${query.order || ''}&orderId=${query.orderId|| ''}&buyerName=${query.buyerName|| ''}&merchantName=${query.merchantName|| ''}&productName=${query.productName|| ''}&status=${query.status|| ''}&offset=${query.offset|| 0}`)

    console.log(result, "result for all consumer ");
    return result;
  };
};

export const getRequestQuotationById = (id) => {
  return async function (dispatch) {
    const result = await GetWithToken(`/v2/admin/request-quotation/${id}`);
    return result;
  };
};

export const getRequestQuotationByIdMerchant = (id) => {
  return async function (dispatch) {
    const result = await GetWithToken(
      `/v2/merchant/request-quotation/${id}`
    );
    return result;
  };
};

export const updateRequestQuotation = (id,data) => {
  return async function (dispatch) {
    const result = await PutWithToken(
      `/v2/admin/request-quotation/${id}`,
      data
    );
    return result;
  };
};

export const updateRequestQuotationByMerchant = (id,data) => {
  return async function (dispatch) {
    const result = await PutWithToken(
      `/v2/merchant/request-quotation/${id}`,
      data
    );
    return result;
  };
};