/**
 * Format Date
 * @param date
 * @param formatString
 * @returns {string}
 */
export default function formatDate(date: Date | Moment | DateTime | Dayjs, formatString: string,formatParser = 'YYYY-MM-DDTHH:mm:ss') {
    if(date){
        return moment(date,formatParser).format(formatString);
    }else{
        return '-'
    }
}