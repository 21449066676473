import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material";
import SvgIcon from "../../../components/Images/SvgIcon";
import { getAllVersionsData } from "../../../redux/actions/appVersion.action";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { showToast, startLoader, stopLoader } from "../../../lib/global";
import { HiAcademicCap } from "react-icons/hi";
import { BsCheckCircleFill } from "react-icons/bs";

const appVersion = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [appVersionData, setAppVersionData] = useState();

  useEffect(() => {
    const breadcrumb = [{ label: "Versions / List", url: null }];
    dispatch(breadcrumbAction(breadcrumb));

    allVersions();
  }, []);

  const allVersions = () => {
    startLoader();
    dispatch(getAllVersionsData("all"))
      .then(({ data }) => {
        setAppVersionData(data?.data);
        setTimeout(() => {
          stopLoader();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        showToast("Internal Server Error", "error");
      });
  };

  const addNewVersion = () => {
    history.push(`/settings/app-version/add`);
  };

  const editVersion = (type) => {
    history.push(`/settings/app-version/edit?applicationType=${type}`);
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="card px-4 py-3 bg-body bg-white rounded shadow-sm">
          <div className="d-flex justify-content-between align-items-center">
            <p className="h3">
              <strong>Versions</strong>
            </p>
            <Button
              onClick={addNewVersion}
              variant="danger"
              type="button"
              className="text-nowrap p-2 my-2 rounded-lg w-20"
            >
              <strong>New version</strong>
            </Button>
          </div>
          <div className="border card p-2 my-3">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="h4 th_cell"
                      component="th"
                      align="left"
                    >
                      <strong>Application type</strong>
                    </TableCell>
                    <TableCell
                      className="h4 th_cell"
                      component="th"
                      align="left"
                    >
                      <strong>Version</strong>
                    </TableCell>
                    <TableCell
                      className="h4 th_cell"
                      component="th"
                      align="center"
                    >
                      <strong>Is active</strong>
                    </TableCell>
                    <TableCell
                      className="th_cell"
                      component="th"
                      align="left"
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!appVersionData?.length && (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <h4 className="text-muted">No data found!</h4>
                      </TableCell>
                    </TableRow>
                  )}
                  {appVersionData?.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell className="td_cell">
                          <strong>{row.applicationType || "N/A"}</strong>
                        </TableCell>
                        <TableCell className="td_cell">
                          {row.version || "N/A"}
                        </TableCell>
                        <TableCell className="td_cell" align="center">
                          <BsCheckCircleFill className="h5 text-success" />
                        </TableCell>
                        <TableCell className="d-flex justify-content-center align-items-center table_action_item">
                          <IconButton
                            className="pointer "
                            onClick={() => editVersion(row.applicationType)}
                          >
                            <SvgIcon
                              src="/icons/edit_icon.svg"
                              alt="view icon"
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default appVersion;
