import { TabPanel } from "../../components/Tabs/TabPannel";
import { Tabs, Tab } from '@mui/material';
import React, { useEffect, useState } from 'react'
import DateRangePickerComp from '../../components/datepicker/MUIDateRange'
import AllNotifications from './AllNotifications';
import { useDispatch } from "react-redux";
import { breadcrumbAction } from "../../redux/actions/config.action";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const initOrderRangeDate = {
  startDate: "",
  endDate: "",
};
const initialSearchData = {
  notificationName: "",
  notificationDate: "",
};


function Notifications() {
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [notificationName, setNotificationName] = useState("");
  const [total, setTotal] = useState({});
  const [search, setSearch] = useState(initialSearchData);
  const [reset, setReset] = useState(false);
  const [orderDateRange, setOrderDateRange] = useState(initOrderRangeDate);


  const handleDateChange = (data) => {
    console.log(data, "sdfasfdasdfadsfasdfasdfa");
    setOrderDateRange(data);
  };

  useEffect(() => {
    const breadcrumb = [
      { label: "Dashboard", url: "/dashboard" },
      { label: "Notifications", url: null }
    ];
    dispatch(breadcrumbAction(breadcrumb));
  }, []);

  const afterDelete = (data) => {
    console.log(data, "total");
    setTotal(data);
    setTabIndex(0);
  };

  const searchData = () => {
    setSearch({
      notificationName,
      ...orderDateRange,
    });
  };
  const handleResetSearch = () => {
    setReset(true);
    setOrderDateRange(initOrderRangeDate);
    setNotificationName("");
    setSearch({
      ...initialSearchData
    });
  };
  return (
    <>
      {/*<div className="mt-1 mx-3 px-2 py-3 rounded bg-white">*/}
      {/*  <div className="col-12 row flex-wrap">*/}
      {/*    <div className="min-width-200 col-md-5">*/}
      {/*      <label>Notification Name</label>*/}
      {/*      <input*/}
      {/*        type="text"*/}
      {/*        className="form-control mt-2"*/}
      {/*        placeholder="Input"*/}
      {/*      //   value={productName}*/}
      {/*      //   onChange={(e) => setProductName(e.target.value)}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="min-width-200 col-md-3">*/}
      {/*      <label className=""> Notification Date</label>*/}

      {/*      <DateRangePickerComp*/}
      {/*      //   reset={reset}*/}
      {/*      //   setReset={setReset}*/}
      {/*        handleDateChange={handleDateChange}*/}
      {/*      />*/}
      {/*    </div>*/}

      {/*  </div>*/}
      {/*  <div className="d-flex justify-content-end mt-3">*/}
      {/*    <button*/}
      {/*      type="button"*/}
      {/*      // onClick={() => handleResetSearch()}*/}
      {/*      className="btn btn-outline-dark px-4 mx-3 btn-sm"*/}
      {/*    >*/}
      {/*      Reset*/}
      {/*    </button>*/}
      {/*    <button*/}
      {/*      type="button"*/}
      {/*      className="btn input-inside-text mx-1 btn-secondary px-3"*/}
      {/*    // onClick={() => searchData()}*/}
      {/*    >*/}
      {/*      Search*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<br />*/}
      {/*<br />*/}
      <div className="container_body container-fluid">
        <div className="card p-3">
          <span>All Notifications</span>
          <div className=" border card  my-3">
            <Tabs
              value={tabIndex}
              onChange={(e, value) => setTabIndex(value)}
              variant="scrollable"
              orientation="horizontal"
              scrollButtons={false}
              visibleScrollbar={false}
              className="px-3"
              aria-label="basic tabs example"
            >
              <Tab label="All" {...a11yProps(0)} />
              <Tab label="ORDERS" {...a11yProps(1)} />
              {/*<Tab label="Unseen" {...a11yProps(2)} />*/}
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <AllNotifications
                type={'ALL'}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <AllNotifications
                type={'ORDERS'}
              />
            </TabPanel>
            {/*<TabPanel value={tabIndex} index={2}>*/}
            {/*  <AllNotifications />*/}
            {/*</TabPanel>*/}
          </div>
        </div>
      </div>
    </>
  )
}

export default Notifications