import { Tab, Tabs } from "@mui/material";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import InputField from "../../../../components/formControl/input/InputField";
import { startLoader, stopLoader, showToast } from "../../../../lib/global";
import { breadcrumbAction } from "../../../../redux/actions/config.action";

import "../../../../static/css/consumerCenterCss/consumerCenterOverview.css";
import AllVoucher from "./AllVoucher";
import { TabPanel } from "../../../../components/Tabs/TabPannel";
import SelectInput from "../../../../components/select";

function changeTabs(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// const initialConsumerSearchDetail = {
//   consumerName: "",
//   consumerRegisteredDate: "",
// };

function VoucherListing() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [search, setSearch] = useState({
    name: "",
    code: "",
    type: "",
    category: "",
  });
  const [tempSearch, setTempSearch] = useState({
    name: "",
    code: "",
    type: "",
    category: "",
  });
  const [categoryList,setCategoryList] = useState([
    {value:'SHOPPING',label:'Shopping'},
    {value:'SHIPPING',label:'Shipping'},
  ]);
  const [typeList,setTypeList] = useState([
    {value:'PERCENTAGE_DISCOUNT',label:'Percentage Discount'},
    {value:'FIXED_PRODUCT_DISCOUNT',label:'Fixed Product Discount'},
    // {value:'FREE_SHIPPING',label:'Free Shipping'},
  ]);
  const [total, setTotal] = useState({});
  const [offSet, setOffSet] = useState(0);
  const handleOffSet = (num) => {
    setOffSet(num);
  };

  // const [searchData, setSearchData] = useState(initialConsumerSearchDetail);

  useEffect(() => {
    startLoader();
    const breadcrumb = [{ label: "Voucher", url: null }];
    dispatch(breadcrumbAction(breadcrumb));
  }, [tabIndex]);

  const onChangeSearch = (e, opt, val) => {
    const key = opt || e.target.name;
    let value = val || e.target.value;
    setTempSearch((pre) => ({ ...pre, [key]: value }));
  };

  const searchChangeCount = (data) => {
    setTotal(data);
  };

  const afterDelete = (data) => {
    setTotal(data && data.total);
    setTabIndex(0);
  };

  const searchVoucher = () => {
    setSearch(tempSearch);
  };

  const handleResetSearch = () => {
    setSearch({
      name: "",
      code: "",
      type: "",
      category: "",
      offset:offSet
    });
  };

  return (
    <div className="main_consumer_account_div">
      <div className="mt-1 mx-3 px-2 py-3 rounded bg-white">
        <div className="col-12 row flex-wrap">
          <div className="min-width-200 col-md-3">
            <label>Voucher Name</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="name"
              name="name"
              value={tempSearch.name}
              onChange={onChangeSearch}
            />
          </div>

          <div className="min-width-200 col-md-3">
            <label>Voucher Code</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="code"
              name="code"
              value={tempSearch.code}
              onChange={onChangeSearch}
            />
          </div>
          <div className="min-width-200 col-md-3">
            <label>Voucher Type</label>
            <div className="form-group mt-2">
              <SelectInput
                options={typeList}
                name="type"
                id="type"
                value={tempSearch.type}
                onChange={(e) => onChangeSearch(e, "type", e)}
                required={true}
                placeholder="Select Type"
              />
            </div>
          </div>
          <div className="min-width-200 col-md-3">
            <label>Voucher Category</label>
            <div className="form-group mt-2">
              <SelectInput
                options={categoryList}
                name="category"
                id="category"
                value={tempSearch.category}
                onChange={(e) => onChangeSearch(e, "category", e)}
                required={true}
                placeholder="Select Category"
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            className="btn btn-outline-dark mr-1 px-4"
            onClick={() => handleResetSearch()}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn btn-dark mx-1 btn-secondary px-3"
            onClick={() => searchVoucher()}
          >
            Search
          </button>
        </div>
      </div>
      <div className="container_body container-fluid mt-2">
        <div className="card p-3">
          <span>ALL VOUCHER</span>
          <div className=" border card rounded-lg my-3">
            <Tabs
              value={tabIndex}
              onChange={(e, value) => setTabIndex(value)}
              variant="scrollable"
              orientation="horizontal"
              scrollButtons={false}
              visibleScrollbar={false}
              className="px-3"
              aria-label="basic tabs example"
            >
              <Tab label={`All(${total?.all || 0})`} {...changeTabs(0)} />
              <Tab label={`On going(${total?.onGoing || 0})`} {...changeTabs(1)} />
              <Tab
                label={`Upcoming(${total?.upcoming || 0})`}
                {...changeTabs(2)}
              />
              <Tab
                label={`Draft(${total?.draft || 0})`}
                {...changeTabs(3)}
              />
              <Tab
                label={`Expired(${total?.expired || 0})`}
                {...changeTabs(4)}
              />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <AllVoucher
                search={search}
                status=""
                totalItem={total?.all|| 0}
                setTotals={setTotal}
                afterDelete={afterDelete}
                reset={handleResetSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <AllVoucher
                search={search}
                status="ON_GOING"
                totalItem={total?.onGoing || 0}
                setTotals={setTotal}
                afterDelete={afterDelete}
                reset={handleResetSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <AllVoucher
                search={search}
                status="UPCOMING"
                totalItem={total?.upcoming || 0}
                setTotals={setTotal}
                afterDelete={afterDelete}
                reset={handleResetSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
              <AllVoucher
                search={search}
                status="DRAFT"
                totalItem={total?.draft || 0}
                setTotals={setTotal}
                afterDelete={afterDelete}
                reset={handleResetSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={4}>
              <AllVoucher
                search={search}
                status="EXPIRED"
                totalItem={total?.expired || 0}
                setTotals={setTotal}
                afterDelete={afterDelete}
                reset={handleResetSearch}
              />
            </TabPanel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VoucherListing;
