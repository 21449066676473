import CloseIcon from "@mui/icons-material/Close";

import React, { useState } from 'react'

const MerchantImportProduct = (props) => {
    const [file, setFile] = useState();



    const handleSubmit = () => {

        const payload = {
            file
        };

        props.onSubmit(payload);
        props.onClose();
    };

    const closeModel = () => {
        props.onClose()
    }



    return (
        <React.Fragment>
            <div className="col-12 p-0 m-0 card">
                <div className="close_icon">
                    <CloseIcon
                        className="close_icon_btn m-2"
                        color="primary2"
                        fontSize="large"
                        title="Close dialog"
                        onClick={props.onClose}
                    />

                </div>
                <div className="card-header d-flex justify-content-center">
                    <h4>Import Product</h4>
                </div>
                <div style={{ height: "10vh" }} className="card-body">
                    <form
                        id="form"
                        enctype="multipart/form-data"
                    >
                        <div className="col-12 mb-2">
                            <label className="col-12 m-0 p-0">Excel File</label>
                            <input
                                className="rounded mt-3"
                                type="file"
                                id="file"
                                name="file"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    setFile(file);
                                }}
                            />
                        </div>
                    </form>
                </div>
                <div className="submit_btn col-md-12 justify-content-end">
                    <button
                        className="btn btn-outline-danger"
                        onClick={() => closeModel()}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSubmit} className="btn btn-danger mx-2">
                        Confirm
                    </button>
                </div>
            </div>
            <style jsx="true">{`
                .card-body {
                min-height: 60vh;
                }
                .submit_btn {
                position: absolute;
                bottom: 15px;
                width: 100%;
                justify-content: center;
                display: flex;
                }
                .close_icon_btn {
                position: absolute;
                right: 0;
                cursor: pointer;
                }
            `}</style>
        </React.Fragment>
    )
}

export default MerchantImportProduct;