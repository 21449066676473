import {
  IconButton,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SvgIcon from "../../../components/Images/SvgIcon";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import {
  deleteBrandById,
  getAllBrandApi,
} from "../../../services/brand.service";
import "../../../static/css/productCss/ProductBrand.css";
import {
  open_dialog,
  showToast,
  startLoader,
  stopLoader,
} from "../../../lib/global";
import { DELETE_BRAND } from "../../../lib/constant";
import PagenationDropdown from "../../../components/DropDown/pagenationDropdown";
import ablity from "../../../helpers/abilityGuard";

const initialSearchData = {
  search: "",
};

const ProductBrand = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [brandData, setBrandData] = useState([]);
  const [searchData, setSearchData] = useState(initialSearchData);
  const [totalBrands, setTotalBrands] = useState(0);

  const [pageNo, setPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const limit = useRef(10);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const breadcrumb = [{ label: "Product Brand", url: null }];
    dispatch(breadcrumbAction(breadcrumb));
    startLoader();
    // const data= {...searchData }

    let filteringdata;
    if (pageLimit != limit.current) {
      limit.current = pageLimit;
      setSearchData(initialSearchData);
      setPageNo(1);
      setOffset(0);
      filteringdata = {
        limit: pageLimit,
      };
    } else {
      filteringdata = {
        ...searchData,
        offset,
        limit: pageLimit,
      };
    }
    apiData(filteringdata);
  }, [offset, pageLimit, searchData]);

  const apiData = (filteringdata) => {
    dispatch(getAllBrandApi(filteringdata))
      .then((data) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
        if (data && data.data && data.data.status === 200) {
          setBrandData(
            data &&
              data.data &&
              data.data.data.map((row, index) => {
                return {
                  brandName: row?.brandName || "N/A",
                  category: row?.category || 0,
                  product: row?.product || 0,
                  releventMerchant: row?.merchant || 0,
                  status: row?.status || "N/A",
                  brandId: row?.brandId || "",
                };
              })
          );
          setTotalBrands(
            data && data.data && data.data.data && data.data.totalBrand
          );
        }
      })
      .catch((error) => {
        console.log({ error }, "kkkkkkkkkkkkkkkkkkkkkk");
        setTimeout(() => {
          stopLoader();
        }, 1000);
      });
  };

  const handlePageChange = (event, value) => {
    console.log(event, value, "gggggggggggggggggggggggggggggggg");
    setOffset(value - 1);
    setPageNo(value);
  };

  const getBrandsDataAfterDelete = (data) => {
    setBrandData(data);
  };
  const DeleteBrandById = (id) => {
    open_dialog(DELETE_BRAND, {
      onSubmit: getBrandsDataAfterDelete,
      id,
    });
  };

  const addBrandFun = () => {
    history.push("/brand-desk");
  };

  const EditBrandfun = (id) => {
    console.log(id, "idss");
    history.push(`/brand-desk/${id}`);
  };

  const onChangeSeachData = (data) => {
    setSearchData(data);
  };

  const handleSorting = (item, sortType, isDate = false) => {
    startLoader();
    try {
      if (typeof brandData[0][item] === "number") {
        let sortedData = brandData.sort((a, b) => {
          if (sortType == "asc") {
            return a[item] - b[item];
          } else if (sortType == "desc") {
            return b[item] - a[item];
          }
        });
        setTimeout(() => {
          setBrandData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof brandData[0][item] === "string" && !isDate) {
        let sortedData = brandData.sort((a, b) => {
          let fa = a[item].toLowerCase(),
            fb = b[item].toLowerCase();
          if (sortType == "asc") {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else if (sortType == "desc") {
            if (fa < fb) {
              return 1;
            }
            if (fa > fb) {
              return -1;
            }
            return 0;
          }
        });
        console.log(sortedData, "llllllllll");
        setTimeout(() => {
          setBrandData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof brandData[0][item] === "string" && isDate) {
        let sortedData = brandData.sort((a, b) => {
          let da = new Date(a[item]);
          let db = new Date(b[item]);

          console.log(da, db);

          if (sortType == "asc") {
            return da - db;
          } else if (sortType == "desc") {
            return db - da;
          }
        });
        setTimeout(() => {
          setBrandData([...sortedData]);
          stopLoader();
        }, [1000]);
      }
    } catch (error) {
      console.log({ error });
      stopLoader();
    }
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="card px-4 py-3 bg-body bg-white rounded shadow-sm">
          <span>BRAND LISTING</span>
          <div className="border card p-3 my-3">
            <div className="d-flex justify-content-between">
              <span>{`All Brands (${totalBrands})`}</span>
              <div className="d-flex my-3">
                <input
                  type="search"
                  placeholder="search"
                  className="form-control mx-3"
                  value={searchData?.search || ""}
                  onChange={(e) =>
                    onChangeSeachData({
                      ...searchData,
                      search: e.target.value,
                    })
                  }
                />

                {ablity({model:'Product', action:'create'}) == 1 && (
                    <button
                      onClick={() => addBrandFun()}
                      type="button"
                      className="btn btn-dark btn-sm text-nowrap mx-3"
                    >
                      {" "}
                      + Add Brand
                    </button>
                )}
              </div>
            </div>
            <TableContainer>
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className="th_cell" component="th">
                      No
                    </TableCell>
                    <TableCell className="th_cell" component="th" align="left">
                      <div className="d-flex  ">
                        <div>Name</div>
                        <div className="d-flex flex-column ">
                          <ExpandLessIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "0px 3px",
                            }}
                            // onClick={() => setSearchData({ ...searchData, item: "brandName", order: "ASC" })}
                            onClick={() => handleSorting("brandName", "asc")}
                          />
                          <ExpandMoreIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "-5px 3px",
                            }}
                            // onClick={() => setSearchData({ ...searchData, item: "brandName", order: "DESC" })}
                            onClick={() => handleSorting("brandName", "desc")}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      <div className="d-flex  ">
                        <div>Category</div>
                        <div className="d-flex flex-column ">
                          <ExpandLessIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "0px 3px",
                            }}
                            onClick={() => handleSorting("category", "asc")}

                            // onClick={() => getApiData({ item: "category", order: "ASC" })}
                          />
                          <ExpandMoreIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "-5px 3px",
                            }}
                            onClick={() => handleSorting("category", "desc")}

                            // onClick={() => getApiData({ item: "category", order: "DESC" })}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      <div className="d-flex  ">
                        <div>Product</div>
                        <div className="d-flex flex-column ">
                          <ExpandLessIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "0px 3px",
                            }}
                            onClick={() => handleSorting("product", "asc")}

                            // onClick={() => getApiData({ item: "product", order: "ASC" })}
                          />
                          <ExpandMoreIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "-5px 3px",
                            }}
                            onClick={() => handleSorting("product", "desc")}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      <div className="d-flex  ">
                        <div> Relevent Merchant</div>
                        <div className="d-flex flex-column ">
                          <ExpandLessIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "0px 3px",
                            }}
                            onClick={() =>
                              handleSorting("releventMerchant", "asc")
                            }

                            // onClick={() => getApiData({ item: " releventMerchant", order: "ASC" })}
                          />
                          <ExpandMoreIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "-5px 3px",
                            }}
                            onClick={() =>
                              handleSorting("releventMerchant", "desc")
                            }

                            // onClick={() => getApiData({ item: " releventMerchant", order: "DESC" })}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell className="th_cell" component="th" align="left">
                      <div className="d-flex  ">
                        <div> Status</div>
                        <div className="d-flex flex-column ">
                          <ExpandLessIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "0px 3px",
                            }}
                            onClick={() => handleSorting("status", "asc")}

                            // onClick={() => getApiData({ item: "  status", order: "ASC" })}
                          />
                          <ExpandMoreIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "-5px 3px",
                            }}
                            onClick={() => handleSorting("status", "desc")}
                          />
                        </div>
                      </div>
                    </TableCell>

                    <TableCell className="table_action_head" component="th">
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!brandData?.length && (
                    <div className="d-flex justify-content-center">
                      <h3 className="text-muted m-auto">No data found!</h3>
                    </div>
                  )}
                  {brandData.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center" className="td_cell">
                        {offset * pageLimit + (index + 1)}
                      </TableCell>
                      <TableCell align="left" className="td_cell capitalize">
                        {row.brandName || "N/A"}
                      </TableCell>
                      <TableCell align="center" className="td_cell">
                        {row.category || "0"}
                      </TableCell>
                      <TableCell align="center" className="td_cell">
                        {row.product || "0"}
                      </TableCell>
                      <TableCell align="center" className="td_cell">
                        {row.merchant || "0"}
                      </TableCell>
                      <TableCell align="left" className="td_cell">
                        {row.status || "N/A"}
                      </TableCell>

                      <TableCell className="d-flex justify-content-between table_action_item">
                        {ablity({model:'Product', action:'update'}) == 1 && (
                            <IconButton
                              className="pointer"
                              onClick={() => EditBrandfun(row.brandId)}
                            >
                              <SvgIcon src="/icons/edit_icon.svg" alt="view icon" />
                            </IconButton>
                        )}

                        {ablity({model:'Product', action:'delete'}) == 1 && (
                          <IconButton
                            className="pointer"
                            onClick={() => DeleteBrandById(row.brandId)}
                          >
                            <SvgIcon
                              src="/icons/delete_icon.svg"
                              alt="delete icon"
                            />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <br />
            <hr />
            <div className="d-flex justify-content-between align-items-center">
              <PagenationDropdown setPageQuant={setPageLimit} />

              <div className="float-right mb-1">
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil((totalBrands || 0) / pageLimit)}
                    shape="rounded"
                    size="small"
                    page={pageNo}
                    onChange={handlePageChange}
                  />
                </Stack>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductBrand;
