import React from "react";
import Pagination from "@mui/lab/Pagination";
import TimelineIcon from "@mui/icons-material/Timeline";
import TranslateIcon from "@mui/icons-material/Translate";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import PolicyIcon from "@mui/icons-material/Policy";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PostAddIcon from "@mui/icons-material/PostAdd";
import CategoryIcon from "@mui/icons-material/Category";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import FilterListIcon from "@mui/icons-material/FilterList";
import PaletteIcon from "@mui/icons-material/Palette";
import CalendarViewDayIcon from "@mui/icons-material/CalendarViewDay";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import SettingsIcon from "@mui/icons-material/Settings";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import RateReviewIcon from "@mui/icons-material/RateReview";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import SvgIcon from "../Images/SvgIcon";
import { FINANCE_REPORT_MODAL } from "../../lib/constant";
import ablity from "../../helpers/abilityGuard";

/**
 * @description all sidebar items list
 * Add your sidebar item here in this list
 * @author jagannath
 * @date 09/04/2021
 * @param color String - icon color
 * @return Array of sidebar items
 */
const sidebarList = (color) => {
  // console.log(ablity({model:'Merchant'}) == 1?true:false);
  return [
    {
      title: "Home",
      icon: <SvgIcon src="/icons/dashboard_icon.svg" alt="dashboard icon" />,
      home: true,
      path: "/home",
    },

    // {
    //     title: 'Language',
    //     icon: <TranslateIcon style={{ color }} />,
    //     home: true,
    //     path: '/language'
    // },
    // {
    //     title: 'Users',
    //     icon: <GroupAddIcon style={{ color }} />,
    //     home: true,
    //     path: '/users'
    // },
    {
      title: "Banner",
      icon: <SvgIcon src="/icons/dashboard_icon.svg" alt="dashboard icon" />,
      home: false,
      path: "/banner",
    },

    {
      title: "Products",
      icon: <SvgIcon src="/icons/product_icon.svg" alt="product icon" />,
      home: false,
      children: [
        {
          title: "Overview",
          path: "/products/overview",
        },
        // {
        //     title: "Performance",
        //     path: '/products/performance'
        // },
        {
          title: "All Products",
          path: "/products/all-products",
        },
        {
          title: "Product Brand",
          path: "/products/brands",
        },
        {
          title: "Product Category",
          path: "/products/categories",
        },
      ],
    },

    {
      title: "Order",
      icon: <SvgIcon src="/icons/order_icon.svg" alt="order icon" />,
      home: false,
      children: [
        // {
        //     title: "Overview",
        //     path: "/orders/overview"
        // },
        {
          title: "Buy Now",
          path: "/orders/all-orders",
        },
        // {
        //   title: "Team Purchase",
        //   path: "/orders/team-purchase",
        // },
        {
          title: "Return/Refund",
          path: "/orders/return-and-refund",
        },
        {
          title: "Redemption",
          path: "/orders/redemption",
        },
      ],
    },
    {
      title: "Merchant Center",
      icon: (
        <SvgIcon
          src="/icons/mercant_center_icon.svg"
          alt="merchant center icon"
        />
      ),
      home: false,
      children: [
        {
          title: "Merchant Account",
          path: "/merchant-center/merchant-account",
        },
        {
          title: "Merchant Profiles",
          path: "/merchant-center/profiles",
        },
        // {
        //     title: "Merchant Report",
        //     path: '/merchant-center/merchant-report'
        // },
        // {
        //   title: "Broadcast Management ",
        //   path: "/merchant-center/merchant-broadcast-management",
        // },
        // {
        //   title: "Help Center",
        //   path: "/merchant-center/merchant-help-center",
        // },
      ],
    },
    // {
    //     title: 'Customer Center',
    //     icon: <SvgIcon src="/icons/customer_center_icon.svg" alt="customer center icon" />,
    //     home: false,
    // },
    {
      title: "Consumer Center",
      icon: (
        <SvgIcon
          src="/icons/customer_center_icon.svg"
          alt="Consumer center icon"
        />
      ),
      home: false,
      children: [
        // {
        //     title: "Overview",
        //     path: "/consumer-center/overview"
        // },
        {
          title: "Consumer Account",
          path: "/consumer-center/account",
        },
      ],
    },
    // {
    //   title: "Content Center",
    //   icon: (
    //     <SvgIcon src="/icons/cs_center_icon.svg" alt="Content center icon" />
    //   ),
    //   home: false,
    //   children: [
    //     // {
    //     //     title: "Overview",
    //     //     path: "/content-center/overview"
    //     // },
    //     {
    //       title: "Livestream",
    //       path: "/content-center/livestream",
    //     },
    //     {
    //       title: "Help Center",
    //       path: "/content-center/help-center",
    //     },
    //   ],
    // },

    // {
    //   title: "Ads",
    //   icon: (
    //     <SvgIcon src="/icons/cs_center_icon.svg" alt="Content center icon" />
    //   ),
    //   home: false,
    //   children: [
    //     {
    //       title: "App Banner",
    //       path: "/ads/app-banner",
    //     },
    //     {
    //       title: "Web Banner",
    //       path: "/ads/web-banner",
    //     },
    //     // {
    //     //     title: "Help Center",
    //     //     path: "/content-center/help-center"
    //     // }
    //   ],
    // },

    {
      title: "Assign Role",
      icon: (
        <SvgIcon src="/icons/customer_center_icon.svg" alt="Ecommerce icon" />
      ),

      home: false,
      children: [
        // {
        //     title: "Add user",
        //     path: "/user-role",
        // },
        {
          title: "User Account",
          path: "/user-Account",
        },
      ],
    },

    // {
    //   title: "Ecommerce",
    //   icon: (
    //     <SvgIcon src="/icons/customer_center_icon.svg" alt="Ecommerce icon" />
    //   ),
    //   home: false,
    //   children: [
    //     {
    //       title: "Help Center",
    //       path: "/ecommerce/help-center",
    //     },
    //   ],
    // },
    {
      title: "Finance",
      icon: (
        <SvgIcon src="/icons/customer_center_icon.svg" alt="Finance icon" />
      ),
      home: false,
      children: [
        // {
        //     title: "Overview",
        //     path: "/finance/overview"
        // },
        {
          title: "Order & Receipt",
          path: "/finance/order-receipt",
        },
        {
          title: "Return/Refund",
          path: "/finance/return-refund",
        },
        // {
        //   title: "Report",
        //   modal: FINANCE_REPORT_MODAL,
        //   // path: "/finance"
        // },
        {
          title: "Cashbooks",
          path: "/finance/upload-cashbooks",
        },
      ],
    },

    // {
    //   title: "CS Center",
    //   icon: <SvgIcon src="/icons/cs_center_icon.svg" alt="CS center icon" />,
    //   home: false,
    //   children: [
    //     {
    //       title: "Help Center",
    //       path: "/cs-center/help-center",
    //     },
    //   ],
    // },
    // {
    //   title: "Marketing",
    //   icon: <SvgIcon src="/icons/cs_center_icon.svg" alt="Promotion icon" />,
    //   home: false,
    //   children: [
    //     // {
    //     //     title: "Overview",
    //     //     path: "/marketing/overview"
    //     // },
    //     {
    //       title: "All Promo",
    //       path: "/marketing/all-promo",
    //     },
    //     {
    //       title: "Merchant Voucher",
    //       path: "/marketing/all-promo/voucher-merchant",
    //     },
    //   ],
    // },
    // {
    //   title: "Promotion",
    //   icon: <SvgIcon src="/icons/cs_center_icon.svg" alt="Promotion icon" />,
    //   home: false,
    //   children: [
    //     {
    //       title: "Help Center",
    //       path: "/promotion/help-center",
    //     },
    //   ],
    // },
    // {
    //   title: "Logistic",
    //   icon: <SvgIcon src="/icons/cs_center_icon.svg" alt="Logistic icon" />,
    //   home: false,
    //   children: [
    //     {
    //       title: "Help Center",
    //       path: "/logistic/help-center",
    //     },
    //   ],
    // },
    // {
    //   title: "Database Management",
    //   icon: (
    //     <SvgIcon
    //       src="/icons/cs_center_icon.svg"
    //       alt="Database Management icon"
    //     />
    //   ),
    //   home: false,
    //   children: [
    //     {
    //       title: "Help Center",
    //       path: "/database-management/help-center",
    //     },
    //   ],
    // },
    {
      title: "Settings",
      icon: <SvgIcon src="/icons/setting_icon.svg" alt="Settings icon" />,
      home: false,
      children: [
        //     {
        //       title: "OTP Setting",
        //       path: "/settings/otp-setting",
        //     },
        {
          title: "App Version",
          path: "/settings/app-version",
        },
        //     {
        //       title: "Help Center",
        //       path: "/settings/help-center",
        //     },
      ],
    },

    // {
    //     title: 'Merchant Center',
    //     icon: <PostAddIcon style={{ color }} />,
    //     home: true,
    //     children: [
    //         {
    //             title: "Merchant Report",
    //             icon: <StarHalfIcon style={{ color }} />,
    //             path: "/merchant-report"
    //         },

    //     ]
    // },
    // {
    //     title: 'Merchant Center',
    //     icon: <PostAddIcon style={{ color }} />,
    //     home: true,
    //     children: [
    //         {
    //             title: "Merchant Report",
    //             icon: <StarHalfIcon style={{ color }} />,
    //             path: "/merchant-report"
    //         },

    //     ]
    // },
    // {
    //     title: 'Categories',
    //     icon: <CategoryIcon style={{ color }} />,
    //     home: true,
    //     path: '/categories'
    // },
    // {
    //     title: 'Attributes',
    //     icon: <AccountTreeIcon style={{ color }} />,
    //     home: true,
    //     path: '/attributes'
    // },

    // {
    //     title: "Reviews & Ratings",
    //     icon: <StarBorderIcon style={{ color }} />,
    //     home: false,
    //     children: [
    //         {
    //             title: "Reviews & Ratings",
    //             path: "/review-and-ratings"
    //         },
    //         {
    //             title: "Review Parameters",
    //             path: '/review-parameters'
    //         }
    //     ]
    // },
    // {
    //   title: "Metatags",
    //   icon: <AcUnitIcon style={{ color: "#969ca4" }} />,
    //   home: true,
    //   path: "/metatags",
    // },

    // {
    //   title: "Chat",
    //   icon: <SvgIcon src="/icons/chat_icon.svg" alt="CS center icon" />,
    //   home: false,
    //   children: [
    //     {
    //       title: "All Chat",
    //       path: "/merchant/chat",
    //     },
    //     {
    //       title: "Chat To Customer",
    //       path: "/chat/admin-to-customer",
    //     },
    //     {
    //       title: "Chat To Merchant",
    //       path: "/chat/admin-to-merchant",
    //     },

    //   ]
    // },
    // {
    //     title: 'App Settings',
    //     icon: <SettingsIcon style={{ color }} />,
    //     home: true,
    //     path: '/app-settings'
    // },
    // {
    //     title: 'App Assets',
    //     icon: <WebAssetIcon style={{ color }} />,
    //     home: true,
    //     path: '/app-assets'
    // },
    // {
    //     title: 'Filters',
    //     icon: <FilterListIcon style={{ color }} />,
    //     home: true,
    //     path: '/filters'
    // },
    // {
    //     title: 'Colors',
    //     icon: <PaletteIcon style={{ color }} />,
    //     home: true,
    //     path: '/colors'
    // },
    // {
    //     title: 'Brands',
    //     icon: <CalendarViewDayIcon style={{ color }} />,
    //     home: true,
    //     path: '/brands'
    // },
    // {
    //     title: 'Models',
    //     icon: <FeaturedPlayListIcon style={{ color }} />,
    //     home: true,
    //     path: '/models'
    // },
    // {
    //     title: 'Terms & Conditions',
    //     icon: <AccessibilityIcon style={{ color }} />,
    //     home: true,
    //     path: '/terms-and-conditions'
    // },
    // {
    //     title: 'Privacy Policy',
    //     icon: <PolicyIcon style={{ color }} />,
    //     home: true,
    //     path: '/privacy-policy'
    // },
    // {
    //     title: 'FAQ',
    //     icon: <QuestionAnswerIcon style={{ color }} />,
    //     home: true,
    //     path: '/faq'
    // },

    {
      title: "Config",
      icon: <SvgIcon src="/icons/dashboard_icon.svg" alt="dashboard icon" />,
      home: false,
      path: "/config",
    },
  ];
};

export default sidebarList;
