import { TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DateRangePickerComp from "../../../components/datepicker/MUIDateRange";
import InputField from "../../../components/formControl/input/InputField";
import SelectInput from "../../../components/select";
import { TabPanel } from "../../../components/Tabs/TabPannel";
import { open_dialog, startLoader, stopLoader } from "../../../lib/global";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import {
  getAllRecieptOrder,
  exportOrderToExcel,
} from "../../../redux/actions/finance.action";
// import RetailBuyNow from "./order-receipt-listing/index"
import RetailBuyNow from "./order-receipt-listing";
import RetailTP from "./retail-TP/RetailTP";
import { GrDownload } from "react-icons/gr";

var initialSearhData = {
  orderStatus: "COMPLETED",
  refNo: "",
  orderId: "",
  date: "",
};
const initOrderRangeDate = {
  startDate: "",
  endDate: "",
};

const orderStatusList = [
  {
    title: "PENDING PAYMENT",
    label: "PENDING PAYMENT",
    value: "PENDINGPAYMENT",
  },
  {
    title: "TO SHIP",
    label: "TO SHIP",
    value: "TOSHIP",
  },
  {
    title: "SHIPPING",
    label: "SHIPPING",
    value: "SHIPPING",
  },
  {
    title: "DELIVERED",
    label: "DELIVERED",
    value: "DELIVERED",
  },
  {
    title: "COMPLETED",
    label: "COMPLETED",
    value: "COMPLETED",
  },
  {
    title: "CANCELLED",
    label: "CANCELLED",
    value: "CANCELLED",
  },
];

const FinanceOrderReceiptIndex = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const dispatch = useDispatch();
  const [orderId, setOrderId] = useState("");
  const [orderStatus, setOrderStatus] = useState("COMPLETED");
  const [refNo, setRefNo] = useState("");
  // const [date, setDate] = useState("");

  const [total, setTotal] = useState({});
  const [search, setSearch] = useState(initialSearhData);

  const [reset, setReset] = useState(false);
  const [orderDateRange, setOrderDateRange] = useState(initOrderRangeDate);
  const handleDateChange = (data) => {
    setOrderDateRange(data);
  };

  useEffect(() => {
    const breadcrumb = [{ label: "Order & Receipt", url: null }];
    dispatch(breadcrumbAction(breadcrumb));
    // getAllData(orderDateRange);
  }, [tabIndex]);

  const getAllData = (orderDateRange) => {
    dispatch(getAllRecieptOrder(orderDateRange))
      .then((data) => {
        stopLoader();
        setTotal(data && data.data && data.data.total);
      })
      .catch((error) => {
        stopLoader();
        console.log(error);
      });
  };
  const searchData = () => {
    setSearch({
      orderId,
      orderStatus: orderStatus.label,
      refNo,
      ...orderDateRange,
    });
  };

  const handleResetSearch = () => {
    setOrderStatus("");
    setRefNo("");
    setOrderId("");
    setReset(true);
    setOrderDateRange(initOrderRangeDate);
    setOrderStatus("COMPLETED");
    setSearch({
      orderId: "",
      refNo: "",
      ...initOrderRangeDate,
    });
  };

  const handleExportData = () => {
    startLoader();
    dispatch(exportOrderToExcel(orderDateRange))
      .then((response) => {
        console.log(response, "responseresponseresponse");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${Date.now()}.xlsx`);
        document.body.appendChild(link);
        setTimeout(() => {
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
          stopLoader();
        }, 1000);
      })
      .catch((err) => {
        console.log({ err });
        alert("error .somthing is wrong");
        stopLoader();
      });
    getAllData(orderDateRange);
  };
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="px-2 py-3 mb-3 card ">
          <div className="col-12 row flex-wrap">
            <div className="min-width-200 col-md-3">
              <label>Order ID</label>
              <InputField
                type="text"
                className="form-control"
                placeholder="Input"
                id="storeId"
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
              />
            </div>

            <div className="min-width-200 col-md-3">
              <label>Transaction Ref No.</label>
              <InputField
                type="text"
                className="form-control"
                placeholder="Input"
                id="merchantRegisteredDate"
                value={refNo}
                onChange={(e) => setRefNo(e.target.value)}
              />
            </div>
            <div className="min-width-200 col-md-3 ">
              <label>Order Date</label>
              <div className=" ">
                <DateRangePickerComp
                  reset={reset}
                  setReset={setReset}
                  handleDateChange={handleDateChange}
                />
              </div>
            </div>
            <div className="min-width-200 col-md-3">
              <label>Order Status</label>
              <div className="my-2">
                <SelectInput
                  className="form-control"
                  options={orderStatusList}
                  label={[]}
                  placeholder="Input"
                  openIn={"left"}
                  value={orderStatus}
                  onChange={(e) =>
                    setOrderStatus({ label: e.label, value: e.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              type="button"
              className="btn input-inside-text mr-1 btn-white border px-4"
              onClick={() => handleResetSearch()}
            >
              Reset
            </button>
            <button
              type="button"
              className="btn input-inside-text mx-1 btn-danger px-3"
              onClick={() => searchData()}
            >
              Search
            </button>
          </div>
        </div>

        <div className="p-3 card">
          <h5>ORDER & RECEIPT LISTING</h5>
          <div className="border rounded-lg">
            <div className="p-3">
              ALL ORDERS & RECEIPTS{`(${total?.total || 0})`}
            </div>
            <div className="px-3 d-flex justify-content-between">
              <div>
                <button
                  onClick={() => {
                    setTabIndex(0);
                  }}
                  autocomplete="off"
                  style={{ width: "100px", height: "30px", margin: "0px 3px" }}
                  className={`mr-2 fntSz14 ${
                    tabIndex == 0 ? "btn btn-danger" : "btn btn-outline-dark"
                  }`}
                  data-bs-toggle="button"
                >
                  <span className="badge">{`All (${total?.total || 0})`}</span>
                </button>
                <button
                  onClick={() => {
                    setTabIndex(1);
                  }}
                  autocomplete="off"
                  style={{ width: "100px", height: "30px", margin: "0px 3px" }}
                  className={`mr-2 fntSz14 ${
                    tabIndex == 1 ? "btn btn-danger" : "btn btn-outline-dark"
                  }`}
                  data-bs-toggle="button"
                >
                  <span className="badge">{`To Settle (${
                    total?.toSettleTotal || 0
                  })`}</span>
                </button>
                <button
                  onClick={() => {
                    setTabIndex(2);
                  }}
                  autocomplete="off"
                  style={{ width: "100px", height: "30px", margin: "0px 3px" }}
                  className={`mr-2 fntSz14 ${
                    tabIndex == 2 ? "btn btn-danger" : "btn btn-outline-dark"
                  }`}
                  data-bs-toggle="button"
                >
                  {" "}
                  <span className="badge">{`Settled (${
                    total?.settleTotal || 0
                  })`}</span>
                </button>
              </div>
              <div>
                <button
                  onClick={handleExportData}
                  className="px-3 fntSz14 btn btn-outline-dark pointer"
                  style={{ width: "120px", height: "30px", margin: "0px 3px" }}
                >
                  <span className="badge badge-light">
                    <GrDownload />
                  </span>
                  <span className="badge">Export Data</span>
                  {/* <strong>Export Data</strong> */}
                </button>
              </div>
            </div>
            {/* <div className="px-3 p-2">
              <button
                onClick={() => {
                  setTabIndex(0);
                }}
                autocomplete="off"
                className={`mr-2 fntSz14 ${
                  tabIndex == 0 ? "btn btn-danger" : "btn btn-outline-dark"
                }`}
                data-bs-toggle="button"
              >
                {`Retail Buy Now (${total?.retail || 0})`}
              </button>
              <button
                onClick={() => {
                  setTabIndex(1);
                }}
                type="button"
                autocomplete="off"
                className={`mr-2 fntSz14 ${
                  tabIndex == 1 ? "btn btn-danger" : "btn btn-outline-dark"
                }`}
                data-bs-toggle="button"
              >
                {`Retail TP (${total?.retailTP || 0})`}
              </button>
              <button
                onClick={() => {
                  setTabIndex(2);
                }}
                type="button"
                autocomplete="off"
                className={`mr-2 fntSz14 ${
                  tabIndex == 2 ? "btn btn-danger" : "btn btn-outline-dark"
                }`}
                data-bs-toggle="button"
              >
                {`Flash Deals (${total?.flashDeall || 0})`}
              </button>
              <button
                onClick={() => {
                  setTabIndex(3);
                }}
                type="button"
                autocomplete="off"
                className={`mr-2 fntSz14 ${
                  tabIndex == 3 ? "btn btn-danger" : "btn btn-outline-dark"
                }`}
                data-bs-toggle="button"
              >
                {`Flash Deals TP (${total?.flashDealTPP || 0})`}
              </button>
              <button
                onClick={() => {
                  setTabIndex(4);
                }}
                type="button"
                autocomplete="off"
                className={`mr-2 fntSz14 ${
                  tabIndex == 4 ? "btn btn-danger" : "btn btn-outline-dark"
                }`}
                data-bs-toggle="button"
              >
                {`Group Deals (${total?.groupDealsS || 0})`}
              </button>
              <button
                onClick={() => {
                  setTabIndex(5);
                }}
                type="button"
                autocomplete="off"
                className={`mr-2 fntSz14 ${
                  tabIndex == 5 ? "btn btn-danger" : "btn btn-outline-dark"
                }`}
                data-bs-toggle="button"
              >
                {`Other Promo (${total?.promoo || 0})`}
              </button>
            </div> */}
            <TabPanel value={tabIndex} index={0}>
              <RetailBuyNow
                transactionsType={"RETAIL"}
                search={search}
                totalItem={total?.totalC || 0}
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <RetailBuyNow
                settlementStatus={"toSettle"}
                transactionsType={"RETAIL"}
                search={search}
                totalItem={total?.toSettleTotal || 0}
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <RetailBuyNow
                settlementStatus={"settle"}
                transactionsType={"RETAIL"}
                search={search}
                totalItem={total?.settleTotal || 0}
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>
            {/* <TabPanel value={tabIndex} index={1}>
              <RetailTP
                status=""
                totalItem={total?.retailTP || 0}
                search={search}
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel> */}
            {/* <RetailBuyNow purchaseType="INDIVIDUAL" search={search} /> */}
            {/* <TabPanel value={tabIndex} index={2}>
              flashDeal
            </TabPanel> */}
            {/* <RetailTP search={search} status="TPFLASHSALE"/> */}
            {/* <TabPanel value={tabIndex} index={3}>
              flashDealTP
            </TabPanel> */}
            {/* <RetailTP search={search} status="TPROADSHOW"/> */}
            {/* <TabPanel value={tabIndex} index={4}>
              groupDeals
            </TabPanel> */}
            {/* <RetailTP earch={search} status="VOID"/> */}
            {/* <TabPanel value={tabIndex} index={5}>
              other promo
            </TabPanel> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FinanceOrderReceiptIndex;
